import React from 'react';
import { connect } from 'react-redux';
import { RootState } from "store";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { Button, Stack, Box } from '@mui/material';
import { wsConnectorDataprocessor, wsConnectorMarket } from '../../components/system/websocket-component';
import {mainTextColor, RESOLUTION_SCREEN, WS_DATAPROCESSOR_URL, WS_MARKET_URL} from '../../config';
import { DjangoContextType, UserProfile } from '../../types/auth';
import {RouterParams} from "../../routes/router-proxy-model";
import {withRouter} from "../../components/util-components/with_router";
import {dataQualityDefaultActions} from "./data-quality.slice";
import {dataQualityApi, loadInstrumentsInfoApi} from "./data-quality.api";
import {AssetVolume} from "../../models/portfolio";
import DataQualityAnalys from "./data-quality-analys";
import {TabItem} from "../../components/tab-component";

interface ComponentProps extends RouterParams, StateProps, DispatchProps {}

class DataQualityDefaultComponent extends React.Component<ComponentProps> {


    componentDidMount() {
        const {assets} = this.props;
        const user = this.getUser();

        if (this.props.user) {
            this.initWs(this.props.user.token)
            // console.log('######################## USER ', user);
        }
        if (! assets || assets.length === 0) {
            this.props.loadInstrumentsInfo();
        }

    }

    getUser(): UserProfile | undefined | null {
        const context = this.context as DjangoContextType;
        return context.user;
    }

    initWs(token) {
        if (!wsConnectorDataprocessor.ready) {
            wsConnectorDataprocessor.url = `${WS_DATAPROCESSOR_URL}/${token}`;
            wsConnectorDataprocessor.init_receiver((payload) => {
                // console.log('################# DATA-PROCESSOR - WS PAYLOAD::', JSON.parse(payload.data || '{}'))
                // this.props.updateInstantValues(JSON.parse(payload.data || '{}'))
            });
        }
    }

    dataQuality = (data) => {
        // @ts-ignore
        this.props.dataQuality(data)
    }

    render() {
        const {   equityOverviewCurrentCaseStr,
            equityOverviewData,dataQualityResult,annualReturnsOverviewData,
            distributionMonthlyData,dailyReturnsOverviewData,monthlyReturnsOverviewData,
        rollingVolatilityData,rollingSortinoData,rollingSortinoCurrentCaseStr,
        rollingSharpeData,rollingSharpeCurrentCaseStr,underwaterPlotData,
        drawdownPeriodsData, worstDrawDownsData,keyMetricsOverviewData,taskStatus} = this.props;


        const handleChange = (item: TabItem) => {
            this.props.setSection(item);
        };
      return (
          <>
              <DataQualityAnalys assetList={this.props.assets} dataQuality={this.dataQuality}
                                 renderData={equityOverviewData}
                                 caseStr={equityOverviewCurrentCaseStr}
                                 dataQualityResult={dataQualityResult}
                                 annualReturns={annualReturnsOverviewData}
                                 distributionMothlyData={distributionMonthlyData}
                                 dailyReturnsData={dailyReturnsOverviewData}
                                 monthlyReturns={monthlyReturnsOverviewData}
                                 rollingVolatility ={rollingVolatilityData}
                                 rollingSharpe={rollingSharpeData}
                                 rollingSharpeCaseString={rollingSharpeCurrentCaseStr}
                                 rollingSortino={rollingSortinoData}
                                 rollingSortinoCaseString={rollingSortinoCurrentCaseStr}
                                 underwaterPlot={underwaterPlotData}
                                 drawdownPeriodsData={drawdownPeriodsData}
                                 worstDrawDownData={worstDrawDownsData}
                                 keyMetricksData={keyMetricsOverviewData}
                                 onChange={handleChange}
                                 taskStatus={taskStatus}
              />
          </>
      )
    }
};

const mapStateToProps = ({ dataQuality: state }: RootState) => ({
    version: '001', ...state
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    loadInstrumentsInfo:    bindActionCreators(loadInstrumentsInfoApi, dispatch),
    dataQuality:            bindActionCreators(dataQualityApi,dispatch),
    setSection:             bindActionCreators(dataQualityDefaultActions.setSection,dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const DataQualityComponent = withRouter(DataQualityDefaultComponent);

export default connect(mapStateToProps, mapDispatchToProps)(DataQualityDefaultComponent);
