import axios, { Axios, AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';
import { AsyncAction } from 'redux-promise-middleware';
import useAuth from '../hooks/useAuth';
import { restoreUser } from '../store/reducers/auth';
import { BASE_RESEARCH_URL } from '../config';

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = 'csrftoken';

const get_headers = (url: string) => {
  const headers = {
  };
  // @ts-ignore
  if (url.startsWith('_not_applied_for_now_')) {
  }
  else {
    const user = restoreUser();
    if (user && user.token) {
      headers['Authorization'] = `Bearer ${user.token}`
    }
  }
  return headers;
}

class MyAxios {
  static get(url: string) {
    return axios(url, { method: "get", headers: get_headers(url), withCredentials: true });
  }

  static post(url: string, data: any) {
    // console.log("@@@@@@@@@ XX POST::[" + url + "]; data::", data)
    return axios(url, { method: "post", headers: get_headers(url), withCredentials: true, data,timeout: 180000 });
  }

  static put(url: string, data: any) {
    return axios(url, { method: "put", headers: get_headers(url), withCredentials: true, data });
  }


  static all(rs: Promise<AxiosResponse<any, any>> []): any {
    return axios.all(rs).then(axios.spread((...responses) => {
      return responses.map(r => r.data);
    }));
  }
}

export async function doAjaxPostAsForm(url: string, values: Record<string, any>)  {
    const data = new FormData();
    for (let name in values) {
      data.append(name, values[name])
    }
    return MyAxios.post(url, data);
}

// export async function doAjaxPostAsForm(url: string, values: Record<string, any>)  {
//     const data = new FormData();
//     for (let name in values) {
//       data.append(name, values[name])
//     }
//     return MyAxios.get('https://worddict.net/materials/list/humanbody/');
// }

//https://worddict.net/perform/?text=

// for the future / for removing

// var file = document.querySelector('#file');
// formData.append("file", file.files[0]);
// // formData.append("document", documentJson); instead of this, use the line below.
// formData.append("document", JSON.stringify(documentJson));

export default MyAxios;
