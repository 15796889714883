import React, {useEffect, useState} from "react";
import {Stack, Box, Grid, Button} from "@mui/material";
import {gapDataGraphics} from "../data-quality.slice";
import WidgetInfoPanel from "../../../components/controls/info-panel";
import {mainTextColor, primaryTextColor} from "../../../config";

interface GapTableProps {
  className:string;
  legendName:string;
};
const GapTable = (props:GapTableProps) => {
        const renderData = gapDataGraphics.getCurrentGraphic();
    // console.log(renderData)
        const className = props.className;
        const legendName = props.legendName;
    return <Stack justifyContent={'center'} direction={'column'} spacing={'14px'}>
        <WidgetInfoPanel
                title={`GAPS ${legendName}`}
            infoMarkers={[]}
        />
        {renderData.length !== 0 ? <Stack>
            <table className={className}>
                <thead>
                <tr>
                    <th>Gap (sec)</th>
                    <th>Size</th>
                    <th>Date</th>
                    <th>Mode</th>
                </tr>
                </thead>
                <tbody>
                {renderData.map((item, idx) => <React.Fragment key={idx + 'qualgap'}>
                    <tr>
                        <td>{item.gap}</td>
                        <td>{item.size}</td>
                        <td>{item.date}</td>
                        <td>{item.mode}</td>
                    </tr>
                </React.Fragment>)}
                </tbody>
            </table>
        </Stack>
            :<Stack justifyContent={'center'}>
                <Box sx={{color:primaryTextColor,fontSize:'20px'}}>No Gaps</Box>
            </Stack>
        }
    </Stack>
};

export default GapTable;

