import React, {ReactNode, useEffect, useState} from 'react';
import {Box, Stack} from "@mui/material";
import {headerBackgroundColor, mainBackgroundColor} from 'config';
import {styled} from '@mui/system';


const itemsNameDefault = [' PORTFOLIO CREATOR', 'TRADE SIMULATION','PORTFOLIO OPTIMIZATION','CHARTS'];
const itemDataDefault = ['June 2023','July 2023', 'July 2023', 'August 2023']

const BoxStyled = styled(Box)({
    width:'320px',
    height:'212px',
    textAlign:'center',
    border:'1px solid' + headerBackgroundColor,
    margin:'10px',
    ":hover": {
        backgroundColor: headerBackgroundColor
    },
})

interface ToogleBoxProps  {
    children: ReactNode;
    id: number
}
const ToggleBox = (props: ToogleBoxProps) => {
    const [color, setColor]= useState(mainBackgroundColor);

    return(
         <BoxStyled key={props.id} onClick={() => setColor(headerBackgroundColor)} >{props.children}</BoxStyled>
    )
}



 const CommonComponent = () => {


    return (
        <>
            <center>
                <Box  width={'1040px'}  marginTop={'100px'} marginBottom={'100px'}>
                    <Stack  direction={'row'} flexWrap={'wrap'} >
                        {itemsNameDefault.map((item, idx) =>
                            <React.Fragment key={idx+'frag'}>
                                <ToggleBox  id={idx} key={'toogleBox' +idx}>
                                    <Box marginTop={'80px'} key={'box' + idx}>
                                        <p style={{color: 'white', lineHeight: '20px'}}>{item}</p>
                                        <p style={{color: 'white', lineHeight: '20px'}}>{itemDataDefault[idx]}</p>
                                    </Box>
                                </ToggleBox>
                            </React.Fragment>
                        )}
                    </Stack>
                </Box>
            </center>
       </>
    )
};

 export default CommonComponent;
