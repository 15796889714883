import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Box, Stack,  Typography } from '@mui/material';
import { OrangeButton, RedButton, SquareButton } from 'components/controls/nb-buttons';

import {buttonBackgroundColor, mainTextColor, primaryTextColor} from 'config';
import MiniWrapLayout from 'layout/MainLayout/mini-wrap-layout';
import { TabItem, TabsComponent } from 'components/tab-component';
import { Portfolio, PortfolioEmptyItem } from 'models/portfolio';
import { ActionMode } from '../../../pages/portfolios/portfolio.common';


interface PortfolioUpdateInfoProps {
   portfolio: Portfolio;
   title?: string;
   onRefresh: Function;
   holder: Record<string, any>;
   infoTabsOnChange?: Function;
   infoTabs?: TabItem[];
   extraCall?: Function;
}

const SectionTabItems = [
    { title: 'Model Portfolios'},
    { title: 'Client Portfolios'},
] as TabItem[];



const PortfolioUpdateInfo = (props: PortfolioUpdateInfoProps) => {
  const portfolio = props.portfolio ? props.portfolio : PortfolioEmptyItem;
  const portfolioEdited = portfolio.id !== '';
    const infoTabs = props.infoTabs || [];
  const closeDetails = () => {
    props.onRefresh({mode: ActionMode.portfolioList});
  };
  // @ts-ignore

    const onChange = (item: TabItem) => props.infoTabsOnChange ? props.infoTabsOnChange(item) : undefined;
  // const title = portfolioEdited ? 'UPDATE PORTFOLIO' : 'CREATE PORTFOLIO';

  const lineOne = <Stack sx={{width: '100%',height:'54px'}} direction="row" justifyContent="space-between" alignItems="start" >
     <span className={'titleGraphs'} >{props.title}</span>
    </Stack>

  const lineTwo = <Stack direction='row' alignItems='center'sx={{width: '100%',height:'40px'}}>
      <TabsComponent onChange={onChange} items={SectionTabItems} />
  </Stack>

  return <MiniWrapLayout lineOne={lineOne} lineTwo={lineTwo}/>
};

export default PortfolioUpdateInfo;
