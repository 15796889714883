import { ReactElement } from 'react';

// third-party
import firebase from 'firebase/compat/app';

// ==============================|| AUTH TYPES  ||============================== //

export type GuardProps = {
  children: ReactElement | null;
};

export type UserProfile = {
  id?: string;
  email?: string;
  avatar?: string;
  image?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  tier?: string;
  extra: string;
  token?: string | null;
};

export const isSuperuser = (user: UserProfile) => user.extra === '1:1:USER:none:None';

export interface AuthProps {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null;
  // token?: string | null;
}

export interface AuthActionProps {
  type: string;
  payload?: AuthProps;
}

export type DjangoContextType = {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null | undefined;
  logout: () => void;
  // login: (email: string, password: string) => Promise<void>;
  login: (email: string, password: string, extra_params?:{country:string, investor:string}) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string, registerKey: string, company: any) => Promise<any>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: VoidFunction;
};
