import React from 'react';
import {connect} from 'react-redux';
import {RootState} from "store";
import { dashboardMonitorActions, sectorIndexesGraphics, stressIndexesGraphics } from './monitor.slice';
import {
  IndicesDaterange,
  loadCandles,
  loadCorrelationData,
  loadEventNewsInfo,
  loadHeadlinesInfo,
  loadMarketCap,
  loadWorldMarketIndexes,
  loadAllSectorIndexes,
  loadAllStressIndexes,
  loadTradedInfo,
  loadUserTradedInfo,
  loadWatchList,
  toggleWatchListItem
} from './monitor.api';
import {AnyAction, bindActionCreators, Dispatch} from "redux";

import { COMPONENT_KEY_NAME } from './monitor.common';

import {Box, Container, Grid, Stack} from '@mui/material';

import {sortTradedBy, Traded} from 'models/asset';

import CandlesView from 'components/candles/candles-view';
import AssetsView from 'components/assests/assets-view';
import {TabItem} from 'components/tab-component';

import AuthContext from 'contexts/DjangoAuthContext';
import {DjangoContextType, UserProfile} from 'types/auth';
import {RouterParams} from 'routes/router-proxy-model';
import InforNewsView from 'components/markets/infonews/infonews-view';
import { WS_DATAPROCESSOR_URL, WS_MARKET_URL } from 'config';
import { wsConnectorDataprocessor, wsConnectorMarket } from 'components/system/websocket-component';

import WorldMarketsView from 'components/markets/world-markets/world-markets-view';
import SectorIndexesView from 'components/markets/indexes/sector_indexes/sector_indexes-view';
import StressIndexesView from 'components/markets/indexes/stress_indexes/stress_indexes-view';
import TableCorrelation from "../../../components/correlation/table-correlation";
import TradingV from './tv';
import { Navigate } from 'react-router-dom';
import AdvancedMarkets from "../advanced/advanced-markets";

interface ComponentProps extends RouterParams, StateProps, DispatchProps {}

class DashboardMonitorComponent extends React.Component<ComponentProps> {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
  };

  isSectorIndexesInited(sectorIndexesCurrentCaseStr: string) {
    return sectorIndexesCurrentCaseStr !== 'undef:-1';
  }

  initWs(token) {
    if (!wsConnectorMarket.ready) {
        wsConnectorMarket.url = `${WS_MARKET_URL}/${token}`;
        wsConnectorMarket.init_receiver(payload => {
          // console.log('################# FIRST PAGE - WS PAYLOAD::', JSON.parse(payload.data || '{}'))
          this.props.updateInstantValues(JSON.parse(payload.data || '{}'))
        });
    }
  }


  getUser(): UserProfile | undefined | null {
    const context = this.context as DjangoContextType;
    return context.user;
  }

  getRouter() { return this.props['router']; }
  componentDidMount() {
    const { candles,
      currentVisibleTraded,
      watchList,
      marketCap,
      worldMarketData,
      correlationData
    } = this.props;
    const user = this.getUser();

    if (user) {
      this.initWs(user.token)
    }
    if (!currentVisibleTraded  || currentVisibleTraded.length === 0) {
      this.props.loadTradedInfo({});
    }
    if (!marketCap || marketCap.length === 0) {
      this.props.loadMarketCap({})
    }
    if (! stressIndexesGraphics.isInited()) {
      this.props.loadAllStressIndexes('all');
    }

    if (! sectorIndexesGraphics.isInited()) {
      this.props.loadAllSectorIndexes('all');
    }

    if (worldMarketData.length == 0) {
       this.props.loadWorldMarketIndexes('all');
    }

    setTimeout(function() {
      // console.log('################### TIMEOUT !!!')
    }.bind(this), 1000 * 10);


    if (!correlationData || correlationData.length === 0) {
        this.props.loadCorrelationData('30day');
    }
    if (!watchList || watchList.length === 0) {
      this.props.loadWatchList({});
      this.props.loadUserTradedInfo({});
    }
    this.props.loadEventNewsInfo({});
    this.props.loadHeadlinesInfo({});
  }

  getSnapshotBeforeUpdate () {
    // const { inprogress, currentTraded, currentVisibleTraded } = this.props;
    // if(currentTraded && inprogress === 'inprogress') {
    //   this.props.loadCandles({tradedId: currentTraded.id});
    // }
    return this.props;
  }

  componentDidUpdate() {
    // const { candles, currentTraded, currentVisibleTraded } = this.props;
    // if (currentTraded && (!candles || candles.length === 0)) {
    //   this.props.loadCandles({tradedId: currentTraded.id});
    // }
  }

  reset = () => {
    this.props.reset();
  }

  setValue = () => {
    this.props.setValue('')
  }

  toggleView = () => {
    this.props.setValue({ name: 'candleView', value: this.props.candleView === 'table' ? 'graph' : 'table' });
  }

  selectTraded = (item: Traded) => {
  }

  sortBy = (field: string, sorter) => {
    this.props.sortTradedBy({field, sorter});
  }

  resolutionScreen = () => window.screen.width > window.screen.height ? 'horizontal' : 'vertical';

  render() {
    const {
      marketCap,
      traded,
      currentVisibleTraded,
      currentTraded,
      candles,
      sectorIndexesCurrentCaseStr,
      stressIndexesCurrentCaseStr,
      worldMarketsCurrentCaseStr,
        correlationCurrentCaseStr,
      correlationData,
      worldMarketData,
      currentSortField,
      candlesResponse,
      apiStatus,
      holder } = this.props;


    const candleFieldNames = candlesResponse.keys;  // actually we have from backend

    const modeInfos = this.getRouter().location.pathname.endsWith('Summary');
    const {location, params, navigate} = this.props['router'];

    const handleChange = (item: TabItem) => {
      this.props.setSection(item);
    }
    const correlationOnChange = (item: TabItem) => {
      handleChange(item)
    }

    const assetView = () => <AssetsView
              size={32}
              sortHolder={holder}
              traded={currentVisibleTraded}
              currentSectionId={this.props.currentSectionId}
              toggleWatchListItem={() => {}}
              currentTraded={currentTraded}
              currentSortField={currentSortField}
              onSectionChange={handleChange}
              selectFunction={this.selectTraded}
              sortByFunction={this.sortBy}
          />;
     // toggleWatchListItem={this.props.toggleWatchList}
    const renderMain = () => (<>
          <CandlesView currentTraded={currentTraded}  candles={candles} names={candleFieldNames}/>
          <Box sx={{display: 'flex', justifyContent: 'flex-start', color: 'blue', width: '3%'}}>&nbsp;&nbsp;</Box>
          { assetView() }
        </>);

      const renderInfos = () =>
      this.resolutionScreen() === 'horizontal' ?
        <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} marginBottom={'32px'}>
          <Grid item xs={12} sm={12} md={12} >
            <WorldMarketsView caseStr={worldMarketsCurrentCaseStr}/>
          </Grid>
          <Grid item xs={7}>
              <SectorIndexesView  caseStr={sectorIndexesCurrentCaseStr} onChange={handleChange}/>
              <Box marginTop={'80px'}>
                  <StressIndexesView caseStr={stressIndexesCurrentCaseStr} onChange={handleChange}/>
              </Box>
          </Grid>
          <Grid item xs={5}>
              { assetView() }
              <Box marginTop={'24px'}><TableCorrelation caseStr={correlationCurrentCaseStr} data={correlationData} timeRangeOnChange={correlationOnChange}
              /></Box>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*    <InforNewsView headlines={this.props.headlines} newsEvents={this.props.newsEvents}/>*/}
          {/*</Grid>*/}
      </Grid>
          : <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{
                    // height: '100%',
                    overflow: "auto",
                }}>
                    <WorldMarketsView caseStr={worldMarketsCurrentCaseStr}/>
                </Box>
              </Grid>
              <Grid item xs={12}>
                  { assetView() }
                  <Box marginTop={'32px'}>
                      <TableCorrelation caseStr={correlationCurrentCaseStr} data={correlationData} timeRangeOnChange={correlationOnChange}/>
                  </Box>
                  <Box marginTop={'38px'}>
                    <SectorIndexesView  caseStr={sectorIndexesCurrentCaseStr} onChange={handleChange}/>
                  </Box>
                  <Box marginTop={'98px'}>
                      <StressIndexesView caseStr={stressIndexesCurrentCaseStr} onChange={handleChange}/>
                  </Box>
              </Grid>
          </Grid>


    const _renderInfos = () => (<>
          <InforNewsView headlines={this.props.headlines} newsEvents={this.props.newsEvents}/>
          <Box sx={{display: 'flex', justifyContent: 'flex-start', color: 'blue', width: '3%'}}>&nbsp;&nbsp;</Box>
          { assetView() }
          </>
    );

    const advancedMarketsTable = () => (
        <>
            <AdvancedMarkets
                size={currentVisibleTraded.length}
                sortHolder={holder}
                traded={currentVisibleTraded}
                toggleWatchListItem={this.props.toggleWatchList}
                currentTraded={currentTraded}
                currentSortField={currentSortField}
                selectFunction={this.selectTraded}
                sortByFunction={this.sortBy}
                onSectionChange={handleChange}
            />
        </>
    );

    return <div>
      <Stack direction="row" justifyContent="space-between">
        {/*{ !modeInfos && renderMain()}*/}
        { modeInfos && renderInfos()}
        {location.pathname.includes('screenshot') && renderInfos()}
        {location.pathname.includes('advancedMarkets') && advancedMarketsTable()}
      </Stack>
    </div>;
  }
}


const ownSettings = {
    version: '0.01'
};


const mapStateToProps = ( { [COMPONENT_KEY_NAME]: state } : RootState) => ({
  ...ownSettings, ...state
});``

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    loadMarketCap:      bindActionCreators(loadMarketCap, dispatch),
    loadCandles:        bindActionCreators(loadCandles, dispatch),
    loadTradedInfo:     bindActionCreators(loadTradedInfo, dispatch),
    loadUserTradedInfo: bindActionCreators(loadUserTradedInfo, dispatch),
    loadWatchList:      bindActionCreators(loadWatchList, dispatch),
    toggleWatchList:    bindActionCreators(toggleWatchListItem, dispatch),
    loadEventNewsInfo:  bindActionCreators(loadEventNewsInfo, dispatch),
    // loadStressIndexes:  bindActionCreators(loadStressIndexes, dispatch),
    loadAllStressIndexes:  bindActionCreators(loadAllStressIndexes, dispatch),
    loadCorrelationData:bindActionCreators(loadCorrelationData, dispatch),
    loadWorldMarketIndexes:  bindActionCreators(loadWorldMarketIndexes, dispatch),
    loadAllSectorIndexes:  bindActionCreators(loadAllSectorIndexes, dispatch),
    loadHeadlinesInfo:  bindActionCreators(loadHeadlinesInfo, dispatch),
    reset:              bindActionCreators(dashboardMonitorActions.reset, dispatch),
    setValue:           bindActionCreators(dashboardMonitorActions.setValue, dispatch),
    setSection:         bindActionCreators(dashboardMonitorActions.setSection, dispatch),
    sortTradedBy:       bindActionCreators(dashboardMonitorActions.sortTradedBy, dispatch),
    updateInstantValues:bindActionCreators(dashboardMonitorActions.updateInstantValues, dispatch)
    // setHideCookie: bindActionCreators(dashboardMonitorActions.setHideCookie, dispatch)
});


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(DashboardMonitorComponent);
