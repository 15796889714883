import React, { useState } from 'react';
import { Box, Button, ButtonGroup, Grid, Paper, Stack,  Typography } from '@mui/material';
import { Traded, TradedEmptyItem } from 'models/asset';
import { OrangeButton, SquareButton } from 'components/controls/nb-buttons';

import { InfoElement } from 'components/controls/elements';
import {buttonBackgroundColor, mainBackgroundColor, mainTextColor, primaryTextColor} from '../../config';
import MiniWrapLayout from 'layout/MainLayout/mini-wrap-layout';
import { TickerVolume } from './values';
import {MainOrangeButton, TabItem, TabsComponent} from '../tab-component';
import {RightOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";


interface CandlesInfoProps {
   traded:  Traded | null;
   onSectionChange: (item: TabItem) => void;
   onTimeRangeChange: (item: TabItem) => void;
   currentSectionId: string;
}

export const AssetSections = {
    crypto:     'crypto',
    watchlist:  'watchlist',
    index:      'index',
    commodity:  'commodity',
    correlation:'correlation',
    cross:  'cross',
    forex:  'forex'
}

const SectionTabItems = [
    { title: 'Crypto',    id: AssetSections.crypto},
    { title: 'Equity',     id: AssetSections.index},
    { title: 'Commodity', id: AssetSections.commodity},
    { title: 'Forex',     id: AssetSections.forex},
    { title: 'Watchlist', id: AssetSections.watchlist},
] as TabItem[];

const DatesTabItems = ['24H', 'W', 'M', 'Y', 'YTD'];

const AssetsInfo = (props: CandlesInfoProps) => {
  const traded = props.traded ? props.traded : TradedEmptyItem;
  const onChange = () => {};
  const navigate = useNavigate();
  const onClickOverview = () => {
      navigate('advancedMarkets');
      props.onSectionChange( { title: 'Crypto',    id: AssetSections.crypto});
  };

  const lineOne = <Stack sx={{width: '100%'}} direction="row" justifyContent="space-between" alignItems="start">
      <span className={'titleGraphs'}>MARKETS</span>
      <MainOrangeButton onClick={onClickOverview} title={'Overview'}/>
    </Stack>;

  const lineTwo = <Stack direction='row' sx={{ width: '100%'}}  alignItems='left' gap={1}>
    <TabsComponent currentTabId={props.currentSectionId} onChange={props.onSectionChange} items={SectionTabItems}/>
  </Stack>


  return<>
      <MiniWrapLayout lineOne={lineOne} lineTwo={lineTwo}/>
      {/*<Stack direction={'row'} alignItems={'center'} justifyContent={'center'} style={{width: '679px', marginTop: '12px'}} spacing={4}>*/}
      {/*    <Box marginLeft={'90px'} marginBottom={'4px'}><TabsComponent width={'20%'} variant={'HMWY'} onChange={props.onTimeRangeChange} items={DatesTabItems} applyBorder={true}/></Box>*/}
      {/*    <Box style={{color:'#AEA49B', fontSize: '12px'}}>Newbridge Market Pulse</Box>*/}
      {/*</Stack>*/}
  </>
};

export default AssetsInfo;
