import React, {createRef, useEffect, useRef, useState} from 'react';
import {createChart, ColorType, TickMarkType, BarPrice} from 'lightweight-charts';
import {buttonBackgroundColor, mainBackgroundColor, mainTextColor} from "../../../config";
import {Stack, Box} from '@mui/material';
import {TabItem} from "../../../components/tab-component";
import {dateSerializerNew} from "../../../components/controls/form/nb-data-picker";
import {fullYearDateFormatter} from "../../../utils/date-utils";

interface TrandingMarkets {
    renderData?: any;
    graphics?: TabItem[];
    width?:number;
    height?: number;
    timeRange?:string;
    infoTabs?: string;
    priceFormatter?: Function;
    tooltipFormatter?:Function;
    portfolioName?:string;
    benchmark?:string| undefined;
    changebackground?:boolean;
    classId?:string;
    caseStr?:string;
}
const TrandingViewThreeLine = (props: TrandingMarkets) => {
    const chartContainerRef = useRef <HTMLDivElement>(null);
    const tooltipRef = useRef <HTMLDivElement | null>(null);
    const graphics = props.graphics || [];
    const refsSeries = useRef([]);
    const [currentLegend, setCurrentLegend] = useState(0);
    const timeRange = props.timeRange || '';
    const [linePrice, setLinePrice] = useState([]);
    const [currentTime, setCurrentTime] = useState(null);
    const [hideSeries,setHideSeries] = useState(true);
    const idSeries = graphics.map((item) => item.id);
    const graphColors= graphics.map((item) => item.graphColor);
    refsSeries.current = [];
    const addToRefs = (el) => {
        // @ts-ignore
        if(el && !refsSeries.current.includes(el)) {
            // @ts-ignore
            refsSeries.current.push(el)
        }
    };

    refsSeries.current.map((ref:any, idx) => {
        ref.onClick(()=> setHideSeries(!hideSeries))
    });

    const myPriceFormatter = (price) => {return (price).toFixed(2)};
    const currentPriceFormatter = props.priceFormatter ? props.priceFormatter :  myPriceFormatter;
    const tooltipPriceFormatter = props.tooltipFormatter ? props.tooltipFormatter : myPriceFormatter;

    useEffect(() => {

        // @ts-ignore
        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: mainBackgroundColor },
                textColor: '#AEA49B',
                fontSize: 12,
                fontFamily: 'Noto Sans',
            },
            leftPriceScale: {
                visible:true,
                entireTextOnly:true,
                minimumWidth:50
            },
            rightPriceScale: {
                visible:false,
            },
            grid: {
                vertLines: {
                    visible: false
                },
                horzLines: {
                    color: '#AEA49B',
                    style:4
                }
            },
            crosshair: {
                vertLine:{
                    labelBackgroundColor: mainBackgroundColor,
                    labelVisible:false,
                },
                horzLine: {
                    labelBackgroundColor: mainBackgroundColor,
                    labelVisible:false,
                }
            },
            width: props.width,
            height: props.height,
            localization:{
                priceFormatter: currentPriceFormatter,
            },
        });

        chart.timeScale().fitContent();
        const timeScaleRange = timeRange === 'day' ? 4 : 5;
        chart.timeScale().applyOptions({
            fixLeftEdge:true,
            fixRightEdge:true,
            timeVisible:false,
            tickMarkMaxCharacterLength: timeScaleRange,
        });

        requestAnimationFrame(() => {
            const visibleLogicalRange =  chart.timeScale().getVisibleLogicalRange();
            if(visibleLogicalRange !== null) {
                chart.timeScale().setVisibleLogicalRange({
                    from:0.2,
                    to:  visibleLogicalRange.to - 0.3,
                })
            }
        } );





        const lineSeries = chart.addLineSeries({
            color: graphColors[0],
            lineWidth:2,
            visible:hideSeries,
            priceLineVisible:false,
            lastValueVisible: false,
        });
        const lineSeriesTwo = chart.addLineSeries({
            color: graphColors[1],
            lineWidth:2,
            priceLineVisible:false,
            lastValueVisible: false,
        });
        const lineSeriesThree = chart.addLineSeries({
            color: graphColors[2],
            lineWidth:2,
            priceLineVisible:false,
            lastValueVisible: false,
        });

        const dashedLine = chart.addLineSeries({
            color:graphColors[3],
            lineWidth:2,
            lineStyle:2,
            priceLineVisible:false,
            lastValueVisible: false,
        })


        for(let key in props.renderData) {
            if(key === idSeries[0]) {
                lineSeries.setData(props.renderData[key])
            }
            if(key === idSeries[1]) {
                lineSeriesTwo.setData(props.renderData[key])
            }
            if(key === idSeries[2]){
                lineSeriesThree.setData(props.renderData[key])
            }
        };




        chart.subscribeCrosshairMove((param) => {

            if(tooltipRef.current && chartContainerRef.current !== null) {
                // @ts-ignore
                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > chartContainerRef.current.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > chartContainerRef.current.clientHeight
                ){
                    tooltipRef.current.style.display = 'none'
                } else {
                    // @ts-ignore
                    const data = [param.seriesData.get(lineSeries), param.seriesData.get(lineSeriesTwo),param.seriesData.get(lineSeriesThree)]
                    if(data !== undefined) {
                        tooltipRef.current.style.display = 'block';

                        // @ts-ignore
                        const coordinate = param.point.y + 50;
                        const shiftedCoordinate = param.point.x > chartContainerRef.current.clientHeight - 100 ? param.point.x + -140 : param.point.x + 60;
                        tooltipRef.current.style.left = shiftedCoordinate + 'px';
                        tooltipRef.current.style.top = coordinate + 'px';
                        // @ts-ignore
                        setLinePrice(data);
                        // @ts-ignore
                        setCurrentTime(fullYearDateFormatter.format(new Date(param.time * 1000)));
                    }
                }
            }
        });

        return () => {
            chart.remove()
        };


    },[hideSeries,props.infoTabs,props.timeRange,props.caseStr]);

    const priceValue = linePrice.map((item: {value:number,time:number}) => {
        if(item !== undefined && item.value !== undefined) {
            return tooltipPriceFormatter(item.value)
        }
    });
    const borderC = priceValue.length === 0 ? '' : '1px solid'+ buttonBackgroundColor;

    return <div ref={chartContainerRef} style={{position:'relative', marginTop:'0px'}}>
        <Stack direction={'row'} spacing={2} style={{marginBottom: '10px'}}>
            {graphics.map((item,idx) => <React.Fragment key={'legendWrraper' + idx}>
                <Stack ref={addToRefs} style={{
                    color:'#AEA49B',
                    marginBottom: 10,
                }}
                   direction={'row'}
                   alignItems={'center'}
                   spacing={1}
                   key={'legendSeries'+idx}
                   id={`${item.id}`}
                >
                    {item.id?.includes('mean') ? <>
                        <Stack direction={'row'} spacing={0.5}>
                            <Box style={{width:5, height:2.8,background: item.graphColor}} key={'legendMark1' +idx}></Box>
                            <Box style={{width:5, height:2.8,background: item.graphColor}} key={idx + 'legendMark21'}></Box>
                            <Box style={{width:5, height:2.8,background: item.graphColor}} key={idx+'legendMark555'+idx}></Box>
                        </Stack>
                        <Box key={'legengTitle'}>{item.title} </Box>
                        </>
                    : item.id === 'clientPortfolio' && props.portfolioName ? <>
                                <Box style={{width:20,height:2.8,background: item.graphColor}} key={'legendMark'}></Box>
                                <Box key={'legengTitle'} >{props.portfolioName}</Box>
                            </>
                    :item.id === 'benchmark' && props.benchmark !== 'No Benchmark'  && props.benchmark !== undefined ?
                        <>
                            <Box style={{width:20, height:2.8,background: item.graphColor}} key={'legendMark'}></Box>
                            <Box key={'legengTitle'} >{props.benchmark}</Box>
                        </>
                    :item.id !== 'benchmark' ?
                        <>
                            <Box style={{width:20, height:2.8,background: item.graphColor}} key={'legendMark'}></Box>
                            <Box key={'legengTitle'} >{item.title}</Box>
                        </>
                    : <></>}
                </Stack></React.Fragment>
            )}
        </Stack>
        {/*{ priceValue.length !== 0 &&*/}
        <Stack ref={tooltipRef} style={{
            position:'absolute',
            border:borderC,
            borderRadius: '5%',
            zIndex: 1000,
            color:'#AEA49B',
            background:mainBackgroundColor,
            opacity:0.9,
            padding: 5,
            fontSize: 12,
        }} alignItems={'center'} direction={'column'}>
            <Box marginBottom={'5px'}>{currentTime}</Box>

            {graphics.map((el,idx) => <React.Fragment key={'ittitle' + idx}>
                { el.id === 'clientPortfolio' && priceValue[idx] !== undefined ? <Stack direction={'row'} justifyContent={'space-between'}>
                        <Box key={idx +'texttitle81'} style={{color: el.graphColor}}>{props.portfolioName} </Box>
                        <Box key={idx +'texttitle2'} style={{color: el.graphColor, marginLeft:'20px'}}> {priceValue[idx]}</Box>
                    </Stack>
                :el.id === 'benchmark' && props.benchmark !== 'No Benchmark' && priceValue[idx] !== undefined ?<Stack direction={'row'} justifyContent={'space-between'}>
                        <Box key={idx +'texttitle97'} style={{color: el.graphColor}}>{props.benchmark} </Box>
                        <Box key={idx +'texttitle4'} style={{color: el.graphColor, marginLeft:'20px'}}>{priceValue[idx]}</Box>
                    </Stack>
                :el.id !== 'benchmark' && priceValue[idx] !== undefined ?<Stack direction={'row'} justifyContent={'space-between'}>
                        <Box key={idx +'texttitle5'} style={{color: el.graphColor}}>{el.title} </Box>
                        <Box key={idx +'texttitle10'} style={{color: el.graphColor, marginLeft:'20px'}}>{priceValue[idx]}</Box>
                    </Stack>
                : <></>}
            </React.Fragment>)}

        </Stack>
    </div>


};

export default TrandingViewThreeLine;
