export const skipWeekOfDay = dataStr => {
  const params = dataStr.split(' ');
  return params.slice(1).join(' ');
}

export const skipMonthDay = dataStr => {
  const params = dataStr.split(' ').slice(0,3);
  return params.slice(0,2).join(' ');
}

export const dateToString = (date: Date) =>
    date ? skipWeekOfDay(date.toDateString()) : '';

export const timestampToString = (timestamp: number) =>
    timestamp ? skipWeekOfDay(timestampToDate(Math.floor(timestamp)).toDateString()) : '';


export const dateToTimestamp = (date: Date): number => date.getTime();
export const timestampToDate = (timestamp: number): Date => new Date(timestamp * 1000);

export  const as_timestamp = (datestr: string) => {
  const base = '2024-03-31'
  if (datestr.length > base.length) {
    datestr = datestr.split(' ').join('T') + 'Z';
  }
  return Date.parse(datestr);
};

const getLastSundayOfMonth = (year, month) => {
  const lastDayOfMonth = new Date(year, month + 1, 0);
  const dayOfWeek = lastDayOfMonth.getDay();
  return new Date(year, month, lastDayOfMonth.getDate() - dayOfWeek);
};

export const isDaylightSavingTimeEurope = () => {
  const currentDate = new Date();
  // const  currentDate = new Date("2024-10-30T00:00:00");
  const october = getLastSundayOfMonth(currentDate.getFullYear(),9);
  const march = getLastSundayOfMonth(currentDate.getFullYear(),2);
  const isDaylightSaving =  currentDate >= march && currentDate < october ? 'Summer Time' : 'Winter Time';
  return isDaylightSaving;
};


export const fullYearDateFormatter = new Intl.DateTimeFormat('en',{day:'2-digit',month: 'short',year: 'numeric',timeZone:'UTC'});
export const xAxisMonthDateFormatter = new Intl.DateTimeFormat('en', {month: 'short',day: '2-digit',timeZone:'UTC'});
export const timeFormatter = new Intl.DateTimeFormat('en', {hour: 'numeric',minute: 'numeric',hourCycle:'h23',timeZone:'UTC'});
export const timeSecFormatter = new Intl.DateTimeFormat('en', {hour: 'numeric',minute: 'numeric',second:'numeric',hourCycle:'h23',timeZone:'UTC'});