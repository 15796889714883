import React, { ReactElement, useState } from 'react';
import { Stack, Box } from '@mui/material';
import WidgetInfoPanel from 'components/controls/info-panel';
import { TabItem } from 'components/tab-component';
import { timeFormat} from "d3-time-format";
import {
  ASIA,
  getDefaultGraphId,
  getGraphicColours,
  getGraphicIds,
  getTabsGraphsInfo,
  WORLD_MARKETS_PANEL_ID,
  WORLDWIDE
} from 'data/graphics';

import ValueGraph, { defaultValueGraphSettings } from 'components/graphs/value-graph';
import WorldMarketsGraphSelectPanel from './world-markets-graph-selector';
import { XTick } from '../../../@react-financial-charts/axes';
import { createReflector, SizedDiv } from 'components/controls/sizer';
import { sectorIndexesGraphics, worldMarkerGraphics } from '../../../pages/dashboard/monitor/monitor.slice';
import { StraightLine, LineSeries } from '../../../@react-financial-charts/series';
import {zipAsDict} from "../../../utils/misc";
import TestMarketsGraph from "../../../pages/dashboard/monitor/test-markets-graph";
import apexAreaChart from "../../../sections/charts/apexchart/ApexAreaChart";
import {RESOLUTION_SCREEN} from "../../../config";


interface WordMarketsProps {
    // renderData?: any [],   // renderData getting from 'worldMarkerGraphics'
    onRefresh?: Function;
    caseStr: string,
};

const marketInfoTabs = [
    { title: 'WORLDWHILE',id: 'WORLDWHILE'},
    { title: 'ASIA',      id: 'ASIA'},
    { title: 'EUROPE',    id: 'EUROPE'},
    { title: 'US',        id: 'US'},
    { title: 'CRYPTO',    id: 'CRYPTO'},
] as TabItem[];


const emptyRenderData = () => {
  return []
};
const resolutionGraphHeight = RESOLUTION_SCREEN === 'horizontal' ? 334 : 250;
const resolutionGraphWidth = RESOLUTION_SCREEN === 'horizontal' ? 1410 : 850;

const WorldMarketsView = (props: WordMarketsProps) => {
  const renderData  = worldMarkerGraphics.getCurrentGraphic();
  const caseStr = props.caseStr;

  const panelId = WORLD_MARKETS_PANEL_ID;
  const initialGraphicId = WORLDWIDE;
  const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
  const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
  const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);

  const reflector =  createReflector();


  const actualRenderData = renderData && renderData.length > 0 ? renderData : emptyRenderData();
    // console.log('actualRenderData',actualRenderData)

    const lastPriceIndex = (seriesIndex) => {
        const prices =  dataRender().map((item) => item.data.map((el) => el.y))[seriesIndex];
        const last = prices.filter((item) => item !== null);
        const value = last[last.length - 1];
        const lastValue = prices.lastIndexOf(value);
        return lastValue;
    };
    const lastPriseValue = (seriesIndex,lastOrBeforLast) => {
        const prices =  dataRender().map((item) => item.data.map((el) => el.y))[seriesIndex];
        const last = prices.filter((item) => item !== null);
        const value = (last[last.length - lastOrBeforLast] );
        return value;
    };
  const dataRender = () => {
    const resultForApex = graphics().map((item,idx) => {
        if(actualRenderData !== undefined) {
            return{
                name:item.title,
                data:actualRenderData.map((el,i) => el[`${item.id}`] === undefined
                    ? {x:el.date.slice(11,16),y:null} : {x:el.date.slice(11,16),y:el[`${item.id}`]}),
                type:'line',
                // categories:actualRenderData.map((el,i) => el['date'])
            }
        } else {
            return {
                name:item.title[0],
                data: [0,0,0,0,0],
                type:'line',
            }
        }
    });
    return resultForApex;
  };



  return <Stack sx={{width: {xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'}}} direction="column" alignItems="top">
      <SizedDiv width={'100%'} reflector={reflector}>
      </SizedDiv>
      <WidgetInfoPanel
        id={panelId}
        title={'WORLD MARKETS'}
        infoTabs={[]}
        onRefresh={props.onRefresh}
        infoMarkers={[]}
      />
      <WorldMarketsGraphSelectPanel infoTabs={graphics()}/>

      <TestMarketsGraph
          height={resolutionGraphHeight}
          width={resolutionGraphWidth}
          series={dataRender()}
          graphics={graphics()}
          categories={actualRenderData}
          lastPriceIndex={lastPriceIndex}
          lastPriseValue={lastPriseValue}
      />
  </Stack>
};

export default WorldMarketsView;
