import { COMPONENT_KEY_NAME } from "./portfolio.common";
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'api';
import { BASE_ASYNC_URL, BASE_DJANGO_URL, BASE_RESEARCH_URL } from 'config';
import { ID } from 'models/common';
import { getCachedValue, setCachedValue } from 'utils/local_storage';
import MyAxios from 'api';


/**
 * loadPortfoliosInfoApi
 * @param traded
 *
 * [
 *     {
 *         "portfolio": {
 *             "name": "Chainlink-WrappedBitcoin-Dogecoin"
 *         },
 *         "assets": [
 *             {
 *                 "asset": "16acbdef-3333-4444-5555-000000000005",
 *                 "value": 33.33
 *             },
 *             {
 *                 "asset": "16acbdef-3333-4444-5555-000000000004",
 *                 "value": 33.33
 *             },
 *             {
 *                 "asset": "16acbdef-3333-4444-5555-000000000002",
 *                 "value": 33.33
 *             }
 *         ]
 *     },
 * ]
 */

export type AssetInputType = {
  id: ID,
  assetId: number,
  value: number
  instrumentId: number;
  instrumentWeight: number;
}

export type PortfolioInputType = {
    id: ID;
    originalId?: string;
    name: string;
    created: number;
    updated: number;
    instruments: any[];
    assets: AssetInputType[];
    benchmark: AssetInputType;
    indicators: number;
    inceptionDate:number;
    currency:string;
}

// @see https://github.com/newbridge-dev/nb_slate/blob/main/source/index.html.md#simulation-reply
export type simulationReply = {
  // msg: string,  // === "simulation-reply"
  // ts: number,
  // seqn: number,
  //
}

/*
{
  "msg" : "simulation-reply",
  "ts" : 1669030576237984,
  "seqn" : 178,
  "client-id" : "CLIENT_1",
  "risk-free" : "0.5",
  "rebalance-interval" : "EOW",
  "date-start" : "2022-08-29",
  "date-end" : "2023-08-28",
  "result" : "success",
  "instruments" : [
    {
     "symbol" : "BTC-USDT",
     "instrument-weight" : "0.105",
     "data" : "134987.11"
    },
    ...
    {
      ...
    }
  ]
}
 */

const candles = (traded: number) => axios.get(`${BASE_ASYNC_URL}/api/candles/${traded}`)

const getAssets = () => axios.get(`${BASE_DJANGO_URL}/portfolio/assets/`);
// const getInstruments = () => axios.get(`${BASE_DJANGO_URL}/portfolio/instruments/`);
const getInstruments = () => axios.get(`${BASE_ASYNC_URL}/api/portfolio/instruments`);
// const getPortfolios = () => axios.get(`${BASE_DJANGO_URL}/portfolio/`);


// const getPortfolios = () => axios.get(`${BASE_DJANGO_URL}/portfolio/`);
const getPortfolios = () => MyAxios.get(`${BASE_DJANGO_URL}/portfolio/`);






export const simulatePortfolioApi = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/simulatePortfolio`,
  async (data) => {
    data['performMode'] = 'async';
    const response = await axios.post(`${BASE_RESEARCH_URL}/portfolio/simulate`, data);
    return response['data'] as simulationReply;
  }
);


export const loadPortfoliosInfoApi = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/portfolios`,
  async (thunkAPI) => {
    const data = await getPortfolios()
    return data['data']['data'] as PortfolioInputType[];
    }
  );


export const loadInstrumentsInfoApiFunct = () => {
  const marker = `${COMPONENT_KEY_NAME}/instruments`;

  return createAsyncThunk(
  marker,
  async (thunkAPI) => {
    const cachedData = getCachedValue(marker);
    if (cachedData) {
      return cachedData;
    }
    const data = await getInstruments();
    const value = data['data']
    setCachedValue(marker, value);
    return value;
    }
  );
}

export const loadInstrumentsInfoApi = loadInstrumentsInfoApiFunct();

export const updatePortfolioApi = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/updatePortfolio`,
  async (data) => {
    const response = await axios.post(`${BASE_DJANGO_URL}/portfolio/update/`, data);
    return response['data'] as PortfolioInputType;
  }
);

export const removePortfolioApi = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/removePortfolio`,
  async (data) => {
    const response = await axios.post(`${BASE_DJANGO_URL}/portfolio/remove/`, data);
    return response['data'];
  }
);
