import React, {useEffect,useRef} from 'react';
import useState from 'react-usestateref'
import {
  Button,
  Stack,
  Box,
  TextField,
  Typography,
  InputAdornment
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {
  buttonBackgroundColor, errorTextColor,
  extraLogging,
  headerBackgroundColor,
  mainBackgroundColor,
  mainTextColor,
  primaryTextColor, REACT_APP_MAX_INSTRUMENTS, RESOLUTION_SCREEN,
  secondaryColdShadeBgColor
} from 'config';
import {MainOrangeButton, TabItem} from 'components/tab-component';
import { AssetVolume, AssetVolumeEmptyItem, Portfolio, PortfolioEmptyItem } from 'models/portfolio';
import { ActionMode, initialEmptyAssetName } from 'pages/portfolios/portfolio.common';
import { styled } from '@mui/material/styles';
import {EditOutlined, DeleteOutlined, RightOutlined} from '@ant-design/icons';
import { floatRound, normaliseAllocating } from 'utils/finance-utils';
import { RefHoster } from 'components/controls/form/ref-hoster';
import { Asset, ASSET_DUMMY_ID, AssetEmptyItem } from 'models/asset';
import { MyNewAutocomplete } from 'components/controls/form/autocomplete-new';
import { MyNumberField, MyTextField } from 'components/controls/form/textfield';
import { useDispatch } from 'react-redux';
import {adjustPossibleAssetOptions} from 'pages/portfolios/portfolio.slice';
import ConfirmDialog from 'components/dialogs/dialog';
import {ASSETS_FOR_PORTFOLIO} from "data/portfolio_assets";
import Autocomplete from "@mui/material/Autocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import PortfolioInsertTable from "../portfolio-insert/portfolio-insert-table";
import {getColorClass,getClassName,totalClassPct,titleClass} from "pages/portfolios/helper";
import {useNavigate} from "react-router-dom";
import {DateValidationError} from "@mui/x-date-pickers/models";
import {dateSerializerNew, NbDatePicker} from "../../controls/form/nb-data-picker";
import DateRangeForm from "../../controls/form/date-range";
import {$$} from "../../../utils/utils";
import DiscreteSliderMarks from "../../controls/form/discrete-slider-marks";


interface PortfolioUpdateProps {
   possibleAssets: Asset[],
    possibleBenchmark:Asset[],
   portfolio: Portfolio;
   currentAssetVolume: AssetVolume,
   onRefresh: Function;
   holder: Record<string, any>;
   onComplete: Function;
   onRemove: Function;
   currentControlDate: string;
   portfolioList:Portfolio[];
};

interface AllocationNum {
  name:string;
};

const SectionTabItems = [
] as TabItem[];

const resolutionAutocompleteWidth = RESOLUTION_SCREEN === 'horizontal' ? '400px' : '200px';
const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';
const resolutionStackDirection = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';
const resolutionStackSpacing = RESOLUTION_SCREEN === 'horizontal' ? '56px' : '0px';
const resolutionMargin = RESOLUTION_SCREEN === 'horizontal' ? '180px' : '0px';
const resolutionBtnHeight = RESOLUTION_SCREEN === 'horizontal' ? '24px' : '18px';
const resolutionWithdTd = (place) => {
  return place === 'first' ? {xs:'65%',md:'84%',lg:'84%'}
        :place === 'second' ? {xs:'21%',md:'11%',lg:'11%'}
        :place === 'third' ? {xs:'14%',md:'5%',lg:'5%'}
        : {xs:'14%',md:'5%',lg:'5%'}
};

const HeaderTable = styled(Box) ({
    textAlign:'left',
    paddingRight:'8px',
    paddingLeft:'8px',
    height: '28px',
    fontWeight: 500,
    fontSize: resolutionFontSize,
    color: mainTextColor,
    paddingTop:'2px',
    paddingBottom:'2px',
});

const TDTable = styled(Box) ({
    paddingRight:'16px',
    paddingLeft:'8px',
    textAlign:'left',
    alignItems:'center',
    color: mainTextColor,
    backgroundColor: headerBackgroundColor,
    fontWeight: 500,
    fontSize: resolutionFontSize,
    borderBottom:'1px solid' + mainBackgroundColor,
    height: '27px',
    // height: '48px',
    paddingTop:'2px',
    paddingBottom:'2px',
});

const PercentField = (props) => <TextField
          required
          style={{width: '100%'}}
          type="number"
          onChange={props.onChange}
          inputRef={props.inputRef}
          defaultValue={props.value}
          id="outlined-basic"
          size={'small'}
          variant="outlined"
        />;

const RemovedField = (props) => <TextField
          disabled
          style={{width: '100%'}}
          defaultValue={props.value}
          size={'small'}
          variant="outlined"
        />;

const MIN_DEFAULT_VALUE = 0.01;
const SELECT_ASSET_REF_NAME = '_asset_ref_name';
const ENTER_ALLOCATION_REF_NAME = '_allocation_ref_name';
const ENTER_CURRENCY_REF_NAME = '_currency_ref_name';
const allocationRefName = (name) => {
  return name
}
// const NumberRefName = allocationRefName()
const portfolioNameFieldId = 'portfolioName';
const referenceCurrency = [
  {name:'CHF',  id:'CHF'},
  {name:'USD',  id:'USD'},
  // {name:'EUR',  id:'EUR'},
];

export const initialEmptyAsset = {...AssetEmptyItem, name: initialEmptyAssetName()} as AssetVolume;


export const composePortfolioName = (names: string[]): string => {
  const MAX_ASSETS_FOR_NAME = 4;
  if (names.length > MAX_ASSETS_FOR_NAME) {
      names = names.slice(0, MAX_ASSETS_FOR_NAME-1);
  }
  const name = names.join('-').replace(/\s/g, '');
  return '' + name;
};

const PortfolioUpdate = (props: PortfolioUpdateProps) => {
  const dispatch = useDispatch();

  const assetsValues = props.portfolio.assets;
  const getValue = (value: number, minValue= 0) =>  {
    const v = Number(value);
    return isNaN(v) ? 0 :  Math.max(v, minValue);
  };

  const currentAssetVolume = props.currentAssetVolume as AssetVolume;
  const possibleAssets = props.possibleAssets;
  const portfolioName = props.portfolio ? props.portfolio.name : '';
  const [assetValues, setAssetValues] = useState(assetsValues);
  const [currentEditableAssetVolume, setCurrentEditableAssetVolume] = useState(AssetVolumeEmptyItem);

  const noBenchmarkAsset = {id: -1, name: 'No Benchmark', symbol: '*', value: 0};
  const possibleBenchmark = [noBenchmarkAsset, ...props.possibleBenchmark];
  const portfolioBenchmark = props.portfolio.benchmark !== null ? possibleBenchmark.find((it) => {
    if(it.id === props.portfolio.benchmark.instrumentId) {
      return it;
    }
  }) : undefined;
  const defaultBenchmark = portfolioBenchmark !== undefined ? portfolioBenchmark : noBenchmarkAsset;
  const [selectBenchmark, setSelectBenchmark] = useState(defaultBenchmark);

  const emptyPossibleAssets = [] as Asset[];
  const emptyAssetVolumes = [] as AssetVolume[];
  const [removedAssets, setRemovedAssets] = useState(emptyAssetVolumes);
  const [removeDialogUp, setRemoveDialogUp] = useState(false);
  const [skipChangesDialogUp, setSkipChangesDialogUp] = useState(false);
  const hasAssetsRemoved = () => removedAssets.length > 0;
  const portfolioEdited = props.portfolio.id !== '';
  const portfolioList = props.portfolioList;
  const [isError, setIsError] = useState(false);
  const [allocationError, setAllocationError] = useState(false);
  const [allocationId, setAllocationId] = useState('');
  const [defaultValue, setDefaultValue] = useState(currentAssetVolume);
  const [selectedForEdit, setSelectedForEdit] = useState(false);
  const portfolioCurrency = referenceCurrency.find((item) => item.name === props.portfolio.currency);
  const defaultCurrency  = portfolioCurrency !== undefined ? portfolioCurrency : {name:'CHF',  id:'CHF'};
  const [currency, setCurrency] = useState(defaultCurrency);


  const dateStart = props.portfolio._dateStart;
  const dateEnd = props.portfolio._dateEnd;
  const currentControlDate = new Date(props.currentControlDate || new Date().toISOString());
  const portfolioInceptionDate = dateSerializerNew(new Date(props.portfolio.inceptionDate * 1000));
  const defaultInceptionDate = props.portfolio.inceptionDate === undefined ? dateSerializerNew($$.getToday())
      :props.portfolio.inceptionDate === null ? dateSerializerNew($$.getOneYearAgo())
      :portfolioInceptionDate;

  const [currentInceptionDate, setInceptionDate] = useState(defaultInceptionDate);

  const [sliderValue,setSliderValue] = useState(0);
  const [error, setError] = React.useState<DateValidationError | ''>('');

  const errorMessageDate = React.useMemo(() => {
    switch (error) {
      case "minDate"      : {return 'Select a date'}
      case 'invalidDate'  : {return 'Date is not valid'}
      case 'maxDate'      : {return 'Select a date'}
      default             : {return ''}
    }
  }, [error]);


  const onChangeInceptionDate = (newValue) => {
    setInceptionDate(newValue);
  };

  const countOfAssets = () => assetValues.length;

  const valuesRefs = new RefHoster();

  const getInputValue = (id: string, defaultValue:any = '') => valuesRefs.getInputValue(id, defaultValue);
  const setInputValue = (id: string, value) => {
    valuesRefs.setInputValue(id, value)
  };


  const removeAsset = (asset: AssetVolume) => {
    setRemovedAssets([...removedAssets, asset]);

    const newAssets = assetValues.filter(it => it.id !== asset.id);
    setAssetValues([...newAssets]);
    dispatch(adjustPossibleAssetOptions({del: [], add: [asset.id]}));
  }

  const restoreLastRemovedAsset = () => {
    if (removedAssets.length > 0) {
      const idx = removedAssets.length - 1;
      const newRemovedAssets = removedAssets.filter((a, index) => index !== idx);
      const assetForRestoring = removedAssets.find((a, index) => index === idx) as AssetVolume;
      setRemovedAssets([ ...newRemovedAssets ]);
      setAssetValues([...assetValues, assetForRestoring]);
      selectForEditAsset(assetForRestoring);
    };
  };

  const selectForEditAsset = (asset: AssetVolume) => {
    const idForDel = defaultValue.id === ASSET_DUMMY_ID ? null : defaultValue.id;
    selectAsset(asset);
    setSelectedForEdit(true);
    setInputValue(ENTER_ALLOCATION_REF_NAME, asset.value);
    dispatch(adjustPossibleAssetOptions({del: [idForDel], add: [asset.id]}));
  };


  const totalPercentage = () => {
    const summa = assetValues.map(it =>
        Number(it.value)).reduce((a, b) => getValue(a) + getValue(b), 0);
    return floatRound(summa, 2);
  };


  const navigate = useNavigate();
  const closeDetails = () => {
    props.onRefresh({mode: ActionMode.portfolioList});
    navigate('');
  };

  const isContentNotChanged = (): boolean => {
    const portfolio = props.portfolio;

    const { name, assets,benchmark,inceptionDate} = collectCurrentContent();
    if (portfolio.name !== name || portfolio.assets.length !== assets.length) {
      return false;
    }
    if(portfolio.benchmark !== benchmark) {
      return false;
    }
    if(portfolio.inceptionDate !== inceptionDate) {
      return false;
    }
    for (let it of portfolio.assets) {
      const asset = assets.find(asv => asv.id === it.id) as AssetVolume;
      if (!asset || asset.value !== it.value) {
        return false;
      }
    }
    return true;
  };

  const checkClose = () => {
    if (isContentNotChanged()) {
      closeDetails();
    }
    else {
      setSkipChangesDialogUp(true);
    }
  };

  const skipChangesConfirm = () => {
    setSkipChangesDialogUp(false);
    closeDetails()
  };

  const skipChangesCancel = () => {
    setSkipChangesDialogUp(false);
  };


  const resetSelectedOption = () => {
    setDefaultValue(initialEmptyAsset);
    setSelectedForEdit(false);
  };

  const isItemSelectedForEdit = (item: AssetVolume) => {
    return selectedForEdit && defaultValue.id === item.id;
  };


  const selectAsset = (selectedItem: AssetVolume) => {
    setDefaultValue(selectedItem);
    setSelectedForEdit(false);
    // const value = getInputValue(ENTER_ALLOCATION_REF_NAME);
    setCurrentEditableAssetVolume({ ...selectedItem} as AssetVolume);
  };

  const selectBenchMark = (selectedItem: AssetVolume) => {
    setSelectBenchmark(selectedItem as AssetVolume);
  };
  const currencyReference = (selectedItem) => {
    setCurrency(selectedItem);
  };

  const currentCurrency =  currency !== undefined ? currency : '';

  const isNameDublicate = () => {
    const inputName = getInputValue(portfolioNameFieldId);
    const whiteSpaceEnd = new RegExp(/\s$/);
    const checkInputName = whiteSpaceEnd.test(inputName) ? inputName.slice(0, -1) : inputName;
    const checkPortfoliosName = (item) => whiteSpaceEnd.test(item) ? item.slice(0, -1) : item;

    const portfolioNameDub = portfolioList.filter((item, idx) => {
      return checkPortfoliosName(item.name).localeCompare(checkInputName) ? 0 : -1
    });

    if (portfolioNameDub.length !== 0 && props.portfolio.name.length !== 0) {
      return false;
    }else if(portfolioNameDub.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const updateAllocation = (event: any) => {
    if (event.code === 'Enter') {
      currentAssetAddToAssets();
    }
    else {
      let value = Number(event.target.valueAsNumber) as number | string;

      if (value < 0) value = ' ';
      setInputValue(ENTER_ALLOCATION_REF_NAME, value);
      setCurrentEditableAssetVolume({ ...currentEditableAssetVolume, value: value } as AssetVolume);
    };
  };


  const updateOrInsertAsset = (assetValues: AssetVolume[], actualValue: AssetVolume): AssetVolume[] => {
    const result = [] as AssetVolume[];
    let added = false;
    for (let asset of assetValues) {
      if (asset.id === actualValue.id) {
        result.push(actualValue);
        added = true;
      }
      else {
        result.push(asset);
      }
    }
    if (!added) {
      result.push(actualValue)
    }
    return result;
  };

  const currentAssetAddToAssets = () => {
    const selectedAsset = getInputValue(SELECT_ASSET_REF_NAME, {});
    const currentValue = getInputValue(ENTER_ALLOCATION_REF_NAME, null);

    if (!currentEditableAssetVolume || currentEditableAssetVolume.id === ASSET_DUMMY_ID) {
      return;
    }
    setAllocationError(false)
    resetSelectedOption();
    setInputValue(SELECT_ASSET_REF_NAME, initialEmptyAsset);
    // setInputValue(ENTER_ALLOCATION_REF_NAME, 0);
    const nextCurrentAssets = [...updateOrInsertAsset(assetValues, currentEditableAssetVolume)];
    setAssetValues(nextCurrentAssets);

    const removedAssetsSkipJustRemoved = removedAssets.filter(it => it.id !== currentEditableAssetVolume.id);
    setRemovedAssets(removedAssetsSkipJustRemoved);

    setCurrentEditableAssetVolume({...initialEmptyAsset, value: 0});
    adjustPossibleAssets(nextCurrentAssets);
  };
  const addToAssetValues = () => {
    setTimeout(currentAssetAddToAssets, 500)
  };

  const adjustPossibleAssets = (appliedAssets) => {
    const appliedIds = appliedAssets.map(it => it.id);
    dispatch(adjustPossibleAssetOptions({add: [], del: appliedIds}));
  };


  const removePortfolioConfirmedGo = () => {
    setRemoveDialogUp(false);
    props.onRemove(props.portfolio.id);
    closeDetails();
  };

  const removePortfolioCancel = () => {
    setRemoveDialogUp(false);
  };
  const weightOnChange = (e, idx: number) => {
    const assets = [] as AssetVolume[];
    let value = floatRound(e.target.value, 2);
    if (value <= 100) {
      for (let i = 0; i < assetValues.length; i += 1) {
        assets.push(i === idx ? {...assetValues[i], value: value} : assetValues[i]);
      }
      setAssetValues(assets);
      setAllocationError(false);
    } else {
      for (let i= 0; i< assetValues.length; i+=1) {
        assets.push(i === idx ? {...assetValues[i],  value: value} : assetValues[i]);
      }
      setAssetValues(assets);
      setAllocationError(true);
    }
  };
  const getCurrency = () => {
    for(let key in currentCurrency as {name:string; id:string}){
      if(key === 'id'){
        return currentCurrency[key]
      };
    };
  };

  const changeSliderValue = (selectedItem: number | number[]) => {
    setSliderValue(selectedItem as number)
  };

  const collectCurrentContent = () => {
    const assets = assetValues.map(it => ({id: it.id, value: it.value}));
    // const benchmark = selectBenchmark.instrumentId ? possibleBenchmark.find(it => it.id === selectBenchmark.instrumentId)
    //     :selectBenchmark;
    const benchmark = selectBenchmark;
    let name = getInputValue(portfolioNameFieldId);
    const inceptionDate = Date.parse(currentInceptionDate)/1000;
    const currency = getCurrency();
    return {name, assets,benchmark,inceptionDate,currency}
  };

  const save = () => {
    const {name, assets, benchmark,inceptionDate,currency} = collectCurrentContent();
    const zeroValue = assets.find((item) => item.value === 0);
    const isZeroValue = zeroValue ? true : false;
    const maxLenght = assets.length <= REACT_APP_MAX_INSTRUMENTS;
    if(name.length !== 0 && totalPercentage() == 100 && !isNameDublicate() && maxLenght && !isZeroValue
        && error === '' || error === null) {
      props.onComplete({id: props.portfolio.id, name, assets, benchmark,inceptionDate,currency});
      closeDetails();
      setIsError(false);
    }else{
      setIsError(true);
    }
  };


  const updatePortfolioName = () => {
    const names = assetValues.map(it => it.name);
    const name = composePortfolioName(names);
    setInputValue(portfolioNameFieldId, name);
  };

  const allocationBgColor = value => value > 100 ? errorTextColor : value === 0 && isError ? errorTextColor : mainBackgroundColor;

  const errorColor = value => value > 100 ? errorTextColor : mainTextColor;

  const defaultAllocationValue = (value) => {
    return value !== undefined ? value.toFixed(2) : '';
  }

  function PortfolioTableData(props) {

    return(
      <Stack direction={'column'} justifyContent={'center'} alignContent={'center'} marginLeft={resolutionMargin}
                sx={{width: {xs:'100%',md:'70%',lg:'70%'}}} >
        <Stack   key={'head'} direction={'row'} sx={{width:'100%'}}>
          <HeaderTable sx={{width:resolutionWithdTd('first')}}>  Asset classes / Assets</HeaderTable>
          <HeaderTable sx={{width:resolutionWithdTd('second')}}> Allocation in %       </HeaderTable>
          <HeaderTable sx={{width:resolutionWithdTd('third')}}>  &nbsp;                </HeaderTable>
        </Stack>
        {titleClass(assetValues).map((item, idx) => <React.Fragment key={'assetClass' + idx}>
          <Stack   key={'head' + idx} direction={'row'} >
            <TDTable key={'head1' + idx} sx={{width:resolutionWithdTd('first'), color:getColorClass(item)}}>
              {item}
            </TDTable>
            {item && <TDTable key={'head2' + idx} sx={{width:resolutionWithdTd('second'), color:getColorClass(item),textAlign:'right'}}>
              {totalClassPct(assetValues,item).toFixed(2)} %
            </TDTable>}
            {/*{item === 'EMPTY CLASS' && <TDTable key={'head2' + idx} sx={{width:resolutionWithdTd('second'),*/}
            {/*  color:getColorClass(item)}}>*/}
            {/*</TDTable>}*/}
            <TDTable key={'head3' + idx} sx={{width:resolutionWithdTd('third')}}>
              &nbsp;
            </TDTable>
          </Stack>
          {assetValues.map((asv, idx) => <React.Fragment key={'idxfragmet' + idx}>
            {item === getClassName(asv.class) ? <Stack key={'trgyh' + idx} direction={'row'} >
              <TDTable key={'td1' + idx} sx={{width:resolutionWithdTd('first'),paddingLeft:'16px'}}>
                {getClassName(asv.class) === 'CRYPTO' ? asv.name + ' - ' + asv.base_symbol
                    :getClassName(asv.class) === 'EMPTY CLASS' ? 'No Asset'
                    :asv.name}
              </TDTable>
              <TDTable key={'td3' + idx} sx={{width:resolutionWithdTd('second'),textAlign:'right',paddingRight:'16px'}}>
                  <MyNumberField
                      onBlur={(event) => weightOnChange(event,idx)}
                      inputRef={el => valuesRefs.initRef(ENTER_ALLOCATION_REF_NAME, el)}
                      defaultValue={defaultAllocationValue(asv.value)}
                      placeholder={'0.00'}
                      errorBgColor={allocationBgColor(asv.value)}
                  />
              </TDTable>
              <TDTable sx={{width:resolutionWithdTd('third')}}>
                {countOfAssets() > 0 &&
                      <DeleteOutlined className={'removeIcon'} onClick={() => removeAsset(asv)}/>
                }
              </TDTable>
            </Stack>: <></>}
          </React.Fragment>)}
        </React.Fragment>)}

        <Stack  key={'footer'} direction={'row'}>
          <HeaderTable sx={{width:resolutionWithdTd('first'),color:errorColor(totalPercentage()),
            borderTop:'1px solid' + mainTextColor}}>
            Portfolio Total
          </HeaderTable>
          <HeaderTable sx={{width:resolutionWithdTd('second'),
            color:errorColor(totalPercentage()),textAlign:'right'
            ,paddingRight:'16px',borderTop:'1px solid' + mainTextColor}}>
            {totalPercentage().toFixed(2)} %
          </HeaderTable>
          <HeaderTable sx={{width:resolutionWithdTd('third'), borderTop:'1px solid' + mainTextColor}}>
            &nbsp;
          </HeaderTable>
        </Stack>
        {isError === true && totalPercentage() !== 100 ?
            <Box className={'errorText'}> * Total weight of Portfolio should be 100%</Box>
            : <></>
        }
        {isError === true && assetValues.length > REACT_APP_MAX_INSTRUMENTS ?
            <Box className={'errorText'}> * Total amount of instruments shouldn't be over 50</Box>
            : <></>
        }
        {allocationError === true  ?
            <Box className={'errorText'}>
              * Amount should be greater than 0.00 and less than 100.00
            </Box>
            : <></>
        }
      </Stack>
    )
  };

  const defaultBench = defaultBenchmark.id === selectBenchmark.id ? defaultBenchmark :selectBenchmark;

  return <>
    <Stack direction='column'  alignItems='left' >
      <Stack direction='row' alignItems='center'  height={'56px'}>
        <Box sx={{color: mainTextColor, width: '180px', fontWeight:500, fontSize:resolutionFontSize}}>
          Portfolio Name
        </Box>
        <Box sx={{paddingBottom: '5px', marginRight:'14px'}}>
          <MyTextField inputRef={el => valuesRefs.initRef(portfolioNameFieldId, el)}
                          style={{width: resolutionAutocompleteWidth}}
                          defaultValue={portfolioName}
          />
        </Box>
        {isError === true && collectCurrentContent().name.length === 0 ? <Box className={'errorText'}>
                * Please specify a portfolio name
            </Box>
            : <></>
        }
        {isNameDublicate() ? <Box className={'errorText'}>
            * This Portfolio name already exists. Please rename.
            </Box>
            : <></>
        }
      </Stack>
      <Stack direction='row' alignItems='center'  height={'56px'}>
        <Box sx={{color: mainTextColor, width: '180px', fontWeight:500, fontSize:resolutionFontSize}}>
          Select Reference Currency
        </Box>
        <Box>
          <MyNewAutocomplete
              id={'currency'}
              onChange={selectedItem => currencyReference(selectedItem)}
              inputRef={el => valuesRefs.initRef(ENTER_CURRENCY_REF_NAME, el)}
              labelParam={'name'}
              // value={currentFrequency}
              defaultValue={currentCurrency}
              placeholder={'please select Currency...'}
              options={referenceCurrency}
              changeIcon={'overview'}
              width={resolutionAutocompleteWidth}
          />
        </Box>
      </Stack>
      <Stack direction={resolutionStackDirection}  spacing={resolutionStackSpacing}  alignItems='center'>
        <Stack direction='row' alignItems='center'  height={'56px'}>
          <Box sx={{color: mainTextColor, width: '180px', fontWeight:500, fontSize:resolutionFontSize}}>
           Search and add Asset
          </Box>
          <Box sx={{paddingBottom: '5px'}}>
            <MyNewAutocomplete
                id={'Search'}
                onChange={selectedItem => selectAsset(selectedItem)}
                onClose={addToAssetValues}
                inputRef={el => valuesRefs.initRef(SELECT_ASSET_REF_NAME, el)}
                labelParam={'name'}
                defaultValue={defaultValue}
                placeholder={'please select...'}
                options={possibleAssets}
                width={resolutionAutocompleteWidth}
                titleClass={titleClass}
            />
          </Box>
        </Stack>
        {/*<Stack sx={{paddingLeft:'395px'}} >*/}
        {/*  <button className={'advancedBtn'}>*/}
        {/*    Upload custom Assets*/}
        {/*  </button>*/}
        {/*</Stack>*/}
      </Stack>
      <Stack sx={{marginTop:'30px',marginBottom:'20px'}}>
        <PortfolioTableData/>
      </Stack>
      <Stack direction='row' alignItems='center'  height={'72px'}>
        <Box sx={{color: mainTextColor, width: '180px', fontWeight:500, fontSize:resolutionFontSize}}>
          Select Benchmark
        </Box>
        <Box>
          <MyNewAutocomplete
              id={'SelectPortfolioBenchmark'}
              onChange={selectedItem => selectBenchMark(selectedItem)}
              inputRef={el => valuesRefs.initRef(SELECT_ASSET_REF_NAME, el)}
              labelParam={'name'}
              defaultValue={defaultBench}
              placeholder={'please select Benchmark...'}
              options={possibleBenchmark}
              width={resolutionAutocompleteWidth}
          />
        </Box>
      </Stack>
      <Stack direction='row' alignItems='center'  height={'72px'}>
        <Box sx={{color: mainTextColor, width: '180px', fontWeight:500, fontSize:resolutionFontSize}}>
          Portfolio Inception Date
        </Box>
        <Box sx={{paddingBottom: '34px',alignItems:'center'}}>
          <DateRangeForm
              dateStart={dateStart as Date}
              dateEnd={dateEnd as Date}
              inceptionDate={currentInceptionDate}
              inceptionDateOnchange={onChangeInceptionDate}
              isError={errorMessageDate}
              setError={setError}
              errorDate={error}
              currentControlDate={currentControlDate}
              className={'createPortfolioPicker'}
              createPortfolio={true}
          />
        </Box>
      </Stack>
      {/*<Stack direction='row' alignItems='center'  height={'72px'}>*/}
      {/*  <Box sx={{color: mainTextColor, width: '180px', fontWeight:500, fontSize:resolutionFontSize}}>*/}
      {/*    Maximum Portfolio Volatility*/}
      {/*  </Box>*/}
      {/*  <Box>*/}
      {/*    <DiscreteSliderMarks*/}
      {/*        value={sliderValue}*/}
      {/*        onChange={selectedItem => changeSliderValue(selectedItem)}*/}
      {/*        width={400}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*</Stack>*/}


      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ width:'1220px',height:'51px'}}>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} >
          <button className={'advancedBtn'} onClick={save} style={{width:'54px', height:resolutionBtnHeight, fontSize:resolutionFontSize}}>
            Save
          </button>

          <button className={'advancedBtn'} onClick={checkClose} style={{width:'54px', height:resolutionBtnHeight, fontSize:resolutionFontSize, color:primaryTextColor,
            border:'1px solid '+primaryTextColor, backgroundColor:mainBackgroundColor }}>
              Cancel
          </button>
        </Stack>
        {/*<Stack direction="row" justifyContent="flex-end" alignItems="flex-end" >*/}
        {/*  <button className={'advancedBtn'} onClick={() => {}} style={{width:'118px', fontSize:'11px'}}>*/}
        {/*    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>*/}
        {/*      Save and Analyze*/}
        {/*      <RightOutlined style={{fontSize: '8px', color: mainBackgroundColor, marginLeft:'4px'}}/>*/}
        {/*    </Stack>*/}
        {/*  </button>*/}
        {/*</Stack>*/}
      </Stack>
    </Stack>

    {removeDialogUp && <ConfirmDialog
            title={'Remove Portfolio'}
            handleAgree={removePortfolioConfirmedGo}
            handleCancel={removePortfolioCancel}
      >
        <Typography noWrap={true}><span>{props.portfolio.name}</span> will be removed.</Typography>
        <p/><div>Please Confirm</div>
      </ConfirmDialog>
    }

    {skipChangesDialogUp && <ConfirmDialog
          title={''}
          handleAgree={skipChangesConfirm}
          handleCancel={skipChangesCancel}
      >
        <Typography noWrap={true}><span>Your changes will not be save.</span>
          <br/><br/>
          <span>Are you sure?</span>
        </Typography>
      </ConfirmDialog>
    }
  </>;
};

export default PortfolioUpdate;
