import { Traded } from '../../models/asset';
import React from 'react';


const VALUE_COLOR_UNDEFINED = '#ffffff';
const VALUE_COLOR_UP = '#52C41A';
const VALUE_COLOR_DOWN = '#FF4D4F';

const priceColor = (traded: Traded) =>
    traded.values_old
  ? traded.values_old.price > traded.values.price ? VALUE_COLOR_DOWN : traded.values.price > traded.values_old.price ? VALUE_COLOR_UP
        :VALUE_COLOR_UNDEFINED : VALUE_COLOR_UNDEFINED
  //   :traded.values_old.price  > traded.values.price ? VALUE_COLOR_DOWN
;

const volumeColor = (traded: Traded) => traded.values_old
  ? traded.values_old && traded.values && traded.values_old.volume > traded.values.volume ? VALUE_COLOR_DOWN : VALUE_COLOR_UP
  : VALUE_COLOR_UNDEFINED;

export const TickerPrice = (props) => {
      const traded = props.item as Traded;
      const price = Number(traded.values ? traded.values.price : 0);
      const color = priceColor(traded);
      if(price > 1.1) {
            return <span style={{color, textAlign:'left'}}>{price.toFixed(2)}</span>
      } else if(price < 0.0001) {
            return <span style={{color, textAlign:'left'}}>{price.toFixed(8)}</span>;

      } else if(price < 1.1) {
            return <span style={{color, textAlign:'left'}}>{price.toFixed(4)}</span>;
      }

      return <span style={{color, textAlign:'left'}}>{price.toFixed(2)}</span>

}
export const TickerVolume = (props) => {
      const traded = props.item as Traded;
      const volume = Number(traded.values ? traded.values.volume : 0);
      const color = volumeColor(traded);
      return <span style={{color}}>{volume.toFixed(2)}</span>;
}
