import React from "react";
import {Stack} from "@mui/material";
import WidgetInfoPanel from "../../../components/controls/info-panel";
import {equityOverviewGraphics, keyMetricsOverviewGraphics} from "../portfolio.slice";


const tableTitle = [' ', 'Client Portfolio Initial','US Stocks'];

interface KeyMetricsProps {
    renderData:any;
    legendName:string;
    usStockLegend:string|undefined;
    className:string;
    placeUse?:string;
}


const float = (value,nameTitle) => {
    try{
        if(nameTitle.includes('Sharpe Ratio')) {
            return value.toFixed(2)
        }else {
            return (value * 100).toFixed(2) + '%'
        }
    }
    catch (e) {
        if(isNaN(value)) {
            return 0
        } else {
            return value
        }
    }
};

const KeyMetrics = (props:KeyMetricsProps) => {
    const renderData = keyMetricsOverviewGraphics.getCurrentGraphic();
    const legendName = props.legendName|| '';
    const className = props.className;
    const placeUse = props.placeUse;


    return <Stack justifyContent={'center'} direction={'column'} spacing={'14px'}>
        <WidgetInfoPanel
            title={'KEY METRICS'}
            infoMarkers={[]}
        />
        <Stack>
            <table className={className}>
                <thead>
                <tr>
                    <th></th>
                    {className === 'keymetricsSimulation' && <th>{placeUse}</th>}
                    <th>{legendName}</th>
                    {props.usStockLegend !== 'No Benchmark' && <th>{props.usStockLegend}</th>}
                </tr>
                </thead>

                <tbody>
                {renderData.length !== 0 && renderData.map((elm, rowNumber,idx) => <React.Fragment key={rowNumber + 'keyMetrics'}>
                        { elm.id !== 'AYR' && <tr key={rowNumber + 'row'}>
                                {elm.name !== undefined && <td>{elm.name}</td>}
                                {elm.simulated !== undefined && <td>{float(elm.id !== 'WSTD' ? elm.simulated
                                    : elm.simulated.max_drawdown,elm.name)}</td>}
                                {elm.optimized !== undefined && <td>{float(elm.id !== 'WSTD' ? elm.optimized
                                    : elm.optimized.max_drawdown,elm.name)}</td>}
                                {elm.clientPortfolio !== undefined  ? <td>{float(elm.id !== 'WSTD' ? elm.clientPortfolio
                                    : elm.clientPortfolio.max_drawdown, elm.name)}</td> : <></>}
                                {elm.strategy !== undefined  ? <td>{float(elm.id !== 'WSTD' ? elm.strategy
                                    : elm.strategy.max_drawdown, elm.name)}</td> : <></>}
                                {elm.benchmark !== undefined && <td>{float(elm.id !== 'WSTD' ? elm.benchmark
                                    : elm.benchmark.max_drawdown,elm.name)}</td>}
                            </tr>
                        }
                </React.Fragment>
                )}
                </tbody>
            </table>
        </Stack>
    </Stack>
};

export default KeyMetrics;
