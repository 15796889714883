import React, {useEffect, useState} from "react";
import {Stack, Box, Grid, Button, FormControl, FormControlLabel} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import {
    buttonBackgroundColor,
    headerBackgroundColor,
    mainBackgroundColor,
    mainTextColor,
    RESOLUTION_SCREEN
} from "../../config";
import {MyNewAutocomplete} from "../../components/controls/form/autocomplete-new";
import {HMWY, TabItem, TabsComponent} from "../../components/tab-component";
import {RefHoster} from "../../components/controls/form/ref-hoster";
import {Asset} from '../../models/asset';
import EquityCurveView from "../portfolios/portfolio-graphs/equity-curve";
import {EQUITY_CURVE_ID, ROLLING_VOLATILITY_ID} from "../../data/graphics";
import {
    annualReturnsOverviewGraphics,
    equityOverviewGraphics,
    rollingSharpeGraphics,
    rollingSortinoGraphics
} from "../portfolios/portfolio.slice";
import KeyMetrics from "../portfolios/portfolio-graphs/key-metrics";
import AnnualReturns from "../portfolios/portfolio-graphs/eoy-returns";
import PerformanceMetrics from "../portfolios/portfolio-graphs/performance-metrics";
import DistributionMonthlyReturn from "../portfolios/portfolio-graphs/distribution-monthly-return";
import MonthlyReturnsPortfolio from "../portfolios/portfolio-graphs/monthly-returns-portfolio";
import RollingSharpe from "../portfolios/portfolio-graphs/rolling-sharpe";
import RollingSortino from "../portfolios/portfolio-graphs/rolling-sortino";
import DailyReturnsPortfioio from "../portfolios/portfolio-graphs/daily-returns-portfioio";
import ReturnQuantiles from "../portfolios/portfolio-graphs/return-quantiles";
import WorstDrawdowns from "../portfolios/portfolio-graphs/worst-drawdowns";
import RollingVolatility from "../portfolios/portfolio-graphs/rolling-volatility";
import RollingBetaToBenchmark from "../portfolios/portfolio-graphs/rolling-beta-benchmark";
import DrawdownPeriods from "../portfolios/portfolio-graphs/drawdown-periods";
import UnderwaterPlot from "../portfolios/portfolio-graphs/underwater-plot";
import ValueRiskExpectedShortfall from "../portfolios/portfolio-graphs/value-risk-expected-shortfall";
import {dateSerializerNew} from "../../components/controls/form/nb-data-picker";
import {$$} from "../../utils/utils";
import GapTable from "./gap/gap-table";
import DateRangeForm from "../../components/controls/form/date-range";
import {getClassName} from "../portfolios/helper";
import {DateValidationError} from "@mui/x-date-pickers/models";
import LatestCheckTable from "./gap/latest-check-table";


interface DataQualityAnalysProps {
    assetList: Asset[];
    dataQuality:Function;
    caseStr: string;
    renderData: any;
    dataQualityResult:string;
    annualReturns:any;
    distributionMothlyData:any;
    dailyReturnsData:any;
    monthlyReturns:any;
    rollingVolatility:any;
    rollingSharpe:any;
    rollingSharpeCaseString:string;
    rollingSortino:any;
    rollingSortinoCaseString:string;
    onChange:(item: TabItem) => void;
    underwaterPlot:any;
    drawdownPeriodsData:any;
    worstDrawDownData:any;
    keyMetricksData:any;
    taskStatus:string;
};

const SectionTabItems = [
    { title:'Overview',    id:'Overview'},
    { title:'Performance', id:'Performance'},
    { title:'Risk Metrics',id:'Risk Metrics'},
] as TabItem[];


const DataQualityAnalys = (props: DataQualityAnalysProps) => {
    const renderData = props.renderData;
    const caseStr = props.caseStr;
    const dataQualityResult = props.dataQualityResult;
    const annualReturns = props.annualReturns;
    const distributionMothlyData = props.distributionMothlyData;
    const dailyReturnsData = props.dailyReturnsData;
    const monthlyReturns = props.monthlyReturns;
    const rollingVolatility = props.rollingVolatility;
    const rollingSharpe = props.rollingSharpe;
    const rollingSortino = props.rollingSortino;
    const underwaterPlot = props.underwaterPlot;
    const worstDrawDownData = props.worstDrawDownData;
    const drawdownPeriodsData = props.drawdownPeriodsData;
    const rollingSharpeCaseString = props.rollingSharpeCaseString;
    const rollingSortinoCaseString =props.rollingSortinoCaseString;
    const keyMetricksData = props.keyMetricksData;
    const taskStatus = props.taskStatus;
    const stackSpace = RESOLUTION_SCREEN === 'horizontal' ? '20px' : '20px';
    const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';
    const resolutionWidthBox = RESOLUTION_SCREEN === 'horizontal' ? '140px' : '100px';
    const resolutionWidthAutocomp = RESOLUTION_SCREEN === 'horizontal' ? '488px' : '220px';
    // const periodList = [{title:'1 minute',id:'1m'}, {title:'1 hour', id:'1h'}, {title: '1 day', id:'1d'}];
    const periodList = ['1d','1h','1m',];
    // const periodList = ['1d','1h'];
    // const periodListName = periodList.map((item) => item.title);
    const exchangeList = ['bnx','okx', ];
    const contractTypeList = ['spot','perpetual'];
    const SELECT_ASSET_REF_NAME = '_asset_ref_name';
    const [period,setPeriod] = useState(periodList[0]);
    const [exchange,setExchange] = useState(exchangeList[0]);
    const [contractType,setContractType] = useState(contractTypeList[0]);
    const valuesRefs = new RefHoster();
    const defaultAsset = {id: -1, name: '', symbol: '*', value: 0};
    const cryptoList = props.assetList.filter((item,idx) => {
        return getClassName(item.class) === 'CRYPTO' && item.name !== 'Crypto Long/Short'
    })
    const assetList =[ defaultAsset,...cryptoList];
    const [selectAsset, setSelectAsset] = useState(defaultAsset);
    const [sectionTab, setSectionTab] = useState(SectionTabItems[0]);
    const [checked, setChecked] = React.useState(false);
    const dateEnd = dateSerializerNew($$.getYesterday()) as string | Date | null;
    const dateStart = dateSerializerNew($$.getOneYearAgo()) as string | Date | null;
    // const currentControlDate = new Date(props.currentControlDate || new Date().toISOString());
    const currentControlDate = new Date( new Date().toISOString());
    const [currentDateStart,setCurrentDateStart] = useState(dateStart);
    const [currentDateEnd,setCurrentDateEnd] = useState(dateEnd);
    const isAnnualReturns = annualReturnsOverviewGraphics.getCurrentGraphic() !== undefined;
    const [isErrorAsset,setIsErrorAsset] = useState(false);
    const [isChangePeriod,setIsChangePeriod] = useState(false);
    const [isChangeExchange,setIsChangeExchange] = useState(false);
    const [isChangeType,setIsChangeType] = useState(false);
    const [isChangeAsset,setIsChangeAsset] = useState(false);

    const [error, setError] = React.useState<DateValidationError | ''>('');

    const errorMessageDate = React.useMemo(() => {
        switch (error) {
            case "minDate"      : {return 'Select a date'}
            case 'invalidDate'  : {return 'Date is not valid'}
            case 'maxDate'      : {return 'Select a date'}
            default             : {return ''}
        }
    }, [error]);

    const formStyle = {
        backgroundColor:headerBackgroundColor,
        borderBottom:'1px solid' + mainBackgroundColor,
        textAlign:'left',
        justifyContent:'space-between',
        '&.MuiFormControlLabel-label': {
            alignItems:'left'
        }
    };

    const styleCheckBox = {
        // marginLeft:'250px',
        color:buttonBackgroundColor,
        '&, &.Mui-checked': {
            color: buttonBackgroundColor,
        },
        '&.MuiCheckbox-root':{
            backgroundColor:mainBackgroundColor,
            color: buttonBackgroundColor,
        },
    };
    // console.log(typeof dateEnd)
    // console.log(xxx)

    const onChangeAsset = (selectedItem) => {
        setSelectAsset(selectedItem);
        if(selectedItem.symbol !== '*') {
            setIsErrorAsset(false);
        }
        if(selectedItem.id !== currentAsset.id) {
            setIsChangeAsset(true);
        }
    };
    // const currentAsset = defaultAsset.id === selectAsset.id ? defaultAsset : selectAsset;
    const currentAsset = selectAsset;

    const onChangePeriod = (item) => {
        setPeriod(item.id)
        if(period !== item.id){
            setIsChangePeriod(true);
        }
    };
    const onChangeExchange = (item) => {
        setExchange(item.id);
        if(exchange !== item.id) {
            setIsChangeExchange(true);
        }
    };
    const onChangeContractType = (item) => {
        setContractType(item.id);
        if(contractType !== item.id){
            setIsChangeType(true);
        }
    };

    const onChangeCheckBox = (event) => {
        setChecked(event.target.checked);
    };

    const onchangeDateStart = (value) => {
        setCurrentDateStart(value);
    };
    // console.log(currentDateStart)
    const onchangeDateEnd = (value) => {
        setCurrentDateEnd(value);
    };

    const startDataQuality = () => {
        if(currentAsset.symbol !== '*') {
            const instruments = [{
                exchange:exchange,
                symbol:currentAsset.symbol,
                interval:period,
                contract_type:contractType,
                schema: 'crypto',
                field:  'close',
            }];
            const showMetrics = checked ? 'YES' : 'NO';
            props.dataQuality({instruments:instruments,time_range:[currentDateStart,currentDateEnd],options:{kind:'candle',
                    include_metrics:showMetrics}});
            setIsChangePeriod(false);
            setIsChangeExchange(false);
            setIsChangeType(false);
            setIsChangeAsset(false);
        } else {
            setIsErrorAsset(true);
        }
    };


    return <>
        <Stack direction={'column'} >
            <Box sx={{color:mainTextColor}}>
                     <span className={'titlePage'}>
                         Data Quality <span className={'titleGraphs'}>Table Name:  {`${exchange} ${currentAsset.symbol} ${contractType} ${period}`}</span>
                     </span>
            </Box>
            <Stack direction='row' alignItems='center' spacing={stackSpace} height={'40px'}>
                <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                    Select Asset
                </Box>
                <Box sx={{paddingBottom: '14px'}}>
                    <MyNewAutocomplete
                        id={'DQAsset'}
                        onChange={selectedItem => onChangeAsset(selectedItem)}
                        inputRef={el => valuesRefs.initRef(SELECT_ASSET_REF_NAME, el)}
                        labelParam={'name'}
                        defaultValue={currentAsset}
                        options={assetList}
                        changeIcon={'overview'}
                        width={resolutionWidthAutocomp}
                    />
                </Box>
                {isErrorAsset && <Box className={'errorText'}>
                    * Please specify an Asset
                    </Box>
                }
            </Stack>
            <Stack direction='row' alignItems='center' spacing={stackSpace} height={'30px'}>
                <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                    Period
                </Box>
                <Box position={'relative'}>
                    <Box  right={'1px'} style={{cursor:'pointer'}}>
                        <TabsComponent width={'100%'} variant={HMWY} onChange={onChangePeriod} items={periodList} applyBorder={true}/>
                    </Box>
                </Box>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={stackSpace} height={'30px'}>
                <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                    Exchange
                </Box>
                <Box position={'relative'}>
                    <Box  right={'1px'} style={{cursor:'pointer'}}>
                        <TabsComponent width={'100%'} variant={HMWY} onChange={onChangeExchange} items={exchangeList} applyBorder={true}/>
                    </Box>
                </Box>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} width={'59%'}>
                <Stack direction='row' alignItems='center' spacing={stackSpace} height={'30px'}>
                    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                        Instrument type
                    </Box>
                    <Box position={'relative'}>
                        <Box  right={'1px'} style={{cursor:'pointer'}}>
                            <TabsComponent width={'100%'} variant={HMWY} onChange={onChangeContractType} items={contractTypeList} applyBorder={true}/>
                        </Box>
                    </Box>
                </Stack>
                {/*<Box sx={{width:{xs:'60%',md:'30%',lg:'30%'}}}>*/}
                {/*    <Button disabled={false} className={'advancedBtn'}*/}
                {/*            onClick={ startDataQuality}>*/}
                {/*        Analys*/}
                {/*    </Button>*/}
                {/*</Box>*/}
            </Stack>
            <Stack direction='row' alignItems='center' spacing={stackSpace} height={'30px'}>
                <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                    Incude Metrics
                </Box>
                <Box position={'relative'}>
                    <Box  right={'1px'} style={{cursor:'pointer'}}>
                        <FormControl>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={checked}
                                    onChange={onChangeCheckBox}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={styleCheckBox}
                                />}
                                label={''}
                                sx={formStyle}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={stackSpace} height={'60px'} >
                <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                    Analysis Period
                </Box>
                <Box sx={{paddingBottom: '34px'}}>
                    <DateRangeForm
                        dateStart={dateStart as Date}
                        dateEnd={dateEnd as Date}
                        isError={errorMessageDate}
                        setError={setError}
                        errorDate={error}
                        currentControlDate={currentControlDate}
                        className={'pickerOptimization'}
                        dQAnalysis={true}
                        dQonChangeStart={onchangeDateStart}
                        dQonChangeEnd={onchangeDateEnd}
                    />
                </Box>
                <Box>
                    <Button disabled={false} className={'advancedBtn'}
                            onClick={ startDataQuality}>
                        Analyze
                    </Button>
                </Box>
                <Box>
                    <Button className={'advancedBtn'}>
                        Latest Quality Check
                    </Button>
                </Box>
            </Stack>
            {taskStatus === 'process' && <Stack justifyContent={'center'} alignItems={'center'} sx={{width: '100%',height: '100px', marginTop:'20px'}}>
                <span className={'loader'}></span>
            </Stack>}

           {/*<LatestCheckTable/>*/}

            {dataQualityResult === 'success' && !isChangePeriod && !isChangeExchange && !isChangeType && !isChangeAsset && <>
                {period !== '1m' ? <Grid container sx={{margin: '40px 0px 90px 0px'}}>
                    <Grid item xs={12}>
                        <EquityCurveView
                            caseStr={caseStr}
                            renderData={renderData}
                            legendName={currentAsset.name}
                            usStockLegend={undefined}
                            panelId={EQUITY_CURVE_ID}
                            dataQuality={true}
                            strategy={true}
                        />
                    </Grid>
                </Grid> : <Box className={'titleGraphs'} sx={{marginBottom:'20px'}}>
                    <span className={'titleGraphs'}>PERFORMANCE</span> graph for 1 minute candles will not be displayed due to large amount of data .
                </Box>}
                <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <GapTable
                            className={'dataQualityGaps'}
                            legendName={currentAsset.name}
                        />
                    </Grid>
                </Grid>
                {checked && isAnnualReturns && <><Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <AnnualReturns
                            renderData={annualReturns}
                            legendName={currentAsset.name}
                            usStockLegend={undefined}
                        />
                        <RollingSharpe
                            rollingSharpe={rollingSharpe}
                            rollingSharpeCaseString={rollingSharpeCaseString}
                            currentResult={'clientPortfolio'}
                            onChange={props.onChange}
                            graphicsName={rollingSharpeGraphics}
                            portfolioName={currentAsset.name}
                        />
                        <RollingSortino
                            rollingSortino={rollingSortino}
                            rollingSortinoCaseString={rollingSortinoCaseString}
                            onChange={props.onChange}
                            graphicsName={rollingSortinoGraphics}
                            portfolioName={currentAsset.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Stack spacing={3}>
                            <MonthlyReturnsPortfolio
                                renderData={monthlyReturns}
                                currentResult={'clientPortfolio'}
                            />
                            <DistributionMonthlyReturn
                                renderData={distributionMothlyData}
                                currentResult={'clientPortfolio'}
                            />
                            <DailyReturnsPortfioio
                                renderData={dailyReturnsData}
                                currentResult={'clientPortfolio'}
                                titleGraph={'DAILY RETURNS PORTFOLIO'}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Stack spacing={5}>
                            <WorstDrawdowns
                                renderData={worstDrawDownData}
                                currentResult={'clientPortfolio'}
                                dateEnd={dateEnd as Date}
                            />
                            <RollingVolatility
                                renderData={rollingVolatility}
                                legendName={currentAsset.name}
                                usStockLegend={undefined}
                                panelId={ROLLING_VOLATILITY_ID}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Stack spacing={5}>
                            <DrawdownPeriods
                                renderData={drawdownPeriodsData}
                                currentResult={'clientPortfolio'}
                                legendName={currentAsset.name}
                            />
                            <UnderwaterPlot
                                renderData={underwaterPlot}
                                currentResult={'clientPortfolio'}
                            />
                        </Stack>
                    </Grid>
                </Grid></>}
            </>}
        </Stack>
    </>
};

export default DataQualityAnalys;
