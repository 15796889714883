import { Autocomplete, TextField, Box } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import {
    buttonBackgroundColor,
    mainBackgroundColor,
    mainColdShadeBgColor,
    mainTextColor,
    primaryTextColor, RESOLUTION_SCREEN,
    secondaryColdShadeBgColor
} from '../../../config';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getColorClass,getClassName} from "pages/portfolios/helper";

interface MyNewAutocompleteProps {
    options: ReadonlyArray<any>;
    // options: any;
    placeholder?: string;
    id: string;
    noOptionsText?: string;
    labelParam: string;
    inputRef: any;
    defaultValue?: any;
    value?: any;
    optionIndex?: number;
    onChange: Function;
    filterOptions?: any;
    onClose?:Function;
    changeIcon?: string;
    width: string;
    titleClass?:Function;
    uncontrole?:boolean;
    simulation?:boolean;
}

const style = {
      '& .MuiOutlinedInput-root': {
        p: 1,
      },
      '& .MuiAutocomplete-tag': {
        bgcolor: 'primary.light',
        border: '1px solid red',
        borderColor: 'primary.light',
        '& .MuiSvgIcon-root': {
          color: 'primary.main',
          '&:hover': {
            color: 'primary.dark'
          }
        }
      },

    };

const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';
const GroupHeader = styled('div')(({ theme }) => ({
    // position: 'sticky',
    // top: '-8px',
    // padding: '4px 10px',
    padding: '0px',
    backgroundColor: mainBackgroundColor,
    fontSize: resolutionFontSize,
}));

const GroupItems = styled('div')({
    paddingLeft: '0px',
    // marginLeft:'-10px',
    color: mainTextColor,
    backgroundColor: mainBackgroundColor,
    fontSize: resolutionFontSize,
});
const groupItemMarging = (item) => {
    return item ? '0px' : '-12px'
}

export const MyNewAutocomplete = (props: MyNewAutocompleteProps) =>  {
  const labelParam = props.labelParam;
  const options = props.options;
  const onChange = props.onChange;
  const optionIndex = props.optionIndex || 0;
  // const defaultValue = props.defaultValue || options[optionIndex];
  // const defaultValue = props.defaultValue ;
    const  defaultValue = props.uncontrole ? props.defaultValue: props.defaultValue || options[optionIndex]
  const value = props.value;
  const placeholder = props.placeholder || 'please select ...';
  const noOptionsText = props.noOptionsText || 'no options';
  const titleClass = props.titleClass;
  const paddingItem = props.id === 'select Benchmark' ? -5 : 0;
  const onClose = props.onClose ? props.onClose :() => {};


  const onChangeSelected = (a, b) => onChange(b);

  // const isOptionEqualToValue = (a, b): boolean => a.id === b.id;
  const isOptionEqualToValue = (a, b) => {
     if(a.id === b.id) {
         return true
     } else if(a.id !== b.id) {
         return false
     }else if(a.id === undefined){
         return false
     } else {
         return false
     }
  };

  const getEditFieldParams = params => {
    const inputProps = params.inputProps;
    const newinputProps = {...inputProps, style: {
      color: 'white',
      fontWeight: 'normal',
      backgroundColor: mainBackgroundColor,
            variant:"standard",

      }}
    const newParams = { ...params, inputProps: newinputProps };
    return { ...newParams,
      style: {
        backgroundColor: mainBackgroundColor,
        color: '#FFF',
          width:props.width,
          // variant:"standard",
        }
      }
  };

  const getIconPop = () => {
     const icon = props.changeIcon === 'overview' ? <ExpandMoreIcon sx={{fontSize:'large'}}/> : <SearchIcon sx={{fontSize:'large'}}/>
      return icon;
  };


  return <Autocomplete
      id={props.id}
      // freeSolo={true}
      blurOnSelect={true}
      popupIcon={getIconPop()}
      forcePopupIcon={true}
      className={'myAutocomplete'}
      size="small"
      noOptionsText={noOptionsText}
      onChange={onChangeSelected}
      onClose={onClose()}
      value={defaultValue}
      // defaultValue={defaultValue}
      renderGroup={(option) =>
          <GroupHeader key={'groupHearder'+ option.key} sx={{color:option.group !== 'PORTFOLIOS'? getColorClass(option.group) : '#C196B8'
          }}>{option.group}
              <GroupItems key={'groupItem' + option.key} sx={{marginLeft:groupItemMarging(option.group)}}>{option.children}</GroupItems>
          </GroupHeader>
      }
     groupBy={(option) => option.class ? getClassName(option.class) :props.simulation ? 'PORTFOLIOS' : ''}
     options={options}
     disableClearable={true}
     getOptionLabel={option => getClassName(option.class) === 'CRYPTO' ? option[labelParam] + ' - ' + option.base_symbol : option[labelParam]}
     isOptionEqualToValue={isOptionEqualToValue}
     renderInput={
       (params) =>
          <TextField { ...getEditFieldParams(params) }
          inputRef={props.inputRef}
          placeholder={placeholder}
          variant={'standard'}
          />
     }
     sx={style}
  />;
};
