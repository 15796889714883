import React from 'react';
import { connect } from 'react-redux';
import { RootState } from "store";
import { Button, Stack, Box } from '@mui/material';
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
    wsConnectorDataprocessor,
    wsConnectorDistGateway,
    wsConnectorMarket
} from '../../components/system/websocket-component';
import { WS_DATAPROCESSOR_URL, WS_MARKET_URL} from '../../config';
import { DjangoContextType, UserProfile } from '../../types/auth';
import { RouterParams } from "../../routes/router-proxy-model";
import { withRouter } from "../../components/util-components/with_router";
import { DistributiveGatewayData, liveDataDefaultActions } from './live-data.slice';
// import { liveDataApi, loadInstrumentsInfoApi } from "./live-data.api";
import { TabItem } from "../../components/tab-component";
import './live-data.css'

interface ComponentProps extends RouterParams, StateProps, DispatchProps {}

class LiveDataDefaultComponent extends React.Component<ComponentProps> {
    componentDidMount() {
        // const {assets} = this.props;
        const user = this.getUser();

        if (this.props.user) {
            this.initWs(this.props.user.token)
        }
        // if (! assets || assets.length === 0) {
        //     this.props.loadInstrumentsInfo();
        // }
    }

    getUser(): UserProfile | undefined | null {
        const context = this.context as DjangoContextType;
        return context.user;
    }

    initWs(token) {
        // const destGatewayWs = 'ws://3.78.53.123:8092';
        const destGatewayWs = 'wss://platform.newbridge.ai/dgws';
        // const destGatewayWs = 'ws://0.0.0.0:4444';
        // const destGatewayWs = 'ws://18.184.122.22:4444';
        // const destGatewayWs = 'wss://platform.newbridge.ai/dgwss';
        const subsMessage = {
            "msg":"Subscription",
            "ts":1721663810436901,
            "seqn":1,
            "pattern":"*",
            "op":"sub",
            "stream":"Position"
        };
        if (!wsConnectorDistGateway.ready) {
            console.log("############### WS INIT")
            wsConnectorDistGateway.url = destGatewayWs;
            wsConnectorDistGateway.connect_message = subsMessage;
            wsConnectorDistGateway.init_receiver((payload) => {
                console.log('################# DATA-PROCESSOR - WS PAYLOAD::', JSON.parse(payload.data || '{}'))
                const data = JSON.parse(payload.data || '{}')
                console.log('####################', data)
                this.props.setData(data);
            });
        }
    }


    render() {
        const { mode, data, setMode } = this.props;


        function renderAsGraph1(data: DistributiveGatewayData[]) {
            return mode === 'graph1' ? <Stack>
                <div className='graph1'>graph1</div>
            </Stack> : null
        }
        function renderAsTable(data: DistributiveGatewayData[]) {
            const className = '';
            return mode === 'table' ?
                <table style={{ color: 'white', width: '100%' }} className={className}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>time</th>
                        <th>symbol</th>
                        <th>position</th>
                        <th>leverage</th>
                        <th>initialMargin</th>
                        <th>maintenanceMargin</th>
                        <th>positionInitialMargin</th>
                        <th>unrealizedPnL</th>
                        <th>entryPx</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, idx) => <React.Fragment key={idx + 'qualgap'}>
                        <tr>
                            <td>{idx}</td>
                            <td>{new Date(item.ts/ 1000).toUTCString()}</td>
                            <td>{item.symbol}</td>
                            <td>{item.position}</td>
                            <td>{item.leverage}</td>
                            <td>{item.initialMargin}</td>
                            <td>{item.maintenanceMargin}</td>
                            <td>{item.positionInitialMargin}</td>
                            <td>{item.unrealizedPnL}</td>
                            <td>{item.entryPx}</td>
                        </tr>
                    </React.Fragment>)}
                    </tbody>
                </table>
           : null;
        }

        function header() {
            return <Stack ><div className="header-title">
                <span className="header-title"><b>Distributive Gateway Data</b></span>
                {/*&nbsp;&nbsp;&nbsp;<span><a href='' onClick={() => setMode('table')}>table</a></span>*/}
                {/*&nbsp;&nbsp;&nbsp;<span><a href='' onClick={() => setMode('graph1')}>graph</a></span>*/}
            </div></Stack>;
        }

        return (
          <>
              {header()}
              {renderAsTable(data)}
              {renderAsGraph1(data)}
          </>
        );
    }
}

const mapStateToProps = ({ liveData: state }: RootState) => ({
    version: '001', ...state
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    setData: bindActionCreators(liveDataDefaultActions.setData, dispatch),
    setMode:             bindActionCreators(liveDataDefaultActions.setMode, dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const LiveDataComponent = withRouter(LiveDataDefaultComponent);

export default connect(mapStateToProps, mapDispatchToProps)(LiveDataDefaultComponent);
