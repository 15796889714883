import React from 'react';
import {Portfolio} from "../../models/portfolio";
import {Box, Stack, styled} from "@mui/material";
import {buttonBackgroundColor, mainTextColor, RESOLUTION_SCREEN, secondaryColdShadeBgColor} from "../../config";
import AssetDonutChart from "./portfolio-graphs/asset-donut-chart";
import {simulationAllocationGraphics} from "./portfolio.slice";
import WidgetInfoPanel from "../../components/controls/info-panel";
import {getColorClass, getClassName, titleClass} from "pages/portfolios/helper";

interface OptimizedSimulatedAllocationProps {
    portfolio: Portfolio;
    classCSS?: string;
    placeUse: string;
    renderData:any;
    title:string;
    extraInstruments:{};
};
const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';

const HeaderTable = styled(Box) ({
    // textAlign:'left',
    paddingRight:'8px',
    paddingLeft:'4px',
    paddingTop:'4px',
    height: '28px',
    fontWeight: 500,
    fontSize: resolutionFontSize,
    color: mainTextColor,
});


const totalClassPct = (item,titleKey) => {
        const result = item ? item.reduce((sum,el,it) =>  {
           titleClass(item).map((element,idx) => {
                getClassName(el.class) === element ? sum[idx] = sum[idx] + el[titleKey] : 0
           })
            return sum;
        },[0,0,0,0,0]) : [0,0,0,0,0]
        return result;
};

const OptimizedSimulatedAllocation = (props:OptimizedSimulatedAllocationProps) => {
    const {portfolio,placeUse} = props;
    const renderData = simulationAllocationGraphics.getCurrentGraphic();
    const title = props.title;
    const extraInstruments = props.extraInstruments;
    const assetsValuesInitial = totalClassPct(renderData,['initial']);
    const assetsValuesSimulated = totalClassPct(renderData, ['optimized']);
    const assetsTitles = titleClass(portfolio.assets);
    const assetTitlesSimulated = renderData ? titleClass(renderData) : [];
    const donutColorsInitial = titleClass(portfolio.assets).map((item) => getColorClass(item));
    const donutColorsSimulated = renderData ? titleClass(renderData).map((item) => getColorClass(item)) : [];
    const stackDirection = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';
    const donutWidth = RESOLUTION_SCREEN === 'horizontal' ? '330px' : '180px';


    const asFloat = (value) => {
        try {
            return value.toFixed(2);
        }
        catch (e) {
            return value;
        }
    };

    const asFloatForPch = (value) => {
        try {
            const sign = value === 0 ? ' ' : value > 0 ? '+' : '';
            return sign + value.toFixed(2);
        }
        catch (e) {
            return value;
        }
    };

  return  <Stack direction={'column'} spacing={2}>
      <WidgetInfoPanel
          title={title}
          infoMarkers={[]}
      />
      <Stack direction={stackDirection}  spacing={2}  alignItems={'flex-start'}
                     style={{width:'100%', height: '100%', marginBottom:'24px'}}>
        <Stack alignItems={'flex-start'} marginTop={'16px'} sx={{width: {xs:'100%',md:'70%',lg:'70%'}}}>
            <Box sx={{height:'100%',width:'100%'}}>
              <Stack style={{width: '100%'}}  key={'head'} direction={'row'}>
                  <HeaderTable style={{width:'37%',paddingLeft: '16px'}}>Assets</HeaderTable>
                  <HeaderTable style={{width:'26%',textAlign:'center'}}>{portfolio.name} in %</HeaderTable>
                  <HeaderTable style={{width:'25%',textAlign:'center'}}>{placeUse} Allocation in %</HeaderTable>
                  <HeaderTable style={{width:'12%',textAlign:'center'}}>Change in %</HeaderTable>
              </Stack>

                {titleClass(renderData).map((item,idx) => <React.Fragment key={'fr' + idx}>
                      <Stack style={{width: '100%'}} key={idx + 'stack'} direction={'row'}>
                          <Box className={'composition'} key={'headtd45' + idx} style={{width:'37%', color:getColorClass(item)}}>
                              {item}
                          </Box>
                          { item &&
                              <Box className={props.classCSS} key={'head73' + idx} style={{
                                  width: '26%',
                                  color: getColorClass(item)
                              }}>{asFloat(totalClassPct(renderData,['initial'])[idx])}</Box> }
                          { item &&
                              <Box className={props.classCSS} key={'head3175' + idx} style={{
                                  width: '25%',
                                  color: getColorClass(item)
                              }}>{asFloat(totalClassPct(renderData,['optimized'])[idx])}</Box> }
                          { item &&
                              <Box className={props.classCSS} key={'head58' + idx} style={{
                                  width: '12%',
                                  color: getColorClass(item)
                              }}>{asFloatForPch(totalClassPct(renderData,['pch'])[idx])}</Box> }
                      </Stack>

                  {renderData.map((it,ind) => <React.Fragment key={'idxfrmet' + ind}>
                      {item === getClassName(it.class) ?
                          <Stack style={{width: '100%'}} key={idx + 'indexstack' } direction={'row'}>
                              <Box className={'composition'} key={idx+'tdtest1'} style={{width:'37%',paddingLeft:'16px'}}>{it.symbol}</Box>
                              <Box className={props.classCSS} key={'tdtest2' + idx} style={{width:'26%'}}>
                                  {asFloat(it.initial)}
                              </Box>
                              <Box className={props.classCSS} key={'tdtest3' + idx} style={{width:'25%'}}>
                                  {asFloat(it.optimized)}
                              </Box>
                              <Box className={props.classCSS} key={'tdtest5523' + idx} style={{width:'12%'}}>
                                  {asFloatForPch(it.pch)}
                              </Box>
                          </Stack>
                          : <></>}
                      </React.Fragment>
                  )}

                  </React.Fragment>
              )}
          </Box>
        </Stack>
          <Stack direction={'row'}>
              <Box style={{width:donutWidth, height:'100%', marginTop:'16px',textAlign:'center',color:'#FFFFFF',fontSize:'12px'}}>
                  {portfolio.name}
                  <AssetDonutChart series={assetsValuesInitial} labels={assetsTitles} colors={donutColorsInitial}/>
              </Box>
              <Box style={{width:donutWidth, height:'100%',marginTop:'16px',textAlign:'center',color:'#FFFFFF',fontSize:'12px'}}>
                  {placeUse === 'Simulation' ? 'Simulated Allocation' : 'Optimized Allocation'}
                  <AssetDonutChart series={assetsValuesSimulated} labels={assetTitlesSimulated} colors={donutColorsSimulated}/>
              </Box>
          </Stack>
      </Stack>
  </Stack>
};

export default OptimizedSimulatedAllocation;
