
export interface SectorIndexInterface {
  date: string;
  index: string,
  value: number
}


export interface StressIndexInterface {
  data_marker: string,
  index_value: number
}

export interface StressIndexValueInterface {
  STRESS_CRYPTO: number,
  STRESS_THRESHOLD: number,
  date: string
}


export interface CorrelationInputDataInterface {
  names: string[],
  values: number[][],
}


export interface GraphicCase {
  cases: any[],
  currentCase: number,
  selectorTable: Record<string, number>;

  isInited(): boolean;
  getIndex(mode: string, range: string): number;
  addCase(mode: string, range: string, data: any): void;
  isMissingData(mode: string, range: string);
  selectCase(mode: string, range: string);
  getCurrentGraphic();
}

export const EMPTY_CASE_STR = 'undef:undef';

/**
 * The use this class because the 'modern/current' react slice doesn't support 'dict' for 'Immer',
 * so we have an issue likes...
 * ...Immer only supports setting array indices and the 'length' property..
 */

export class GraphicCaseImpl implements GraphicCase {

  cases: any[] = [];
  currentCase: number = -1;
  defaultCaseStr: string = EMPTY_CASE_STR;
  selectorTable: Record<string, number> = {};
  timeRanges: string[] = [];

  constructor(selectorTable, defaultCaseStr: string, timeRanges: string[]) {
    this.selectorTable = selectorTable;
    this.defaultCaseStr = defaultCaseStr;
    this.timeRanges = timeRanges;
    for(let i= 0; i < Object.keys(this.selectorTable).length; i+=1) {
      this.cases.push([]);
    }
  }

  getDefaultTimeRange() {
    return this.timeRanges[0];
  }

  getDefaultCaseStr() {
    return this.currentCase !== -1 ? this.defaultCaseStr : EMPTY_CASE_STR;
  }

  parseCaseStr(caseStr: string) {
    return caseStr.split(':');
  }

  dataForSlice(dataFieldName, currentFieldName) {
    return {[dataFieldName]: this.cases, [currentFieldName]: this.currentCase}
  }

  getIndex(mode: string, rangeStr: string): number {
      const key = `${mode}:${rangeStr}`;
      return this.selectorTable[key];
  }

  getIndexByCaseStr(caseStr: string): number {
    return this.selectorTable[caseStr];
  }

  addCase(mode: string, range: string, data: any): void {
      const idx = this.getIndex(mode, range);
      if (idx) {
        this.cases[idx] = data;
        this.currentCase = this.currentCase === -1 ? idx : this.currentCase;
      }
  }

  setInitialCases(data: any): void {
    this.cases = data;
    const index = this.getIndexByCaseStr(this.defaultCaseStr);
    this.currentCase = index;
  }

  selectCase(mode: string, range: string) {
      const idx = this.getIndex(mode, range);
      if (idx !== undefined) {
        this.currentCase = idx;
        return this.cases[idx];
      }
  }

  isMissingData(mode: string, range: string){
      const idx = this.getIndex(mode, range);
      return idx ? this.cases[idx].length === 0 : true;
  }
  getCurrentGraphic() {
    return this.currentCase !== -1 ? this.cases[this.currentCase] : [];
  }

  isInited(): boolean {
    return this.currentCase !== -1;
  }
}
