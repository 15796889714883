// action - state management
import { REGISTER, LOGIN, LOGOUT } from './actions';

// types
import { AuthProps, AuthActionProps } from 'types/auth';
import { User } from '../../models/user';
import {goTo} from "../../utils/react-utils";
import AppRoutes from "../../routes/AppRoutes";

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};


const LOCAL_STORAGE_USER_KEY = 'user';
export const restoreUser = () => {
  if (window.localStorage.getItem(LOCAL_STORAGE_USER_KEY)) {
    const userStr = window.localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    // @ts-ignore
    return JSON.parse(userStr);
  }
    return undefined;
}

export const resetUser = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
}

export const storeUser = (user: User) => {
  window.localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
}

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action: AuthActionProps) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload!;
      return {
        ...state,
        user
      };
    }
    case LOGIN: {
      const { user } = action.payload!;
      if (user) {
        storeUser(user as User);
      }
      document
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case LOGOUT: {
      resetUser();
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
