import { createAsyncThunk } from '@reduxjs/toolkit';
import MyAxios from 'api';
import {COMPONENT_KEY_NAME} from "./data-quality.common";
import {getCachedValue, setCachedValue} from "../../utils/local_storage";
import axios from "../../api";
import {BASE_ASYNC_URL, BASE_RESEARCH_URL} from "../../config";
import {simulationReply} from "../portfolios/portfolio.api";

const getInstruments = () => axios.get(`${BASE_ASYNC_URL}/api/portfolio/instruments`);

export const loadInstrumentsInfoApiFunct = () => {
    const marker = `${COMPONENT_KEY_NAME}/instruments`;

    return createAsyncThunk(
        marker,
        async (thunkAPI) => {
            const cachedData = getCachedValue(marker);
            if (cachedData) {
                return cachedData;
            }
            const data = await getInstruments();
            const value = data['data']
            setCachedValue(marker, value);
            return value;
        }
    );
};

const xxxData = { 'instruments': [{
        'exchange': 'bnx',
'symbol': 'BTC-USDT',
    'interval': '1d',
'contract_type': 'spot',
'schema': 'crypto',
    'field':  'close'
}]}

export const dataQualityApi = createAsyncThunk(
    `${COMPONENT_KEY_NAME}/analysis`,
    async (data) => {
        // const response = await axios.post(`${BASE_RESEARCH_URL}/data/analysis`, xxxData);
        const response = await axios.post(`${BASE_RESEARCH_URL}/data/analysis`, data);
        return response['data'] as simulationReply;
    }
);

export const loadInstrumentsInfoApi = loadInstrumentsInfoApiFunct();