
export const COMPONENT_KEY_NAME = 'researchProducts';

export type ActionModeType = 'researchProducts'
|'strategy'
// |'thematics'
;

export const ActionMode = {
    researchProducts: 'researchProducts' as ActionModeType,
    strategy:          'strategy' as ActionModeType,
    // thematics:         'thematics' as ActionModeType,
};

export const strategyDescription = [
// {name:'Long-Short Strategy',
// id_name:'SG-02' ,
// location_name:'Long-Short-Strategy',
// type:'strategy',
// description: 'Our innovative long-short strategy aims to capitalize on price dislocations between currencies by short-selling overvalued currencies and buying undervalued currencies. ' +
//             'These opportunities are detected through a sophisticated artificial intelligence model designed to identify statistical arbitrage opportunities. To ensure risk control and stability, ' +
//             'our portfolio construction incorporates measures to control for volatility, resulting in a market-neutral strategy. We are proud to maintain a full-sample correlation of just 0.02 with BTC,' +
//             ' demonstrating our commitment to providing diversification. Our signals are generated on a daily basis, allowing for flexible holding periods ranging from 2 days to 3 weeks. We have intentionally chosen' +
//             ' this lower frequency to enhance scalability and accommodate a wider range of investors. We strive to deliver consistent returns by taking advantage of currency price dislocations, while prioritizing risk management and scalability.'
// },

{name:'Long-Flat Strategy',
id_name:'SG-01' ,
type:'strategy',
location_name:'Long-Flat-Strategy',
description: "Aiming to consistently outperform Bitcoin with reduced volatility, our long-flat strategy hinges on a model that either takes a position or remains on the sidelines," +
            " focusing on medium-frequency momentum trends in the most liquid cryptocurrencies.Employing artificial intelligence, the model strives to detect and adjust to momentum shifts in cryptocurrency prices, " +
            "ensuring participation on the upside, while protecting the investor from large drawdowns. Our portfolio construction is steered by advanced machine learning algorithms, ensuring effective risk management." +
            "With a goal to surpass Bitcoin's long-term performance, this strategy offers not only decreased volatility but also significantly curtailed drawdowns."
},

{name:'Smart Contract Platforms',
id_name:'newBridge:SmartContractPlatform' ,
type:'thematics',
location_name:'Smart-Contract-Platforms',
description: "The Smart Contract Platform sector forms the foundational infrastructure for decentralized applications (dApps), enabling the execution of code on a distributed ledger without centralized control." +
            " It's segmented into three core layers: Layer 0 underpins interoperability across multiple blockchains, Layer 1 operates as the main execution layer for dApps transactions, and Layer 2 enhances scalability" +
            " and efficiency for Layer 1 networks. Ethereum (ETH), the dominant Layer 1 blockchain, currently serves as the main hub for executing financial transactions and trading digital collectibles."
},

{name:'Payments',
    id_name:'newBridge:PurePrivacy' ,
    type:'thematics',
    location_name: 'Payments',
    description: "The Payments sector encompasses blockchain-based projects designed to streamline the transfer of value across networks and borders. Leveraging decentralized technology, it aims to provide faster," +
        " more transparent, and cost-efficient alternatives to traditional financial systems. By reducing transaction fees and settlement times, it enables efficient global transactions and opens new opportunities for" +
        " businesses and individuals. Projects in this sector are redefining how value is exchanged worldwide, contributing to the future of secure, seamless, and inclusive digital payments."

},

{name:'NFT, Gaming, Metaverse',
id_name:'newBridge:NFT' ,
type:'thematics',
location_name: 'NFT-Gaming-Metaverse',
description: 'This combined sector explores the convergence of non-fungible tokens (NFTs), digital gaming, and Metaverse platforms, focusing on digital ownership, entertainment, and virtual realities.' +
            ' NFTs serve as a mechanism to own unique digital items such as art, collectibles, or in-game assets, which are integral to gaming and virtual worlds. Gaming platforms use blockchain technology to enable' +
            ' players to truly own their in-game items and earn through play-to-earn models. Metaverse platforms, like Decentraland and The Sandbox, provide immersive environments where these NFTs and gaming experiences can be experienced and traded.'
},

{name:'DeFi',
id_name:'newBridge:DeFi' ,
type:'thematics',
location_name:'DeFi',
description: "The DeFi sector contains projects which allows individuals to manage their financial activities through decentralized applications without central intermediaries." +
            " It encompasses most traditional financial services in a decentralized way, such as spot and futures exchanges, lending, insurance and asset management. Financial offerings" +
            " within DeFi are available to anyone without the need for personal identification, operating on platforms underpinned by smart contracts."
},

// {name:'Web3',
// id_name:'newBridge:Web3' ,
// type:'thematics',
// location_name:'Web3',
// description: ''
// },



// {name:'Payments',
// id_name:'newBridge:PurePrivacy' ,
// type:'thematics',
// location_name:'Payments',
// description: ''
// },

];

