import React, {useEffect, useState} from "react";
import {Stack, Box, Grid, Button} from "@mui/material";
import { connect } from 'react-redux';
import { RootState } from 'store';
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {COMPONENT_KEY_NAME, strategyDescription} from "./researche-products.common";
import {withRouter} from "../../components/util-components/with_router";
import {
    headerBackgroundColor,
    mainBackgroundColor,
    mainTextColor,
    primaryTextColor,
    WS_RESEARCH_URL
} from "../../config";
import {MainOrangeButton} from "../../components/tab-component";
import {styled} from '@mui/system';
import MonitoringStrategies from "./monitoring-strategies";
import ProductsList from "./products-list";
import {restoreUser, storeUser} from "../../store/reducers/auth";
import {
    loadInstrumentsInfoApi,
    loadPortfoliosInfoApi,
    simulatePortfolioApi,
    // updatePortfolioApi
} from "./strategy.api";
import {updatePortfolioApi} from "../portfolios/portfolio.api";
import {applyTemporraryPortfolioTask, dashboardMonitorActions} from "./research-products.slice";
import {Portfolio} from "../../models/portfolio";
import {dateSerializerNew} from "../../components/controls/form/nb-data-picker";
import {$$} from "../../utils/utils";
import {getIndexByPortfolioId, getPortfolioByIndex} from "../portfolios/helper";
import {ID} from "../../models/common";
import {ActionMode} from "./researche-products.common";
import {wsConnectorResearch} from "../../components/system/websocket-component";
import ConfirmDialog from "../../components/dialogs/dialog";
import StrategiesWaitListDialog from "./strategies-wait-list-dialog";


interface ComponentProps extends StateProps, DispatchProps {}

class DashboardMonitorComponent extends React.Component<ComponentProps> {

    getUser = () => restoreUser();


    initWs(userId) {
        if (!wsConnectorResearch.ready) {
            const secureKey  = 'RSX';
            wsConnectorResearch.url = `${WS_RESEARCH_URL}/${userId}/${secureKey}`;
            console.log("############# wsConnectorResearch.url::", wsConnectorResearch.url)
            wsConnectorResearch.init_receiver((payload) => {
                let error: string | undefined = undefined;
                try {
                    const data = JSON.parse(payload.data || '{}');
                    if(data.result === 'error') {
                        error = data['err-msg']
                        console.log('WEBSOCKET DATA REPORTED ERROR::', data)
                        this.props.reportTaskError({data, message: error})
                    }
                    else {
                        console.log('############### PORTFOLIO. WEBSOCKET Payload::DATA', data);
                        this.props.applySimulationWsResult(data);
                    }
                }
                catch (e) {
                    this.props.reportTaskError({data: {}, message: 'Unexpected error'})
                }
            });
        }
    }
    componentDidMount() {
        const { assets, portfolios, currentPortfolioIndex,isWaitlistSubmited } = this.props;
        const { location, navigate, params } = this.props['router'];
        const user = this.getUser();

        if (!user) {
            return;
        }
        if (!portfolios  || portfolios.length === 0) {
            this.props.loadPortfoliosInfo();
        }
        if (! assets || assets.length === 0) {
            this.props.loadInstrumentsInfo();
        }
        if(location.state !== null && location.pathname !== '/researchProducts'){
            const namePortfolio =  strategyDescription.find(item => item.name === location.state.name);
            const currentPortfolio = portfolios.find(portfolio => portfolio.name === namePortfolio?.id_name);
            const currentId = location.state.name ? currentPortfolio?.id : location.state.id;
            if(assets.length !== 0 && portfolios.length !== 0) {
                setTimeout(() => this.props.refresh({mode: ActionMode.strategy, targetId:currentId}),500);
            } else {
                setTimeout(() => this.props.refresh({mode: ActionMode.strategy, targetId:currentId}),500);
            }
        }
        if(location.state !== null) {
            this.getUpdatePortfolio(location.state.id);
        }
        // if(isWaitlistSubmited === false){
        // this.getSubmitedWaitlist();
        // }
        this.props.getCurrentDemouser(user.uid);
    }

    componentDidUpdate() {
    }

    clonePortfolio = (targetId: ID) => {
        const base = this.props.portfolios.find(it => it.id === targetId) as Portfolio;
        const portfolio = { ...base, id: '', name: base.name + ' CLONED' };
        // @ts-ignore
        portfolio.assets = base.assets.map(it => ({ id: it.id, value: it.value }));
        this.updatePortfolio(portfolio);
    }

    getUpdatePortfolio = (id) => {
        setTimeout(() => this.props.applyTemporraryPortfolioTask(id), 1000);
    }

    updatePortfolio = (portfolio: Portfolio) => {
        // @ts-ignore
        this.props.updatePortfolio(portfolio);
    }


    onRefresh = (updateParams: {}) => {
        this.props.refresh(updateParams);
    };
    benchmarkList = () => {
       return  this.props.assets;
    };

    simulatePortfolio = (portfolio: Portfolio) => {
        const user = restoreUser();
        const dateStart = () => portfolio._dateStart;
        const dateEnd = () => portfolio._dateEnd;
        const inceptionDate = () => {
            if(portfolio.inceptionDate !== null) {
                const inception = dateSerializerNew(new Date(portfolio.inceptionDate * 1000));
                return inception;
            } else {
                return  portfolio._dateStart;
            }
        };

        const benchmark = () =>  {
            if(portfolio.benchmark !== null||undefined) {
                const bench = this. benchmarkList().find(it => it.id === portfolio.benchmark.instrumentId) || portfolio.benchmark;
                return {symbol: bench?.symbol};
            }
        };
        const msg = () => portfolio.msg;

        const extraInstruments = portfolio.extra_instruments;

        const instrumentAssets = portfolio.assets.map((it,idx) => {
            return {symbol: it.symbol, weight: it.value}
        });

        let simulationSeq = user['simulationSeqn'] || 0;
        simulationSeq += 1
        user['simulationSeqn'] = simulationSeq;
        storeUser(user);

        const datawithoutBench = {
            // msg : 'simulation-request',
            msg:msg(),
            ts: Date.now().valueOf(),
            // dateStart: dateStart(),
            // dateStart: msg() === 'simulation-request' ? inceptionDate() : dateStart(),
            dateStart: inceptionDate(),
            dateEnd: dateEnd(),
            seqn: simulationSeq,
            rebalance_interval:portfolio.frequency,
            referencing_currency:portfolio.currency,
            // optimized_method:portfolio.optimized_method,
            optimized_function:portfolio.optimized_function,
            clientId: user.id,
            instruments: instrumentAssets,
            extra_instruments:extraInstruments,
        }

        const data = portfolio.benchmark.symbol === '*'  ?  datawithoutBench : {...datawithoutBench, benchmark:benchmark()}
        // @ts-ignore
        this.props.simulatePortfolio(data);
    }


    setCurrentTask = (seqn: number) => this.props.setCurrentTask(seqn);

    closeWaitlist = () => {
        this.props.setOpenWaitlist(false);
        this.props.setSubmitedWaitlist(false);
    };

    openWaitlist = () => {
        this.props.setOpenWaitlist(true);
    };

    submitWaitlist = () => {
        this.props.setSubmitedWaitlist(true);
        this.props.setOpenWaitlist(false);
    }

    // getSubmitedWaitlist = () => {
    //     // setTimeout(() => this.openWaitlist(),120000);
    //     setTimeout(() => this.openWaitlist(),30000);
    // }



    render(){
        const {
            actionMode,
            portfolios,
            currentPortfolioIndex,
            equityOverviewCurrentCaseStr,
            equityOverviewData,
            keyMetricsOverviewData,
            annualReturnsOverviewData,
            strategyResult,
            openWaitlist,
            isWaitlistSubmited,
            currentDemouser,
        } = this.props;
        const {location,navigate,params} = this.props['router'];
        const portfolio = () => {
            const namePortfolio = strategyDescription.find(item => {
                if(location.state !== null){
                    return item.name === location.state.name;
                }
            });
            const currentPortfolio = portfolios.find(portfolio => portfolio.name === namePortfolio?.id_name);
            const currentId = location.state !== null ? location.state.name ? currentPortfolio?.id : location.state.id : -1;
            const index = location.state !== null ? getIndexByPortfolioId(portfolios,currentId) : -1;
            if(currentPortfolioIndex !== -1 && index !== null && currentPortfolioIndex === index) {
                return getPortfolioByIndex(portfolios, currentPortfolioIndex);
            } else {
                return getPortfolioByIndex(portfolios,index);
            }
        };

        // console.log(isWaitlistSubmited + '++++++++++++++' + openWaitlist)
        // setTimeout(() => {
        //     // const user = restoreUser();
        //     // console.log(user.uid)
        //     if(isWaitlistSubmited === true &&  currentDemouser){
        //
        //     }else {
        //         this.openWaitlist()
        //     }
        // },30000)

        return (<>
            {location.pathname.includes('strategy')  || location.pathname.includes('thematics')?
                <MonitoringStrategies
                    portfolio={portfolio()}
                    renderData={equityOverviewData}
                    caseStr={equityOverviewCurrentCaseStr}
                    actionMode={actionMode}
                    annualReturns={annualReturnsOverviewData}
                    keyMetricksData={keyMetricsOverviewData}
                    possibleBenchmark={this.benchmarkList()}
                    extraCall={this.simulatePortfolio}
                    strategyResult={strategyResult}
                    setCurrentTask={this.setCurrentTask}
                />
            :
                <ProductsList
                portfolios={portfolios}
                onRefresh={this.onRefresh}
                />
            }
            {/*{openWaitlist && <ConfirmDialog title={'Wait List'} handleAgree={this.submitWaitlist} handleCancel={this.closeWaitlist} waitlist={true}>*/}
            {/*    <StrategiesWaitListDialog/>*/}
            {/*</ConfirmDialog>}*/}
        </>)
    }
};

const ownSettings = {
    version: '0.01'
};


const mapStateToProps = ( { [COMPONENT_KEY_NAME]: state } : RootState) => ({
    ...ownSettings, ...state
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    loadPortfoliosInfo: bindActionCreators(loadPortfoliosInfoApi, dispatch),
    loadInstrumentsInfo:bindActionCreators(loadInstrumentsInfoApi, dispatch),
    simulatePortfolio:  bindActionCreators(simulatePortfolioApi, dispatch),
    refresh:            bindActionCreators(dashboardMonitorActions.refresh, dispatch),
    updatePortfolio:    bindActionCreators(updatePortfolioApi, dispatch),
    applyTemporraryPortfolioTask:    bindActionCreators(dashboardMonitorActions.applyTemporraryPortfolioTask, dispatch),
    applySimulationWsResult: bindActionCreators(dashboardMonitorActions.applySimulationWsResult, dispatch),
    reportTaskError:    bindActionCreators(dashboardMonitorActions.reportTaskError, dispatch),
    setCurrentTask:     bindActionCreators(dashboardMonitorActions.setCurrentTask, dispatch),
    setOpenWaitlist:   bindActionCreators(dashboardMonitorActions.setOpenWaitlist,dispatch),
    setSubmitedWaitlist:   bindActionCreators(dashboardMonitorActions.setSubmitedWaitlist,dispatch),
    getCurrentDemouser:   bindActionCreators(dashboardMonitorActions.getCurrentDemouser,dispatch),

});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;


const DashboardMonitorComponentWithRouter = withRouter(DashboardMonitorComponent);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardMonitorComponentWithRouter);

