import { createSlice, current } from '@reduxjs/toolkit';
import { isPendingAction, isRejectedAction, isSucceededAction } from 'utils/refdux-utils';
import { BasePageDefaultState, BasePageState } from 'components/pages/pages-common';
import React from 'react';
import {COMPONENT_KEY_NAME, gapDataGraphSelector, gapDataGraphTimeranges} from "./data-quality.common";
import {expandPortfolios} from "../portfolios/portfolio.common";
import {Asset} from "../../models/asset";
import {PortfolioInputType} from "../portfolios/portfolio.api";
import {dataQualityApi, loadInstrumentsInfoApi} from "./data-quality.api";
import {AssetVolume, AssetVolumeEmptyItem} from "../../models/portfolio";
import {EMPTY_CASE_STR, GraphicCaseImpl} from "../../models";
import {getClassName, getPortfolioTaskBySeqn} from "../portfolios/helper";
import {
    annualReturnsOverviewGraphics,
    annualReturnsSimulatioGraphics,
    dailyReturnsOverviewGraphics,
    distributionMonthlyGraphics, drawdownPeriodsGraphics,
    equityOverviewGraphics,
    keyMetricsOverviewGraphics,
    monthlyReturnsOverviewGraphics,
    rollingSharpeGraphics, rollingSharpeOptimizeGraphics, rollingSharpeSimulatedGraphics,
    rollingSortinoGraphics, rollingSortinoOptimizedGraphics, rollingSortinoSimulatedGraphics,
    rollingVolatilityGraphics, underwaterGraphics, worstDrawDownsGraphics
} from "../portfolios/portfolio.slice";
import {randomString} from "../../utils/misc";
import {$$} from "../../utils/utils";
import {ROLLSHARPE, ROLLSORTINO} from "../../data/graphics";


interface State extends BasePageState {
    assets: Asset[];
    dataQualityResult:string;
    _portfoliosRawInput: PortfolioInputType[];
    equityOverviewCurrentCaseStr: string;
    equityOverviewData: any[];
    keyMetricsOverviewCurrentCaseStr:string;
    keyMetricsOverviewData:any[];
    annualReturnsOverviewCurrentCaseStr:string;
    annualReturnsOverviewData:any[];
    performanceMetricsOverviewCurrentCaseStr:string;
    performanceMetricsOverviewData:any[];
    worstDrawDownsCurrentCaseStr:string;
    worstDrawDownsData:any[];
    drawdownPeriodsCurrentCaseStr:string;
    drawdownPeriodsData:any[];
    rollingVolatilityCurrentCaseStr:string;
    rollingVolatilityData:any[];
    rollingSharpeCurrentCaseStr:string;
    rollingSharpeData:any[];
    rollingSortinoCurrentCaseStr:string;
    rollingSortinoData:any[];
    distributionMonthlyCurrentCaseStr:string;
    distributionMonthlyData:any[];
    dailyReturnsOverviewCurrentCaseStr:string;
    dailyReturnsOverviewData:any[];
    monthlyReturnsOverviewCurrentCaseStr:string;
    monthlyReturnsOverviewData:any[];
    underwaterPlotCurrentCaseStr:string;
    underwaterPlotData:any[];
    gapData:any[];
    taskStatus:string;
}

export const gapDataGraphics = new GraphicCaseImpl(gapDataGraphSelector,'day',gapDataGraphTimeranges);

const initialState: State = {
    ...BasePageDefaultState,
    assets: [],
    dataQualityResult:'',
    _portfoliosRawInput: [],
    equityOverviewCurrentCaseStr: EMPTY_CASE_STR,
    equityOverviewData:[],
    keyMetricsOverviewCurrentCaseStr:EMPTY_CASE_STR,
    keyMetricsOverviewData:[],
    annualReturnsOverviewCurrentCaseStr:EMPTY_CASE_STR,
    annualReturnsOverviewData:[],
    performanceMetricsOverviewCurrentCaseStr:EMPTY_CASE_STR,
    performanceMetricsOverviewData:[],
    worstDrawDownsCurrentCaseStr:EMPTY_CASE_STR,
    worstDrawDownsData:[],
    drawdownPeriodsCurrentCaseStr:EMPTY_CASE_STR,
    drawdownPeriodsData:[],
    rollingSharpeCurrentCaseStr:EMPTY_CASE_STR,
    rollingSharpeData:[],
    rollingSortinoCurrentCaseStr:EMPTY_CASE_STR,
    rollingSortinoData:[],
    rollingVolatilityCurrentCaseStr:EMPTY_CASE_STR,
    rollingVolatilityData:[],
    distributionMonthlyCurrentCaseStr:EMPTY_CASE_STR,
    distributionMonthlyData:[],
    dailyReturnsOverviewCurrentCaseStr:EMPTY_CASE_STR,
    dailyReturnsOverviewData:[],
    monthlyReturnsOverviewCurrentCaseStr:EMPTY_CASE_STR,
    monthlyReturnsOverviewData:[],
    underwaterPlotCurrentCaseStr:EMPTY_CASE_STR,
    underwaterPlotData:[],
    gapData:[],
    taskStatus:'',
}


const performSuccessRequest = (state, successPerformer?, action?) => {
    state['apiStatus'] = 'succeeded';
    return successPerformer ? successPerformer(state, action) : state;
}

const apiError = (state, action) => {
    state.apiStatus = 'failed';
    if (action.error && action.error.message && action.error.message.endsWith('401')) {
        state.apiStatus = '401';
    }
    return state;
}
const applyInstrumentsInfo = (state: State, action) => {
    const payload = action.payload || {};

    // @ts-ignore
    state.assets = payload.instruments;

    state.assets.sort((a,b) => {
        return  a.name.localeCompare(b.name)
    }).sort((c,d) => {
        return c.class.localeCompare(d.class)
    })

    console.log('INSTRUMENT::Info:',state.assets);

    // if (state._portfoliosRawInput) {
    //     state.portfolios = expandPortfolios(state._portfoliosRawInput, state.assets);
    //     state._portfoliosRawInput = [];
    // }
    return state;
}

const dataQualityStartGo = (state,instruments) => {
    return state;
};


const dataQualityStart = (state: State, action) => {
    if (action.meta && action.meta.arg) {
        // console.log('dataQualityStart',action)
        const {instruments} = action.meta.arg;
        const taskId = $$.uniqueId('taskId');
        state.dataQualityResult = '';
        state.taskStatus = 'process';
        return dataQualityStartGo(state,action.meta.arg);
    }
    return state;
};
const is_action_synchronises = payload => payload.result && payload.result === 'success';
const is_action_synchronises_error = payload => payload.result && payload.result === 'error';

const dataQuality = (state: State, action) => {
    // console.log('dataQuality',action)
    // if (is_action_synchronises(action.payload)) {
    //     state.dataQualityResult = action.payload.result;
    //     return applyDataQualityResultSuccess(state, action.payload);
    // }
    //
    // if (is_action_synchronises_error(action.payload)) {
    //     // return applySimulationResultError(state, action.payload);
    // }
    //
    // if(action.payload.status === 'ERROR') {
    //     console.log('REQUEST ERROR')
    //     // return applySimulationResultError(state, action.payload);
    // }
    // else {
    //     const { taskId, seqn, dateStart, dateEnd, benchmark,frequency } = action.payload;
    //     // return simulatePortfolioStartGo(state, taskId, seqn, dateStart, dateEnd, benchmark,frequency);
    // }
    if(action.payload) {
        state.dataQualityResult = 'success';
        state.taskStatus = '';
    }
    return applyDataQualityResultSuccess(state, action.payload);
}

const applyDataQualityResultSuccess = (state: State, payload) => {
    const seqn = payload.seqn;
    const data = payload;
    const equityCurve = data.close && data.close;
    const annualReturns = data.risk_metrics && [{clientPortfolio:data.risk_metrics.AYR}];
    const distributionMonthly = data.risk_metrics &&  [{clientPortfolio:data.risk_metrics.DMR}];
    const dailyReturns = data.risk_metrics && [{clientPortfolio:data.risk_metrics['PERF-SR']}];
    const monthlyReturns = data.risk_metrics && [{clientPortfolio:data.risk_metrics['PERF-AMR']}];
    const rollingVolatility = data.risk_metrics && [{clientPortfolio:data.risk_metrics.ROLVOL}];
    const rollingSharpe = data.risk_metrics && [{clientPortfolio:data.risk_metrics.ROLSHARPE}];
    const rollingSortino = data.risk_metrics && [{clientPortfolio:data.risk_metrics.ROLSORTINO}];
    const underwaterPlot = data.risk_metrics && [{clientPortfolio:data.risk_metrics.UNDERWATER}];
    const drawdownPeriods = data.risk_metrics && [{clientPortfolio:data.risk_metrics.TOP5DD}];
    const worstDrawdown = data.risk_metrics && [{clientPortfolio:data.risk_metrics.WORST10DD}];
    const keyMetrics = data.risk_metrics && [{clientPortfolio:data.risk_metrics.YTDR},
        {clientPortfolio:data.risk_metrics.ANRR},
        {clientPortfolio:data.risk_metrics.CUMRET},
        {clientPortfolio:data.risk_metrics.ANVOL},
        {clientPortfolio:data.risk_metrics.SRATIO},
    ];
    const gap = data.gaps;
    // console.log(actualRollingSharpeSimulated)
    const sixMonth = (currentGraph,currentResult) => {
        const month = currentGraph !== undefined && currentGraph.map((item) => {
            if(item[currentResult] !== undefined) {
                return [item[currentResult].result_6m.result, item[currentResult].mean_6m.result]
            }else{
                return []
            }
        });
        return month;
    };
    const twoYear = (currentGraph,currentResult) => {
        const year = currentGraph !== undefined && currentGraph.map((item) => {
            if(item[currentResult] !== undefined) {
                return [item[currentResult].result_2y.result, item[currentResult].mean_2y.result]
            }else{
                return []
            }
        });
        return year;
    };

    const actualRollingSharpe = [sixMonth(rollingSharpe,['clientPortfolio']),
        twoYear(rollingSharpe,['clientPortfolio'])];
    const actualRollingSortino = [sixMonth(rollingSortino,['clientPortfolio']),
        twoYear(rollingSortino,['clientPortfolio'])];

    console.log('################# metrics::', data);


    equityOverviewGraphics.setInitialCases([equityCurve]);
    state.equityOverviewCurrentCaseStr = `auto:${randomString()}`;
    state.annualReturnsOverviewCurrentCaseStr = `auto:${randomString()}`;
    annualReturnsOverviewGraphics.setInitialCases([annualReturns]);
    state.distributionMonthlyCurrentCaseStr = `auto:${randomString()}`;
    distributionMonthlyGraphics.setInitialCases([distributionMonthly]);
    dailyReturnsOverviewGraphics.setInitialCases([dailyReturns]);
    state.dailyReturnsOverviewCurrentCaseStr = `auto:${randomString()}`;
    monthlyReturnsOverviewGraphics.setInitialCases([monthlyReturns]);
    state.monthlyReturnsOverviewCurrentCaseStr = `auto:${randomString()}`;
    rollingVolatilityGraphics.setInitialCases([rollingVolatility]);
    state.rollingVolatilityCurrentCaseStr = `auto:${randomString()}`;
    state.rollingSharpeCurrentCaseStr = rollingSharpeGraphics.getDefaultCaseStr();
    rollingSharpeGraphics.setInitialCases([actualRollingSharpe][0]);
    state.rollingSortinoCurrentCaseStr = rollingSortinoGraphics.getDefaultCaseStr();
    rollingSortinoGraphics.setInitialCases([actualRollingSortino][0]);
    underwaterGraphics.setInitialCases([underwaterPlot]);
    state.underwaterPlotCurrentCaseStr = `auto:${randomString()}`;
    drawdownPeriodsGraphics.setInitialCases([drawdownPeriods]);
    state.drawdownPeriodsCurrentCaseStr = `auto:${randomString()}`;
    worstDrawDownsGraphics.setInitialCases([worstDrawdown]);
    state.worstDrawDownsCurrentCaseStr = `auto:${randomString()}`;
    keyMetricsOverviewGraphics.setInitialCases([keyMetrics]);
    state.keyMetricsOverviewCurrentCaseStr = `auto:${randomString()}`;
    gapDataGraphics.setInitialCases([gap]);


    // console.log('############ applySimulationResult::getCurrentState::', getCurrentState(state))
    return state;
}

export const dataQualitySlice = createSlice({
    name:COMPONENT_KEY_NAME,
    initialState,
    reducers:{
        setSection(state, action) {
            // const sectionId = action.payload;
            const {id, timeRange} = action.payload;
            // console.log(action.payload)
            const sectionId = id;
            // console.log(sectionId)

            if(sectionId === ROLLSHARPE) {
                const xtab = {
                    '6m window': '6mWindow',
                    '2y window': '2yWindow'
                };

                const actualTimeRange = xtab[timeRange];
                const index = rollingSharpeGraphics.getIndex(sectionId, actualTimeRange);
                state.rollingSharpeCurrentCaseStr = `${sectionId}:${actualTimeRange}`;
                rollingSharpeGraphics.selectCase(sectionId, actualTimeRange);
            }
            if(sectionId === ROLLSORTINO) {
                const xtab = {
                    '6m window': '6mWindow',
                    '2y window': '2yWindow'
                };

                const actualTimeRange = xtab[timeRange];
                const index = rollingSortinoGraphics.getIndex(sectionId,actualTimeRange);
                state.rollingSortinoCurrentCaseStr = `${sectionId}:${actualTimeRange}`;
                rollingSortinoGraphics.selectCase(sectionId,actualTimeRange);
            }
            return state;
        },
    },
    extraReducers: (builder) => builder
        .addCase(loadInstrumentsInfoApi.fulfilled,(state, action)   => performSuccessRequest(state, applyInstrumentsInfo, action))
        .addCase(dataQualityApi.fulfilled,(state,action) =>performSuccessRequest(state,dataQuality,action))
        .addCase(dataQualityApi.pending,(state,action) =>performSuccessRequest(state,dataQualityStart,action))
        .addMatcher(isPendingAction,        (state, action) => { state.apiStatus = 'pending'; return state; })
        .addMatcher(isRejectedAction,       (state, action) => apiError(state, action))
        .addMatcher(isSucceededAction,      (state, action) => { state.apiStatus = 'succeeded'; return state; })

});

export const dataQualityDefaultActions = dataQualitySlice.actions;

export default dataQualitySlice.reducer;



