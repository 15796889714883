import React, {useEffect} from "react";
import {Stack, Box, Grid, Button} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EquityCurveView from "./portfolio-graphs/equity-curve";
import EoyReturns from "./portfolio-graphs/eoy-returns";
import RollingSharpe from "./portfolio-graphs/rolling-sharpe";
import RollingSortino from "./portfolio-graphs/rolling-sortino";
import MonthlyReturnsPortfolio from "./portfolio-graphs/monthly-returns-portfolio";
import DailyReturnsPortfioio from "./portfolio-graphs/daily-returns-portfioio";
import ReturnQuantiles from "./portfolio-graphs/return-quantiles";
import {
    AssetVolume,
    AssetVolumeEmptyItem,
    Portfolio,
    PortfolioEmptyItem,
    PortfolioTaskStatusType
} from "../../models/portfolio";
import {
    buttonBackgroundColor, extraLogging,
    mainBackgroundColor,
    mainTextColor,
    primaryTextColor, RESOLUTION_SCREEN,
    secondTextColor
} from 'config';
import {TabItem, TabsComponent} from "components/tab-component";
import DateRangeForm from "components/controls/form/date-range";
import {MyNewAutocomplete} from "components/controls/form/autocomplete-new";
import KeyMetrics from "./portfolio-graphs/key-metrics";
import PerformanceMetrics from "./portfolio-graphs/performance-metrics";
import {Asset} from "../../models/asset";
import useState from "react-usestateref";
import {RefHoster} from "components/controls/form/ref-hoster";
import PortfolioComposition from "./portfolio-composition";
import {ActionMode, getRouteSuffix} from "./portfolio.common";
import {
    adjustPossibleAssetOptions, equityOverviewGraphics,
    reportTaskError,
    rollingSharpeGraphics,
    rollingSortinoGraphics
} from "./portfolio.slice";
import {useDispatch} from "react-redux";
import ButtonsNavigation from "components/portfolios/portfolio-buttons/portfolio-navigation-buttons";
import {floatRound} from "utils/finance-utils";
import { getCurrentPortfolioTask, getLatestPortfolioTask } from './helper';
import '../../components/portfolios/porfolio.css';
import WorstDrawdowns from "./portfolio-graphs/worst-drawdowns";
import RollingVolatility from "./portfolio-graphs/rolling-volatility";
import RollingBetaToBenchmark from "./portfolio-graphs/rolling-beta-benchmark";
import ValueRiskExpectedShortfall from "./portfolio-graphs/value-risk-expected-shortfall";
import DrawdownPeriods from "./portfolio-graphs/drawdown-periods";
import UnderwaterPlot from "./portfolio-graphs/underwater-plot";
import DistributionMonthlyReturn from "./portfolio-graphs/distribution-monthly-return";
import AnnualReturns from "./portfolio-graphs/eoy-returns";
import {EQUITY_CURVE_ID, ROLLING_VOLATILITY_ID} from "../../data/graphics";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import {dateSerializerNew} from "../../components/controls/form/nb-data-picker";
import {getClassName} from "pages/portfolios/helper";
import {as_timestamp} from "../../utils/date-utils";


interface PortfolioOverviewProps {
    portfolio: Portfolio;
    onRefresh: Function;
    holder: Record<string, any>;
    infoTabsOnChange?:Function;
    possibleBenchmark: Asset[];
    possibleAssets:Asset[];
    currentAssetVolume: AssetVolume;
    onRemove: Function;
    extraCall?: Function;
    caseStr: string;
    renderData: any;
    rollingSharpe:any;
    rollingSharpeCaseString:string;
    rollingSortino:any;
    rollingSortinoCaseString:string;
    keyMetricksData:any;
    performanceMetrics:any;
    rollingVolatility:any;
    currentControlDate: string;
    worstDrawDownData:any;
    dailyReturnsData:any;
    drawdownPeriodsData:any;
    distributionMonthlyCaseString:string;
    distributionMothlyData:any;
    monthlyReturns:any;
    underwaterPlot:any;
    annualReturns:any;
    setCurrentTask: Function;
    onChange:(item: TabItem) => void;
    actionMode:string;
    isSectionIdMonitoring:boolean;
    startTimeRequest:number;
}

const SectionTabItems = [
    { title:'Overview',    id:'Overview'},
    { title:'Performance', id:'Performance'},
    { title:'Risk Metrics',id:'Risk Metrics'},
] as TabItem[];
export const processingFontResolution = RESOLUTION_SCREEN === 'horizontal' ? '24px' : '16px';


const PortfolioViewOverview = (props:PortfolioOverviewProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const portfolio = props.portfolio;
    const dateStart = portfolio._dateStart;
    const dateEnd = portfolio._dateEnd;
    const inceptionDate = portfolio.inceptionDate;
    const resultResponse = 'success';
    const currentControlDate = new Date(props.currentControlDate || new Date().toISOString());
    const renderData = props.renderData;
    const keyMetricksData = props.keyMetricksData;
    const performanceMetrics = props.performanceMetrics;
    const worstDrawDownData =props.worstDrawDownData;
    const dailyReturnsData = props.dailyReturnsData;
    const underwaterPlot = props.underwaterPlot;
    const annualReturns = props.annualReturns;
    const monthlyReturns = props.monthlyReturns;
    const rollingVolatility = props.rollingVolatility;
    const drawdownPeriodsData = props.drawdownPeriodsData;
    const distributionMonthlyData = props.distributionMothlyData;
    const distributionMonthlyCaseString = props.distributionMonthlyCaseString;
    const rollingSharpe = props.rollingSharpe;
    const rollingSortino = props.rollingSortino;
    const rollingSharpeCaseString = props.rollingSharpeCaseString;
    const rollingSortinoCaseString = props.rollingSortinoCaseString;
    const caseStr = props.caseStr;
    const assetsValues = portfolio.assets;
    const portfolioSelected = portfolio.id !== '';
    const onRefresh = props.onRefresh;

    const noBenchmarkAsset = {id: -1, name: 'No Benchmark', symbol: '*', value: 0};
    const possibleBenchmark = [noBenchmarkAsset,...props.possibleBenchmark];
    const portfolioBenchmark = portfolio.benchmark !== null ? possibleBenchmark.find((it) => {
            if(it.id === props.portfolio.benchmark.instrumentId) {
                return it
            }
    }) : undefined;
    const defaultBenchmark = portfolioBenchmark !== undefined ?  portfolioBenchmark : noBenchmarkAsset;
    const [selectBenchmark, setSelectBenchmark] = useState(defaultBenchmark);


    const defaultFrequency  = { name: 'No Rebalancing',   id: 'NoRebalancing'};
    const [frequency, setFrequency] = useState(defaultFrequency);

    const [assetValues, setAssetValues] = useState(assetsValues);
    const SELECT_ASSET_REF_NAME = '_asset_ref_name';
    const SELECT_FREQUENCY_REF_NAME = '_frequency_ref_name';
    const valuesRefs = new RefHoster();

    const [showGraphs, setShowGraphs] = useState(false);
    const [sectionTab, setSectionTab] = useState(SectionTabItems[0]);

    const instrumentLength = portfolio.assets.length;
    const maxInstrumentLength = 20;

    const tasks = portfolio.tasks;
    const latestTask = getCurrentPortfolioTask(portfolio);
    const runningTasks = tasks.filter(task => task.status === 'started' && task.msg === 'simulation-request');
    const completedTasks = tasks.filter((task,idx) => task.seqn === latestTask?.seqn && task.status === 'completed');
    const runningSomething = runningTasks.length > 0;
    const isrunningSomething = completedTasks.length === 0;
    // console.log('latestTask',latestTask)
    // console.log(isrunningSomething)
    // const errorTasksMessage = latestTask && latestTask.status === 'error' ? latestTask.errorMessage : null;
    const errorTasksMessage = latestTask && latestTask.status === 'error' && latestTask.msg === 'simulation-request'? latestTask.errorMessage : null;
    const anyError = errorTasksMessage;
    const isTextErrorMatch = errorTasksMessage?.slice(9).match(/^(.*?),/);
    const textError = isTextErrorMatch && isTextErrorMatch[1];
    const errorMessage = '';
    const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';
    const resolutionWidthBox = RESOLUTION_SCREEN === 'horizontal' ? '140px' : '100px';
    const resolutionWidthAutocomp = RESOLUTION_SCREEN === 'horizontal' ? '488px' : '220px';
    const stackSpace = RESOLUTION_SCREEN === 'horizontal' ? '61px' : '30px';
    const stackSpaceDatePicker = RESOLUTION_SCREEN === 'horizontal' ? '61px' : '30px';
    const stackHeightDatePicker = RESOLUTION_SCREEN === 'horizontal' ? '40px' : '140px';
    const stackDirection = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';
    const params = useParams();

    extraLogging && console.log("############ PortfolioViewOverview::portfolio::PORTFOLIO", portfolio);
    extraLogging && console.log("############ PortfolioViewOverview::portfolio::latestTask", latestTask);
    // console.log("############ PortfolioViewOverview::portfolio::latestTask", latestTask);


     const checkDate = (dateStart,dateEnd) => {
        if (dateStart !== undefined || null || null){
            const start = inceptionDate === null ? new Date(dateSerializerNew(dateStart)).getTime() : inceptionDate * 1000;
           return  start >= Date.parse(dateEnd);
        }
    };

    const getValue = (value: number, minValue= 0) =>  {
        const v = Number(value);
        return isNaN(v) ? 0 :  Math.max(v, minValue);
    };
    const totalPercentage = () => {
        const summa = assetsValues.map(it => Number(it.value)).reduce((a, b) => getValue(a) + getValue(b), 0);
        return floatRound(summa, 2);
    };


    useEffect(() => {
        setTimeout(() => simulatePortfolio(),2000);
    },[params.action,params.id,props.isSectionIdMonitoring]);



    const simulatePortfolio = () => {
        const msg = 'simulation-request';
        const selectedFrequency = frequency.id === '#' || frequency.id === 'NoRebalancing' ? 'None' : frequency;
        if(totalPercentage() === 100  && !checkDate(inceptionDate,dateEnd)) {
            // @ts-ignore
            props.extraCall({...portfolio,benchmark: selectBenchmark, frequency: selectedFrequency,msg:msg});
            setShowGraphs(true);
        } else {
            setShowGraphs(false);
        };
    };



    const onChange = (item: TabItem) => props.infoTabsOnChange ? props.infoTabsOnChange(item) : undefined;
    const changeSectionTab = (item: TabItem) => {
        setSectionTab(item);
    };

    const isDateInceptionLessEnd = () => {
        if(dateEnd !== undefined||null) {
            const oneDay = 24 * 60 * 60 * 1000;
            const end =  new Date(dateSerializerNew(dateEnd)).getTime();
            const start = inceptionDate === null ? new Date(dateSerializerNew(dateStart)).getTime() : inceptionDate * 1000;
            const days = Math.round(Math.abs((end - start)/oneDay))
            return days > 184;
        };
    };


    const reSelect = () => {
       const bench = possibleBenchmark.find(it =>  it.id === defaultBenchmark.id )
        if( bench !== undefined && 'base_symbol' in bench) {
            return getClassName(bench.class) === 'CRYPTO' ? bench.name + ' - ' + bench.base_symbol : bench.name
        }
   };


    const usStockLegend =  reSelect();

    return (<>
        {portfolioSelected ? <Stack direction={'column'} width={'100%'}>
            {runningSomething && !anyError && !errorTasksMessage && props.isSectionIdMonitoring
                  ? <Stack justifyContent={'center'} alignItems={'center'} sx={{width: '100%',height: '100px', marginTop:'20px'}}>
                        <span className={'loader'}></span>
                        {/*<Box style={{color:mainTextColor, fontSize:processingFontResolution}}><br/>Processing. Please wait...</Box>*/}
                    </Stack>
                  : <></>}
            {errorTasksMessage || anyError? <Stack justifyContent={'center'}
                                                    alignItems={'center'}
                                                    sx={{width: '100%',height: '100%', marginTop:'20px'}}>
                    {errorTasksMessage?.includes('No data for instrument') ?
                        <Box sx={{color: mainTextColor}}> No trading days {textError} within selected analysis period.</Box>
                        // :isNotEqualToZeroEQ().length === 0  ?  <Box sx={{color: buttonBackgroundColor}}> * Portfolio metrics will appear after two trading days since inception date.</Box>
                        : <Box className={'errorMessage'} >
                            ERROR! {errorTasksMessage} {errorMessage}.
                            <br/>
                            Please double-check Your values.
                        </Box>
                    }
                </Stack>
                :<></>
            }
            {checkDate(inceptionDate, dateEnd) &&  <Box sx={{color: buttonBackgroundColor}}> * Portfolio metrics will appear after two trading days since inception date.</Box>}


            {showGraphs && !anyError && !runningSomething && resultResponse === 'success' &&  <>
                <Grid container sx={{marginTop:'20px'}}>
                    <Grid item xs={12}>
                        <EquityCurveView
                            caseStr={caseStr}
                            renderData={renderData}
                            legendName={portfolio.name}
                            dateStart={dateStart as Date}
                            dateEnd={dateEnd as Date}
                            usStockLegend={usStockLegend}
                            panelId={EQUITY_CURVE_ID}
                        />
                    </Grid>
                </Grid>

                <Stack direction='row' alignItems='center' sx={{width: '100%',height:'40px'}} marginTop={'80px'} marginBottom={'47px'}>
                    <TabsComponent onChange={changeSectionTab} items={SectionTabItems} />
                </Stack>

                {sectionTab === SectionTabItems[0]  && <>
                     <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <KeyMetrics
                                renderData={keyMetricksData}
                                legendName={portfolio.name}
                                usStockLegend={usStockLegend}
                                className={'keymetrics'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <AnnualReturns
                                renderData={annualReturns}
                                legendName={portfolio.name}
                                usStockLegend={usStockLegend}
                            />
                        </Grid>
                    </Grid>
                    </>
                }

                {sectionTab === SectionTabItems[1] &&
                    <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={5}>
                                <PerformanceMetrics
                                    renderData={performanceMetrics}
                                    legendName={portfolio.name}
                                    usStockLegend={usStockLegend}
                                    className={'performancemetrics'}
                                />
                                <DistributionMonthlyReturn
                                    renderData={distributionMonthlyData}
                                    currentResult={'clientPortfolio'}
                                />
                                <MonthlyReturnsPortfolio
                                    renderData={monthlyReturns}
                                    currentResult={'clientPortfolio'}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack  direction={'column'} spacing={5}>
                                {isDateInceptionLessEnd() && <>
                                    <RollingSharpe
                                    rollingSharpe={rollingSharpe}
                                    rollingSharpeCaseString={rollingSharpeCaseString}
                                    currentResult={'clientPortfolio'}
                                    onChange={props.onChange}
                                    graphicsName={rollingSharpeGraphics}
                                    portfolioName={portfolio.name}
                                    />
                                    <RollingSortino
                                        rollingSortino={rollingSortino}
                                        rollingSortinoCaseString={rollingSortinoCaseString}
                                        onChange={props.onChange}
                                        graphicsName={rollingSortinoGraphics}
                                        portfolioName={portfolio.name}
                                    />
                                </>}
                                <DailyReturnsPortfioio
                                    renderData={dailyReturnsData}
                                    currentResult={'clientPortfolio'}
                                    titleGraph={'DAILY RETURNS PORTFOLIO'}
                                />
                                {/*{isDateInceptionLessEnd() && <>*/}
                                {/*    <Stack spacing={4} sx={{opacity:0.3, marginTop:'100px'}}>*/}
                                {/*        <ReturnQuantiles/>*/}
                                {/*    </Stack>*/}
                                {/*</>}*/}
                            </Stack>
                        </Grid>
                    </Grid>
                }

                {sectionTab === SectionTabItems[2] &&
                    <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                    {/*//  <Grid container spacing={4}>*/}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={5}>
                                <WorstDrawdowns
                                    renderData={worstDrawDownData}
                                    currentResult={'clientPortfolio'}
                                    dateEnd={dateEnd as Date}
                                />
                                {isDateInceptionLessEnd() && <>
                                    <RollingVolatility
                                        renderData={rollingVolatility}
                                        legendName={portfolio.name}
                                        usStockLegend={usStockLegend}
                                        panelId={ROLLING_VOLATILITY_ID}
                                    />
                                    {/*<Stack sx={{opacity:0.3}}>*/}
                                    {/*    <RollingBetaToBenchmark*/}
                                    {/*        renderData={rollingVolatility}*/}
                                    {/*        legendName={portfolio.name}*/}
                                    {/*        usStockLegend={usStockLegend}*/}
                                    {/*        panelId={ROLLING_VOLATILITY_ID}*/}
                                    {/*    />*/}
                                    {/*</Stack>*/}
                                </>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={5}>
                                <DrawdownPeriods
                                    renderData={drawdownPeriodsData}
                                    currentResult={'clientPortfolio'}
                                    legendName={portfolio.name}
                                />
                                <UnderwaterPlot
                                    renderData={underwaterPlot}
                                    currentResult={'clientPortfolio'}
                                />
                                {/*{isDateInceptionLessEnd() && <>*/}
                                {/*    <Stack  sx={{opacity:0.3}}>*/}
                                {/*        <ValueRiskExpectedShortfall/>*/}
                                {/*    </Stack>*/}
                                {/*</>}*/}
                            </Stack>
                        </Grid>
                    </Grid>
                }
                </>
            }
    </Stack>
    :<></>}
    </>)
};

export default PortfolioViewOverview;
