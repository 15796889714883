import React, {createRef, useEffect, useRef, useState} from 'react';
import {createChart, ColorType, TickMarkType, BarPrice} from 'lightweight-charts';
import {buttonBackgroundColor, mainBackgroundColor, mainTextColor} from "../../../config";
import {Stack, Box} from '@mui/material';
import {TabItem} from "../../../components/tab-component";
import {current} from "@reduxjs/toolkit";
import {CRYPTO} from "../../../data/graphics";
import {
    as_timestamp,
    fullYearDateFormatter,
    timeFormatter,
    timeSecFormatter,
    xAxisMonthDateFormatter
} from "../../../utils/date-utils";
import {dateSerializerNew} from "../../../components/controls/form/nb-data-picker";

interface TVLightProps {
    renderData: any;
    graphics?: TabItem[];
    width?:number;
    height?: number;
    timeRange?:string;
    infoTabs?: string;
    priceFormatter?: Function;
    tooltipForrmatter?:Function;
    portfolioName?:string;
    benchmark?:string| undefined;
    changebackground?:boolean;
    classId?:string;
    caseStr?:string;
}

const TrendingViewLight = (props: TVLightProps) => {
    const chartContainerRef = useRef <HTMLInputElement | null>(null);
    const tooltipRef = useRef <HTMLInputElement | null>(null);
    const legendSeries = useRef<HTMLInputElement | null>(null);
    const graphics = props.graphics || [];
    const refsSeries = useRef([]);
    const [currentLegend, setCurrentLegend] = useState(0);
    const timeRange = props.timeRange || '';
    const [linePrice, setLinePrice] = useState([]);
    const [currentTime, setCurrentTime] = useState(null);
    const [hideSeries,setHideSeries] = useState(true);
    const idSeries = graphics.map((item) => item.id);
    const renderData = props.renderData ? props.renderData : {};
    const resolutionScreen = () => window.screen.width > window.screen.height ? 'horizontal' : 'vertical';
    const resolutionFontsize = resolutionScreen() === 'horizontal' ? 12 : 8;

    // console.log(renderData)

    // const as_timestamp = (datestr: string) => {
    //     const base = '2024-03-31'
    //     if (datestr.length > base.length) {
    //         datestr = datestr.split(' ').join('T') + 'Z';
    //     }
    //     return Date.parse(datestr);
    // };

    refsSeries.current = [];
    const addToRefs = (el) => {
        // @ts-ignore
        if(el && !refsSeries.current.includes(el)) {
            // @ts-ignore
            refsSeries.current.push(el)
        }
    };
    const myPriceFormatter = (price) => {return price.toFixed(2)};
    const currentPriceFormatter = props.priceFormatter|| myPriceFormatter;
    const tooltipPriceFormatter = props.tooltipForrmatter || myPriceFormatter;

    const setId = (id) => {
        setCurrentLegend(id);
    };


    const result = {};
    renderData.forEach((item) => {
      Object.entries(item).forEach(([key,value],idx) => {
          if(idSeries.includes(key)){
              if(!result[key]){
                  result[key] = [];
                  result[key].push({time: as_timestamp(item.date), value});
              } else {
                  result[key].push({time: as_timestamp(item.date), value});
              }
          }
      })
    });



    useEffect(() => {

        // @ts-ignore
        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: mainBackgroundColor },
                textColor: '#AEA49B',
                fontSize: resolutionFontsize,
                fontFamily: 'Noto Sans',
                fontWieght:400,
            },
            leftPriceScale: {
                visible:true,
                entireTextOnly:true,
                minimumWidth:50
                // scaleMargins: {top: 0.2 , bottom:0.2}
            },
            rightPriceScale: {
                visible:false,
            },
            grid: {
                vertLines: {
                    visible: false
                },
                horzLines: {
                    color: '#AEA49B',
                    style:4
                }
            },
            crosshair: {
                vertLine:{
                    labelBackgroundColor: mainBackgroundColor,
                    labelVisible:false,
                },
                horzLine: {
                    labelBackgroundColor: mainBackgroundColor,
                    labelVisible:false,
                }
            },
            // width: props.width,
            // height: props.height,
            // autoSize:true,
             timeScale: {
                tickMarkFormatter: (time, tickMarkType, locale) => {
                    const date = new Date(time);
                    if(timeRange === 'day') {
                        return timeFormatter.format(date);
                    }
                    if(timeRange === 'year') {
                        return fullYearDateFormatter.format(date);
                    };
                    if(timeRange === 'week' || 'month1' || 'month3' || 'month') {
                        switch (tickMarkType) {
                            case TickMarkType.Year:
                                return xAxisMonthDateFormatter.format(date);
                            case TickMarkType.Month:
                                return timeFormatter.format(date);
                            case TickMarkType.TimeWithSeconds:
                                return  timeSecFormatter.format(date);
                            default:
                                return timeFormatter.format(date);
                        };

                    };
                },

            },

            localization:{
                priceFormatter: currentPriceFormatter,
            },

        });

        chart.timeScale().fitContent();
        requestAnimationFrame(() => {
           const visibleLogicalRange =  chart.timeScale().getVisibleLogicalRange();
            if(visibleLogicalRange !== null) {
                    chart.timeScale().setVisibleLogicalRange({
                       from:0.35,
                       to:  visibleLogicalRange.to - 0.35,
                   })
            }
        } );

        const timeScaleRange = timeRange === 'day' ? 5 : 10;

        chart.timeScale().applyOptions({
            fixLeftEdge:true,
            fixRightEdge:true,
            timeVisible:false,
            tickMarkMaxCharacterLength: timeScaleRange,
        });


        const lineSeries = graphics.map((el,idx) => chart.addLineSeries(
            {
            color: el.graphColor,
            lineWidth:2,
            priceLineVisible:false,
            lastValueVisible: false,
        }
        ));


        lineSeries.forEach((line,idx) => {
          for(let key in result) {
              if(key === idSeries[idx]) {
                line.setData(result[key]);
              }
          }
        });


        const toogleSeries = (line,isVisiable) => { line.applyOptions({visible: isVisiable})};

        const visiableLegend = (legend,isVisiable) => {
            legend.style.opacity = isVisiable ? '0.5':'1' ;
        };

        chart.subscribeCrosshairMove((param) => {
            // @ts-ignore
            const date = new Date(param.time);
            const pad = num => ("0" + num).slice(-2);
            let hours = date.getUTCHours(),
                minutes = date.getUTCMinutes(),
                day = date.getUTCDate(),
                month = date.getMonth(),
                year = date.getUTCFullYear()
            if(tooltipRef.current && chartContainerRef.current !== null) {
                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > chartContainerRef.current.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > chartContainerRef.current.clientHeight
                ){
                    tooltipRef.current.style.display = 'none'
                } else {
                    const data = lineSeries.map((line) => {
                       return param.seriesData.get(line)
                    });
                    // if(data !== undefined) {
                    if(data && data !== undefined) {
                        tooltipRef.current.style.display = 'block';
                        const coordinate = param.point.y + 50;
                        const shiftedCoordinate = param.point.x > chartContainerRef.current.clientHeight - 100 ? param.point.x + -140 : param.point.x + 60;
                        tooltipRef.current.style.left = shiftedCoordinate + 'px';
                        tooltipRef.current.style.top = coordinate + 'px';
                        // @ts-ignore
                        setLinePrice(data);
                        if(timeRange === 'day' && props.infoTabs) {
                            // @ts-ignore
                            setCurrentTime(timeFormatter.format(date) + ' ' + fullYearDateFormatter.format(date))
                        } else if(timeRange === 'week' && props.infoTabs) {
                            // @ts-ignore
                            setCurrentTime(fullYearDateFormatter.format(date) + ' ' + timeFormatter.format(date));
                        } else{
                            // @ts-ignore
                            setCurrentTime(fullYearDateFormatter.format(date));
                        }
                    }
                }
            }
        });

        return () => {
            chart.remove()
        };
    },[hideSeries,props.infoTabs,props.timeRange,props.caseStr])
    // },[])

    const priceValue = linePrice.map((item: {value:number,time:number}) => {
        if(item !== undefined && item.value !== undefined) {
           // return item.value.toFixed(2)
           return tooltipPriceFormatter(item.value);
        }
    });

    const borderC = priceValue.length === 0 ? '' : '1px solid'+ buttonBackgroundColor;

    return <div ref={chartContainerRef} style={{position:'relative', marginTop:'0px',height:'100%',width:'100%'}}>
        <Stack direction={'row'} spacing={2} style={{marginBottom: '10px',width:'100%'}}>
            {graphics.map((item,idx) => <React.Fragment key={'legendWrraper' + idx}>
                <Stack ref={addToRefs} style={{
                    color:'#AEA49B',
                    // marginLeft: 50,
                    marginBottom: 10,
                }}
                direction={'row'}
                alignItems={'center'}
                spacing={1}
                key={'legendSeries'+idx}
                id={`${item.id}`}
                // onClick={setId(idx)}
                >
                    {item.id === 'value' && props.portfolioName ? <>
                            <Box sx={{width: {xs:15,md:20,lg:20},height:2.8,background: item.graphColor}} key={'legendMark'}></Box>
                            <Box key={'legengTitle'} >{props.portfolioName}</Box>
                        </>
                    :item.id === 'benchmark' && props.benchmark !== 'No Benchmark'  && props.benchmark !== undefined ?
                            <>
                                <Box sx={{width:{xs:15,md:20,lg:20}, height:2.8,background: item.graphColor}} key={'legendMark'}></Box>
                                <Box key={'legengTitle'} >{props.benchmark}</Box>
                            </>
                    :item.id !== 'benchmark' ?
                                <>
                                    <Box sx={{width:{xs:15,md:20,lg:20}, height:2.8,background: item.graphColor}} key={'legendMark'}></Box>
                                    <Box key={'legengTitle'} >{item.title}</Box>
                                </>
                    : <></>}
                </Stack></React.Fragment>
            )}
        </Stack>
        <Stack ref={tooltipRef} style={{
            position:'absolute',
            border:borderC,
            borderRadius: '5%',
            zIndex: 1000,
            color:'#AEA49B',
            background:mainBackgroundColor,
            opacity:0.9,
            padding: 5,
            fontSize: resolutionFontsize,
        }} alignItems={'center'} direction={'column'}>
            <Box marginBottom={'5px'}>{currentTime}</Box>

            {graphics.map((el,idx) => <React.Fragment key={'ittitle' + idx}>
                {/*{priceValue[idx] !== undefined ? <Box key={idx +'texttitle'} style={{color: el.graphColor}}>{el.title} {priceValue[idx]}</Box> : <Box></Box>}*/}
                { el.id === 'value' && priceValue[idx] !== undefined  && props.portfolioName ? <Stack direction={'row'} justifyContent={'space-between'}>
                    <Box key={idx +'texttitle45'} style={{color: el.graphColor}}>{props.portfolioName} </Box>
                    <Box key={idx +'texttitle2'} style={{color: el.graphColor, marginLeft:'20px'}}> {priceValue[idx]}</Box>
                </Stack>
                :el.id === 'benchmark' && props.benchmark !== 'No Benchmark' && priceValue[idx] !== undefined ?<Stack direction={'row'} justifyContent={'space-between'}>
                        <Box key={idx +'texttitle56'} style={{color: el.graphColor}}>{props.benchmark} </Box>
                        <Box key={idx +'texttitle78'} style={{color: el.graphColor, marginLeft:'20px'}}>{priceValue[idx]}</Box>
                        </Stack>
                :el.id !== 'benchmark' && priceValue[idx] !== undefined ?<Stack direction={'row'} justifyContent={'space-between'}>
                            <Box key={idx +'texttitle85'} style={{color: el.graphColor}}>{el.title} </Box>
                            <Box key={idx +'texttitle25'} style={{color: el.graphColor, marginLeft:'20px'}}>{priceValue[idx]}</Box>
                            </Stack>
                : <></>}
            </React.Fragment>)}

        </Stack>
    </div>


};

export default TrendingViewLight;
