import { Asset, AssetEmptyItem } from './asset';
import { dateToTimestamp } from 'utils/date-utils';
import { ID } from './common';
import { dateSerializerNew } from '../components/controls/form/nb-data-picker';
import { $$ } from 'utils/utils';


export interface AssetVolume extends Asset {
  value: number;
  instrumentId: number;
  instrumentWeight: number;
  class: string;
  base_symbol: string;
}

export const AssetVolumeEmptyItem = {
    ...AssetEmptyItem,
    value: 0,
  // instrumentWeight: 0
} as AssetVolume;

export type PortfolioValueType = number;

export const H24 = 'H24';
export const D30 = 'D30';
export const YTD = 'YTD';

export interface PortfolioIndicators {
  [H24]: PortfolioValueType,
  [D30]: PortfolioValueType,
  [YTD]: PortfolioValueType,
}

export const PortfolioIndicatorsEmptyItem = {
  [H24]: 0,
  [D30]: 0,
  [YTD]: 0,
};

export interface Portfolio {
  id: ID;
  name: string;
  type: string;
  class:string;
  created: number;
  updated: number;
  inceptionDate:number;
  currency:string;
  indicators: PortfolioIndicators;
  assets: AssetVolume[];
  _assetCount: number;
  benchmark:AssetVolume;
  frequency: {};
  optimized_method:{},
  optimized_function:{},
  _system?: {},
  _dateStart?: string | Date | null;
  _dateEnd?: string | Date | null;
  _currentTaskSeqn: number;
  msg:string;
  extra_instruments:AssetVolume[];
  tasks: PortfolioTask[];
}

export type PortfolioTaskStatusType = 'empty' | 'started' | 'running' | 'completed' | 'error' | 'expired' | 'forgotten';

export interface PortfolioTask {
  ts: number;
  taskType: string;
  status: PortfolioTaskStatusType;
  dateStart: string;
  dateEnd: string;
  benchmark:{};
  frequency: {},
  optimized_method:{},
  optimized_function:{},
  result: string;
  errorMessage: string;
  taskId: string;
  msg:string;
  seqn: number;
}

export const getEmptyPortfolioTask = () : PortfolioTask => {
  return {
    ts: Date.now().valueOf(),
    taskType: '',
    status: 'empty',
    dateStart: '',
    dateEnd: '',
    result: '',
    errorMessage: '',
    benchmark:{},
    frequency:{},
    optimized_method:{},
    optimized_function:{},
    msg:'',
    taskId: '',
    seqn: -1
  } as PortfolioTask;
}

export const PortfolioEmptyItem = {
  id: '',
  name: '',
  type: '',
  created: dateToTimestamp(new Date()),
  indicators: PortfolioIndicatorsEmptyItem,
  assets: [] as AssetVolume[],
  _assetCount: 0,
  benchmark:{},
  optimized_method:{},
  optimized_function:{},
  _system: {},
  frequency:{},
  extra_instruments:[] as AssetVolume[],
  _dateStart: dateSerializerNew($$.getYesterday()),
  _dateEnd: dateSerializerNew($$.getYesterday()),
  _currentTaskSeqn: -1,
  msg:'',
  tasks: [] as PortfolioTask[]
} as Portfolio;


