import React, {useRef, useState, useMemo} from "react";
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import {TabItem} from "../../../components/tab-component";
import '../../../components/portfolios/porfolio.css';


const getChartOptions = (labels: string[],series,graphics:TabItem[],plotOptions,stroke,yTitle,) => {
    return{
        chart: {
            // height: 350,
            // type: 'line',
            toolbar: {
                autoSelected: 'zoom',
                show: false,
                tools: {
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: true,
                    reset: true,
                    selection: true,
                    download:false,
                },
            },
            animations: {
                enabled:false
            },
            events: {
                dataPointMouseEnter: function(event) {
                    if(event.fromElement !== null){
                        event.fromElement.style.cursor = "default";
                    }
                }
            },
            offsetX:-10
        },
        dataLabels: {
            enabled: false,

        },
        stroke:{
            width:1.5,
            curve:'smooth'
        },
        grid: {
            borderColor: 'rgba(174,164,155,0.5)',
            strokeDashArray: 2,
            borderWidth:0.5,
            xaxis: {
                lines: {
                    show: false,
                }
            },
            yaxis: {
                lines: {
                    show: true,
                }
            },
        },

        fill: {
            type:'solid',
        },
        markers: {
            size: [0,0,4]
        },
        tooltip: {
            // shared: true,
            // intersect: true,
        },
        // labels: labels,
        xaxis: {
            type: 'numeric',
            min: 5,
            max: 7.9,
            tickAmount: 10,
            labels:{
                style:{
                    colors:'#AEA49B'
                },
            },
            axisBorder: {
                show: true,
                color:'#AEA49B',
                height:0.1
            },
            axisTicks:{
                show: false
            },
            tooltip:{
                enabled:false,
            },
            crosshairs: {
                stroke: {
                    color: 'rgba(174,164,155,1)',
                    width: 0.5,
                    dashArray: 5
                }
            },
            title:{
                text:'Volatility',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: '#AEA49B',
                    fontSize: '12px',
                    fontFamily: 'Noto Sans',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
        },
        yaxis: {
            labels:{
                style:{
                    colors:'#AEA49B'
                },
            },
            axisBorder: {
                show: true,
                width:'0.1px',
                color:'#AEA49B',

            },
            axisTicks:{
                show: false,
            },
            crosshairs: {
                stroke: {
                    color: 'rgba(174,164,155,1)',
                    width: 1,
                    dashArray: 5
                }
            },
            title:{
                text:yTitle,
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: '#AEA49B',
                    fontSize: '12px',
                    fontFamily: 'Noto Sans',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            tooltip: {
                enabled:true
            },
        },
        colors: graphics.map((item) => item.graphColor),
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            showForSingleSeries: true,
            fontSize: 14,
            fontFamily: 'Noto Sans',
            fontWeight: 400,
            labels: {
                colors: '#AEA49B' ,
            },
            markers:{
                width: [20,20,20,20],
                height: 2.8,
                radius:0,
                offsetY: -3,
            }
        },

    }
};

const EfficientGraph = (props) => {
    const {series, labels,graphics,width,height,plotOptions,stroke,yTitle} = props;
    const [options, setOptions] = useState<ChartProps>(getChartOptions(labels,series,graphics,plotOptions,stroke,yTitle));
    return (
        <ReactApexChart options={options} series={series} type="line" height={height} width={width}/>
    )
};

export default EfficientGraph;

