import { createSlice } from '@reduxjs/toolkit';

import {
  loadPortfoliosInfoApi,
  loadInstrumentsInfoApi,
  updatePortfolioApi,
  PortfolioInputType,
  removePortfolioApi, simulatePortfolioApi
} from './portfolio.api';
import {
    ActionMode,
    ActionModeType,
    COMPONENT_KEY_NAME,
    expandPortfolio,
    expandPortfolios,
    ExtraParameter,
    equityOverviewGraphSelector,
    equityOverviewGraphTimeranges,
    keyMetricsGraphSelector,
    keyMetricsGraphTimeranges,
    equitySimulationGraphSelector,
    equitySimulationGraphTimeranges,
    keyMetricsSimulationGraphSelector,
    keyMetricsSimulationTimeranges,
    simulationAllocationGraphSelector,
    simulationAllocationTimeranges,
    performanceMetricsGraphSelector,
    performanceMetricsGraphTimeranges,
    worstDrawDownsGraphSelector,
    worstDrawDownsTimeranges,
    annualReturnsOverviewTimeranges,
    annualReturnsOverviewGraphSelector,
    dailyReturnsOverviewGraphSelector,
    dailyReturnsOverviewTimeranges,
    underwaterPlotGraphSelector,
    underwaterPlotTimeranges,
    monthlyReturnsOverviewGraphSelector,
    monthlyReturnsTimeranges,
    annualReturnsSimulationTimeranges,
    annualReturnsSimulationGraphSelector,
    rollingVolatilityTimeranges,
    rollingVolatilityGraphSelector,
    drawdownPeriodsTimeranges,
    drawdownPeriodsGraphSelector,
    rollingSharpeGraphSelector,
    rollingSharpeGraphTimeranges,
    rollingSortinoGraphTimeranges,
    rollingSortinoGraphSelector, distributionMonthlyGraphSelector, distributionMonthlyTimeranges,
} from './portfolio.common';

import { getCurrentState, isPendingAction, isRejectedAction, isSucceededAction } from 'utils/refdux-utils';

import {
  AssetVolume,
  AssetVolumeEmptyItem, getEmptyPortfolioTask,
  Portfolio,
  PortfolioTask,
  PortfolioTaskStatusType
} from 'models/portfolio';
import { BasePageDefaultState, BasePageState } from 'components/pages/pages-common';

import { Asset, AssetEmptyItem } from 'models/asset';
import { $$ } from 'utils/utils';
import {randomString} from "utils/misc";

import {EMPTY_CASE_STR, GraphicCaseImpl} from "models";
import { extraLogging } from '../../config';
import { getCurrentPortfolioTask, getIndexByPortfolioId, getPortfolioByIndex, getPortfolioTaskBySeqn } from './helper';
import {ItemSorter} from "../../components/util-components/sorter";
import { resetUser } from '../../store/reducers/auth';
import {ROLLSHARPE, ROLLSORTINO} from "../../data/graphics";
import {stressIndexesGraphics} from "../dashboard/monitor/monitor.slice";


const itemSorters = {};

const getItemSorter = (name) => {
  if (!itemSorters[name]) {
    itemSorters[name] = new ItemSorter();
  }
  return itemSorters[name];
}


interface State extends BasePageState {
  equityOverviewCurrentCaseStr: string;
  equityOverviewData: any[];
  equitySimulationCurrentCaseStr: string;
  equitySimulationData: any[];
  keyMetricsOverviewCurrentCaseStr:string;
  keyMetricsOverviewData:any[];
  performanceMetricsOverviewCurrentCaseStr:string;
  performanceMetricsOverviewData:any[];
  keyMetricsSimulationCurrentCaseStr:string;
  keyMetricsSimulationData:any[];
  worstDrawDownsCurrentCaseStr:string;
  worstDrawDownsData:any[];
  simulationAllocationCurrentCaseStr:string;
  simulationAllocationData:any[];
  annualReturnsOverviewCurrentCaseStr:string;
  annualReturnsOverviewData:any[];
  annualReturnsSimulationCurrentCaseStr:string;
  annualReturnsSimulationData:any[];
  dailyReturnsOverviewCurrentCaseStr:string;
  dailyReturnsOverviewData:any[];
  underwaterPlotCurrentCaseStr:string;
  underwaterPlotData:any[];
  drawdownPeriodsCurrentCaseStr:string;
  drawdownPeriodsData:any[];
  rollingVolatilityCurrentCaseStr:string;
  rollingVolatilityData:any[];
  rollingSharpeCurrentCaseStr:string;
  rollingSharpeData:any[];
  rollingSharpeSimulatedCurrentCaseStr:string;
  rollingSharpeSimulatedData:any[];
  rollingSortinoCurrentCaseStr:string;
  rollingSortinoData:any[];
  rollingSharpeOptimizeCurrentCaseStr:string;
  rollingSharpeOptimizeData:any[];
  rollingSortinoSimulatedCurrentCaseStr:string;
  rollingSortinoSimulatedData:any[];
  rollingSortinoOptimizedCurrentCaseStr:string;
  rollingSortinoOptimizedData:any[];
  monthlyReturnsOverviewCurrentCaseStr:string;
  monthlyReturnsOverviewData:any[];
  distributionMonthlyCurrentCaseStr:string;
  distributionMonthlyData:any[];
  assets: Asset[];
  assetsCurrent: Asset[];
  currentAssetVolume: AssetVolume;
  portfolios: Portfolio[];
  currentPortfolioIndex: number;
  _temporraryPortfolioTask:any;
  currentSectionId: string;
  actionMode: ActionModeType;
  errorMessage: string; // todo remove
  currentTaskIndex: number;
  currentControlDate: string;
  currentSortField: string;
  _portfoliosRawInput: PortfolioInputType[];  // pre-save in case assets was loaded after portfolios
    startTimeRequest:number;
}

export const equityOverviewGraphics = new GraphicCaseImpl(equityOverviewGraphSelector, 'log-scaled', equityOverviewGraphTimeranges);
export const equitySimulationGraphics = new GraphicCaseImpl(equitySimulationGraphSelector, 'log-scaled', equitySimulationGraphTimeranges);
export const keyMetricsOverviewGraphics = new GraphicCaseImpl(keyMetricsGraphSelector,'day',keyMetricsGraphTimeranges);
export const performanceMetricsOverviewGraphics = new GraphicCaseImpl(performanceMetricsGraphSelector,'day',performanceMetricsGraphTimeranges);
export const keyMetricsSimulationGraphics = new GraphicCaseImpl(keyMetricsSimulationGraphSelector,'day',keyMetricsSimulationTimeranges);
export const simulationAllocationGraphics = new GraphicCaseImpl(simulationAllocationGraphSelector,'day',simulationAllocationTimeranges);
export const worstDrawDownsGraphics = new GraphicCaseImpl(worstDrawDownsGraphSelector,'day',worstDrawDownsTimeranges);
export const annualReturnsOverviewGraphics = new GraphicCaseImpl(annualReturnsOverviewGraphSelector,'day',annualReturnsOverviewTimeranges);
export const annualReturnsSimulatioGraphics = new GraphicCaseImpl(annualReturnsSimulationGraphSelector,'day',annualReturnsSimulationTimeranges);
export const dailyReturnsOverviewGraphics = new GraphicCaseImpl(dailyReturnsOverviewGraphSelector,'day',dailyReturnsOverviewTimeranges);
export const underwaterGraphics = new GraphicCaseImpl(underwaterPlotGraphSelector,'day', underwaterPlotTimeranges);
export const monthlyReturnsOverviewGraphics = new GraphicCaseImpl(monthlyReturnsOverviewGraphSelector,'day',monthlyReturnsTimeranges);
export const rollingVolatilityGraphics = new GraphicCaseImpl(rollingVolatilityGraphSelector,'day',rollingVolatilityTimeranges);
export const drawdownPeriodsGraphics = new GraphicCaseImpl(drawdownPeriodsGraphSelector,'day',drawdownPeriodsTimeranges);
export const rollingSharpeGraphics = new GraphicCaseImpl(rollingSharpeGraphSelector,`${ROLLSHARPE}:6mWindow`,rollingSharpeGraphTimeranges);
export const rollingSharpeSimulatedGraphics = new GraphicCaseImpl(rollingSharpeGraphSelector,`${ROLLSHARPE}:6mWindow`,rollingSharpeGraphTimeranges);
export const rollingSharpeOptimizeGraphics = new GraphicCaseImpl(rollingSharpeGraphSelector,`${ROLLSHARPE}:6mWindow`,rollingSharpeGraphTimeranges);
export const rollingSortinoGraphics = new GraphicCaseImpl(rollingSortinoGraphSelector,`${ROLLSORTINO}:6mWindow`,rollingSortinoGraphTimeranges);
export const rollingSortinoSimulatedGraphics = new GraphicCaseImpl(rollingSortinoGraphSelector,`${ROLLSORTINO}:6mWindow`,rollingSortinoGraphTimeranges);
export const rollingSortinoOptimizedGraphics = new GraphicCaseImpl(rollingSortinoGraphSelector,`${ROLLSORTINO}:6mWindow`,rollingSortinoGraphTimeranges);
export const distributionMonthlyGraphics = new GraphicCaseImpl(distributionMonthlyGraphSelector,'day',distributionMonthlyTimeranges);
const equityOverviewGraphicsHistory = {};

const initialState: State = {
  ...BasePageDefaultState,

  equityOverviewCurrentCaseStr: EMPTY_CASE_STR,
  equityOverviewData:[],
  equitySimulationCurrentCaseStr:  EMPTY_CASE_STR,
  equitySimulationData: [],
  keyMetricsOverviewCurrentCaseStr:EMPTY_CASE_STR,
  keyMetricsOverviewData:[],
  performanceMetricsOverviewCurrentCaseStr:EMPTY_CASE_STR,
  performanceMetricsOverviewData:[],
  keyMetricsSimulationCurrentCaseStr:EMPTY_CASE_STR,
  keyMetricsSimulationData:[],
  simulationAllocationData:[],
  simulationAllocationCurrentCaseStr:EMPTY_CASE_STR,
  worstDrawDownsCurrentCaseStr:EMPTY_CASE_STR,
  worstDrawDownsData:[],
  drawdownPeriodsCurrentCaseStr:EMPTY_CASE_STR,
  drawdownPeriodsData:[],
  annualReturnsOverviewCurrentCaseStr:EMPTY_CASE_STR,
  annualReturnsOverviewData:[],
  annualReturnsSimulationCurrentCaseStr:EMPTY_CASE_STR,
  annualReturnsSimulationData:[],
  dailyReturnsOverviewCurrentCaseStr:EMPTY_CASE_STR,
  dailyReturnsOverviewData:[],
  underwaterPlotCurrentCaseStr:EMPTY_CASE_STR,
  underwaterPlotData:[],
  rollingVolatilityCurrentCaseStr:EMPTY_CASE_STR,
  rollingVolatilityData:[],
  monthlyReturnsOverviewCurrentCaseStr:EMPTY_CASE_STR,
  monthlyReturnsOverviewData:[],
  rollingSharpeCurrentCaseStr:EMPTY_CASE_STR,
  rollingSharpeData:[],
  rollingSharpeSimulatedCurrentCaseStr:EMPTY_CASE_STR,
  rollingSharpeSimulatedData:[],
  rollingSharpeOptimizeCurrentCaseStr:EMPTY_CASE_STR,
  rollingSharpeOptimizeData:[],
  rollingSortinoCurrentCaseStr:EMPTY_CASE_STR,
  rollingSortinoData:[],
  rollingSortinoSimulatedCurrentCaseStr:EMPTY_CASE_STR,
  rollingSortinoSimulatedData:[],
  rollingSortinoOptimizedCurrentCaseStr:EMPTY_CASE_STR,
  rollingSortinoOptimizedData:[],
  distributionMonthlyCurrentCaseStr:EMPTY_CASE_STR,
  distributionMonthlyData:[],
  assets: [],
  assetsCurrent: [],
  portfolios: [],
  currentPortfolioIndex: -1,
  _temporraryPortfolioTask:null,
  currentAssetVolume: AssetVolumeEmptyItem,
  currentSectionId: '',
  actionMode: ActionMode.portfolioList,
  errorMessage: '',
  currentControlDate:'',
  currentSortField: '',
  currentTaskIndex: 0,
  _portfoliosRawInput: [],
  startTimeRequest:0
}


const getInitialState = () : State => {
  const state = initialState;
  return state;
}

const applyPortfolioInfo = (state: State, action) => {
  const this_right_portfolioInput = action.payload as PortfolioInputType[];

  const portfolioInput = this_right_portfolioInput;
  if (portfolioInput && portfolioInput.length > 0) {
    state.currentPortfolioIndex = -1;
  }

  if (! state.assets) {
    state._portfoliosRawInput = portfolioInput;
  }
  else {
    // state.portfolios = expandPortfolios(portfolioInput, state.assets);
    state.portfolios = expandPortfolios(portfolioInput, state.assets).sort((a,b) => {
      return b.updated - a.updated
    });

  }
  return state;
}

const applyInstrumentsInfo = (state: State, action) => {
  const payload = action.payload || {};

  // @ts-ignore
  state.assets = payload.instruments;

  console.log('INSTRUMENT::Info:',state.assets);

  state.assets.sort((a,b) => {
    return  a.name.localeCompare(b.name)
  }).sort((c,d) => {
    return c.class.localeCompare(d.class)
  })


  if (state._portfoliosRawInput) {
    state.portfolios = expandPortfolios(state._portfoliosRawInput, state.assets);
    state._portfoliosRawInput = [];
  }
  return state;
}

const addSystemParameters = (portfolio: Portfolio, name, value) => {
    portfolio._system = portfolio._system || {};
    portfolio._system[name] = value;
    return portfolio;
}

const updatePortfolio = (state: State, action) => {
  const portfolioData = action.payload as PortfolioInputType;
  // console.log('PORTFOLIODATA',portfolioData)
  const originalId = portfolioData.originalId;
  // @ts-ignore
  // const benchmark = portfolioData.benchmarks;
  const portfolio = addSystemParameters(expandPortfolio(portfolioData, state.assets), ExtraParameter.updated, 'yes');

  const result = [] as Portfolio[];
  let added = false;

  for (let item of state.portfolios) {
    if ((originalId && item.id === originalId) || item.id ===  portfolio.id) {
      // result.push(portfolio);
      result.unshift(portfolio);
      added = true;
      continue;
    }
    result.push(addSystemParameters(item, ExtraParameter.updated, ''));
    // result.unshift(addSystemParameters(item, ExtraParameter.updated, ''));
  }
  if (!added) {
    // result.push(portfolio)
    result.unshift(portfolio)
  }
  // console.log('RESULT',result)
  state.portfolios = result
  return state;
}

const removePortfolio = (state: State, action) => {
  const { id } = action.payload;
  state.portfolios = state.portfolios.filter(it => it.id !== id);
  return state;
}

const performSuccessRequest = (state, successPerformer?, action?) => {
    state['apiStatus'] = 'succeeded';
    return successPerformer ? successPerformer(state, action) : state;
}

const apiError = (state, action) => {
    state.apiStatus = 'failed';
    if (action.error && action.error.message && action.error.message.endsWith('401')) {
      // resetUser()
      state.apiStatus = '401';
    }
    return state;
}

const resetPortfolioEdit = state => {
  state.assetsCurrent = [];
  state.currentAssetVolume = AssetVolumeEmptyItem;
  return state;
}

const adjustPortfolioPossibleAssets = (state, portfolio?: Portfolio) => {
  if(portfolio) {
    const appliedAssetIds = portfolio.assets.map(it => it.id);
    state.assetsCurrent = state.assets.filter(it => !appliedAssetIds.includes(it.id));
  }
  return state;
}

const getCurrentPortfolio = (state: State): Portfolio => {
    const locationId = location.pathname.match(/\/([^\/]*)$/);
    const currentLocationId = locationId ? locationId[1] : '';
    const index = getIndexByPortfolioId(state.portfolios, currentLocationId);
    // console.log('index',index)
    // console.log('currentPortfolioIndex',state.currentPortfolioIndex)
    if(state.currentPortfolioIndex === -1) {
        // @ts-ignore
        return undefined;
    }
    if(state.currentPortfolioIndex !== index) {
        // @ts-ignore
        return undefined;
    }
  return getPortfolioByIndex(state.portfolios, state.currentPortfolioIndex);
}

const is_action_synchronises = payload => payload.result && payload.result === 'success';
const is_action_synchronises_error = payload => payload.result && payload.result === 'error';

const simulatePortfolioStartGo = (state: State, taskId, seqn, dateStart, dateEnd, benchmark,frequency,msg) => {
    const task = {
      ...getEmptyPortfolioTask(),
      taskType: 'simulate',
      status: 'started',
      msg:msg,
      dateStart,
      dateEnd,
      taskId,
      benchmark,
      frequency,
      seqn,
    } as PortfolioTask;
    // getCurrentPortfolio(state).tasks.push(task);
    const currentPortfolio = getCurrentPortfolio(state);
    // console.log('currentPortfolio',currentPortfolio)
    // console.log('currentTsk',task)
    if(currentPortfolio !== undefined) {
        currentPortfolio.tasks.push(task);
        state.startTimeRequest = new Date().getTime();
    } else {
        state.startTimeRequest = new Date().getTime();
        state._temporraryPortfolioTask = task;
    }
    // console.log('############ simulatePortfolio::getCurrentState::', getCurrentState(state))
    return state;
};


const simulatePortfolio = (state: State, action) => {
  if (is_action_synchronises(action.payload)) {
    return applySimulationResultSuccess(state, action.payload);
  }

  if (is_action_synchronises_error(action.payload)) {
    return applySimulationResultError(state, action.payload);
  }

  if(action.payload.status === 'ERROR') {
    return applySimulationResultError(state, action.payload);
    // const {seqn, message} = action.payload;
    // let task = getPortfolioTaskBySeqn(state.portfolios, seqn);
    // if (task) {
    //     task.status = 'error';
    //     task.errorMessage = message;
    // }
    // else {
    //   task = {...getEmptyPortfolioTask(),
    //     seqn,
    //     taskType: 'simulate',
    //     status: 'error',
    //     errorMessage: message };
    //   getCurrentPortfolio(state).tasks.push(task);
    // }
  }
  else {
    const { taskId, seqn, dateStart, dateEnd, benchmark,frequency,msg } = action.payload;
    return simulatePortfolioStartGo(state, taskId, seqn, dateStart, dateEnd, benchmark,frequency,msg);
  }
}

const simulatePortfolioStart = (state: State, action) => {
  if (action.meta && action.meta.arg) {
      // console.log(action.meta)
      const {seqn, dateStart, dateEnd, benchmark,frequency,msg} = action.meta.arg;
      const taskId = $$.uniqueId('taskId');
      return simulatePortfolioStartGo(state, taskId, seqn, dateStart, dateEnd, benchmark,frequency,msg);
  }
  return state;
}

const applySimulationResultSuccess = (state: State, payload) => {
    state.startTimeRequest = 0;
    const seqn = payload.seqn;
    const data = payload.data;
    const equityCurve = data.equityCurve;
    const equitySimulation = data.equityCurve;
    const keyMetrics = data.keyMetrics.filter((item,idx) => item.kind === 'OVERVIEW');
    const performanceMetrics = data.keyMetrics.filter((item,idx) => item.kind === 'PERFORMANCE');
    // console.log(keyMetrics)
    const dailyReturns = data.keyMetrics.filter((item,idx) =>  item.id === 'PERF-SR');
    const annualReturns = data.keyMetrics.filter((item,idx) => item.id === 'AYR');
    const underwaterPlot = data.keyMetrics.filter((item,idx) => item.id === 'UNDERWATER');
    const monthlyReturns = data.keyMetrics.filter((item,idx) => item.id === 'PERF-AMR');
    const worstDrawdown = data.keyMetrics.filter((item,idx) => item.id === 'WORST10DD');
    const rollingVolatility = data.keyMetrics.filter((item,idx) => item.id === 'ROLVOL');
    const drawdownPeriods = data.keyMetrics.filter((item,idx) => item.id === 'TOP5DD');
    const rollingSharpe = data.keyMetrics.filter((item) => item.id === 'ROLSHARPE');
    const rollingSortino = data.keyMetrics.filter((item) => item.id === 'ROLSORTINO');
    const distributionMonthly = data.keyMetrics.filter((item) => item.id === 'DMR');
    const sixMonth = (currentGraph,currentResult) => {
      const month = currentGraph.map((item) => {
          if(item[currentResult] !== undefined) {
             return [item[currentResult].result_6m.result, item[currentResult].mean_6m.result]
          }else{
            return []
          }
      });
      return month;
    };
    const twoYear = (currentGraph,currentResult) => {
      const year = currentGraph.map((item) => {
          if(item[currentResult] !== undefined) {
              return [item[currentResult].result_2y.result, item[currentResult].mean_2y.result]
          }else{
              return []
          }
      });
      return year;
    };

    const actualRollingSharpe = [sixMonth(rollingSharpe,['clientPortfolio']),
        twoYear(rollingSharpe,['clientPortfolio'])];

    const actualRollingSharpeSimulated = [sixMonth(rollingSharpe,['simulated']),
        twoYear(rollingSharpe,['simulated'])];

    const actualRollingSharpeOptimized = [sixMonth(rollingSharpe,['optimized']),
        twoYear(rollingSharpe,['optimized'])];

    const actualRollingSortino = [sixMonth(rollingSortino,['clientPortfolio']),
        twoYear(rollingSortino,['clientPortfolio'])];

    const actualRollingSortinoSimulated = [sixMonth(rollingSortino,['simulated']),
        twoYear(rollingSortino,['simulated'])];

    const actualRollingSortinoOptimized = [sixMonth(rollingSortino,['optimized']),
        twoYear(rollingSortino,['optimized'])];

    // console.log(actualRollingSharpeSimulated)

    const dateStart = payload['date-start'];
    const dateEnd   = payload['date-end'];
    const allocation = data.allocation;

    console.log('################# metrics::', data.keyMetrics);

    const task = getPortfolioTaskBySeqn(state.portfolios, seqn);


    if (task) {
      task.status = 'completed';
      task.dateStart = dateStart;
      task.dateEnd = dateEnd;
      equityOverviewGraphicsHistory[seqn] = equityCurve;
    }
    equityOverviewGraphics.setInitialCases([equityCurve]);
    state.equityOverviewCurrentCaseStr = `auto:${randomString()}`;
    equitySimulationGraphics.setInitialCases([equitySimulation]);
    state.equitySimulationCurrentCaseStr = `auto:${randomString()}`;
    keyMetricsOverviewGraphics.setInitialCases([keyMetrics]);
    state.keyMetricsOverviewCurrentCaseStr = `auto:${randomString()}`;
    performanceMetricsOverviewGraphics.setInitialCases([performanceMetrics]);
    state.performanceMetricsOverviewCurrentCaseStr = `auto:${randomString()}`;
    worstDrawDownsGraphics.setInitialCases([worstDrawdown]);
    state.worstDrawDownsCurrentCaseStr = `auto:${randomString()}`;
    keyMetricsSimulationGraphics.setInitialCases([keyMetrics]);
    state.keyMetricsSimulationCurrentCaseStr = `auto:${randomString()}`;
    simulationAllocationGraphics.setInitialCases([allocation]);
    state.simulationAllocationCurrentCaseStr = `auto:${randomString()}`;
    annualReturnsOverviewGraphics.setInitialCases([annualReturns]);
    state.annualReturnsOverviewCurrentCaseStr = `auto:${randomString()}`;
    annualReturnsSimulatioGraphics.setInitialCases([annualReturns]);
    state.annualReturnsSimulationCurrentCaseStr = `auto:${randomString()}`;
    dailyReturnsOverviewGraphics.setInitialCases([dailyReturns]);
    state.dailyReturnsOverviewCurrentCaseStr = `auto:${randomString()}`;
    underwaterGraphics.setInitialCases([underwaterPlot]);
    state.underwaterPlotCurrentCaseStr = `auto:${randomString()}`;
    monthlyReturnsOverviewGraphics.setInitialCases([monthlyReturns]);
    state.monthlyReturnsOverviewCurrentCaseStr = `auto:${randomString()}`;
    rollingVolatilityGraphics.setInitialCases([rollingVolatility]);
    state.rollingVolatilityCurrentCaseStr = `auto:${randomString()}`;
    drawdownPeriodsGraphics.setInitialCases([drawdownPeriods]);
    state.drawdownPeriodsCurrentCaseStr = `auto:${randomString()}`;
    state.rollingSharpeCurrentCaseStr = rollingSharpeGraphics.getDefaultCaseStr();
    rollingSharpeGraphics.setInitialCases([actualRollingSharpe][0]);
    state.rollingSharpeSimulatedCurrentCaseStr = rollingSharpeSimulatedGraphics.getDefaultCaseStr();
    rollingSharpeSimulatedGraphics.setInitialCases([actualRollingSharpeSimulated][0]);
    state.rollingSharpeOptimizeCurrentCaseStr = rollingSharpeOptimizeGraphics.getDefaultCaseStr();
    rollingSharpeOptimizeGraphics.setInitialCases([actualRollingSharpeOptimized][0]);
    state.rollingSortinoCurrentCaseStr = rollingSortinoGraphics.getDefaultCaseStr();
    rollingSortinoGraphics.setInitialCases([actualRollingSortino][0]);
    state.rollingSortinoSimulatedCurrentCaseStr = rollingSortinoSimulatedGraphics.getDefaultCaseStr();
    rollingSortinoSimulatedGraphics.setInitialCases([actualRollingSortinoSimulated][0]);
    state.rollingSortinoOptimizedCurrentCaseStr = rollingSortinoOptimizedGraphics.getDefaultCaseStr();
    rollingSortinoOptimizedGraphics.setInitialCases([actualRollingSortinoOptimized][0]);
    state.distributionMonthlyCurrentCaseStr = `auto:${randomString()}`;
    distributionMonthlyGraphics.setInitialCases([distributionMonthly]);
    // console.log('############ applySimulationResult::getCurrentState::', getCurrentState(state))
    return state;
  }

const applySimulationResultError = (state: State, payload) => {

    // console.log("############ ERROR PAYLOAD::", payload);

    const seqn = payload.seqn;
    const message = payload.message || payload['err-msg'];
    let task = getPortfolioTaskBySeqn(state.portfolios, seqn);
    if (task) {
      // console.log("############ ERROR PAYLOAD:: 0 TASK::", seqn, " MESS::", message);
        task.status = 'error';
        task.errorMessage = message;
        task.msg = payload.msg;
    }
    else {
      // console.log("############ ERROR PAYLOAD:: 1 TASK::", seqn, " MESS::", message);
      task = {...getEmptyPortfolioTask(),
        seqn,
        taskType: 'simulate',
        status: 'error',
        errorMessage: message,
        msg:payload.msg,
      };
        const currentPortfolio = getCurrentPortfolio(state);
        if(currentPortfolio) {
            currentPortfolio.tasks.push(task);
        } else {
            state._temporraryPortfolioTask = task;
        }
      // getCurrentPortfolio(state).tasks.push(task);
    }
    return state;
}

export const dashboardMonitorSlice = createSlice({
  name: COMPONENT_KEY_NAME,
  initialState: getInitialState(),
  reducers: {
    reset(state) {
      return state;
    },
    setTestData(state, action) {
      state.portfolios = action.payload;
    },

    setValue(state, action) {
      const { name, value } = action.payload;
      state[name] = value;
      return state;
    },

    setCurrentControlDate(state,action) {state.currentControlDate = action.payload},

    runPeriodic(state, action) {
      // const data = action.payload;
      // const jutNow = Date.now().valueOf();
      // state.tasks.forEach(task => {
      //   if (task.ts - jutNow > 10) {
      //     task.status = 'expired';
      //     console.log('############### TASK EXPIRED');
      //   }
      // });

      extraLogging && console.log("*** runPeriodic ***");
      return state;
    },

    adjustPossibleAssets(state, action) {
      const { add, del } = action.payload;
      let tmp = $$.isNonEmptyArray(state.assetsCurrent) ? state.assetsCurrent : state.assets;
      tmp = tmp.filter(it => !del.includes(it.id));
      const tmpIds = tmp.map(it => it.id);

      const addAssets = state.assets.filter(it => add.includes(it.id) && !tmpIds.includes(it.id));
      state.assetsCurrent = [...tmp, ...addAssets];
      return state;
    },

    refresh(state, action) {
      const data = action.payload;
      const mode = data.mode
      // {mode: 'editPortfolio', targetId: '008e1996-02b5-46cb-9085-6bf1b6344f06'}
      if ([ActionMode.expandPortfolio].includes(data.mode)) {
        state.holder[data.mode] = data.targetId;
        state.currentPortfolioIndex = getIndexByPortfolioId(state.portfolios, data.targetId);
        return state;
      }

      if([ActionMode.newPortfolio,
          ActionMode.editPortfolio,
          ActionMode.portfolioList].includes(data.mode)) {
        state.actionMode = data.mode;
        state = resetPortfolioEdit(state);

        if (ActionMode.editPortfolio) {
          state.currentPortfolioIndex = getIndexByPortfolioId(state.portfolios, data.targetId);
          adjustPortfolioPossibleAssets(state, getCurrentPortfolio(state));
        }
      }

      if([ActionMode.extraviewPortfolio, ActionMode.simulationPortfolio,ActionMode.optimizationPortfolio,ActionMode.analysisPortfolio].includes(data.mode)) {
        state.actionMode = data.mode;
        state.currentPortfolioIndex = getIndexByPortfolioId(state.portfolios, data.targetId);
        adjustPortfolioPossibleAssets(state, getCurrentPortfolio(state));
      }

      return state;
    },

    sortPortfolioBy(state, action) {
      const {field, sorter} = action.payload;
      const  sortField = field;
      const internalSorter = getItemSorter(field);
      const sortFunction = undefined
      state.portfolios = internalSorter.sortByField(state.portfolios, sortField, sortFunction);
      state.currentSortField = internalSorter.sortedField();
      // const sortField = action.payload;
      return state;
    },
    setSection(state, action) {
      // const sectionId = action.payload;
      const {id, timeRange} = action.payload;
      // console.log(action.payload)
      const sectionId = id;
      // console.log(sectionId)

      if(sectionId === ROLLSHARPE) {
        const xtab = {
            '6m window': '6mWindow',
            '2y window': '2yWindow'
        };

        const actualTimeRange = xtab[timeRange];
        const index = rollingSharpeGraphics.getIndex(sectionId, actualTimeRange);
        state.rollingSharpeCurrentCaseStr = `${sectionId}:${actualTimeRange}`;
        rollingSharpeGraphics.selectCase(sectionId, actualTimeRange);
        state.rollingSharpeSimulatedCurrentCaseStr = `${sectionId}:${actualTimeRange}`;
        rollingSharpeSimulatedGraphics.selectCase(sectionId, actualTimeRange);
        state.rollingSharpeOptimizeCurrentCaseStr = `${sectionId}:${actualTimeRange}`;
        rollingSharpeOptimizeGraphics.selectCase(sectionId, actualTimeRange);
      }
      if(sectionId === ROLLSORTINO) {
          const xtab = {
              '6m window': '6mWindow',
              '2y window': '2yWindow'
          };

          const actualTimeRange = xtab[timeRange];
          const index = rollingSortinoGraphics.getIndex(sectionId,actualTimeRange);
          state.rollingSortinoCurrentCaseStr = `${sectionId}:${actualTimeRange}`;
          rollingSortinoGraphics.selectCase(sectionId,actualTimeRange);
          state.rollingSortinoSimulatedCurrentCaseStr = `${sectionId}:${actualTimeRange}`;
          rollingSortinoSimulatedGraphics.selectCase(sectionId,actualTimeRange);
          state.rollingSortinoOptimizedCurrentCaseStr = `${sectionId}:${actualTimeRange}`;
          rollingSortinoOptimizedGraphics.selectCase(sectionId,actualTimeRange);
      }
      return state;
    },

    applySimulationWsResult(state, action) {
      const {msg, data, seqn, result} = action.payload;
      if (! msg || ! data) {
        return state;
      }
      if (result === 'success') {
        return applySimulationResultSuccess(state, action.payload);
      }
      return state;
    },

    setCurrentTask(state, action) {
      const seqn = action.payload;
      getCurrentPortfolio(state)._currentTaskSeqn = seqn;
      // console.log('CURRENT seqn::', seqn)
      const task = getCurrentPortfolioTask(getCurrentPortfolio(state));

      if (task?.status === 'completed') {
        equityOverviewGraphics.setInitialCases([equityOverviewGraphicsHistory[seqn]]);
        state.equityOverviewCurrentCaseStr = `auto:${randomString()}`;
      }
      return state;
    },
    applyTemporraryPortfolioTask(state,action) {
      // console.log('task',state._temporraryPortfolioTask )
      // console.log(action.payload)
        if(state._temporraryPortfolioTask ) {
            const index = getIndexByPortfolioId(state.portfolios, action.payload);
            const currentPortfolio = getPortfolioByIndex(state.portfolios,index);
            if(currentPortfolio) {
                currentPortfolio.tasks.push(state._temporraryPortfolioTask);
            }
            state._temporraryPortfolioTask = null;
                // console.log('############ simulatePortfolio::getCurrentState::', getCurrentState(state))
        }
        return state;
    },


    reportTaskError(state, action) {
      const {data, message} = action.payload;
      const next_task_status = data.next_task_status;
      const seqn = data.seqn;
      state.errorMessage = message;

      if (seqn) {
        const task = getPortfolioTaskBySeqn(state.portfolios, seqn);
        if (task) {
          task.status = next_task_status || 'error';
          task.errorMessage = message;
        }
      }
      // console.log('############ ERROR reportTaskError::getCurrentState::', getCurrentState(state))
      return state;
    },

    setDateStart(state,action){
      const portfolio = getCurrentPortfolio(state);
      portfolio._dateStart = action.payload;
      return state;
    },
    setDateEnd(state,action){
      const portfolio = getCurrentPortfolio(state);
      portfolio._dateEnd = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => builder
    .addCase(loadPortfoliosInfoApi.fulfilled, (state, action) => performSuccessRequest(state, applyPortfolioInfo, action))
    .addCase(loadInstrumentsInfoApi.fulfilled,(state, action)   => performSuccessRequest(state, applyInstrumentsInfo, action))
    .addCase(updatePortfolioApi.fulfilled,    (state, action)   => performSuccessRequest(state, updatePortfolio, action))
    .addCase(removePortfolioApi.fulfilled,    (state, action)   => performSuccessRequest(state, removePortfolio, action))
    .addCase(simulatePortfolioApi.pending,    (state, action)   => performSuccessRequest(state, simulatePortfolioStart, action))
    .addCase(simulatePortfolioApi.fulfilled,  (state, action)   => performSuccessRequest(state, simulatePortfolio, action))
    .addMatcher(isPendingAction,        (state, action) => { state.apiStatus = 'pending'; return state; })
    .addMatcher(isRejectedAction,       (state, action) => apiError(state, action))
    .addMatcher(isSucceededAction,      (state, action) => { state.apiStatus = 'succeeded'; return state; })
});

export const setDateStart = dashboardMonitorSlice.actions.setDateStart;
export const setDateEnd = dashboardMonitorSlice.actions.setDateEnd;
export const reportTaskError = dashboardMonitorSlice.actions.reportTaskError;
export const applyTemporraryPortfolioTask = dashboardMonitorSlice.actions.applyTemporraryPortfolioTask;
export const setCurrentControlDate = dashboardMonitorSlice.actions.setCurrentControlDate;
export const dashboardMonitorActions = dashboardMonitorSlice.actions;
export default dashboardMonitorSlice.reducer;

export const adjustPossibleAssetOptions = dashboardMonitorSlice.actions.adjustPossibleAssets;
