import React from 'react';
import {
    buttonBackgroundColor,
    mainBackgroundColor,
    mainTextColor,
    RESOLUTION_SCREEN,
    secondaryColdShadeBgColor
} from 'config';
import {D30, H24, Portfolio, YTD} from 'models/portfolio';
import {timestampToString} from 'utils/date-utils';
import {ActionMode, getRouteSuffix} from 'pages/portfolios/portfolio.common';
import JustTable from '../../controls/table';
import {Box, Stack, styled, Typography} from '@mui/material';
import '../../controls/tablescss.css'
import AssetsClassesGraph from "../../../pages/portfolios/portfolio-graphs/assets-classes-graph";
import {adjustPossibleAssetOptions} from "../../../pages/portfolios/portfolio.slice";
import {useDispatch} from "react-redux";
import {RightOutlined} from "@ant-design/icons";
import PortfolioInsertTable from "../portfolio-insert/portfolio-insert-table";
import useState from "react-usestateref";
import ConfirmDialog from 'components/dialogs/dialog';
import {goTo} from "../../../utils/react-utils";
import AppRoutes from "../../../routes/AppRoutes";
import {MainOrangeButton} from "../../tab-component";
import {Navigate, useNavigate, useParams,Link} from "react-router-dom";
import {totalClassPct,titleClass,totalClassProcent,
    totalClassPctComposition} from "../../../pages/portfolios/helper";


interface PortfolioTableProps {
  portfolios:  Portfolio[];
  selectFunction: Function;
  onRefresh: Function;
  onRemove:Function;
  holder: Record<string, any>;
  sortByFunction:Function;
  currentSortField: string;
  extraCall?: Function;
}



function PortfolioAssetsTable(props) {
  const { portfolio, onRefresh } = props;
  const navigate = useNavigate();

    const resolutionWidthSim = RESOLUTION_SCREEN === 'horizontal' ? '120px' : '90px';
    const resolutionWidthOpt = RESOLUTION_SCREEN === 'horizontal' ? '138px' : '100px';
    const stackDirection = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';

  return <>
    <Stack direction={stackDirection} justifyContent={'space-between'}
           style={{ height: '100%', marginBottom:'10px', marginTop:'10px',width:'100%'}} >
        <Stack sx={{width: {xs:'100%',md:'50%',lg:'50%'}}}>
            <PortfolioInsertTable portfolio={portfolio} classCSS={'tdportfolioTable'} borderBottom={true}/>
        </Stack>
        <Stack direction={'row'} spacing={2} paddingTop={'6px'}>
    </Stack>
    </Stack>
  </>
}

const AssetsGraph = props => {
    const {portfolio} = props;
    // const assetsValues = totalClassProcent(portfolio);
    const assetsValues = totalClassPctComposition(portfolio);
    const assetsTitles = titleClass(portfolio.assets);

    return <AssetsClassesGraph series={assetsValues} labels={assetsTitles}/>;
}


export default function PortfolioTable(props: PortfolioTableProps) {
  const { portfolios, selectFunction, holder, onRefresh,onRemove, sortByFunction,currentSortField} = props;
  const expandedId = holder[ActionMode.expandPortfolio];
  const [removeDialogUp, setRemoveDialogUp] = useState(false);
  const [removePortfolioId,setRemovePortfolioId] = useState({} as Portfolio);




  const getIndicatorStyle = (item: Portfolio) => {
    const value = '00.00';
    const extraStyles = value.startsWith('-') ? { color: 'red' } : {color: 'green'};
    return {...extraStyles };
  }

  const colorIndicator = item => item < 0 ? 'red': 'green';

  const indicatorViewH24 = (_, item: Portfolio) =>{
    return <span style={{color:colorIndicator(item.indicators[H24])}}>{item.indicators[H24]}%</span>;
  }

    const indicatorViewD30 = (_, item: Portfolio) => {
       return <span style={{color:colorIndicator(item.indicators[D30])}}>{item.indicators[D30]}%</span>;
    }
    const indicatorViewYTD = (_, item: Portfolio) => {
      return  <span style={{color:colorIndicator(item.indicators[YTD])}}>{item.indicators[YTD]}%</span>;
    }

    const expandAssets = (item) => {
        onRefresh({'mode': ActionMode.expandAssets, targetId: item.id});
    };


    const getNamePortfolio = (_, item: Portfolio) =>
          <span>{item.name}</span>
    const byCountOf = (item: Portfolio) => item.assets?.length;
    const presentDate = (date?: number) => date ? timestampToString(date) : '';
    const getPortfolioAssetsCount = (_, item: Portfolio) => item._assetCount;
    const createdDate = (_, item: Portfolio) => presentDate(item.created);
    const updatedDate = (_, item: Portfolio) => item.created === item.updated ? '' : presentDate(item.updated);

  const getAssetsClassesRange = (_, item: Portfolio) => <AssetsGraph portfolio={item}/>;
  const expandPortfolio = (item) => {
    const id = item.id;
    const nextExpandId = id === expandedId ? -1 : id;
    onRefresh({'mode': ActionMode.expandPortfolio, targetId: nextExpandId});
  };
    const dispatch = useDispatch();

  const navigate = useNavigate();
  const updateAssets = (item) => {
    onRefresh({'mode': ActionMode.editPortfolio, targetId: item.id});
    navigate(`editPortfolio/${item.id}`);
  };


  const extraviewPortfolio = (item) => {
      onRefresh({'mode': ActionMode.analysisPortfolio, targetId: item.id});
      navigate(`analysisPortfolio/${item.id}`);
  };

  const onRemovePortfolio = (item) => {
    onRemove(item.id);
  };

  const checkRemove = (item) => {
    setRemoveDialogUp(true);
    setRemovePortfolioId(item);
  };

  const skipRemoveConfirm = () => {
    setRemoveDialogUp(false);
    onRemovePortfolio(removePortfolioId)
  };
  const skipRemoveCancel = () => {
    setRemoveDialogUp(false);
  };
  const itemSorter = (field_name:string, sorter) => {
    sortByFunction(field_name, sorter)
  };

  const expandedRowRender = (row :Portfolio, index: number, numberOfColumns: number) =>
      <tr><td colSpan={10} style={{backgroundColor: mainBackgroundColor}}>
          <PortfolioAssetsTable portfolio={row} key={'at' + row.id} index={index} onRefresh={onRefresh}/>
      </td></tr>

  return <>
    {RESOLUTION_SCREEN === 'horizontal' ? <JustTable
      switchCSS={'portfolioTable'}
      name={'portfolios'}
      data={portfolios}
      visibleRowsNumber={25}
      width={'100%'}
      fields={[
        { name: '',                                   'width': '4%'},
        { name: 'name',       title: 'Portfolio Name','width': '28%','sorted': 'DOWN',  render: getNamePortfolio },
        { name: 'updated',    title: 'Last edit',     'width': '10%','sorted': 'NUMBER',render: updatedDate },
        { name: 'created',    title: 'Creation Date', 'width': '12%','sorted': 'NUMBER',render: createdDate },
        // { name: '24H',        title: '24H',           'width': '6%', 'sorted': 'NUMBER',render: indicatorViewH24 },
        // { name: 'd30',        title: '30D',           'width': '6%', 'sorted': 'NUMBER',render: indicatorViewD30 },
        // { name: 'ytd',        title: 'YTD',           'width': '6%', 'sorted': 'NUMBER',render: indicatorViewYTD },
        { name: '_assetCount',title: 'Holdings',      'width': '10%','sorted': 'NUMBER',render: getPortfolioAssetsCount },
        { name: 'assets',     title: 'Asset Classes', 'width': '40%',                   render: getAssetsClassesRange},
        { name: '',                                   'width': '10%'},
        ]}
      searchFields={['created', 'updated', 'name']}
      itemSorter={itemSorter}
      currentSortField={currentSortField}
      actions={[
        {action: 'EXPAND', onClick: expandAssets, posit: 'before'},
        {action: 'EXTRAVIEW', onClick: extraviewPortfolio},
        {action: 'EDIT', onClick:   updateAssets},
        {action: 'REMOVE', onClick:  checkRemove},
      ]}
      onSelect={selectFunction}
      expandedRowRender={expandedRowRender}
    />
        : <JustTable
            switchCSS={'portfolioTable'}
            name={'portfolios'}
            data={portfolios}
            visibleRowsNumber={25}
            width={'100%'}
            fields={[
                { name: '',                                   'width': '4%'},
                { name: 'name',       title: 'Portfolio Name','width': '36%','sorted': 'DOWN',  render: getNamePortfolio },
                { name: '_assetCount',title: 'Holdings',      'width': '10%','sorted': 'NUMBER',render: getPortfolioAssetsCount },
                { name: 'assets',     title: 'Asset Classes', 'width': '40%',                   render: getAssetsClassesRange},
                { name: '',                                   'width': '10%'},
            ]}
            searchFields={['created', 'updated', 'name']}
            itemSorter={itemSorter}
            currentSortField={currentSortField}
            actions={[
                {action: 'EXPAND', onClick: expandAssets, posit: 'before'},
                {action: 'EXTRAVIEW', onClick: extraviewPortfolio},
                {action: 'EDIT', onClick:   updateAssets},
                {action: 'REMOVE', onClick:  checkRemove},
            ]}
            onSelect={selectFunction}
            expandedRowRender={expandedRowRender}
        />}

      {removeDialogUp && <ConfirmDialog
          title={''}
          handleAgree={skipRemoveConfirm}
          handleCancel={skipRemoveCancel}
      >
          <Typography noWrap={true}><span>The portfolio '{removePortfolioId.name}' will be removed.</span>
              <br/><br/>
              <span>Are you sure?</span>
          </Typography>
      </ConfirmDialog>
      }
  </>
}
