
import * as React from 'react';
import { styled } from '@mui/system';
import { Stack} from '@mui/material';
import { useEffect, useState } from 'react';
import {
    buttonBackgroundColor,
    headerBackgroundColor,
    mainBackgroundColor,
    mainTextColor,
    primaryTextColor, secondaryColdShadeBgColor, secondaryWarmShadeBgColor
} from 'config';
import { $$ } from '../utils/utils';
import {RightOutlined} from "@ant-design/icons";

const ItemTabComponent = styled('div')({
    display: 'inline',
    borderCollapse: 'collapse',
    cursor:'pointer'
    // marginTop:'2px'
});

const ItemTabSeparator = styled('div')({
    display: 'inline',
    borderCollapse: 'collapse',
    paddingRight: '16px',
    paddingBottom: '2px',
    borderBottom: '1.5px solid ' + headerBackgroundColor,
});

const ItemTabSeparatorHMWY= styled('div')({
    display: 'inline',
    borderCollapse: 'collapse',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    paddingRight: '8px',
    margin: '-4px',
});

export interface TabItem {
  title: string;
  id?: string;
  graphColor?: string;
  section?: string;
  timeRange?: string;
}

interface TabsComponentProps {
  items: TabItem[] | string[],
  currentTabId?: string;
  onChange: (item: TabItem) => void;
  variant?: string;
  width?: string;
  applyBorder?: boolean;
  isRunning?: boolean;
}

export const HMWY = 'HMWY';

interface OrangeButtonProps {
    onClick:() => void;
    title: string;
    disable?:boolean;
}
export const MainOrangeButton = (props: OrangeButtonProps) => {
    return (
        <>
            <button className={ props.disable ? 'disabledBtn': 'advancedBtn'} onClick={props.onClick}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    {props.title}
                    <RightOutlined style={{fontSize: '8px', color: props.disable ? primaryTextColor : mainBackgroundColor, marginLeft:'4px'}}/>
                </Stack>
            </button>
        </>
    )
}

export const TabsComponent = (props: TabsComponentProps) => {
  let items = $$.isString(props.items[0]) ? props.items.map(it => ({title: it, id: it})) : props.items;
  items = items.map(it => it.id ? it : {title: it.title, id: it.title});
  const width = props.width || '100%';
  const variant = props.variant;
  const applyBorder = props.applyBorder;
  const runningSimulation = props.isRunning;
  // @ts-ignore
  const [current, setCurrent] = useState(props.currentTabId || (items.length > 0 ? items[0].id : ''));
  // const [current, setCurrent] = useState(props.currentTabId);
  //   console.log('currentTAbId',current);
    // console.log(props.currentTabId)
  useEffect(() => {
    if(props.currentTabId && props.currentTabId !== current) {
      setCurrent(props.currentTabId);
    }
  }, [props.currentTabId])

  const clickHandle = (e, tabItem: TabItem) => {
      //todo Anastasiia - Change it when WatchList will be working and Client Portfolio
      if(tabItem.id !== 'watchlist'
          && tabItem.title !== 'Client Portfolios'
          && tabItem.id !== 'AdvancedSimulation'
          && tabItem.id !== 'Optimizer'
          && !runningSimulation
      ) {
        e.preventDefault();
        setCurrent(tabItem.id);
        props.onChange(tabItem);
      }

        // e.preventDefault();
        // setCurrent(tabItem.id);
        // props.onChange(tabItem);

  }

  const extraStyle = (item: TabItem) => variant === HMWY
    ? item.id === current
      ? {}
      : {}
    : item.id === current
      ? { borderBottom: '2.5px solid ' + buttonBackgroundColor, paddingBottom: '1px',cursor:'pointer',paddingLeft:'2px',paddingRight:'2px'}
      : { borderBottom: '1.5px solid ' + headerBackgroundColor, paddingBottom: '2px',cursor:'pointer',paddingLeft:'2px',paddingRight:'2px'};

  const getTextColor = item => variant === HMWY
    ? item.id === current
      ? buttonBackgroundColor
      : mainTextColor
    : item.id === 'watchlist' ? '#746765'//todo delete when everything will be working leave only :mainTextColor
    : item.title === 'Client Portfolios'? '#746765'
    : item.id === 'AdvancedSimulation'? '#746765'
    : item.id === 'Optimizer'? '#746765'
    :mainTextColor;



    const resolutionScreen = () => window.screen.width > window.screen.height ? 'horizontal' : 'vertical';
    const resolutionScreenStyle = resolutionScreen() === 'horizontal'? '12px' : '8px';

  const separator = idx => items.length -1 === idx ?
    <></>
    : variant === HMWY ? <ItemTabSeparatorHMWY></ItemTabSeparatorHMWY> : <ItemTabSeparator></ItemTabSeparator>;
    const actualStyle = (it) :React.CSSProperties => {
        const style = extraStyle(it);
        return  applyBorder
                  ? {...style,
                      border: `1.5px solid ${headerBackgroundColor}`,
                      fontSize: resolutionScreenStyle,
                      paddingRight: '6px',
                      paddingLeft: '6px',
                      paddingTop: '2px',
                      paddingBottom: '2px',
                      lineHeight: '20px',
                      cursor:'pointer'
                    }
                  : style;
    };

    const BorderColor =(item) => {
        const style = actualStyle(item);
        return item.id === current && applyBorder
            ? {...style,
                border: `1.5px solid ${buttonBackgroundColor}`
            } : style
    };


    // @ts-ignore
    return items.length === 0
    ? <></>
    :items.length !== 0 && applyBorder === true ? <Stack style={{display: 'inline', width: width}} direction="row" justifyContent="space-between" >
        { items.map((it, idx) =><span key={it.id} onClick={(e) => clickHandle(e, it)} >
            <ItemTabComponent className={'nowrap'}
                style={BorderColor(it)}
            >
                <span style={{color: getTextColor(it)}}>{it.title}</span>
            </ItemTabComponent>{separator(idx)}</span>)
        }
    </Stack>
    :<Stack style={{display: 'inline', marginTop:'4px',width: width,borderBottom: '2px solid' + headerBackgroundColor,paddingBottom:'0.05px'}} direction="row" justifyContent="space-between" >
        { items.map((it, idx) =><span key={it.id} onClick={(e) => clickHandle(e, it)}>
            <ItemTabComponent className={'nowrap'}
                              style={BorderColor(it)}
            >
                <span style={{color: getTextColor(it)}}>{it.title}</span>
            </ItemTabComponent>{separator(idx)}</span>)
        }
    </Stack>;
}
