import React, { useEffect } from "react";

/*
https://www.tradingview.com/charting-library-docs/latest/ui_elements/drawings/Drawings-List
 */

const TradingViewChartInjector = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/jsx";
    script.src = "/charting_library/charting_library.js";
    document.head.appendChild(script);
  }, []); //eslint-disable-line
  return <div id="tv_chart_container"/>
};

export default TradingViewChartInjector;
