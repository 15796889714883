import React, { useRef, useState } from 'react';
import { PropsWithChildren } from 'react'


// @todo move to utils / remove
function getOffset(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}


export interface Reflector {
  ref :any;
  current: any;
  top: () => number;
  left: () => number;
  width: () => number;
  height: () => number;
}

export const createReflector = () => {

  const ref = useRef();

  // @ts-ignore
  const rect = () => ref.current.getBoundingClientRect();

  const height = () => rect().height;
  const width = () => rect().width;
  const top  = () => rect().top;
  const left = () => rect().left;
  return {
    ref, current: ref.current, height, width, top, left
  }
}

export type SizedDivProps = PropsWithChildren<{
  reflector: Reflector | undefined;
  height?: number | string;
  width?: number | string;
  style?: {};
}>;


export const SizedDiv = (props: SizedDivProps) => {
  const initialWidth = props.width || '100%';
  const initialHeight = props.height || undefined;
  const [actualWidth, setActualWidth] = useState(initialWidth);
  const [actualHeight, setActualHeight] = useState(initialHeight);

  const style = { ...props.style, width: actualWidth, height: actualHeight};
  return <div
      style={style}
      ref={el => {
        // @ts-ignore
        props.reflector.ref.current = el;
      }}
    >
      {props.children}
    </div>;
}

// @todo below just an example of usage
// export const ResizeViewExample = () => {
//   const reflector =  createReflector();
//
//   const test = (size) => {
//     console.log("### ref.current::offsetWidth::height", reflector.height());
//     console.log("### ref.current::offsetWidth::top", reflector.top());
//   }
//   return <>DDD<SizedDiv
//     height={'100px'}
//     width={'200px'}
//     style={{color: 'red',  border: "5px solid grey"}}
//     reflector={reflector}>AAA</SizedDiv><div onClick={() =>test(500)} >test500</div>
//     <div onClick={() =>test(300)} >test300</div>
// </>}
//
// export default ResizeViewExample;

// @todo remove
// https://www.npmjs.com/package/react-split
