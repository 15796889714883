import React, {useRef, useState} from "react";
import {Stack} from "@mui/material";
import WidgetInfoPanel from "../../../components/controls/info-panel";
import {
    DAILY, getDefaultGraphId,
    getGraphicColours,
    getGraphicIds, getTabsGraphsInfo, MONTHLY, QUARTERLY, RETURN_QUANTILES_ID,  WEEKLY, YEARLY,
} from "../../../data/graphics";
import {TabItem} from "../../../components/tab-component";
import ValueGraph from "../../../components/graphs/value-graph";
import {timeFormat} from "d3-time-format";
import {zipAsDict} from "../../../utils/misc";
import {CandlestickSeries, LineSeries, ScatterSeries} from "../../../@react-financial-charts/series";
import {Triangle} from "../../../@react-financial-charts/series/markers";
import TestGraphBox from "./testgraph-box";
import {RESOLUTION_SCREEN} from "../../../config";


interface Margin {
    left: number;
    right: number;
    top: number;
    bottom: number;
}

interface ValueGraphSettings {
    graphicLineWidth: number;
    graphicSelectedLineWidth: number;
    margin: Margin;
    chartHeight: number;
    zeroStroke: {};
    tickFormatter: Function;
    extraGrid: any;
}

const defaultValueGraphSettings = {
    graphicLineWidth: 1,
    graphicSelectedLineWidth: 3,
    margin: { left: 27, right: 10, top: 0, bottom: 24 },
    chartHeight: 400,
    zeroStroke: {lineWidth: 1, strokeStyle: '#AEA49B', dasharray: 'ShortDot'},
    tickFormatter: value =>  value,
    extraGrid: {}
} as ValueGraphSettings;


const renderDataX = [
    {'rebalance': 'Daily','daily':{'low':0.02,'close':0.05,'medium':0.07,'open':0.07,'high':0.09}, 'romb':[0.1]},
    {'rebalance': 'Weekly','weekly':{'low':-0.01,'close':0.04,'medium':0.06,'open':0.06,'high':0.07}, 'romb':[-0.015]},
    {'rebalance': 'Monthly','monthly':{'low':-0.01,'close':0.02,'medium':0.04,'open':0.04,'high':0.06}, 'romb':[0.065]},
    {'rebalance': 'Quarterly','quarterly':{'low':-0.002,'close':0.0,'medium':0.02,'open':0.02,'high':0.03}, 'romb':[0.035]},
    {'rebalance': 'Yearly','yearly':{'low':-0.01,'close':0.02,'medium':0.04,'open':0.04,'high':0.06}, 'romb':[0.065]},
];

const graphicSettings = { ...defaultValueGraphSettings,
    chartHeight: 200,
    graphicLineWidth: 3,
    tickFormatter: value => value * 10
};


// const categories = renderDataX.map((el)=> el.rebalance)
const categories = ['Daily','Weekly']

const ReturnQuantiles = () => {
    const panelId = RETURN_QUANTILES_ID;
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = DAILY;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    }

    const seriesAxep = [
        {name:graphics().map((item) => item.title)[0],type:'boxPlot',
        data:[
            {x: 0, y: renderDataX.map((item) => item.daily !== undefined ?
                    [item.daily.low,item.daily.close,item.daily.medium,item.daily.open,item.daily.high]
                    :null)[0],  },
            { x: 1,y: renderDataX.map((item) => item.weekly !== undefined ?
                    [item.weekly.low,item.weekly.close,item.weekly.medium,item.weekly.open,item.weekly.high]
                    :null)[1],},
            {x: 2,y: renderDataX.map((item) => item.monthly !== undefined ?
                    [item.monthly.low,item.monthly.close,item.monthly.medium,item.monthly.open,item.monthly.high]
                    :null)[2],},
            {x: 3,y: renderDataX.map((item) => item.quarterly !== undefined ?
                    [item.quarterly.low,item.quarterly.close,item.quarterly.medium,item.quarterly.open,item.quarterly.high]
                    :null)[3],},
            {x: 4,y: renderDataX.map((item) => item.yearly !== undefined ?
                    [item.yearly.low,item.yearly.close,item.yearly.medium,item.yearly.open,item.yearly.high]
                    :null)[4],},
        ]},
        {name:'romb',type: 'scatter',
        data:[
            {x:0,y:0.095},{x:0,y:0.07},{x:0,y:0.01},{ x:0,y:-0.0015},
            {x:1,y:0.05},{x:1,y:0.07},{x:1,y:0.04},{x:2,y:0.07},
            {x:2,y:0.05},
            {x:3,y:0.02 },{x:3,y:0.05 },{x:3,y:-0.007},
            {x:4,y:0.02},{x:4,y:0.05},{x:4,y:-0.007},
        ]
    },
];
// console.log(seriesAxep)

    const graphSelectPanelOnChange = (item: TabItem) => {
        setCurrentGraphicId(item.id!);
    };

    const timeRangeSelectPanelOnChange = (item: TabItem) => {
        console.log("############ timeRangeSelectPanelOnChange TITLE::", item);
    };

    const graphicSettings = { ...defaultValueGraphSettings,
        chartHeight: 200,
        graphicLineWidth: 3,
        tickFormatter: value => value * 10
    };

    const colors = zipAsDict(getGraphicIds(graphics()),getGraphicColours(graphics()))
    const seriesByName = (name: string) => data => data[name];

    const series = getGraphicIds(graphics());

    const seriesType = () => <>
    { series.map((s,i) =>
            s[i] === s[4] ?
               <ScatterSeries
               key={s}
               yAccessor={d => d.romb}
               marker={Triangle}
                markerProps={{width: 8, stroke: "#AEA49B", fillStyle: "#AEA49B" }}
               /> :

                <CandlestickSeries
                key={'candle' + s}
                clip={true}
                candleStrokeWidth={10}
                width={97}
                // stroke={"#000000"}
                fill={colors[s]}
                wickStroke={'#AEA49B'}
                yAccessor={(d) =>({open: d.OPEN, close: d.CLOSE, high: d.HIGH, low: d.LOW,})}/>
        )}
    </>

    const resolutionGraphWidth = RESOLUTION_SCREEN === 'horizontal' ? 698 : 358;


    return <Stack style={{width: '100%', marginTop:'86px'}} direction="column" >
        <WidgetInfoPanel
            // id={panelId}
            title={'RETURN QUANTILES'}
            // infoTabs={DatesTabItems}
            // graphics={graphics()}
            infoMarkers={[]}
            // infoTabsOnChange={infoTabsOnChange}
            // advancedButton={<MainOrangeButton onClick={() => {}} title={'Advanced'}/>}
        />
        {/*<ValueGraph*/}
        {/*    width={resolutionGraphWidth}*/}
        {/*    height={276}*/}
        {/*    series={getGraphicIds(graphics())}*/}
        {/*    colors={getGraphicColours(graphics())}*/}
        {/*    seriesType={seriesType}*/}
        {/*    disableInteraction*/}
        {/*    currentSeriesId={currentGraphicId}*/}
        {/*    settings={graphicSettings}*/}
        {/*    renderData={renderData}*/}
        {/*/>*/}
        <TestGraphBox
            series={seriesAxep}
            // categories={categories}
            height={350}
            width={698}
            graphics={graphics()}
            type={'boxPlot'}
        />
    </Stack>
};

export default ReturnQuantiles;
