import { AsyncInProgressStatus, AsyncApiStatus } from 'models/nb-models';

export interface BasePageState {
  holder: Record<string, any>;
  apiStatus: AsyncApiStatus;
  inprogress: AsyncInProgressStatus;
}

export const BasePageDefaultState = {
  holder: {},
  apiStatus: 'idle' as AsyncApiStatus,
  inprogress: 'done' as AsyncInProgressStatus,
};
