import { useLocation, useNavigate, useParams } from "react-router-dom";

import Admin from 'pages/admin/admin-main';
import useAuth from 'hooks/useAuth';
import { UserProfile } from '../../types/auth';
import { useEffect, useState } from 'react';

export function routerInjector() {

    const [dateTime, setDateTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setDateTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const location = useLocation();
    const { user } = useAuth();
    const navigation = useNavigate();
    const params = useParams();
    return <Admin user={user as UserProfile} currentTime={dateTime} params={params} location={location} navigation={navigation}/>;
}
export default routerInjector;
