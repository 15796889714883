import React, {useRef, useState} from "react";
import {Stack} from "@mui/material";
import WidgetInfoPanel from "../../../components/controls/info-panel";
import {
     DAILY, VALUE_SHORTFALL_ID,
    getDefaultGraphId,
    getTabsGraphsInfo,
} from "../../../data/graphics";
import {TabItem} from "../../../components/tab-component";
import TrandingviewStraightLines from "./tv-straight-lines";





const renderData = [
    {"date" : '2022-05-10', 'DAILY_RETURNS' : 0 ,'VAR': 0, 'ES': 0},
    {"date" : '2022-05-11','DAILY_RETURNS' : -0.20554 ,'VAR': 0.5442, 'ES': 0.2316},
    {"date" : '2022-05-12','DAILY_RETURNS' : -0.00554 ,'VAR': 0.5442, 'ES': 0.2316},
    {"date" : '2022-05-13','DAILY_RETURNS' : 0.46554,'VAR': 0.5442, 'ES': 0.2316 },
    {"date" : '2022-05-14', 'DAILY_RETURNS' : 0.126554,'VAR': 0.5442, 'ES': 0.2316},
    {"date" : '2022-05-15', 'DAILY_RETURNS' : 0.0156554 ,'VAR': 0.11321, 'ES': 0.23456},
    {"date" : '2022-05-20', 'DAILY_RETURNS' : -0.256554 ,'VAR': 0.11321, 'ES': 0.23456},
    {"date" : '2022-05-25', 'DAILY_RETURNS' : 0.256554,'VAR': 0.02103, 'ES': 0.2513645 },
    {"date" : '2022-05-30', 'DAILY_RETURNS' : -0.36554,'VAR': 0.02103, 'ES': 0.2513645 },
    {"date" : '2022-06-05', 'DAILY_RETURNS' : 0 ,'VAR': -0.2156, 'ES': -0.2513645},
    {"date" : '2022-06-10',  'DAILY_RETURNS' : -0.20554,'VAR': -0.2156, 'ES': -0.2513645 },
    {"date" : '2022-06-12','DAILY_RETURNS' : -0.00554,'VAR': 0.02103, 'ES': 0.2513645 },
    {"date" : '2022-06-13', 'DAILY_RETURNS' : 0.46554,'VAR': 0.02103, 'ES': 0.2513645  },
    {"date" : '2022-06-14',  'DAILY_RETURNS' : 0.126554,'VAR': 0.15635, 'ES': 0.02516 },
    {"date" : '2022-06-15', 'DAILY_RETURNS' : 0.0156554,'VAR': 0.15635, 'ES': 0.02516  },
    {"date" : '2022-06-20', 'DAILY_RETURNS' : -0.256554,'VAR': -0.02103, 'ES': -0.2513645 },
    {"date" : '2022-06-25', 'DAILY_RETURNS' : 0.256554,'VAR': -0.02103, 'ES': -0.2513645  },
    {"date" : '2022-06-30',  'DAILY_RETURNS' : -0.36554,'VAR': 0.2135163, 'ES': 0.02031 },
    {"date" : '2022-07-05', 'DAILY_RETURNS' : 0 ,'VAR': 0.2135163, 'ES': 0.02031},
    {"date" : '2022-07-10', 'DAILY_RETURNS' : -0.20554,'VAR': 0.2135163, 'ES': 0.02031 },
    {"date" : '2022-07-12', 'DAILY_RETURNS' : -0.00554,'VAR': 0.6564686, 'ES': 0.13415 },
    {"date" : '2022-07-13', 'DAILY_RETURNS' : 0.46554 ,'VAR': 0.6564686, 'ES': 0.13415},
    {"date" : '2022-07-14', 'DAILY_RETURNS' : 0.126554,'VAR': -0.02103, 'ES': -0.2513645 },
    {"date" : '2022-07-15', 'DAILY_RETURNS' : 0.0156554,'VAR': -0.02103, 'ES': -0.2513645 },
    {"date" : '2022-07-20', 'DAILY_RETURNS' : -0.256554 ,'VAR': -0.02103, 'ES': -0.2513645},
    {"date" : '2022-07-25', 'DAILY_RETURNS' : 0.256554 ,'VAR': -0.02103, 'ES': -0.2513645},
    {"date" : '2022-07-30', 'DAILY_RETURNS' : -0.36554, 'VAR': 0.01516, 'ES': 0.0456265 },
    {"date" : '2022-08-05',  'DAILY_RETURNS' : 0 , 'VAR': 0.01516, 'ES': 0.0456265},
    {"date" : '2022-08-10', 'DAILY_RETURNS' : -0.20554, 'VAR': 0.01516, 'ES': 0.0456265 },
    {"date" : '2022-08-12', 'DAILY_RETURNS' : -0.00554, 'VAR': 0.01516, 'ES': 0.0456265 },
    {"date" : '2022-08-13', 'DAILY_RETURNS' : 0.46554,'VAR': 0.0231, 'ES': 0.0215065 },
    {"date" : '2022-08-14', 'DAILY_RETURNS' : 0.126554,'VAR': 0.0231, 'ES': 0.0215065 },
    {"date" : '2022-08-15', 'DAILY_RETURNS' : 0.0156554 ,'VAR': 0.0231, 'ES': 0.0215065},
    {"date" : '2022-08-20', 'DAILY_RETURNS' : -0.256554,'VAR': 0.0564, 'ES': 0.04785 },
    {"date" : '2022-08-25', 'DAILY_RETURNS' : 0.256554,'VAR': 0.0564, 'ES': 0.04785 },
    {"date" : '2022-08-30',  'DAILY_RETURNS' : -0.36554,'VAR': 0.0564, 'ES': 0.04785 },
    {"date" : '2022-09-05', 'DAILY_RETURNS' : 0,'VAR': 0.0564, 'ES': 0.04785 },
    {"date" : '2022-09-10', 'DAILY_RETURNS' : -0.20554,'VAR': 0.02103, 'ES': 0.2513645 },
    {"date" : '2022-09-11', 'DAILY_RETURNS' : -0.00554 ,'VAR': 0.02103, 'ES': 0.2513645},
    {"date" : '2022-09-12', 'DAILY_RETURNS' : 0.49554 ,'VAR': 0.02103, 'ES': 0.2513645},
    {"date" : '2022-09-13', 'DAILY_RETURNS' : 0.126554 ,'VAR': 0.02103, 'ES': 0.2513645},
    {"date" : '2022-09-14', 'DAILY_RETURNS' : 0.0156554,'VAR': -0.02103, 'ES': -0.2513645 },
    {"date" : '2022-09-15', 'DAILY_RETURNS' : -0.256554,'VAR': -0.02103, 'ES': -0.2513645  },
    {"date" : '2022-09-20', 'DAILY_RETURNS' : 0.356554 ,'VAR': -0.02103, 'ES': -0.2513645 },
    {"date" : '2022-09-25', 'DAILY_RETURNS' : -0.36554,'VAR': -0.02103, 'ES': -0.2513645  },
    {"date" : '2022-10-05', 'DAILY_RETURNS' : 0 ,'VAR': -0.02103, 'ES': -0.2513645  },
    {"date" : '2022-10-10', 'DAILY_RETURNS' : -0.20554,'VAR': -0.0561, 'ES': -0.08456 },
    {"date" : '2022-10-12', 'DAILY_RETURNS' : -0.00554,'VAR': -0.0561, 'ES': -0.08456 },
    {"date" : '2022-10-13', 'DAILY_RETURNS' : 0.46554 ,'VAR': -0.0561, 'ES': -0.08456},
    {"date" : '2022-10-14',  'DAILY_RETURNS' : 0.126554 ,'VAR': -0.0561, 'ES': -0.08456},
    {"date" : '2022-10-15',  'DAILY_RETURNS' : 0.0156554 ,'VAR': -0.0561, 'ES': -0.08456},
    {"date" : '2022-10-20', 'DAILY_RETURNS' : -0.556554,'VAR': 0.02103, 'ES': 0.2513645 },
    {"date" : '2022-10-25', 'DAILY_RETURNS' : 0.456554 ,'VAR': 0.02103, 'ES': 0.2513645 },
    {"date" : '2022-10-30',  'DAILY_RETURNS' : -0.36554 ,'VAR': 0.02103, 'ES': 0.2513645 },
    {"date" : '2022-11-05',  'DAILY_RETURNS' : 0,'VAR': 0.02103, 'ES': 0.2513645  },
    {"date" : '2022-11-10', 'DAILY_RETURNS' : -0.20554 ,'VAR': 0.02103, 'ES': 0.2513645 },
    {"date" : '2022-11-12', 'DAILY_RETURNS' : -0.00554,'VAR': 0.02103, 'ES': 0.06510 },
    {"date" : '2022-11-13', 'DAILY_RETURNS' : 0.56554,'VAR': 0.02103, 'ES': 0.06510  },
    {"date" : '2022-11-14', 'DAILY_RETURNS' : 0.126554,'VAR': 0.02103, 'ES': 0.06510  },
    {"date" : '2022-11-15', 'DAILY_RETURNS' : 0.0156554,'VAR': 0.0651623, 'ES': 0.02513645 },
    {"date" : '2022-11-20',  'DAILY_RETURNS' : -0.356554 ,'VAR': 0.0651623, 'ES': 0.02513645},
    {"date" : '2022-11-25', 'DAILY_RETURNS' : 0.256554 ,'VAR': 0.0651623, 'ES': 0.02513645},
    {"date" : '2022-11-30', 'DAILY_RETURNS' : -0.36554,'VAR': 0.0546, 'ES': 0.0541412 },
    {"date" : '2022-12-05', 'DAILY_RETURNS' : 0,'VAR': 0.0546, 'ES': 0.0541412  },
    {"date" : '2022-12-10',  'DAILY_RETURNS' : -0.20554,'VAR': 0.0546, 'ES': 0.0541412  },
    {"date" : '2022-12-12', 'DAILY_RETURNS' : -0.00554 ,'VAR': 0.0546, 'ES': 0.0541412 },
    {"date" : '2022-12-13', 'DAILY_RETURNS' : 0.46554,'VAR': 0.01021, 'ES': 0.12020 },
    {"date" : '2022-12-14', 'DAILY_RETURNS' : 0.326554,'VAR': 0.01021, 'ES': 0.12020  },
    {"date" : '2022-12-15', 'DAILY_RETURNS' : 0.0156554,'VAR': 0.06546, 'ES': 0.051633 },
    {"date" : '2022-12-20', 'DAILY_RETURNS' : -0.356554 ,'VAR': 0.06546, 'ES': 0.051633 },
    {"date" : '2022-12-25', 'DAILY_RETURNS' : 0.256554,'VAR': 0.06546, 'ES': 0.051633  },
    {"date" : '2022-12-30', 'DAILY_RETURNS' : -0.36554,'VAR': 0.0985632, 'ES': 0.0523103 },
];


const ValueRiskExpectedShortfall = () => {

    const panelId = VALUE_SHORTFALL_ID;
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = DAILY;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    };


    const graphSelectPanelOnChange = (item: TabItem) => {
        setCurrentGraphicId(item.id!);
    };

    const timeRangeSelectPanelOnChange = (item: TabItem) => {
        console.log("############ timeRangeSelectPanelOnChange TITLE::", item);
    };


    const priceFormatter = (price) => {return (price * 10).toFixed(0) + '%'};
    const tooltipFormatter = (price) => {return (price * 10).toFixed(2) + '%'};

    return <Stack style={{width: '100%',height:'210px'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={'VALUE AT RISK AND EXPECTED SHORTFALL'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
            // advancedButton={<MainOrangeButton onClick={() => {}} title={'Advanced'}/>}
        />
        <TrandingviewStraightLines
            renderData={renderData}
            graphics={graphics()}
            // width={698}
            height={148}
            timeRange={'year'}
            priceFormatter={priceFormatter}
            tooltipForrmatter={tooltipFormatter}
        />
    </Stack>
};

export default ValueRiskExpectedShortfall;
