import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';
import {
  REACT_APP_GITTAG,
  REACT_APP_BUILDTIME,
  REACT_APP_ENVINROMENT,
  buttonBackgroundColor,
  mainTextColor, primaryTextColor
} from 'config';
import CookieSettings from '../../pages/Cookie/Cookie';
import React from 'react';

const colorForEnvStatus = str =>
  str === 'local' ? buttonBackgroundColor
  :str === 'production' ? primaryTextColor
  :str === 'develop' ? '#D43B10' : mainTextColor;


const Footer = () => <>
  <CookieSettings />
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
      <Link component={RouterLink} to="#" variant="caption">
        &copy; All rights reserved
      </Link>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      <div title={REACT_APP_GITTAG} style={{"color": colorForEnvStatus(REACT_APP_ENVINROMENT), "fontSize": "80%"}}>
        build: {REACT_APP_BUILDTIME}
      </div>
      <Link component={RouterLink} to="#" target="_blank" variant="caption">
        About us
      </Link>
      <Link component={RouterLink} to="#" target="_blank" variant="caption">
        Privacy
      </Link>
      <Link component={RouterLink} to="#" target="_blank" variant="caption">
        Terms
      </Link>
    </Stack>
  </Stack>
</>;


export default Footer;
