// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  FundOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

import AppRoutes from 'routes/AppRoutes';



// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other: NavItemType = {
  id: 'other',
  // title: <FormattedMessage id="others" />,
  type: 'group',
  children: [
    {
      id: 'home',
      title: <FormattedMessage id="dashboard-page" />,
      type: 'item',
      url: AppRoutes.NB_HOME,
      icon: ChromeOutlined
    },
    {
      id: 'wealth-lab',
      title: <FormattedMessage id="wealth-lab" />,
      type: 'item',
      url: AppRoutes.WEALTH_LAB,
      icon: DollarOutlined
    },
    {
      id: 'trade-lab',
      title: <FormattedMessage id="trade-lab" />,
      type: 'item',
      url: AppRoutes.TRADE_LAB,
      icon: FundOutlined
    },
  ]
};

export default other;
