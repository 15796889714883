import React, {useState} from "react";
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {useDispatch} from "react-redux";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {Stack,Box, TextField, FormControlLabel, FormHelperText, Typography} from "@mui/material";
import {setDateEnd, setDateStart} from "pages/portfolios/portfolio.slice";
import { DateValidationError } from '@mui/x-date-pickers/models';
import {dateSerializerNew, NbDatePicker} from './nb-data-picker';
import { $$ } from 'utils/utils';
import {RESOLUTION_SCREEN} from "../../../config";



interface DataRangeFormProps {
    dateStart: Date;
    dateEnd: Date;
    isError: string;
    currentControlDate: Date|null;
    className:string;
    createPortfolio?:boolean;
    inceptionDate?:string;
    inceptionDateOnchange?:Function;
    dQonChangeStart?:Function;
    dQonChangeEnd?:Function;
    dQAnalysis?:boolean;
    errorDate:string|DateValidationError;
    setError:Function;
}

const DateRangeForm = (props: DataRangeFormProps) => {
    const dispatch = useDispatch();
    const className = props.className;
    const dateFrom = new Date(props.dateStart);
    const dateTo = new Date(props.dateEnd);
    const createPortfolio = props.createPortfolio;
    const dQAnalysis = props.dQAnalysis;

    const inceptionDate = props.inceptionDate ?  new Date(props.inceptionDate) : new Date();
    const inceptionDateOnchange = props.inceptionDateOnchange ? props.inceptionDateOnchange : ()=>{};
    const dQonChangeStart = props.dQonChangeStart ? props.dQonChangeStart : ()=>{};
    const dQonChangeEnd = props.dQonChangeEnd ? props.dQonChangeEnd : ()=>{};
    const errorMessage = props.isError ;
    const error = props.errorDate;
    const setError = props.setError;


    const onChangeDateStart = (value) => {
       return dQAnalysis ? dQonChangeStart(value) : dispatch(setDateStart(value));
    };
    const onChangeDateEnd = (value) => {
       return dQAnalysis ? dQonChangeEnd(value) : dispatch(setDateEnd(value));
    };

    const yesterday = new Date(new Date()).setDate(new Date ().getDate() -1);
    // const [error, setError] = React.useState<DateValidationError | ''>('');
    const resoludionDirection = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';




    // const errorMessage = React.useMemo(() => {
    //     switch (error) {
    //         case "minDate"      : {return 'Select a date'}
    //         case 'invalidDate'  : {return 'Your date is not valid'}
    //         default             : {return ''}
    //     }
    // }, [error]);

    return (<>
            {!props.createPortfolio ?
               <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack direction={resoludionDirection} spacing={2} >

                                <FormControlLabel control={
                                    <NbDatePicker
                                        helperText={errorMessage}
                                        error={!!error}
                                        setError={setError}
                                        value={dateFrom}
                                        // onAccept={newValue => dispatch(setDateStart(newValue))}
                                        onAccept={newValue => onChangeDateStart(newValue)}
                                        className={className}
                                    />
                                }
                                label={''}
                                checked={true}
                                aria-required={true}
                                value={undefined}
                                />

                                <NbDatePicker
                                        helperText={errorMessage}
                                        error={!!error}
                                        setError={setError}
                                        value={dateTo}
                                        // onAccept={newValue => dispatch(setDateEnd(newValue))}
                                        onAccept={newValue => onChangeDateEnd(newValue)}
                                        className={className}
                                />
                            </Stack>
                </LocalizationProvider>

                    {dateFrom >= dateTo && <Box className={'errorText'}>
                        * Please ensure the end date is later than the start date.
                    </Box>}
               </>
                : <>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack direction={resoludionDirection} spacing={2} >

                            <FormControlLabel control={
                                <NbDatePicker
                                    helperText={errorMessage}
                                    error={!!error}
                                    setError={setError}
                                    value={inceptionDate}
                                    onAccept={newValue => inceptionDateOnchange(newValue)}
                                    className={className}
                                    createPortfolio={createPortfolio}
                                />
                            }
                              label={''}
                              checked={true}
                              aria-required={true}
                              value={undefined}
                            />
                        </Stack>
                    </LocalizationProvider>
                </>
            }
        </>
    )
};

export default DateRangeForm;
