import React, {useRef, useState, useMemo} from "react";
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import {RESOLUTION_SCREEN} from "../../../config";
import {TabItem} from "../../../components/tab-component";
import '../../../components/portfolios/porfolio.css';



const getChartOptions = (labels: string[],series,graphics:TabItem[],categories,plotOptions,stroke,yTitle,type,topFiveStart,topFiveEnd,zeroLine) => {
    const resolutionScreenFontSize = RESOLUTION_SCREEN === 'horizontal'? '12px' : '8px';
    const resolutionScreenLabel = RESOLUTION_SCREEN === 'horizontal'? 14 : 10;
    const resolutionScreenMarkers = RESOLUTION_SCREEN === 'horizontal'? [20,0,20,20] : [15,0,15,15];
    // console.log(topFiveStart)
    return {
        chart: {
            type: type,
            toolbar: {
                // autoSelected: 'zoom',
                show: false,
                tools: {
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: true,
                    reset: true,
                    selection: true,
                    download:false,
                },
            },
            animations: {
                enabled:false
            },
            events: {
                dataPointMouseEnter: function(event) {
                    if(event.fromElement !== null){
                    event.fromElement.style.cursor = "default";
                    }
                }
            },
            offsetX:-10,
            zoom:{
             enabled:false
            },
        },
        dataLabels: {
            enabled: false,

        },
        stroke: stroke,
        fill: {
            opacity: 1,
        },


        grid: {
            borderColor: 'rgba(174,164,155,0.5)',
            strokeDashArray: 2,
            borderWidth:0.5,
            xaxis: {
                lines: {
                    show: false,
                }
            },
            yaxis: {
                lines: {
                    show: true,
                }
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        plotOptions:plotOptions ? plotOptions : {},
        xaxis: {
            type:'category',
            strokeDashArray:1,
            // categories: series.map((it) => dateSerializerNew( new Date(new Date(it.date).valueOf()))),
            // categories: series.map((it) => dateSerializerNew( new Date(new Date(it.date).valueOf() + 1000*60*60))), todo + one day
            categories: categories,
            labels:{
                style:{
                    colors:'#AEA49B',
                    fontSize: resolutionScreenFontSize,
                    fontFamily: 'Noto Sans',
                    fontWeight: 400,
                },

                rotateAlways: false,
                hideOverlappingLabels: false,
                showDuplicates: false,
                trim: false,
                offsetX:0,
                offsetY:0,

            },
            tickPlacement: 'on',
            tickAmount:12,
            axisBorder: {
                show: true,
                color:'#AEA49B',
                height:0.1

            },
            axisTicks:{
                show: false
            },
            tooltip:{
                enabled:false,
            },
            crosshairs: {
                stroke: {
                    color: 'rgba(174,164,155,1)',
                    width: 0.5,
                    dashArray: 5
                }
            },
        },
        yaxis: {
            forceNiceScale: true,
            min:function () {
               const minValues = series.map((item) => {
                  return Math.min(...item.data);
               });
                return Math.min(...minValues) + (-0.05);
            },
            max:function () {
                const maxValues = series.map((item) => {
                    return Math.max(...item.data);
                });
                return Math.max(...maxValues) + 0.05;
            },
            labels:{
                style:{
                    colors:'#AEA49B',
                    fontSize: resolutionScreenFontSize,
                    fontFamily: 'Noto Sans',
                    fontWeight: 400,
                },
                formatter: (value) => { return (value * 100)?.toFixed(0) + '%' },
            },
            axisBorder: {
                show: true,
                width:'0.1px',
                color:'#AEA49B',

            },
            axisTicks:{
                show: false,
            },
            crosshairs: {
                stroke: {
                    color: 'rgba(174,164,155,1)',
                    width: 1,
                    dashArray: 5
                }
            },
            title:{
                text:yTitle,
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: '#AEA49B',
                    fontSize: resolutionScreenFontSize,
                    fontFamily: 'Noto Sans',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            tooltip: {
                enabled:true
            },
        },
        markers: {
            colors: '#AEA49B',
            strokeWidth:0,
            hover: {
                size: undefined,
                sizeOffset: 4
            }
        },
        colors: graphics.map((item) => item.graphColor),
        tooltip:{
            enabled:true,
            followCursor:true,
            theme:false,
            custom: function({series, seriesIndex, dataPointIndex, w, categories}) {
               let tooltip = '';
               tooltip += '<div class="arrow_box">'
               tooltip += '<div style="margin-bottom: 8px">';
               tooltip += '<span style="color: #AEA49B">' + w.globals.lastXAxis.categories[dataPointIndex] +'</span>'
               tooltip += '</div>';
               series.map((item,idx) => {
                   if(item[dataPointIndex] !== null && item[dataPointIndex] !== undefined){
                       tooltip += '<div style="display: flex;flex-direction: row;justify-content: space-between;">';
                       tooltip += '<div>';
                       if(w.globals.seriesNames[idx] !== ''){
                        tooltip += '<span style="color: ' + w.globals.colors[idx] + '" class="value-tooltip">' + w.globals.seriesNames[idx] +  '</span>';
                       } else {
                           tooltip += '<span style="color: ' + w.globals.colors[idx] + '" class="value-tooltip">' + 'Mean' +  '</span>';
                       }
                       tooltip += '</div>';
                       tooltip += '<div>';
                       tooltip += '<span style="color: ' + w.globals.colors[idx] + ';margin-left: 30px" class="value-tooltip">'  + (item[dataPointIndex] * 100).toFixed(2) +'%' + '</span>';
                       tooltip += '</div>';
                       tooltip += '</div>';}
               })
               tooltip += '</div>'
               return tooltip;
            }
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            showForSingleSeries: true,
            fontSize: resolutionScreenLabel,
            fontFamily: 'Noto Sans',
            fontWeight: 400,
            labels: {
                colors: '#AEA49B' ,
            },
            markers:{
                width: resolutionScreenMarkers,
                height: 2.8,
                radius:0,
                offsetY: -3,
            }
        },

        annotations:{
            xaxis: [
                {
                    x: '3%',
                    borderColor:  '#C24046',
                    strokeDashArray: 3,
                },

            ],
            yaxis: [
                {
                    y: zeroLine,
                    strokeDashArray: 0,
                }
            ]
        }
    }
}

const TestGraph = (props) => {
    const {series, labels,benchmark,graphics,width,height,categories,plotOptions,stroke,yTitle,type,topFiveStart,topFiveEnd,zeroLine} = props;
    const [options, setOptions] = useState<ChartProps>(getChartOptions(labels,series,graphics,categories,plotOptions,stroke,yTitle,type,topFiveStart,topFiveEnd,zeroLine));

    return (
        <ReactApexChart options={options} series={series} type={type} height={height} width={width}/>
    )
};

export default TestGraph;