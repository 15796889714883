import React, { ReactElement, useState } from 'react';
import { Stack } from '@mui/material';
import { timeFormat} from "d3-time-format";
import WidgetInfoPanel from 'components/controls/info-panel';
import {MainOrangeButton, TabItem} from 'components/tab-component';

import GraphSelectPanel from 'components/controls/graph-selector';
import {
  STRESS_INDICES_PANEL_ID,
  CRYPTO,
  THRESHOLD,
  getTabsGraphsInfo,
  getGraphicColours,
  getGraphicIds,
  STRESSINDEX

} from 'data/graphics';

import ValueGraph, { defaultValueGraphSettings } from 'components/graphs/value-graph';
import {RightOutlined} from "@ant-design/icons";
import {mainBackgroundColor, RESOLUTION_SCREEN} from "../../../../config";
import { sectorIndexesGraphics, stressIndexesGraphics } from '../../../../pages/dashboard/monitor/monitor.slice';
import TradingV from "../../../../pages/dashboard/monitor/tv";
import TrandingViewLight from '../../../../pages/dashboard/monitor/tv-light';

interface StressIndexesProps {
    onChange:(item: TabItem) => void;
    caseStr: string,
    onRefresh?: Function;
}

  const infoTabs = [
    { title: 'Crypto Stress Index', id: CRYPTO},
    { title: 'threshold',           id: THRESHOLD},
] as TabItem[];


const StressIndexesView = (props: StressIndexesProps) => {

  const panelId = STRESS_INDICES_PANEL_ID;
  const currentGraphicSetId = CRYPTO;

  const caseStr = props.caseStr || 'STRESSINDEX:week';
  const timeRange = caseStr.split(':')[1] || 'week';


  if (!stressIndexesGraphics.isInited()) {
    return <></>;
  }
  let renderData  = stressIndexesGraphics.getCurrentGraphic();
  // renderData = renderData.map(it => ({...it, STRESS_THRESHOLD: 0.0}));
  renderData = renderData.map(it => ({...it}));

  const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
  const [currentGraphicId, setCurrentGraphicId] = useState(getTabsGraphsInfo(panelId, currentGraphicSetId));

  if (!stressIndexesGraphics.isInited()) {
    return <></>;
  }

  const infoTabsOnChange = (item: TabItem) => {
  }

  const graphSelectPanelOnChange = (item: TabItem) => {
  };

  const timeRangeSelectPanelOnChange = (item: TabItem) => {
     props.onChange({id: STRESSINDEX, timeRange: item.id} as TabItem)
  };

  function getGraphicsInfo(panelId: string, currentGraphicsSet: string) {
    return undefined;
  }
  const currentTabId = item => item ===  'week' ? '1 week'
      :item === 'month1' ? '1 month'
      :item === 'month3' ? '3 months'
      :'1 week';

  const priceFormatter = (price) => {return (price * 10).toFixed(0)};
  const tooltipFormatter = (price) => {return (price * 10).toFixed(2)};
  const resolutionGraphHeight = RESOLUTION_SCREEN === 'horizontal' ? '300px' : '200px';

  // console.log('DATATATTATATATTAA',renderData)
  return <Stack style={{width: '100%',height:resolutionGraphHeight}} direction="column" alignItems="top">
    <WidgetInfoPanel
      id={panelId}
      title={'STRESS INDEX'}
      infoTabs={[]}
      graphics={graphics()}
      infoMarkers={[]}
      infoTabsOnChange={infoTabsOnChange}
    />
    <GraphSelectPanel
      graphicsOnChange={graphSelectPanelOnChange}
      timeRangeOnChange={timeRangeSelectPanelOnChange}
      infoMarkers={['1 week', '1 month', '3 months']}
      // infoTabs={graphics()}
      infoTabs={[]}
      width={'100%'}
      currentTabId={currentTabId(timeRange)}
    />
    <TrandingViewLight
        renderData={renderData}
        graphics={graphics()}
        timeRange={timeRange}
        priceFormatter={priceFormatter}
        tooltipForrmatter={tooltipFormatter}
    />
  </Stack>
};

export default StressIndexesView;
