import React, {useEffect} from "react";
import {Stack, Box, Grid, Button} from "@mui/material";
import {buttonBackgroundColor, mainTextColor, primaryTextColor} from "../../config";
import EquityCurveView from "../portfolios/portfolio-graphs/equity-curve";
import {EQUITY_CURVE_ID, EQUITY_CURVE_OPTIMIZATION_ID} from "../../data/graphics";
import KeyMetrics from "../portfolios/portfolio-graphs/key-metrics";
import AnnualReturns from "../portfolios/portfolio-graphs/eoy-returns";
import {floatRound} from "../../utils/finance-utils";
import useState from "react-usestateref";
import {dateSerializerNew} from "../../components/controls/form/nb-data-picker";
import {Portfolio} from "../../models/portfolio";
import {getClassName} from "pages/portfolios/helper";
import {Asset} from "../../models/asset";
import {getCurrentPortfolioTask} from "../portfolios/helper";
import {equityOverviewGraphics} from "../portfolios/portfolio.slice";
import {useLocation, useParams} from "react-router-dom";
import {strategyDescription} from "./researche-products.common";
import PortfolioComposition from "../portfolios/portfolio-composition";

interface MonitoringStrategyProps {
    // strategyName: string;
    portfolio: Portfolio;
    possibleBenchmark: Asset[];
    caseStr: string;
    renderData: any;
    keyMetricksData:any;
    annualReturns:any;
    extraCall?: Function;
    strategyResult:string;
    setCurrentTask: Function;
    actionMode: string;
}

const MonitoringStrategies = (props:MonitoringStrategyProps) =>{
    // const strategyName = props.strategyName;
    const portfolio = props.portfolio;
    const assetsValues = portfolio.assets;
    const dateStart = portfolio._dateStart;
    const dateEnd = portfolio._dateEnd;
    const inceptionDate = portfolio.inceptionDate;

    const renderData = props.renderData;
    const keyMetricksData = props.keyMetricksData;
    const caseStr = props.caseStr;
    const annualReturns = props.annualReturns;

    const tasks = portfolio.tasks;
    const latestTask = getCurrentPortfolioTask(portfolio);
    const errorTasksMessage = latestTask && latestTask.status === 'error' ? latestTask.errorMessage : null;
    const anyError = errorTasksMessage;
    const runningTasks = tasks.filter(task => task.status === 'started');
    const resultResponse = 'success';

    const runningSomething = runningTasks.length > 0;

    const defaultFrequency  = { name: 'No Rebalancing',   id: 'NoRebalancing'};
    const [frequency, setFrequency] = useState(defaultFrequency);

    const noBenchmarkAsset = {id: -1, name: 'No Benchmark', symbol: '*', value: 0};
    const possibleBenchmark = [noBenchmarkAsset,...props.possibleBenchmark];
    const portfolioBenchmark = portfolio.benchmark !== null ? possibleBenchmark.find((it) => {
        if(it.id === props.portfolio.benchmark.instrumentId) {
            return it
        }
    }) : undefined;
    const defaultBenchmark = portfolioBenchmark !== undefined ?  portfolioBenchmark : noBenchmarkAsset;
    const [selectBenchmark, setSelectBenchmark] = useState(defaultBenchmark);

    const [showGraphs, setShowGraphs] = useState(false);

    const equityHere = equityOverviewGraphics.getCurrentGraphic().length !== 0;

    const params = useParams();
    const location = useLocation();

    const discription = strategyDescription.find(item => item.id_name === portfolio.name);
    const legName = discription ? discription.name : portfolio.name;
    const currentBenchmark = portfolio.benchmark ? portfolioBenchmark : noBenchmarkAsset;

    const isNotEqualToZeroEQ = () => {
        if(latestTask?.status === 'completed' && equityOverviewGraphics.getCurrentGraphic().length !== 0 ) {
            return equityOverviewGraphics.getCurrentGraphic().filter((item) => item.value !== 0);
        }
        return [];
    };


    const getValue = (value: number, minValue= 0) =>  {
        const v = Number(value);
        return isNaN(v) ? 0 :  Math.max(v, minValue);
    };
    const totalPercentage = () => {
        const summa = assetsValues.map(it => Number(it.value)).reduce((a, b) => getValue(a) + getValue(b), 0);
        return floatRound(summa, 2);
    };

    const checkDate = (dateStart,dateEnd) => {
        if (dateStart !== undefined || null || null){
            const start = inceptionDate === null ? new Date(dateSerializerNew(dateStart)).getTime() : inceptionDate * 1000;
            return  start >= Date.parse(dateEnd);
        }
    };

    useEffect(() => {
        setTimeout(() => simulatePortfolio(),800);
    },[params.action,props.actionMode === 'strategy',params.name])

    const simulatePortfolio = () => {
        const msg = portfolio.name === 'SG-01' ? 'strategies-request' : 'simulation-request';
        // const msg = '\'strategies-request';
        const selectedFrequency = frequency.id === '#' || frequency.id === 'NoRebalancing' ? 'None' : frequency;
        if(totalPercentage() === 100  && !checkDate(inceptionDate,dateEnd)) {
            // @ts-ignore
            // props.extraCall({...portfolio,benchmark: selectBenchmark, frequency: selectedFrequency,msg:msg});
            props.extraCall({...portfolio,benchmark: currentBenchmark, frequency: selectedFrequency,msg:msg});
            setShowGraphs(true);
        } else {
            setShowGraphs(false);
        };
    };

    const reSelect = () => {
        const bench = possibleBenchmark.find(it =>  it.id === defaultBenchmark.id )
        if( bench !== undefined && 'base_symbol' in bench) {
            return getClassName(bench.class) === 'CRYPTO' ? bench.name + ' - ' + bench.base_symbol : bench.name
        }
    };

    const usStockLegend =  reSelect();


    return <>
        <Stack direction={'column'} width={'100%'}>
            <Box sx={{marginBottom:'20px'}}>
                <span className={'titlePage'}>
                    {discription?.name.toUpperCase()}
                </span>
            </Box>
            <Box sx={{color: primaryTextColor, marginBottom:'40px'}}>
                {discription?.description}
            </Box>
            {discription?.type === 'thematics' && <PortfolioComposition portfolio={portfolio} thematics={true}/>}

            {runningSomething && !anyError && ! errorTasksMessage
                ? <Stack justifyContent={'center'} alignItems={'center'} sx={{width: '100%',height: '100px', marginTop:'20px'}}>
                    <span className={'loader'}></span>
                    {/*<Box style={{color:mainTextColor, fontSize:processingFontResolution}}><br/>Processing. Please wait...</Box>*/}
                </Stack>
                : <></>}
            {errorTasksMessage || anyError ? <Stack justifyContent={'center'}
                                                    alignItems={isNotEqualToZeroEQ().length === 0  ? 'left' : 'center'}
                                                    sx={{width: '100%',height: '100%', marginTop:'20px'}}>
                    {errorTasksMessage?.includes('No data for instrument') ?
                        <Box sx={{color: mainTextColor}}> No trading days {errorTasksMessage?.slice(8)} within selected analysis period.</Box>
                        :isNotEqualToZeroEQ().length === 0  ?  <Box sx={{color: buttonBackgroundColor}}> * Portfolio metrics will appear after two trading days since inception date.</Box>
                            : <Box className={'errorMessage'} >
                                {/*ERROR! {errorTasksMessage} {errorMessage}.*/}
                                ERROR! {errorTasksMessage} .
                                <br/>
                                Please double-check Your values.
                            </Box>
                    }
                </Stack>
                :<></>
            }

            {showGraphs && !anyError && !runningSomething && resultResponse === 'success' && isNotEqualToZeroEQ().length !== 0 && <>
            <Grid container sx={{margin:'40px 0px 90px 0px'}}>
                <Grid item xs={12}>
                    <EquityCurveView
                        caseStr={caseStr}
                        renderData={renderData}
                        legendName={legName}
                        dateStart={dateStart as Date}
                        dateEnd={dateEnd as Date}
                        usStockLegend={usStockLegend}
                        panelId={EQUITY_CURVE_ID}
                        strategy={true}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <KeyMetrics
                        renderData={keyMetricksData}
                        legendName={legName}
                        usStockLegend={usStockLegend}
                        className={'keymetrics'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <AnnualReturns
                        renderData={annualReturns}
                        legendName={legName}
                        usStockLegend={usStockLegend}
                    />
                </Grid>
            </Grid>
            </>}
        </Stack>
    </>
};

export default MonitoringStrategies;
