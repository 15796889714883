import {
  AaveIcon,
  AlgorandIcon,
  ApeCoinIcon, AptosIcon,
  AvalancheIcon,
  AxieInfinityIcon, BalancerIcon, BarnBridgeIcon, BasicAttentionTokenIcon,
  BinanceIcon,
  BitcoinCashIcon,
  BitcoinIcon,
  BitcoinSVIcon,
  BitDAOIcon, BitTorrentNewIcon,
  BnbIcon,
  CardanoIcon,
  ChainlinkIcon,
  ChilizIcon, CompoundIcon,
  CosmosIcon,
  CronosIcon, CurveDAOTokenIcon,
  DaiIcon, DashIcon, DecentralandIcon,
  DogecoinIcon,
  DYdXIcon,
  EOSIcon,
  EthereumClassicIcon,
  EthIcon,
  FantomIcon,
  FilecoinIcon,
  FlowIcon, GalaIcon, GeminiDollarIcon, HarmonyIcon,
  HederaIcon, HeliumIcon,
  HuobiTokenIcon, InjectiveIcon,
  InternetComputerIcon,
  KuCoinTokenIcon, KyberNetworkCrystalvTwoIcon,
  LidoDAOIcon,
  LitecoinIcon, MakerIcon,
  MoneroIcon,
  MultiversXElrondIcon,
  NEARProtocolIcon,
  OKBIcon, OneinchNetworkIcon, PancakeSwapIcon,
  PaxDollarIcon,
  PolkadotIcon,
  PolygonIcon,
  QuantIcon, RadixIcon, RaydiumIcon, SerumIcon,
  ShibaIcon,
  SolanaIcon,
  StellarIcon, SushiSwapIcon, SynthetixIcon,
  TerraClassicIcon, TerraIcon,
  TetherIcon,
  TezosIcon, TheGraphIcon,
  TheSandboxIcon, ThetaFuelIcon,
  ThetaNetworkIcon, THORChainIcon,
  ToncoinIcon,
  TronIcon,
  TrueUSDIcon, TrustWalletTokenIcon, UltraIcon, UMAIcon,
  UniswapIcon,
  UnusSedLeoIcon,
  UsdcoinIcon,
  USDDIcon,
  VeChainIcon,
  WrappedBitcoinIcon,
  XBTIcon,
  XrpIcon,
  XxxIcon, YearnFinanceIcon,
  ZcashIcon,
  DOWJONES, NASDAQ, StacksIcon, RocetPoolIcon, OptimismIcon, ImmutableIcon, ArbitrumIcon, LidoStakedETHIcon
} from 'assets/icons';


import { NET_CURRENCY_NAMES } from './net-currency-names';

export const ASSET_LIST = [
  { symbol: 'BTC',     icon: <BitcoinIcon/> },
  { symbol: 'BCH',     icon: <BitcoinCashIcon/> },
  { symbol: 'APE',     icon: <ApeCoinIcon/> },
  { symbol: 'STX',     icon: <StacksIcon/> },
  { symbol: 'USDT',    icon: <TetherIcon/> },
  { symbol: 'RPL',    icon: <RocetPoolIcon/> },
  { symbol: 'OP',    icon: <OptimismIcon/> },
  { symbol: 'IMX',    icon: <ImmutableIcon/> },
  { symbol: 'TRX',    icon: <TronIcon/> },
  { symbol: 'ARB',    icon: <ArbitrumIcon/> },
  { symbol: 'TON',    icon: <ToncoinIcon/> },
  { symbol: 'ATOM',    icon: <CosmosIcon/> },
  { symbol: 'ALGO',    icon: <AlgorandIcon/> },
  { symbol: 'MATIC',   icon: <PolygonIcon/> },
  { symbol: 'LINK',    icon: <ChainlinkIcon/> },
  { symbol: 'ADA',    icon: <CardanoIcon/> },
  { symbol: 'CRO',    icon: <CronosIcon/> },
  { symbol: 'DOT',     icon: <PolkadotIcon/>},
  { symbol: 'LTC',     icon: <LitecoinIcon/>},
  { symbol: 'LDO',     icon: <LidoDAOIcon/>},
  { symbol: 'DOGE',    icon: <DogecoinIcon/>},
  { symbol: 'DYDX',    icon: <DYdXIcon/>},
  { symbol: 'NEAR',    icon: <NEARProtocolIcon/>},
  { symbol: 'SOL',     icon: <SolanaIcon/>},
  { symbol: 'SHIB',     icon: <ShibaIcon/>},
  // { symbol: 'NEAR',    icon: <XxxIcon/> },
  { symbol: 'AVAX',    icon: <AvalancheIcon/> },
  { symbol: 'QNT',    icon: <QuantIcon/> },
  { symbol: 'MANA',    icon: <XxxIcon/>},
  { symbol: 'VET',    icon: <VeChainIcon/>},
  { symbol: 'FIL',    icon: <FilecoinIcon/>},
  { symbol: 'ETH',     icon: <EthIcon/> },
  { symbol: 'ETC',     icon: <EthereumClassicIcon/> },
  { symbol: 'XTZ',     icon: <XxxIcon/>,},
  { symbol: 'BNB',     icon: <BnbIcon/>,},
  { symbol: 'OKB',     icon: <OKBIcon/>,},
  { symbol: 'XMR',     icon: <MoneroIcon/>,},
  { symbol: 'WBTC',     icon: <WrappedBitcoinIcon/>,},
  { symbol: 'USDC',     icon: <UsdcoinIcon/>,},
  { symbol: 'ICP',     icon: <InternetComputerIcon/>,},
  { symbol: 'UNI',     icon: <UniswapIcon/>,},
  { symbol: 'STETH',     icon: <LidoStakedETHIcon/>,},
  { symbol: 'LEO',     icon: <UnusSedLeoIcon/>,},
  { symbol: 'XBT',     icon: <XBTIcon/>,},
  { symbol: 'EOS',     icon: <EOSIcon/>,},
  { symbol: 'DAI',     icon: <DaiIcon/>},
  { symbol: 'HBAR',     icon: <HederaIcon/>},
  { symbol: 'USDP',     icon: <PaxDollarIcon/>},
  { symbol: 'HT',     icon: <HuobiTokenIcon/>},
  { symbol: 'UOS',     icon: <UltraIcon/>},
  { symbol: 'TUSD',     icon: <TrueUSDIcon/>},
  { symbol: 'SRM',     icon: <SerumIcon/>},
  { symbol: 'BSV',     icon: <BitcoinSVIcon/>},
  { symbol: 'BOND',     icon: <BarnBridgeIcon/>},
  { symbol: 'BIT',     icon: <BitDAOIcon/>},
  { symbol: 'APT',     icon: <AptosIcon/>},
  { symbol: 'DASH',     icon: <DashIcon/>},
  { symbol: 'MKR',     icon: <MakerIcon/>},
  { symbol: 'LUNC',     icon: <TerraClassicIcon/>},
  { symbol: 'EGLD',     icon: <MultiversXElrondIcon/>},
  { symbol: 'FLOW',     icon: <FlowIcon/>},
  { symbol: 'CHZ',     icon: <ChilizIcon/>},
  { symbol: 'KCS',     icon: <KuCoinTokenIcon/>},
  { symbol: 'SAND',     icon: <TheSandboxIcon/>},
  { symbol: 'FTM',     icon: <FantomIcon/>},
  { symbol: 'SUSHI',     icon: <SushiSwapIcon/>},
  { symbol: '1INCH',     icon: <OneinchNetworkIcon/>},
  { symbol: 'TFUEL',     icon: <ThetaFuelIcon/>},
  { symbol: 'YFI',     icon: <YearnFinanceIcon/>},
  { symbol: 'UMA',     icon: <UMAIcon/>},
  { symbol: 'XRD',     icon: <RadixIcon/>},
  { symbol: 'GALA',     icon: <GalaIcon/>},
  { symbol: 'RAY',     icon: <RaydiumIcon/>},
  { symbol: 'RUNE',     icon: <THORChainIcon/>},
  { symbol: 'SNX',     icon: <SynthetixIcon/>},
  { symbol: 'KNC',     icon: <KyberNetworkCrystalvTwoIcon/>},
  { symbol: 'LUNA',     icon: <TerraIcon/>},
  { symbol: 'ONE',     icon: <HarmonyIcon/>},
  { symbol: 'HNT',     icon: <HeliumIcon/>},
  { symbol: 'INJ',     icon: <InjectiveIcon/>},
  { symbol: 'TWT',     icon: <TrustWalletTokenIcon/>},
  { symbol: 'COMP',     icon: <CompoundIcon/>},
  { symbol: 'CRV',     icon: <CurveDAOTokenIcon/>},
  { symbol: 'BAL',     icon: <BalancerIcon/>},
  { symbol: 'BAT',     icon: <BasicAttentionTokenIcon/>},
  { symbol: 'GUSD',     icon: <GeminiDollarIcon/>},
  { symbol: 'MANA',     icon: <DecentralandIcon/>},
  { symbol: 'BTT',     icon: <BitTorrentNewIcon/>},
  { symbol: 'CAKE',     icon: <PancakeSwapIcon/>},
  { symbol: 'GRT',     icon: <TheGraphIcon/>},
  { symbol: 'ZEC',     icon: <ZcashIcon/>},
  { symbol: 'AXS',     icon: <AxieInfinityIcon/>},
  { symbol: 'THETA',     icon: <ThetaNetworkIcon/>},
  { symbol: 'USDD',     icon: <USDDIcon/>},
  { symbol: 'XTZ',     icon: <TezosIcon/>},
  { symbol: 'AAVE',     icon: <AaveIcon/>},
  { symbol: 'BUSD',    icon: <BinanceIcon/>},
  { symbol: 'XLM',    icon: <StellarIcon/>},
  { symbol: 'PAX',     icon: <XxxIcon/>},
  { symbol: 'XRP',     icon: <XrpIcon/>},
  { symbol: 'JOWJONES',     icon: <DOWJONES/>},
  { symbol: 'NASDAQ',     icon: <NASDAQ/>}
] as Array<any>;

export const ASSETS = {};

const prepareNetCurrencyTable = () => {
  ASSET_LIST.forEach(item => {
    item.name = NET_CURRENCY_NAMES[item.symbol] || '???';
    item.link = `https://coinmarketcap.com/currencies/${item.name}/`;
    // @ts-ignore
    ASSETS[item.symbol] = item;
  })
}

prepareNetCurrencyTable();
