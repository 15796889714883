import React from 'react';
import {Stack, Box} from '@mui/material';
import {buttonBackgroundColor, mainBackgroundColor, RESOLUTION_SCREEN} from "../../../config";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

interface ButtonsNavigationProps {
    leftSide:boolean;
    rightSide:boolean;
    titleBack1?: string;
    titleBack2?: string;
    onClickBack1?: () => void;
    onClickBack2?: () => void;
    countBackButtons?: number;
    titleGoTo1?: string;
    titleGoTo2?: string;
    onClickGoTo1?: () => void;
    onClickGoTo2?: () => void;
    countGoToButtons?: number;
};

const ButtonsNavigation = (props: ButtonsNavigationProps) => {

    const onClickGoTo1 = props.onClickGoTo1 ? props.onClickGoTo1 : () => <></>;
    const onClickGoTo2 = props.onClickGoTo2 ? props.onClickGoTo2 : () => <></>;

    const onClickBack1 = props.onClickBack1 ? props.onClickBack1 : () => <></>;
    const onClickBack2 = props.onClickBack2 ? props.onClickBack2 : () => <></>;

    return <Stack direction={'row'}  justifyContent={'space-between'} alignItems={'center'} width={'100%'} marginBottom={'20px'}>
        {props.leftSide ? props.countBackButtons === 1 ? <Box alignItems={'center'}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <button className={'advancedBtn'} onClick={onClickBack1} style={{ color: buttonBackgroundColor,
                    border: '1px solid' + buttonBackgroundColor, backgroundColor:mainBackgroundColor,
                }}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <LeftOutlined style={{fontSize: '8px', color: buttonBackgroundColor,marginRight:'4px'}}/>
                        {props.titleBack1}
                    </Stack>
                </button>
            </Stack>
        </Box>
            : <Stack direction={'row'} spacing={2}>
                <Box alignItems={'center'}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <button className={'advancedBtn'} onClick={onClickBack1} style={{color: buttonBackgroundColor,
                            border: '1px solid' + buttonBackgroundColor, backgroundColor:mainBackgroundColor
                        }}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <LeftOutlined style={{fontSize: '8px', color: buttonBackgroundColor,marginRight:'4px'}}/>
                                {props.titleBack1}
                            </Stack>
                        </button>
                    </Stack>
                </Box>
                <Box alignItems={'center'}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <button className={'advancedBtn'} onClick={onClickBack2} style={{color: buttonBackgroundColor,
                            border: '1px solid' + buttonBackgroundColor, backgroundColor:mainBackgroundColor,
                        }}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <LeftOutlined style={{fontSize: '8px', color: buttonBackgroundColor,marginRight:'4px'}}/>
                                {props.titleBack2}
                            </Stack>
                        </button>
                    </Stack>
                </Box>
            </Stack> : <></>
        }

        {props.rightSide ? props.countGoToButtons === 1 ?
            <Box>
                <Stack direction={'row'} spacing={2}>
                    <button className={'advancedBtn'}  onClick={onClickGoTo1}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            {props.titleGoTo1}
                            <RightOutlined style={{fontSize: '8px', color: mainBackgroundColor,marginLeft:'4px'}}/>
                        </Stack>
                    </button>
                </Stack>
            </Box>
            :  <Box>
                <Stack direction={'row'} spacing={2}>
                    <button className={'advancedBtn'} onClick={onClickGoTo1} >
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            {props.titleGoTo1}
                            <RightOutlined style={{fontSize: '8px', color: mainBackgroundColor,marginLeft:'4px'}}/>
                        </Stack></button>
                    <button className={'advancedBtn'} onClick={onClickGoTo2} >
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            {props.titleGoTo2}
                            <RightOutlined style={{fontSize: '8px', color: mainBackgroundColor,marginLeft:'4px'}}/>
                        </Stack>
                    </button>
                </Stack>
            </Box> : <></> }


    </Stack>

};

export default ButtonsNavigation;
