import React from "react";
import {Stack,Box} from '@mui/material';
import {
    mainTextColor,
    RESOLUTION_SCREEN
} from "../../../config";
import {MyNewAutocomplete} from "../../../components/controls/form/autocomplete-new";
import {Asset,} from "../../../models/asset";
import {AssetVolume} from "../../../models/portfolio";
import {initialEmptyAsset} from "../../../components/portfolios/portfolio-update/portfolio-update";
import AddIcon from "@mui/icons-material/Add";
import {styled} from "@mui/material/styles";
import {DeleteOutlined} from "@ant-design/icons";

interface OptimizationConstraintsProps {
    possibleAssets:Asset[];
    currentAssetVolume:AssetVolume;
    extraOptInstruments:ExtraOptInstruments[];
    removeAsset:Function;
    isItemSelectedForEdit:Function;
    selectAsset:Function;
    defaultValue:AssetVolume;
    updateMin:Function;
    updateMax:Function;
    currentAssetAddToAssets:Function;
    allocationError:boolean;
    defaultMinWeight:string;
    defaultMaxWeight:string;
    valuesRefs:any;
    doubleSliderChange:Function;
    doubleSliderValue:number[];

};

export interface ExtraOptInstruments {
    class:string;
    id:number;
    label:string;
    maxWeight:number;
    minWeight:number;
    name:string;
    records:number;
    symbol:string;
};

const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';
const resolutionWidthBox = RESOLUTION_SCREEN === 'horizontal' ? '140px' : '100px';
const resolutionBox = RESOLUTION_SCREEN === 'horizontal' ? '200px' : '10px';
const resolutionExtraBox = RESOLUTION_SCREEN === 'horizontal' ? '233px' : '0px';
const resolutionWidthAutocomp = RESOLUTION_SCREEN === 'horizontal' ? '488px' : '220px';
const stackSpace = RESOLUTION_SCREEN === 'horizontal' ? '61px' : '20px';
const stackDirection = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';
const resolutionIconSize = RESOLUTION_SCREEN === 'horizontal' ? '14px' : '8px';

const HeaderTable = styled(Box) ({
    textAlign:'left',
    paddingRight:'8px',
    paddingLeft:'8px',
    height: '28px',
    fontWeight: 500,
    fontSize: resolutionFontSize,
    color: mainTextColor,
    paddingTop:'4px',
});

const TDTable = styled(Box) ({
    paddingRight:'8px',
    paddingLeft:'8px',
    color: mainTextColor,
    fontWeight: 500,
    fontSize: resolutionFontSize,
    borderBottom:'1px solid' + mainTextColor,
    height: '28px',
    paddingTop:'4px',
});

const OptimizationConstraints = (props:OptimizationConstraintsProps) => {
    const possibleAssets = [initialEmptyAsset,...props.possibleAssets];
    const currentAssetVolume = props.currentAssetVolume;
    const valuesRefs = props.valuesRefs;
    const extraOptInstruments = props.extraOptInstruments;
    const removeAsset = props.removeAsset;
    const isItemSelectedForEdit = props.isItemSelectedForEdit;
    const selectAsset = props.selectAsset;
    const defaultValue = props.defaultValue;
    const updateMin = props.updateMin;
    const updateMax = props.updateMax;
    const currentAssetAddToAssets = props.currentAssetAddToAssets;
    const allocationError = props.allocationError;
    const defaultMinWeight = props.defaultMinWeight;
    const defaultMaxWeight = props.defaultMaxWeight;

    const SELECT_ASSET_REF_NAME = '_asset_ref_name';
    const ENTER_MIN_REF_NAME = '_min_ref_name';
    const ENTER_MAX_REF_NAME = '_max_ref_name';

    const doubleSliderChange = props.doubleSliderChange;
    const doubleSliderValue = props.doubleSliderValue;

    const getInputValue = (id: string, defaultValue:any = '') => valuesRefs.getInputValue(id, defaultValue);
    const currentMin = getInputValue(ENTER_MIN_REF_NAME, null)
    const currentMax = getInputValue(ENTER_MAX_REF_NAME, null)

    const countOfAssets = () => extraOptInstruments.length;


    function ExtraOptTable () {

        return <>
            {countOfAssets() > 0 && <Stack direction={'column'}  sx={{width:'100%', marginBottom:'20px'}}>
            <Stack direction={'row'} sx={{width:{xs:'100%',md:'40.5%',lg:'40.5%'}}}>
                <HeaderTable sx={{width:'85%'}}>Asset</HeaderTable>
                <HeaderTable style={{width:'15%'}}>&nbsp;</HeaderTable>
            </Stack>
            <Stack>
                {extraOptInstruments.map((elm,idx) => <React.Fragment key={'extra' + idx}>
                    <Stack direction={'row'} sx={{width:{xs:'100%',md:'40.5%',lg:'40.5%'}}}>
                        <TDTable sx={{width:'85%',textAlign:'left'}}>{elm.name}</TDTable>
                        <TDTable style={{width:'15%',textAlign:'right',paddingRight:'1px'}}>
                            {countOfAssets() > 0 &&
                                <span style={{paddingLeft: '2px', paddingRight: '1px'}} className={'clickable'} onClick={() => removeAsset(elm)}>
                                    <DeleteOutlined  style={{color:isItemSelectedForEdit(elm) ? 'orange' : 'white',  fontSize: resolutionIconSize}}/>
                                </span>
                            }
                        </TDTable>
                    </Stack>
                </React.Fragment>)}
            </Stack>
        </Stack>}
        </>
    };

  return   <Stack direction={'column'} spacing={2} width={'100%'} height={'100%'}>
      <Stack direction={stackDirection}  spacing={stackSpace} height={'100%'}>
          <Stack direction='row' alignItems='center' spacing={stackSpace}>
              <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                  Select and add Asset
              </Box>
              <Box sx={{paddingBottom: '14px'}}>
                  <MyNewAutocomplete
                      id={'SearchOptimization'}
                      onChange={selectedItem => selectAsset(selectedItem)}
                      inputRef={el => valuesRefs.initRef(SELECT_ASSET_REF_NAME, el)}
                      labelParam={'name'}
                      defaultValue={defaultValue}
                      placeholder={'please select...'}
                      options={possibleAssets}
                      width={resolutionWidthAutocomp}
                  />
              </Box>
          </Stack>
              {/*todo return or delete Slider weights for extraInstruments*/}
          {/*<Stack direction='row' alignItems='center' spacing={stackSpace}>*/}
              {/*<Stack direction={'row'}>*/}
              {/*    <DiscreteSliderMarks*/}
              {/*        value={doubleSliderValue}*/}
              {/*        onChange={doubleSliderChange}*/}
              {/*        doubleSlider={true}*/}
              {/*    />*/}
              {/*</Stack>*/}
              {/*<Stack direction={'row'} alignItems='center'>*/}
              {/*    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>*/}
              {/*        Minimum Weight*/}
              {/*    </Box>*/}
              {/*    <Box sx={{paddingBottom: '14px'}}>*/}
              {/*        <MyNumberField*/}
              {/*            onChange={updateMin}*/}
              {/*            onKeyDown={updateMin}*/}
              {/*            inputRef={el => valuesRefs.initRef(ENTER_MIN_REF_NAME, el)}*/}
              {/*            style={{width: '40px', alignItems:'center', textAlign:'center'}}*/}
              {/*            defaultValue={defaultMinWeight}*/}
              {/*        />*/}
              {/*    </Box>*/}
              {/*</Stack>*/}
              {/*<Stack direction={'row'} alignItems='center'>*/}
              {/*    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>*/}
              {/*        Maximum Weight*/}
              {/*    </Box>*/}
              {/*    <Box sx={{paddingBottom: '14px'}}>*/}
              {/*        <MyNumberField*/}
              {/*            onChange={updateMax}*/}
              {/*            onKeyDown={updateMax}*/}
              {/*            inputRef={el => valuesRefs.initRef(ENTER_MAX_REF_NAME, el)}*/}
              {/*            style={{width: '40px', alignItems:'center', textAlign:'center'}}*/}
              {/*            defaultValue={defaultMaxWeight}*/}
              {/*        />*/}
              {/*    </Box>*/}
              {/*</Stack>*/}
          {/*</Stack>*/}
          <Stack sx={{width:{xs:'12%',md:'4%',lg:'4%'}}}>
              <button className={'advancedBtn'} style={{ marginBottom: '6px'}} onClick={() => currentAssetAddToAssets()}>
                  <Stack direction={'row'} alignItems={'center'} >
                      <AddIcon style={{fontSize:resolutionIconSize,marginRight:'4px'}}></AddIcon>
                      add
                  </Stack>
              </button>
          </Stack>
      </Stack>
      {allocationError && currentMin > 100 && <Stack direction={'row'} marginTop={'-24px'}>
          <Box sx={{width:resolutionBox}}></Box>
          <Box className={'errorText'}>
          * Minimum  Weight amount should be greater than 0 and less than 100 </Box>
      </Stack>
      }
      {allocationError && currentMax > 100 && <Stack direction={'row'} marginTop={'-24px'}>
          <Box sx={{width:resolutionBox}}></Box>
          <Box className={'errorText'}>
          * Maximum Weight amount should be greater than 0 and less than 100 </Box>
          </Stack>
      }
      {allocationError && currentMax < currentMin &&  <Stack direction={'row'} marginTop={'-24px'}>
          <Box sx={{width:resolutionBox}}></Box>
          <Box className={'errorText'}>
          * Minimum  Weight amount should be  less than Maximum Weight </Box>
          </Stack>
      }
      {allocationError && defaultValue.symbol === '*'   &&
          <Stack direction={'row'} marginTop={'-24px'}>
          <Box sx={{width:resolutionBox}}></Box>
          <Box className={'errorText'}>
          * Please select Asset and Add it</Box>
          </Stack>
      }
      {allocationError  &&
          <Stack direction={'row'} marginTop={'-24px'}>
          <Box sx={{width:resolutionBox}}></Box>
          <Box className={'errorText'}>
          * Please add selected Asset </Box>
          </Stack>
      }
      <Stack direction='row' alignItems='center'  >
          <Box sx={{color: mainTextColor, width: resolutionExtraBox, fontWeight:500, fontSize:resolutionFontSize}}>
          </Box>
        <ExtraOptTable/>
      </Stack>
  </Stack>
};

export default OptimizationConstraints;