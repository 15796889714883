import React, {useRef, useState} from 'react';
import { Stack, Box } from '@mui/material';
import WidgetInfoPanel from 'components/controls/info-panel';
import {TabItem} from 'components/tab-component';
import GraphSelectPanel from 'components/controls/graph-selector';
import {
    CLIENT_PORTFOLIO_INITIAL,
    getTabsGraphsInfo,
    getDefaultGraphId,

} from "../../../data/graphics";
import {equityOverviewGraphics} from "../portfolio.slice";
import {RESOLUTION_SCREEN} from "../../../config";
import TrandingviewStraightLines from "./tv-straight-lines";
import DataQualityGraph from "../../data-quality/gap/data-quality-graph";


interface EquityCurveViewProps{
    renderData:any;
    caseStr:string;
    legendName:string;
    dateStart?: Date;
    dateEnd?: Date;
    usStockLegend:string|undefined;
    panelId:string;
    strategy?:boolean;
    dataQuality?:boolean;
}


const EquityCurveView = (props:EquityCurveViewProps) => {
    const caseStr = props.caseStr;
    const renderData = equityOverviewGraphics.getCurrentGraphic();
    const panelId = props.panelId;
    const legendName = props.legendName|| '';
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = CLIENT_PORTFOLIO_INITIAL;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    }

    const graphSelectPanelOnChange = (item: TabItem) => {
        setCurrentGraphicId(item.id!);
    };

    const timeRangeSelectPanelOnChange = (item: TabItem) => {
        // console.log("############ timeRangeSelectPanelOnChange TITLE::", item);
    };

    const priceFormatter = (price) => {
        return (price*100).toFixed(0) + '%';
    };
    const tooltipFormatter = (price) => {
        return (price * 100).toFixed(2) + '%';
    };

    const dQPriceFormatter = (price) => {
        return price.toFixed(2);
    };
    const dQTooltipFormatter = (price) => {
        return price.toFixed(2);
    };

    const currentPriceFormatter = props.dataQuality ? dQPriceFormatter : priceFormatter;
    const currentTooltipFormatter = props.dataQuality ? dQTooltipFormatter : tooltipFormatter;

    const resolutionGraphHeight = RESOLUTION_SCREEN === 'horizontal' ? '400px' : '200px';


    return<Stack style={{width: '100%', height: resolutionGraphHeight}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={props.strategy ? 'PERFORMANCE' : 'PORTFOLIO PERFORMANCE'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
        />
        <GraphSelectPanel
            graphicsOnChange={graphSelectPanelOnChange}
            timeRangeOnChange={timeRangeSelectPanelOnChange}
            infoMarkers={[]}
            infoTabs={[]}
            width={'100%'}
        />
        {props.dataQuality ?
            <DataQualityGraph
                renderData={renderData}
                // width={1410}
                // height={318}
                priceFormatter={dQPriceFormatter}
                tooltipForrmatter={dQTooltipFormatter}
                graphics={graphics()}
                timeRange={'year'}
                portfolioName={legendName}
                benchmark={props.usStockLegend}
            />
            :<TrandingviewStraightLines
            renderData={renderData}
            // width={1410}
            // height={318}
            priceFormatter={priceFormatter}
            tooltipForrmatter={tooltipFormatter}
            graphics={graphics()}
            timeRange={'year'}
            portfolioName={legendName}
            benchmark={props.usStockLegend}
        />}
    </Stack>
};

export default EquityCurveView;
