import React from 'react';
import {DesktopDatePicker} from "@mui/x-date-pickers";


export const dateSerializerNew = date_string => {
    const date = new Date(date_string);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day   = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join("-");
} ;

interface NbDatePickerProps {
    helperText: string;
    error: boolean;
    setError: Function;
    value: Date;
    onAccept: Function;
    className:string;
    createPortfolio?:boolean;
}
export const NbDatePicker = (props: NbDatePickerProps) => {
  const helperText = props.helperText;
  const className = props.className;
  const error = props.error;
  const setError = props.setError;
  const value = props.value;
  const onAccept = props.onAccept;
  const createPortfolio = props.createPortfolio;

  return <DesktopDatePicker
      className={className}
      slotProps={{
        textField: {
          variant: 'filled',
          size: 'medium',
          helperText: helperText,
          error: error,
          InputProps: {
            disableUnderline: true,
            readOnly: true,
            required:true
          }
        },
      }}
      views={[ 'year', 'month', 'day' ]}
      timezone={'system'}
      maxDate={createPortfolio ? new Date(Date.now()) : new Date(Date.now() - 86400000)}
      minDate={new Date("2020-01-01")}
      value={value}
      openTo={'year'}
      // autoFocus={false}
      // onAccept={newValue => onAccept(dateSerializerNew(newValue))}
      // open={true}
      // closeOnSelect={false}
      onChange={newValue => onAccept(dateSerializerNew(newValue))}
      // onAccept={newValue => onAccept(dateSerializerNew(newValue))}
      onError={(newError) => setError(newError)}
      showDaysOutsideCurrentMonth={true}
      format={"yyyy-MM-dd"}
    />;
}
