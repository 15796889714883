import { useEffect, useState } from 'react';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';




const getChartOptions = (labels: string[],series,colors) => {
    return {
        chart: {
            type: 'donut',
            animations: {
                enabled: false,
            },
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: '60%'
                }
            }
        },
        tooltip:{
            enabled: false,
        },

        dataLabels: {
            enabled: false,
        },
        legend: {
            show:false,
        },
        states: {
            hover: {
                filter: 'none'
            },
            active:{
                filter: 'none'
            }
        },

        colors: colors,

        stroke:{
            show:false,

        }
    }
}

const AssetDonutChart = (props) => {
    const {series, labels,colors} = props;
    const [options, setOptions] = useState<ChartProps>(getChartOptions(labels,series,colors));

    return (
        <ReactApexChart options={options} series={series} type="donut" />
    )
};

export default AssetDonutChart;
