import { useLocation, useNavigate, useParams } from "react-router-dom";

import DashboardMonitor from 'pages/dashboard/monitor/monitor';

export function routerInjector() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <DashboardMonitor router={{ location, navigate, params }}/>;
}
export default routerInjector;
