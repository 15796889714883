import { createSlice } from '@reduxjs/toolkit';
import {BasePageDefaultState, BasePageState} from "../../components/pages/pages-common";
import {COMPONENT_KEY_NAME, ActionModeType, ActionMode, strategyDescription} from "./researche-products.common";
import {getEmptyPortfolioTask, Portfolio, PortfolioTask} from "../../models/portfolio";
import {
    loadInstrumentsInfoApi,
    loadPortfoliosInfoApi,
    simulatePortfolioApi,
} from "./strategy.api";
import {PortfolioInputType,removePortfolioApi, updatePortfolioApi} from "../portfolios/portfolio.api";
import {expandPortfolios} from "../portfolios/portfolio.common";
import {Asset} from "../../models/asset";
import {
    getCurrentPortfolioTask,
    getIndexByPortfolioId,
    getPortfolioByIndex,
    getPortfolioTaskBySeqn
} from "../portfolios/helper";
import {$$} from "../../utils/utils";
import {randomString} from "../../utils/misc";
import {
    annualReturnsOverviewGraphics,
    annualReturnsSimulatioGraphics,
    equityOverviewGraphics,
    keyMetricsOverviewGraphics,

} from "../portfolios/portfolio.slice";
import {EMPTY_CASE_STR} from "../../models";
import {getCurrentState, isPendingAction, isRejectedAction, isSucceededAction} from "../../utils/refdux-utils";

interface State extends BasePageState {
    actionMode: ActionModeType;
    portfolios: Portfolio[];
    currentPortfolioIndex: number;
    currentTaskIndex: number;
    _portfoliosRawInput: PortfolioInputType[];
    assets: Asset[];
    _temporraryPortfolioTask:any;
    equityOverviewCurrentCaseStr: string;
    equityOverviewData: any[];
    keyMetricsOverviewCurrentCaseStr:string;
    keyMetricsOverviewData:any[];
    annualReturnsOverviewCurrentCaseStr:string;
    annualReturnsOverviewData:any[];
    errorMessage: string;
    strategyResult:string;
    openWaitlist:boolean;
    isWaitlistSubmited:boolean;
    currentDemouser:string;

}

const equityOverviewGraphicsHistory = {};

const initialState: State = {
    ...BasePageDefaultState,
    actionMode:ActionMode.researchProducts,
    portfolios: [],
    currentPortfolioIndex: -1,
    currentTaskIndex: 0,
    _portfoliosRawInput: [],
    assets: [],
    _temporraryPortfolioTask:null,
    equityOverviewCurrentCaseStr: EMPTY_CASE_STR,
    equityOverviewData:[],
    keyMetricsOverviewCurrentCaseStr:EMPTY_CASE_STR,
    keyMetricsOverviewData:[],
    annualReturnsOverviewCurrentCaseStr:EMPTY_CASE_STR,
    annualReturnsOverviewData:[],
    errorMessage: '',
    strategyResult:'',
    openWaitlist:false,
    isWaitlistSubmited:false,
    currentDemouser:'',
}

const getInitialState = () : State => {
    const state = initialState;
    return state;
}

const applyPortfolioInfo = (state: State, action) => {
    const this_right_portfolioInput = action.payload as PortfolioInputType[];
    console.log('^^^^^^^^^^^^^^applyPortfolioInfo^^^^^^^^^^^^^^',action.payload)

    const portfolioInput = this_right_portfolioInput;
    if (portfolioInput && portfolioInput.length > 0) {
        state.currentPortfolioIndex = -1;
    }

    if (! state.assets) {
        state._portfoliosRawInput = portfolioInput;
    }
    else {
        // state.portfolios = expandPortfolios(portfolioInput, state.assets);
        state.portfolios = expandPortfolios(portfolioInput, state.assets).sort((a,b) => {
            return b.updated - a.updated
        });

    }
    return state;
}
const applyInstrumentsInfo = (state: State, action) => {
    const payload = action.payload || {};

    // @ts-ignore
    state.assets = payload.instruments;

    console.log('INSTRUMENT::Info:',state.assets);
    // state.assets.sort((a,b) => {
    //     return  a.name.localeCompare(b.name)
    // }).sort((c,d) => {
    //     return c.class.localeCompare(d.class)
    // })

    if (state._portfoliosRawInput) {
        state.portfolios = expandPortfolios(state._portfoliosRawInput, state.assets);
        state._portfoliosRawInput = [];
    }
    return state;
}


const addSystemParameters = (portfolio: Portfolio, name, value) => {
    portfolio._system = portfolio._system || {};
    portfolio._system[name] = value;
    return portfolio;
}

const performSuccessRequest = (state, successPerformer?, action?) => {
    state['apiStatus'] = 'succeeded';
    return successPerformer ? successPerformer(state, action) : state;
}

const apiError = (state, action) => {
    state.apiStatus = 'failed';
    if (action.error && action.error.message && action.error.message.endsWith('401')) {
        // resetUser()
        state.apiStatus = '401';
    }
    return state;
}

const adjustPortfolioPossibleAssets = (state, portfolio?: Portfolio) => {
    if(portfolio) {
        const appliedAssetIds = portfolio.assets.map(it => it.id);
        state.assetsCurrent = state.assets.filter(it => !appliedAssetIds.includes(it.id));
    }
    return state;
}

const getCurrentPortfolio = (state: State): Portfolio => {
    const locationId = location.pathname.match(/\/([^\/]*)$/);
    const currentLocationId = locationId ? locationId[1] : '';
    const discriptionName = strategyDescription.find((item) => item.location_name === currentLocationId);
    const portfolioName = discriptionName ? discriptionName?.id_name : ' ';
    const portfolioId = state.portfolios.find(portfolio => portfolio.name === portfolioName)?.id;
    const index = getIndexByPortfolioId(state.portfolios, portfolioId);


    if(state.currentPortfolioIndex === -1) {
        // @ts-ignore
        return undefined;
    }
    if(state.currentPortfolioIndex !== index) {
        // @ts-ignore
        return undefined;
    }
    return getPortfolioByIndex(state.portfolios, state.currentPortfolioIndex);
}

const is_action_synchronises = payload => payload.result && payload.result === 'success';
const is_action_synchronises_error = payload => payload.result && payload.result === 'error';

const simulatePortfolioStartGo = (state: State, taskId, seqn, dateStart, dateEnd, benchmark,frequency) => {
    const task = {
        ...getEmptyPortfolioTask(),
        taskType: 'simulate',
        status: 'started',
        dateStart,
        dateEnd,
        taskId,
        benchmark,
        frequency,
        seqn,
    } as PortfolioTask;
    // getCurrentPortfolio(state).tasks.push(task);
    const currentPortfolio = getCurrentPortfolio(state);
    if(currentPortfolio !== undefined) {
        currentPortfolio.tasks.push(task);
    } else {
        state._temporraryPortfolioTask = task;
    }
    // console.log('############ simulatePortfolio::getCurrentState::', getCurrentState(state))
    return state;
};


const simulatePortfolio = (state: State, action) => {
    if (is_action_synchronises(action.payload)) {
        state.strategyResult = action.payload.result;
        return applySimulationResultSuccess(state, action.payload);
    }

    if (is_action_synchronises_error(action.payload)) {
        return applySimulationResultError(state, action.payload);
    }

    if(action.payload.status === 'ERROR') {
        return applySimulationResultError(state, action.payload);
    }
    else {
        const { taskId, seqn, dateStart, dateEnd, benchmark,frequency } = action.payload;
        return simulatePortfolioStartGo(state, taskId, seqn, dateStart, dateEnd, benchmark,frequency);
    }
}

const simulatePortfolioStart = (state: State, action) => {
    if (action.meta && action.meta.arg) {
        const {seqn, dateStart, dateEnd, benchmark,frequency} = action.meta.arg;
        const taskId = $$.uniqueId('taskId');
        return simulatePortfolioStartGo(state, taskId, seqn, dateStart, dateEnd, benchmark,frequency);
    }
    return state;
}

const applySimulationResultSuccess = (state: State, payload) => {
    const seqn = payload.seqn;
    const data = payload.data;
    const equityCurve = data.equityCurve;
    const keyMetrics = data.keyMetrics.filter((item,idx) => item.kind === 'OVERVIEW');
    const annualReturns = data.keyMetrics.filter((item,idx) => item.id === 'AYR');
    // console.log(actualRollingSharpeSimulated)

    const dateStart = payload['date-start'];
    const dateEnd   = payload['date-end'];
    const allocation = data.allocation;

    console.log('################# metrics::', data.keyMetrics);

    const task = getPortfolioTaskBySeqn(state.portfolios, seqn);
    if (task) {
        task.status = 'completed';
        task.dateStart = dateStart;
        task.dateEnd = dateEnd;
        equityOverviewGraphicsHistory[seqn] = equityCurve;
    }
    equityOverviewGraphics.setInitialCases([equityCurve]);
    state.equityOverviewCurrentCaseStr = `auto:${randomString()}`;

    keyMetricsOverviewGraphics.setInitialCases([keyMetrics]);
    state.keyMetricsOverviewCurrentCaseStr = `auto:${randomString()}`;

    annualReturnsOverviewGraphics.setInitialCases([annualReturns]);
    state.annualReturnsOverviewCurrentCaseStr = `auto:${randomString()}`;

    // console.log('############ applySimulationResult::getCurrentState::', getCurrentState(state))
    return state;
}

const applySimulationResultError = (state: State, payload) => {

    // console.log("############ ERROR PAYLOAD::", payload);

    const seqn = payload.seqn;
    const message = payload.message || payload['err-msg'];
    let task = getPortfolioTaskBySeqn(state.portfolios, seqn);
    if (task) {
        // console.log("############ ERROR PAYLOAD:: 0 TASK::", seqn, " MESS::", message);
        task.status = 'error';
        task.errorMessage = message;
    }
    else {
        // console.log("############ ERROR PAYLOAD:: 1 TASK::", seqn, " MESS::", message);
        task = {...getEmptyPortfolioTask(),
            seqn,
            taskType: 'simulate',
            status: 'error',
            errorMessage: message };
        const currentPortfolio = getCurrentPortfolio(state);
        if(currentPortfolio) {
            currentPortfolio.tasks.push(task);
        } else {
            state._temporraryPortfolioTask = task;
        }
        // getCurrentPortfolio(state).tasks.push(task);
    }
    return state;
}

export const dashboardMonitorSlice = createSlice({
    name: COMPONENT_KEY_NAME,
    initialState: getInitialState(),
    reducers: {
        setValue(state, action) {
            const { name, value } = action.payload;
            state[name] = value;
            return state;
        },

        refresh(state, action) {
            const data = action.payload;
            const mode = data.mode
            // console.log(action.payload)
            if([ActionMode.strategy,].includes(data.mode)) {
                console.log(data.name)
                const currentPortfolio = state.portfolios.find(portfolio => portfolio.name === data.name);
                const locationId = location.pathname.match(/\/([^\/]*)$/);
                // console.log('refreshLOcation',location)
                const currentLocationId = locationId ? locationId[1] : '';
                const discriptionName = strategyDescription.find((item) => item.location_name === currentLocationId);
                const portfolioName = discriptionName?.id_name;
                const portfolioId = state.portfolios.find(portfolio => portfolio.name === portfolioName);
                // console.log(portfolioId?.id)
                state.actionMode = data.mode;
                // state.currentPortfolioIndex = data.targetId === '' || data.targetId === undefined ? getIndexByPortfolioId(state.portfolios, portfolioId?.id) : getIndexByPortfolioId(state.portfolios, data.targetId);
                state.currentPortfolioIndex = data.name ? getIndexByPortfolioId(state.portfolios, currentPortfolio?.id)
                    :data.targetId === undefined ? getIndexByPortfolioId(state.portfolios, portfolioId?.id)
                    :getIndexByPortfolioId(state.portfolios, data.targetId);
                // console.log('refresh +state.currentPortfolioIndex  ',state.currentPortfolioIndex )
                adjustPortfolioPossibleAssets(state, getCurrentPortfolio(state));
            }

            return state;
        },
        applySimulationWsResult(state, action) {
            const {msg, data, seqn, result} = action.payload;
            if (! msg || ! data) {
                return state;
            }
            if (result === 'success') {
                return applySimulationResultSuccess(state, action.payload);
            }
            return state;
        },

        setCurrentTask(state, action) {
            const seqn = action.payload;
            getCurrentPortfolio(state)._currentTaskSeqn = seqn;
            // console.log('CURRENT seqn::', seqn)
            const task = getCurrentPortfolioTask(getCurrentPortfolio(state));

            if (task?.status === 'completed') {
                equityOverviewGraphics.setInitialCases([equityOverviewGraphicsHistory[seqn]]);
                state.equityOverviewCurrentCaseStr = `auto:${randomString()}`;
            }
            return state;
        },
        applyTemporraryPortfolioTask(state,action) {
            // console.log('WORK IN applyTemporraryPortfolioTask')
            if(state._temporraryPortfolioTask ) {
                const index = getIndexByPortfolioId(state.portfolios, action.payload);
                const currentPortfolio = getPortfolioByIndex(state.portfolios,index);
                if(currentPortfolio) {
                    currentPortfolio.tasks.push(state._temporraryPortfolioTask);
                }
                state._temporraryPortfolioTask = null;
                // console.log('############ simulatePortfolio::getCurrentState::', getCurrentState(state))
            }
            return state;
        },


        reportTaskError(state, action) {
            const {data, message} = action.payload;
            const next_task_status = data.next_task_status;
            const seqn = data.seqn;
            state.errorMessage = message;

            if (seqn) {
                const task = getPortfolioTaskBySeqn(state.portfolios, seqn);
                if (task) {
                    task.status = next_task_status || 'error';
                    task.errorMessage = message;
                }
            }
            // console.log('############ ERROR reportTaskError::getCurrentState::', getCurrentState(state))
            return state;
        },

        setDateStart(state,action){
            const portfolio = getCurrentPortfolio(state);
            portfolio._dateStart = action.payload;
            return state;
        },
        setDateEnd(state,action){
            const portfolio = getCurrentPortfolio(state);
            portfolio._dateEnd = action.payload;
            return state;
        },
        setOpenWaitlist(state,action){
            state.openWaitlist = action.payload;
            return state;
        },
        setSubmitedWaitlist(state,action) {
            state.isWaitlistSubmited = action.payload;
          return state;
        },
        getCurrentDemouser(state,action){
            state.currentDemouser = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => builder
        .addCase(loadPortfoliosInfoApi.fulfilled, (state, action) => performSuccessRequest(state, applyPortfolioInfo, action))
        .addCase(loadInstrumentsInfoApi.fulfilled,(state, action)   => performSuccessRequest(state, applyInstrumentsInfo, action))
        .addCase(simulatePortfolioApi.pending,    (state, action)   => performSuccessRequest(state, simulatePortfolioStart, action))
        .addCase(simulatePortfolioApi.fulfilled,  (state, action)   => performSuccessRequest(state, simulatePortfolio, action))
        .addMatcher(isPendingAction,        (state, action) => { state.apiStatus = 'pending'; return state; })
        .addMatcher(isRejectedAction,       (state, action) => apiError(state, action))
        .addMatcher(isSucceededAction,      (state, action) => { state.apiStatus = 'succeeded'; return state; })
});

export const setDateStart = dashboardMonitorSlice.actions.setDateStart;
export const setDateEnd = dashboardMonitorSlice.actions.setDateEnd;
export const reportTaskError = dashboardMonitorSlice.actions.reportTaskError;
export const applyTemporraryPortfolioTask = dashboardMonitorSlice.actions.applyTemporraryPortfolioTask;
export const refresh = dashboardMonitorSlice.actions.refresh;

export const dashboardMonitorActions = dashboardMonitorSlice.actions;
export default dashboardMonitorSlice.reducer;

