import React, {useEffect} from "react";
import {Stack, Box, Grid, Button} from "@mui/material";
import {AssetVolume, Portfolio} from "../../models/portfolio";
import ButtonsNavigation from "../../components/portfolios/portfolio-buttons/portfolio-navigation-buttons";
import {ActionMode} from "./portfolio.common";
import {reportTaskError} from "./portfolio.slice";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {getCurrentPortfolioTask} from "./helper";
import PortfolioComposition from "./portfolio-composition";
import {TabItem, TabsComponent} from "../../components/tab-component";
import useState from "react-usestateref";
import {Asset} from "../../models/asset";
import PortfolioViewOverview from "./portfolio-view-overview";
import PortfolioSimulation from "./portfolio-simulation";
import PortfolioOptimization from "./portfolio-optimization";



interface PortfolioAnalysisProps {
    portfolio: Portfolio;
    onRefresh: Function;
    holder: Record<string, any>;
    infoTabsOnChange?:Function;
    possibleBenchmark: Asset[];
    possibleAssets:Asset[];
    extraInstrumentsList:Asset[];
    currentAssetVolume: AssetVolume;
    onRemove: Function;
    extraCall?: Function;
    portfolioList:Portfolio[];
    caseStr: string;
    renderData: any;
    simulationAllocation:any;
    rollingSharpe:any;
    rollingSharpeCaseString:string;
    rollingSortino:any;
    rollingSortinoCaseString:string;
    keyMetricksData:any;
    performanceMetrics:any;
    rollingVolatility:any;
    currentControlDate: string;
    worstDrawDownData:any;
    dailyReturnsData:any;
    drawdownPeriodsData:any;
    distributionMonthlyCaseString:string;
    distributionMothlyData:any;
    monthlyReturns:any;
    underwaterPlot:any;
    annualReturns:any;
    setCurrentTask: Function;
    onChange:(item: TabItem) => void;
    actionMode:string;
    startTimeRequest:number;
};

const SectionTabItems = [
    { title:'Monitoring',    id:'Monitoring'},
    { title:'Simulation', id:'Simulation'},
    { title:'Optimizer',id:'Optimizer'},
] as TabItem[];

const PortfolioAnalysis = (props:PortfolioAnalysisProps ) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const startTimeRequest = props.startTimeRequest;
    const portfolio = props.portfolio;
    const portfolioSelected = portfolio.id !== '';
    const onRefresh = props.onRefresh;
    const tasks = portfolio.tasks;
    const latestTask = getCurrentPortfolioTask(portfolio);
    const currentControlDate = new Date(props.currentControlDate || new Date().toISOString());
    const renderData = props.renderData;
    const keyMetricksData = props.keyMetricksData;
    const performanceMetrics = props.performanceMetrics;
    const worstDrawDownData =props.worstDrawDownData;
    const dailyReturnsData = props.dailyReturnsData;
    const underwaterPlot = props.underwaterPlot;
    const annualReturns = props.annualReturns;
    const monthlyReturns = props.monthlyReturns;
    const rollingVolatility = props.rollingVolatility;
    const simulationAllocation = props.simulationAllocation;
    const drawdownPeriodsData = props.drawdownPeriodsData;
    const distributionMonthlyData = props.distributionMothlyData;
    const distributionMonthlyCaseString = props.distributionMonthlyCaseString;
    const rollingSharpe = props.rollingSharpe;
    const rollingSortino = props.rollingSortino;
    const rollingSharpeCaseString = props.rollingSharpeCaseString;
    const rollingSortinoCaseString = props.rollingSortinoCaseString;
    const caseStr = props.caseStr;
    const assetsValues = portfolio.assets;
    const completedTasks = tasks.filter((task,idx) => task.seqn === latestTask?.seqn && task.status === 'completed');
    const runningSomething = completedTasks.length === 0;
    // console.log('isRunning', completedTasks);
    // console.log('latestTask!!!!!!!!!!!', latestTask);
    // console.log('TAsts::::::::::::::::::', tasks);
    // console.log('RUNNNINGGGG', runningSomething);

    const [sectionTab, setSectionTab] = useState(SectionTabItems[0]);
    const isSectionIdMonitoring = sectionTab.id === 'Monitoring';

    const back = () => {
        onRefresh({mode: ActionMode.portfolioList});
        const data = latestTask && latestTask.status === 'error' ? {seqn: latestTask.seqn, next_task_status: 'forgotten'} : {};
        dispatch(reportTaskError({data, message: ''}));
        navigate('');
    };

    const changeSectionTab = (item: TabItem) => {
        if(!runningSomething) {
            setSectionTab(item);
        }
    };

    return <>
        {portfolioSelected &&  <Stack direction={'column'} width={'100%'}>
            <ButtonsNavigation
                rightSide={false}
                leftSide={true}
                onClickBack1={back}
                titleBack1={'Back to Workspace'}
                countBackButtons={1}
            />
            <Stack>
                <Box>
                    <span className={'titlePage'}>
                        {portfolio.name.toUpperCase()} {portfolio.currency !== null ? '('+ portfolio.currency +')' : '('+ ' ' +')'}
                    </span>
                </Box>
                <PortfolioComposition portfolio={portfolio}/>
            </Stack>
            <Stack direction='row' alignItems='center' sx={{width: '100%',height:'40px'}} marginTop={'20px'} marginBottom={'47px'}>
                <TabsComponent onChange={changeSectionTab} items={SectionTabItems} isRunning={runningSomething}/>
            </Stack>
            {sectionTab.id === 'Monitoring' &&
               <PortfolioViewOverview
                    possibleAssets={props.possibleAssets}
                    portfolio={portfolio}
                    onRefresh={onRefresh}
                    holder={props.holder}
                    actionMode={props.actionMode}
                    possibleBenchmark={props.possibleBenchmark}
                    currentAssetVolume={props.currentAssetVolume}
                    onRemove={props.onRemove}
                    extraCall={props.extraCall}
                    onChange={props.onChange}
                    caseStr={caseStr}
                    renderData={renderData}
                    keyMetricksData={keyMetricksData}
                    currentControlDate={props.currentControlDate}
                    performanceMetrics={performanceMetrics}
                    worstDrawDownData={worstDrawDownData}
                    dailyReturnsData={dailyReturnsData}
                    annualReturns={annualReturns}
                    underwaterPlot={underwaterPlot}
                    rollingVolatility ={rollingVolatility}
                    rollingSharpe={rollingSharpe}
                    rollingSharpeCaseString={rollingSharpeCaseString}
                    rollingSortino={rollingSortino}
                    rollingSortinoCaseString={rollingSortinoCaseString}
                    monthlyReturns={monthlyReturns}
                    distributionMonthlyCaseString={distributionMonthlyCaseString}
                    distributionMothlyData={distributionMonthlyData}
                    setCurrentTask={props.setCurrentTask}
                    drawdownPeriodsData={drawdownPeriodsData}
                    isSectionIdMonitoring={isSectionIdMonitoring}
                    startTimeRequest={startTimeRequest}
               />
            }
            {sectionTab.id === 'Simulation' &&
                <PortfolioSimulation
                    portfolio={portfolio}
                    currentAssetVolume={props.currentAssetVolume}
                    possibleAssets={props.possibleAssets}
                    possibleBenchmark={props.possibleBenchmark}
                    extraInstrumentsList={props.extraInstrumentsList}
                    onRefresh={onRefresh}
                    currentControlDate={props.currentControlDate}
                    extraCall={props.extraCall}
                    caseStr={caseStr}
                    renderData={renderData}
                    keyMetricksData={keyMetricksData}
                    simulationAllocation={simulationAllocation}
                    onChange={props.onChange}
                    annualReturnsSimulation={annualReturns}
                    dailyReturnsData={dailyReturnsData}
                    underwaterPlot={underwaterPlot}
                    rollingVolatility ={rollingVolatility}
                    rollingSharpe={rollingSharpe}
                    rollingSharpeSimulatedCaseStr={rollingSharpeCaseString}
                    rollingSortinoSimulated={rollingSortino}
                    rollingSortinoSimulatedCaseString={rollingSortinoCaseString}
                    worstDrawDownData={worstDrawDownData}
                    monthlyReturns={monthlyReturns}
                    performanceMetrics={performanceMetrics}
                    drawdownPeriodsData={drawdownPeriodsData}
                    distributionMonthlyCaseString={distributionMonthlyCaseString}
                    distributionMothlyData={distributionMonthlyData}
                    portfolioList={props.portfolioList}
                />
            }
            {sectionTab.id === 'Optimizer' &&
                <PortfolioOptimization
                    portfolio={portfolio}
                    onRefresh={onRefresh}
                    possibleAssets={props.possibleAssets}
                    possibleBenchmark={props.possibleBenchmark}
                    currentControlDate={props.currentControlDate}
                    onChange={props.onChange}
                    currentAssetVolume={props.currentAssetVolume}
                    extraCall={props.extraCall}
                    keyMetricksData={keyMetricksData}
                    annualReturnsOptimization={annualReturns}
                    drawdownPeriodsData={drawdownPeriodsData}
                    caseStr={caseStr}
                    renderData={renderData}
                    performanceMetrics={performanceMetrics}
                    optimizationAllocation={simulationAllocation}
                    dailyReturnsData={dailyReturnsData}
                    monthlyReturns={monthlyReturns}
                    worstDrawDownData={worstDrawDownData}
                    rollingVolatility ={rollingVolatility}
                    underwaterPlot={underwaterPlot}
                    rollingSharpeOptimizedData={rollingSharpe}
                    rollingSharpeOptimizedCaseStr={rollingSharpeCaseString}
                    rollingSortinoOptimizedCurrentCaseStr={rollingSortinoCaseString}
                    rollingSortinoOptimizedData={rollingSortino}
                    distributionMonthlyCaseString={distributionMonthlyCaseString}
                    distributionMothlyData={distributionMonthlyData}
                />
            }

        </Stack>}
    </>
};

export default PortfolioAnalysis;
