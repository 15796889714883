import React, { useState } from 'react';
import { Box, Stack,  Typography } from '@mui/material';

import {headerBackgroundColor, mainTextColor, primaryTextColor} from 'config';
import MiniWrapLayout from 'layout/MainLayout/mini-wrap-layout';
import { HMWY, TabItem, TabsComponent } from 'components/tab-component';

interface WidgetInfoPanelProps {
  id?: string,
  title: string;
  infoTabs?: TabItem[];
  infoMarkers: string[];   // @todo rename / update type after it would be more clear
  advancedButton?: JSX.Element;
  onRefresh?: Function;
  infoTabsOnChange?: Function;
  graphics?: TabItem[];
  currentTabId?: string;
}

// const defaultDevTabItems = [
//     { title: 'All ',          id: 'all'},
//     { title: 'Latest',        id: 'latest'},
//     { title: 'Crypto Only',   id: 'crypto'},
// ] as TabItem[];
//
// const defaultDevMarkers = ['X', 'Y', 'Z'];

const WidgetInfoPanel = (props: WidgetInfoPanelProps) => {
  const title = props.title;
  const advancedButton = props.advancedButton || <></>;
  const infoTabs = props.infoTabs || [];
  const infoMarkers = props.infoMarkers || [];
  const graphics = props.graphics || [];
  const currentTabId = props.currentTabId || '';
  const onChange = (item: TabItem) => props.infoTabsOnChange ? props.infoTabsOnChange(item) : undefined;

  const lineOne = <Stack sx={{width: '100%'}} direction="row" justifyContent="space-between" >
      <span className={'titleGraphs'}>{title}</span>
    { infoMarkers.length > 0 && <TabsComponent width={'40%'} variant={HMWY} onChange={onChange} items={infoMarkers} /> }
    { advancedButton }
    </Stack>;

  const lineTwo = <Stack direction='row' alignItems='center' gap={1} style={{width: '100%'}}>
    <TabsComponent onChange={onChange} items={infoTabs} currentTabId={currentTabId}/>
  </Stack>

  return <>{infoTabs.length === 0 ? <MiniWrapLayout lineOne={lineOne}/> : <MiniWrapLayout lineOne={lineOne} lineTwo={lineTwo}/> }</>
};

export default WidgetInfoPanel;
