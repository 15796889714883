import React, {useState, useRef} from 'react';
import { Stack } from '@mui/material';
import WidgetInfoPanel from 'components/controls/info-panel';
import {TabItem} from 'components/tab-component';
import GraphSelectPanel from 'components/controls/graph-selector';
import {
    ANNUAL_RETURNS_ID,
    CLIENT_PORTFOLIO_INITIAL,
    getDefaultGraphId,
     getTabsGraphsInfo,

} from "../../../data/graphics";
import TestGraph from "./testgraph";
import {annualReturnsOverviewGraphics} from "../portfolio.slice";
import {RESOLUTION_SCREEN} from "../../../config";

interface AnnualReturnsProps {
    renderData:any;
    legendName:string;
    usStockLegend:string|undefined;
};


const AnnualReturns = (props:AnnualReturnsProps) => {
    const renderData =  annualReturnsOverviewGraphics.getCurrentGraphic();

    const isStrategy = renderData.map(item => Object.keys(item).includes('strategy'))[0];

    const typeReques = isStrategy ? 'strategy' : 'clientPortfolio';
    // console.log('renderData',renderData)
    const dataClient = () => renderData.map((item) => item[typeReques].result.result)[0];
    // console.log('dataclient',dataClient())

    const panelId = ANNUAL_RETURNS_ID;
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = CLIENT_PORTFOLIO_INITIAL;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    }

    const graphSelectPanelOnChange = (item: TabItem) => {
        setCurrentGraphicId(item.id!);
    };

    const timeRangeSelectPanelOnChange = (item: TabItem) => {
        console.log("############ timeRangeSelectPanelOnChange TITLE::", item);
    };

    // const xAxisFormatter = dataClient.map((item) => item.date);
    const getxAxisFormatter = () => dataClient() !== undefined ? dataClient().map((item) => item.date) : [];

    const xAxis = [' ',...getxAxisFormatter(),' '];
    const widthOfBars = xAxis.length > 4 ? '80%' : '50%';

    const seriesApex = () => {
        const data = renderData.map ((item, idx) =>{
        const addClientValues = item[typeReques] ? item[typeReques].result.result.map((item) => item.value === undefined ? null : item.value) : [];
        const addMeanValues = item[typeReques] ? item[typeReques].mean.result.map((item) => item.value === undefined ? null : item.value) : [];
        const extraValueForMean = addMeanValues.find((el) => el);
        const addBenchmarkValues = item.benchmark?.result.result.map((item) => item.value === undefined ? null : item.value);
        if(item.benchmark === undefined) {
            return [
                {name: props.legendName,type:'bar',data: [null, ...addClientValues,null] },
                { name: '',type:'line',data: [extraValueForMean, ...addMeanValues,extraValueForMean]},
           ]
        } else {
            return [
                {name: props.legendName,type:'bar',data: [null, ...addClientValues,null]},
                {name: '',type:'line',data: [extraValueForMean, ...addMeanValues,extraValueForMean]},
                { name: props.usStockLegend, type:'bar', data: [null, ...addBenchmarkValues,null]},
            ]
        }
        } );
        return data
    };
    const data = seriesApex().map((item) => item);

    const stroke = {show:true,
        curve: 'straight',
        width:1.5,
        dashArray:[0,4,0]
    };


    const plotOptions = {
        bar:{
            horizontal: false,
            columnWidth: widthOfBars,
        }
    };
    const resolutionGraphWidth = RESOLUTION_SCREEN === 'horizontal' ? '100%' : '100%';
    const resolutionGraphHeight = RESOLUTION_SCREEN === 'horizontal' ? 276 : 200 ;

    return <Stack style={{width: '100%'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={'ANNUAL RETURNS'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
        />

        {data[0] !== undefined && <TestGraph
            series={data[0]}
            graphics={graphics()}
            width={resolutionGraphWidth}
            height={resolutionGraphHeight}
            categories={xAxis}
            stroke={stroke}
            plotOptions={plotOptions}
            type={'line'}
            zeroLine={0}
        />}
    </Stack>
};

export default AnnualReturns;
