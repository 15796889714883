import React from "react";
import {Stack} from "@mui/material";
import WidgetInfoPanel from "../../../components/controls/info-panel";
import {keyMetricsSimulationGraphics, worstDrawDownsGraphics} from "../portfolio.slice";
import {dateSerializerNew} from "../../../components/controls/form/nb-data-picker";




const tableTitle = ['Started', 'Recovered', 'Drawdown', 'Days'];

interface DrawDownsProps {
    renderData:any;
    currentResult:string;
    dateEnd: Date|string;
};


const asFloat = (value) => {
    try {
        return (value * 100).toFixed(2);
    }
    catch (e) {
        return value
    }
};

const DrawDowns = (props:DrawDownsProps) => {
    const renderData = worstDrawDownsGraphics.getCurrentGraphic();
    const currentResult = props.currentResult;
    const dateTo = new Date(props.dateEnd);
    const currentData = renderData.map((item) => item[`${currentResult}`] ? item[`${currentResult}`] : item.clientPortfolio);
    const daysOfDrawDown = currentData.map((item,idx) => {
        const daysDrawdown = item.map((elem,it) => {
           const dateStart = new Date(dateSerializerNew(elem.drawdown_start)).getTime();
           const dateRecovery = elem.recovery_date.includes('yet') ? new Date(dateSerializerNew(dateTo)).getTime() : new Date(dateSerializerNew(elem.recovery_date)).getTime();
            const oneDay = 24 * 60 * 60 * 1000;
           const days = Math.round(Math.abs((dateStart - dateRecovery)/ oneDay))
            return days;
        });
        return daysDrawdown;
    });


    return <Stack justifyContent={'center'} direction={'column'} spacing={'14px'} >
        <WidgetInfoPanel
            title={'WORST 10 DRAWDOWNS'}
            infoMarkers={[]}
        />
        <Stack sx={{height:'312px'}}>
            <table className={'worstDrawdown'}>
                    <thead>
                        <tr>
                            {tableTitle.map((it,idx) =>
                                <th key={idx+ 'ttitle'}>
                                    {it}
                                </th>
                            )}
                        </tr>
                    </thead>
                <tbody>
                {currentData.length !== 0 && currentData.map((item,idx) =><React.Fragment key={idx + 'worstMetrics'}>
                    {item.map((elem,it) =>
                        asFloat(elem.drawdown) !== '0.00' &&  <tr key={it +'lala' +idx}>
                            <td key={'tdtd'+idx}>
                                {dateSerializerNew(elem.drawdown_start)}
                            </td>
                            <td key={'tada' + idx}>
                                {elem.recovery_date.includes('yet') ? elem.recovery_date :dateSerializerNew(elem.recovery_date)}
                            </td>
                            <td key={'draw'+idx}>
                                {asFloat(elem.drawdown) + '%'}
                            </td>
                            <td key={'aaaday' + idx}>
                                {daysOfDrawDown[idx][it]}
                            </td>
                        </tr>
                    )}
                    </React.Fragment>
                )}
                </tbody>
            </table>
        </Stack>
    </Stack>
};

export default DrawDowns;