import { $$ } from './utils';

export const randomString = (prefix = '_'): string => prefix + Math.round(Math.random() * 1000000) + '';

export const typeIsString = (value: any): boolean => typeof value === 'string' || value instanceof String;

export const distinct = (arr: any[]): any[] => arr.filter((value, index) => arr.indexOf(value) === index);
export const difference = (base: any[], diff: any[]) => base.filter(x => !diff.includes(x));
export const commonValues = (base: any[], diff: any[]) => base.filter(x => diff.includes(x));
export const removeElement = (arr: any[], element: any) => arr.splice(arr.indexOf(element), 1);

export const xrange = (n: number) => [...Array(n).keys()];

export const sortBy = (items: any[], name: string, reverse = false, sorter?: any): any[] => {
  const path = name.split('\.');

  const byComplexName = (a, b): number => {
    const av = path.length > 1 ? $$.safeGet(a, [...path]): a[name];
    const bv = path.length > 1 ? $$.safeGet(b, [...path]): b[name];
    if (Number(av)) {
      return av === bv ? 0 : Number(av) > Number(bv) ? 1 : -1;
    }
    else {
      // console.log('String [' + av + '] name: [' + name + ']')
      return av === bv ? 0 : av > bv ? 1 : -1;
    }
  }

  const byFunction = (a, b): number => sorter(a) - sorter(b);

  const comparator = $$.isFunction(sorter) ? byFunction : byComplexName;
  const result = [...items].sort(comparator);
  return reverse ? result.reverse() : result;
}

export const  minValueBy = (obj: any, name: string) =>
  obj.reduce((a, b) => a[name] < b[name] ? a[name] : b[name]);


export const zip = (a, b) => a.map((k, i) => ({name: k, value: b[i]}));
export const zipAsDict = (a, b) => {
  const result = {}
  const size = Math.min(a.length, b.length);
  for (let i= 0; i < size; i+=1) {
      result[a[i]] = b[i];
  }
  return result
}

export const jsonUnPack = (paramsStr: string): Object => {
  if(paramsStr && paramsStr.length > '{"A":""}'.length && paramsStr.trim()[0]=== '{') {
    return JSON.parse(paramsStr);
  }
  return {};
}

const visitWebsite = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function getCurrentDimension(){
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
}
