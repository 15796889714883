import React from "react";
import {Stack} from "@mui/material";
import WidgetInfoPanel from "../../../components/controls/info-panel";
import { performanceMetricsOverviewGraphics} from "../portfolio.slice";


interface PerformanceMetricksProps {
    renderData:any;
    legendName:string;
    usStockLegend:string|undefined;
    simulation?:boolean;
    className:string;
    placeUse?:string;
};


const float = (value,nameTitle) => {
    try{
        if(nameTitle.includes('number of trading months')) {
            return value.toFixed(0)
        } else {
            return (value * 100).toFixed(2) + '%'
        }
    }
    catch (e) {
        return value
    }
};

const PerformanceMetrics = (props: PerformanceMetricksProps) => {
    const renderData = performanceMetricsOverviewGraphics.getCurrentGraphic();
    const placeUse = props.placeUse;
    const clearData = renderData.filter((item,idx) => {
        if(item.id !== 'PERF-AMR' && item.id !== 'PERF-SR' && item.id !== 'ROLSHARPE' && item.id !==  'JALPHA' && item.id !== 'ROLSORTINO'
        && item.id !=='DMR') {
            return item
        }
    });
    const legendName = props.legendName|| '';


    return <Stack justifyContent={'center'} direction={'column'} spacing={'14px'}>
        <WidgetInfoPanel
            title={'PERFORMANCE METRICS'}
            infoMarkers={[]}
        />
        {clearData.length !== 0 && <Stack>
            <table className={props.className}>
                <thead>
                <tr>
                    <th></th>
                    {props.simulation && <th>{placeUse}</th>}
                    <th>{legendName}</th>
                    {props.usStockLegend !== 'No Benchmark' && <th>{props.usStockLegend}</th>}
                </tr>
                </thead>
                <tbody>
                {clearData.map((elm, rowNumber, idx) => <React.Fragment key={rowNumber + 'performanceMetrics'}>
                        <tr key={rowNumber + 'row'}>
                            <td>{elm.name}</td>
                            {elm.simulated !== undefined && <td>{float(elm.simulated, elm.name)}</td>}
                            {elm.optimized !== undefined && <td>{float(elm.optimized, elm.name)}</td>}
                            <td>{float(elm.clientPortfolio, elm.name)}</td>
                            {elm.benchmark !== undefined && <td>{float(elm.benchmark, elm.name)}</td>}
                        </tr>
                    </React.Fragment>
                )}
                </tbody>
            </table>
        </Stack>}
    </Stack>
};

export default PerformanceMetrics;
