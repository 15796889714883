import { createSlice, current } from '@reduxjs/toolkit';

import { COMPONENT_KEY_NAME } from "./admin.common";
import { isPendingAction, isRejectedAction, isSucceededAction } from 'utils/refdux-utils';
import { BasePageDefaultState, BasePageState } from 'components/pages/pages-common';

import { getBuildInfoApi, getDataStatisticsApi, resetTasksStatLogApi, startSyncActionApi } from './admin.api';
import React from 'react';
import { $$ } from '../../utils/utils';

export type TabName = 'aaa' | 'bbb';

export interface TaskNameInfo {
  name: string;
  title: string;
}

interface State extends BasePageState {
  name: string;
  build_info: string;
  data_statistics: any[],
  tabs: Object;
  tasks: Object;
  feeds: Object;
  streams: Object;
  hosts: Object;
  apps: any[];
  appNames: any[];
  taskNames: TaskNameInfo[];
  sysUsage: Record<string, SysInfo>;
}

const initialState: State = {
  ...BasePageDefaultState,
  name: '',
  build_info: '',
  data_statistics: [],
  tabs: {},
  tasks: {},
  feeds: {},
  streams: {},
  hosts: {},
  apps: [],
  appNames: [],
  taskNames: [
    {title: 'Correlation',          name:  'update_correlation'},
    {title: 'World Index',          name:  'world_index'},
    {title: 'world_index_crypto',   name:  'world_index_crypto'},

    {title: 'Sector Index Cross',   name:  'sector_index_cross'},
    {title: 'Sector Index Crypto',  name:  'sector_index_crypro'},

    {title: 'Stress_index',         name:  'stress_index'},

    {title: 'sector_index_crypro',   name:  'sector_index_crypro'},
    {title: 'world_index_indexes',   name:  'world_index_indexes'},

    {title: 'Polygon index recollect',name:'polygon_index_gap_fix'},
    {title: 'Twelve index recollect', name:'twelve_index_gap_fix'},
    {title: 'Twelve for graph',       name:'twelve_for_graph_task'},

    {title: 'Orderbook Snapshot',    name:  'orderbook_snapshot'},
    {title: 'Indicator Calculation', name:  'indicator_calculation'},

    {title: 'MarketCap Snapshot',   name:  'market_cap_snapshot'},
    ],
  sysUsage: {}
}

export interface CpuLoad {
  cpu_load: number;
  cpu_count: number;
  load1: number;
  load5: number;
  load15: number;
}

export interface diskUsage {
  total: number;
  used: number;
  free: number;
  percent: number;
}

export interface MemoryInfo {
  total: number;
  available: number;
  percent: number;
  used: number;
  // free: number;
  // active: number;
  // inactive: number;
  // wired: number;
}

export interface pythonInfo {
  memory: ProcessMemoryInfo,
  version: string
}

export interface ProcessMemoryInfo {
  rss: number;
  vms: number;
}

export interface ProcessInfo {
  pid: number,
  started: string
  running: string
}

export interface PlatformInfo {
  os: string;
  release: string
  version: string;
}

export interface SysInfo {
  service_name: string;
  hostname: string;
  docker: boolean;
  platform: PlatformInfo;
  cpu: CpuLoad;
  memory: MemoryInfo;
  python: pythonInfo;
  disk: diskUsage;
  datemark: number;
  process: ProcessInfo;
}

export interface TaskInterface {
    status?: 'failed' | 'completed' | undefined;
    name: string;
    start: string;
    stop: string;
    kind: string;
    duration: string;
    exception?: string;
}

const performSuccessRequest = (state, successPerformer?, action?) => {
  state['apiStatus'] = 'succeeded';
  return successPerformer ? successPerformer(state, action) : state;
}

const apiError = (state, action) => {
  state.apiStatus = 'failed';
  if (action.error && action.error.message && action.error.message.endsWith('401')) {
    state.apiStatus = '401';
  }
  return state;
}


const applyGetInfo = (state: State, action) => {
  state.build_info = action.payload;
  state.tabs['BuildInfo'] = true;
  return state;
}


const applyGetDataStatisticsInfo = (state: State, action) => {
  // data will be delivered via WS
  // state.tabs['DataStatistics'] = true;
  return state;
}

const applyResetTasksStatLog = (state: State, action) => {
  return state;
}

const startStartSyncAction = (state: State, action) => {
  console.log('############# startStartSyncAction ACTION::', action)
  state.apiStatus = 'pending';
  return state;
}

const applyStartSyncAction = (state: State, action) => {
  console.log('############# applyStartSyncAction ACTION::', action)
  return state;
}

export const adminSlice = createSlice({
  name: COMPONENT_KEY_NAME,
  initialState,
  reducers: {
    setName(state, action) { state.name = action.payload },
    toggleTab(state, action) {
      const tabName = action.payload;
      const value = state.tabs[tabName];
      state.tabs[tabName] = !value;
      return state;
    },

    updateInstantValues(state, action) {
      const { status,  apps, monitor, feed, stream, data } = action.payload;

      // console.log('###################### ACTION.PAYLOAD:::::', action.payload)

      if (status) {
        if (status === 'system_usage') {
          const hostname = data.hostname;
          state.sysUsage[hostname] = data;
        } else if (status !== 'heartbeat') {
          state.tasks[status] = data;
        }
        // console.log('###################### ', current(state.tasks))
      }
      else if (apps) {
        // console.log('###################### APPS::', action.payload.apps)
        state.appNames = action.payload.apps.map(it => it.feed.split(':')[2])
        state.apps = action.payload.apps;
      }
      else if (feed) {
        state.feeds[feed] = action.payload;
      }
      else if (monitor) {
        const hostName = data.host || 'unknown';
        state.hosts[hostName] = data;
        // console.log(':: :: ############ ############ hostName:: [' + hostName + ']', data);
      }
      else if (stream) {
        const pp = stream.split(':');
        const name = pp[2];
        const queue = '' + pp[0] + ':' + pp[1];
        state.streams[name] = state.streams[name] ? state.streams[name] : {};
        state.streams[name][queue] = data;
        state.streams[name]['datemark'] =  new Date().getTime() / 1000;
        // console.log('######################>>> streams::', current(state.streams))
      }
      // Data Examples:
      // {"status": "failed", "data": []}
      // {"feed": "feed:binance:futures:markprice", "datemark": 1709370650.984002, "data": "USDCUSDT"}
      // {"status": "system_usage", "data": {"service_name": "services", "hostname": "Sergiis-MacBook-Pro.local", "docker": false, "platform": {"os": "Darwin", "release": "23.2.0", "version": "Darwin Kernel Version 23.2.0: Wed Nov 15 21:54:10 PST 2023; root:xnu-10002.61.3~2/RELEASE_X86_64"}, "process": {"pid": 79653, "started": "2024-03-02 10:06:41", "running": "00:04:22"}, "cpu": {"cpu_load": 10.1, "load1": 5.57, "load5": 3.52, "load15": 2.82, "cpu_count": 16}, "memory": {"total": 65536.0, "available": 41387.75, "percent": 36.8, "used": 19815.73}, "python": {"memory": {"rss": 142.34, "vms": 35606.96}, "version": "3.12.0 (v3.12.0:0fb18b02c8, Oct  2 2023, 09:45:56) [Clang 13.0.0 (clang-1300.0.29.30)]"}, "disk": {"total": 953904.12, "used": 428139.97, "free": 525764.15, "percent": 44.88}}}
      // {'stream': 'queue:input:OkxFuturesOrderbook', 'datemark': 1709587468.14642, 'data': {'in': 284748, 'out': 284748, 'qsize': 0, 'maxsize': 'infinity'}}

      return state;
    }
  },
  extraReducers: (builder) => builder
  .addCase(getBuildInfoApi.fulfilled, (state, action) => performSuccessRequest(state, applyGetInfo, action))
  .addCase(getDataStatisticsApi.fulfilled, (state, action) => performSuccessRequest(state, applyGetDataStatisticsInfo, action))
  .addCase(resetTasksStatLogApi.fulfilled, (state, action) => performSuccessRequest(state, applyResetTasksStatLog, action))
  .addCase(startSyncActionApi.fulfilled, (state, action) => performSuccessRequest(state, applyStartSyncAction, action))
  .addCase(startSyncActionApi.pending, (state, action) => performSuccessRequest(state, startStartSyncAction, action))
  .addMatcher(isPendingAction, (state, action) => {
    state.apiStatus = 'pending';
    return state;
  })
  .addMatcher(isRejectedAction, (state, action) => apiError(state, action))
  .addMatcher(isSucceededAction, (state, action) => {
    state.apiStatus = 'succeeded';
    return state;
  })
});

export const incrementAsync = name => dispatch => {
  setTimeout(() => {
    dispatch(adminSlice.actions.setName(name))
  }, 1000)
}


export const adminDefaultActions = adminSlice.actions;

export default adminSlice.reducer;
