export const NET_CURRENCY_SIGNS = {
  'Bitcoin': 'BTC',
  'Ethereum': 'ETH',
  'Tether': 'USDT',
  'BNB': 'BNB',
  'USD Coin': 'USDC',
  'XRP': 'XRP',
  'Binance USD': 'BUSD',
  'Cardano': 'ADA',
  'Polygon': 'MATIC',
  'Dogecoin': 'DOGE',
  'Solana': 'SOL',
  'Polkadot': 'DOT',
  'Litecoin': 'LTC',
  'Shiba Inu': 'SHIB',
  'TRON': 'TRX',
  'Avalanche': 'AVAX',
  'Dai': 'DAI',
  'Uniswap': 'UNI',
  'Wrapped Bitcoin': 'WBTC',
  'Cosmos': 'ATOM',
  'Chainlink': 'LINK',
  'UNUS SED LEO': 'LEO',
  'Ethereum Classic': 'ETC',
  'OKB': 'OKB',
  'Monero': 'XMR',
  'Toncoin': 'TON',
  'Bitcoin Cash': 'BCH',
  'Aptos': 'APT',
  'Lido DAO': 'LDO',
  'Stellar': 'XLM',
  'Hedera': 'HBAR',
  'Filecoin': 'FIL',
  'NEAR Protocol': 'NEAR',
  'ApeCoin': 'APE',
  'Cronos': 'CRO',
  'Algorand': 'ALGO',
  'VeChain': 'VET',
  'Internet Computer': 'ICP',
  'Quant': 'QNT',
  'Fantom': 'FTM',
  'The Graph': 'GRT',
  'Decentraland': 'MANA',
  'BitDAO': 'BIT',
  'Aave': 'AAVE',
  'MultiversX': 'EGLD',
  'Flow': 'FLOW',
  'Axie Infinity': 'AXS',
  'Theta Network': 'THETA',
  'EOS': 'EOS',
  'The Sandbox': 'SAND',
  'Tezos': 'XTZ',
  'Terra Classic': 'LUNC',
  'TrueUSD': 'TUSD',
  'ImmutableX': 'IMX',
  'Chiliz': 'CHZ',
  'Rocket Pool': 'RPL',
  'Mina': 'MINA',
  'Pax Dollar': 'USDP',
  'Huobi Token': 'HT',
  'KuCoin Token': 'KCS',
  'Curve DAO Token': 'CRV',
  'Dash': 'DASH',
  'Bitcoin SV': 'BSV',
  'Frax Share': 'FXS',
  'Zcash': 'ZEC',
  'USDD': 'USDD',
  'Maker': 'MKR',
  'BitTorrent-New': 'BTT',
  'eCash': 'XEC',
  'GMX': 'GMX',
  'PancakeSwap': 'CAKE',
  'IOTA': 'MIOTA',
  'Synthetix': 'SNX',
  'Klaytn': 'KLAY',
  'Optimism': 'OP',
  'Neo': 'NEO',
  'Loopring': 'LRC',
  'Trust Wallet Token': 'TWT',
  'Gemini Dollar': 'GUSD',
  'THORChain': 'RUNE',
  'SingularityNET': 'AGIX',
  'Osmosis': 'OSMO',
  'PAX Gold': 'PAXG',
  'Zilliqa': 'ZIL',
  'GateToken': 'GT',
  'Convex Finance': 'CVX',
  'BinaryX': 'BNX',
  'Render Token': 'RNDR',
  'Enjin Coin': 'ENJ',
  'dYdX': 'DYDX',
  'MAGIC': 'MAGIC',
  'Basic Attention Token': 'BAT',
  'Oasis Network': 'ROSE',
  'Stacks': 'STX',
  '1inch Network': '1INCH',
  'Terra': 'LUNA',
  'Nexo': 'NEXO',
  'Fei USD': 'FEI',
  'EthereumPoW': 'ETHW',
  'Casper': 'CSPR',
  'Arweave': 'AR',
  'Celo': 'CELO',
  'Kava': 'KAVA',
  'Holo': 'HOT',
  'WOO Network': 'WOO',
  'Astar': 'ASTR',
  'XDC Network': 'XDC',
  'Ravencoin': 'RVN',
  'Compound': 'COMP',
  'Onyxcoin': 'XCN',
  'Helium': 'HNT',
  'Fetch.ai': 'FET',
  'NEM': 'XEM',
  'Decred': 'DCR',
  'Theta Fuel': 'TFUEL',
  'ssv.network': 'SSV',
  'Threshold': 'T',
  'Harmony': 'ONE',
  'Balancer': 'BAL',
  'Bone ShibaSwap': 'BONE',
  'Gala': 'GALA',
  'Kusama': 'KSM',
  'JasmyCoin': 'JASMY',
  'Blur': 'BLUR',
  'Audius': 'AUDIO',
  'SushiSwap': 'SUSHI',
  'Qtum': 'QTUM',
  'Ethereum Name Service': 'ENS',
  'Ankr': 'ANKR',
  'Bitcoin Gold': 'BTG',
  'Ocean Protocol': 'OCEAN',
  'Gnosis': 'GNO',
  'TerraClassicUSD': 'USTC',
  'IoTeX': 'IOTX',
  'Chia': 'XCH',
  'Waves': 'WAVES',
  'Mask Network': 'MASK',
  'Injective': 'INJ',
  'STEPN': 'GMT',
  'yearn.finance': 'YFI',
  'Moonbeam': 'GLMR',
  'Conflux': 'CFX',
  'Livepeer': 'LPT',
  'Golem': 'GLM',
  'JUST': 'JST',
  'Kadena': 'KDA',
  'Band Protocol': 'BAND',
  'Symbol': 'XYM',
  'SKALE': 'SKL',
  'SwissBorg': 'CHSB',
  'Flux': 'FLUX',
  'Dogelon Mars': 'ELON',
  'Siacoin': 'SC',
  'OMG Network': 'OMG',
  'ICON': 'ICX',
  'Synapse': 'SYN',
  'Hive': 'HIVE',
  '0x': 'ZRX',
  'Ontology': 'ONT',
  'Reserve Rights': 'RSR',
  'IOST': 'IOST',
  'Amp': 'AMP',
  'SafePal': 'SFP',
  'Stargate Finance': 'STG',
  'Horizen': 'ZEN',
  'DAO Maker': 'DAO',
  'BORA': 'BORA',
  'Solar': 'SXP',
  'Biconomy': 'BICO',
  'Illuvium': 'ILV',
  'WAX': 'WAXP',
  'MetisDAO': 'METIS',
  'Polymath': 'POLY',
  'Nervos Network': 'CKB',
  'Storj': 'STORJ',
  'DigiByte': 'DGB',
  'Keep Network': 'KEEP',
  'iExec RLC': 'RLC',
  'Everscale': 'EVER',
  'OriginTrail': 'TRAC',
  'Celer Network': 'CELR',
  'Syscoin': 'SYS',
  'Voyager Token': 'VGX',
  'ConstitutionDAO': 'PEOPLE',
  'Lisk': 'LSK',
  'UMA': 'UMA',
  'MobileCoin': 'MOB',
  'Hooked Protocol': 'HOOK',
  'Kyber Network Crystal v2': 'KNC',
  'Braintrust': 'BTRST',
  'PlayDapp': 'PLA',
  'APENFT': 'NFT',
  'Chromia': 'CHR',
  'MediBloc': 'MED',
  'aelf': 'ELF',
  'Hashflow': 'HFT',
  'MX TOKEN': 'MX',
  'Neutrino Index': 'XTN',
  'Secret': 'SCRT',
  'Smooth Love Potion': 'SLP'
};


export const NET_CURRENCY_NAMES = Object.fromEntries(Object.entries(NET_CURRENCY_SIGNS).map(a => a.reverse()));
