import React from "react";
import {Stack, Box, Grid,Button} from "@mui/material";
import {
    mainTextColor,
     RESOLUTION_SCREEN,
} from "../../config";
import {MainOrangeButton, TabItem, TabsComponent} from "../../components/tab-component";
import {MyNewAutocomplete} from "../../components/controls/form/autocomplete-new";
import {AssetVolume, AssetVolumeEmptyItem, Portfolio, PortfolioEmptyItem} from "../../models/portfolio";
import useState from "react-usestateref";
import {RefHoster} from "../../components/controls/form/ref-hoster";
import {Asset} from "../../models/asset";
import PortfolioComposition from "./portfolio-composition";
import {InfoCircleOutlined} from "@ant-design/icons";
import DiscreteSliderMarks from "../../components/controls/form/discrete-slider-marks";
import DateRangeForm from "../../components/controls/form/date-range";
import EquityCurveView from "./portfolio-graphs/equity-curve";
import KeyMetrics from "./portfolio-graphs/key-metrics";
import {ActionMode, getRouteSuffix} from "./portfolio.common";
import ButtonsNavigation from "../../components/portfolios/portfolio-buttons/portfolio-navigation-buttons";
import '../../components/portfolios/porfolio.css';
import {getCurrentPortfolioTask} from "./helper";
import OptimizedSimulatedAllocation from "./optimized-simulated-allocation";
import AnnualReturnsSimulation from "./portfolio-graphs/annual-returns-simulation";
import DailyReturnsPortfioio from "./portfolio-graphs/daily-returns-portfioio";
import UnderwaterPlot from "./portfolio-graphs/underwater-plot";
import WorstDrawdowns from "./portfolio-graphs/worst-drawdowns";
import RollingVolatility from "./portfolio-graphs/rolling-volatility";
import RollingBetaToBenchmark from "./portfolio-graphs/rolling-beta-benchmark";
import RollingSharpe from "./portfolio-graphs/rolling-sharpe";
import RollingSortino from "./portfolio-graphs/rolling-sortino";
import DrawdownPeriods from "./portfolio-graphs/drawdown-periods";
import MonthlyReturnsPortfolio from "./portfolio-graphs/monthly-returns-portfolio";
import ValueRiskExpectedShortfall from "./portfolio-graphs/value-risk-expected-shortfall";
import DistributionMonthlyReturn from "./portfolio-graphs/distribution-monthly-return";
import ReturnQuantiles from "./portfolio-graphs/return-quantiles";
import PerformanceMetrics from "./portfolio-graphs/performance-metrics";
import {
    ANNUAL_RETURNS_SIMULATION_ID,
    EQUITY_CURVE_SIMULATION_ID,
    ROLLING_VOLATILITY_SIMULATION_ID
} from "../../data/graphics";
import {initialEmptyAsset} from "../../components/portfolios/portfolio-update/portfolio-update";
import {processingFontResolution} from "./portfolio-view-overview";
import {rollingSharpeSimulatedGraphics, rollingSortinoSimulatedGraphics} from "./portfolio.slice";
import {useNavigate} from "react-router-dom";
import {getClassName} from "pages/portfolios/helper";
import RadioSelection from "../../components/controls/form/radio-selection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {dateSerializerNew} from "../../components/controls/form/nb-data-picker";
import { DateValidationError } from '@mui/x-date-pickers/models';

interface PorfolioSimulationProps {
    currentAssetVolume: AssetVolume,
    possibleAssets: Asset[],
    possibleBenchmark: Asset[];
    extraInstrumentsList:Asset[];
    portfolio: Portfolio;
    infoTabsOnChange?:Function;
    onRefresh: Function;
    currentControlDate: string;
    extraCall?: Function;
    caseStr:string;
    renderData:any;
    keyMetricksData:any;
    rollingSharpe:any;
    rollingSharpeSimulatedCaseStr:string;
    rollingSortinoSimulated:any;
    rollingSortinoSimulatedCaseString:string;
    onChange:(item: TabItem) => void;
    annualReturnsSimulation:any;
    simulationAllocation:any;
    dailyReturnsData:any;
    rollingVolatility:any;
    underwaterPlot:any;
    worstDrawDownData:any;
    monthlyReturns:any;
    performanceMetrics:any;
    drawdownPeriodsData:any;
    distributionMonthlyCaseString:string;
    distributionMothlyData:any;
    portfolioList:Portfolio[];
}

const SectionTabItems = [
    {title: 'Single Asset Simulation',id: 'SingleAssetSimulation'},
    {title: 'Advanced Simulation',id:'AdvancedSimulation'},
]as TabItem[];
const TabsItems = [
    {title: 'Overview', id:'Overview'},
    {title: 'Asset Allocation',id: 'AssetAllocation'},
    {title: 'Performance',id: 'PerformanceSimulation'},
    {title: 'Risk Metrics',id: 'RiskMetricsSimulation'},
] as TabItem[];


const rebalancingFrequency = [
    { name: 'No Rebalancing',   id: 'NoRebalancing'},
    { name: 'Daily',            id: 'Daily'},
    { name: 'Weekly',           id: 'Weekly'},
    { name: 'Monthly',          id: 'Monthly'},
    { name: 'Quarterly',        id: 'Quarterly'},
];


const PortfolioSimulation = (props:PorfolioSimulationProps) => {
    const portfolio = props.portfolio;
    const currentAssetVolume = props.currentAssetVolume as AssetVolume;
    const renderData = props.renderData;
    const keyMetricksData = props.keyMetricksData;
    const caseStr = props.caseStr;
    const simulationAllocation = props.simulationAllocation;
    const annualReturnsSimulation = props.annualReturnsSimulation;
    const  dailyReturnsData = props.dailyReturnsData;
    const underwaterPlot = props.underwaterPlot;
    const worstDrawDownData = props.worstDrawDownData;
    const rollingVolatility = props.rollingVolatility;
    const monthlyReturns = props.monthlyReturns;
    const performanceMetrics= props.performanceMetrics;
    const drawdownPeriodsData = props.drawdownPeriodsData;
    const rollingSortinoSimulated = props.rollingSortinoSimulated;
    const rollingSortinoSimulatedCaseString = props.rollingSortinoSimulatedCaseString;
    const rollingSharpe = props.rollingSharpe;
    const rollingSharpeSimulatedCaseStr = props.rollingSharpeSimulatedCaseStr;
    const distributionMonthlyData = props.distributionMothlyData;
    const distributionMonthlyCaseString = props.distributionMonthlyCaseString;
    const resultResponse = 'success';

    const noBenchmarkAsset = {id: -1, name: 'No Benchmark', symbol: '*', value: 0};
    const possibleBenchmark = [noBenchmarkAsset,...props.possibleBenchmark];
    const portfolioBenchmark = portfolio.benchmark !== null  ? possibleBenchmark.find((it) => {
            if(it.id === portfolio.benchmark.instrumentId) {
                return it
            }
    }) : undefined;
    const defaultBenchmark = portfolioBenchmark !== undefined && portfolio?  portfolioBenchmark : noBenchmarkAsset;
    const [selectBenchmark, setSelectBenchmark] = useState(defaultBenchmark);

    // const extraInstrumentsList = props.extraInstrumentsList;
    const extraInstrumentsList = [initialEmptyAsset,...props.extraInstrumentsList];
    const defaultExtraInstrument = {id: -1, name: '', symbol: '*', value: 0};
    const [extraInstrument,setExtrsInstrument] = useState(defaultExtraInstrument );

    const [sliderValue,setSliderValue] = useState(0);

    const defaultFrequency  = { name: 'No Rebalancing',   id: 'NoRebalancing'};
    const [frequency, setFrequency] = useState(defaultFrequency);

    const onRefresh = props.onRefresh;
    const [defaultValue, setDefaultValue] = useState(currentAssetVolume);
    const [selectedForEdit, setSelectedForEdit] = useState(false);
    const [currentEditableAssetVolume, setCurrentEditableAssetVolume] = useState(AssetVolumeEmptyItem);
    const ENTER_ALLOCATION_REF_NAME = '_allocation_ref_name';
    const SELECT_ASSET_REF_NAME = '_asset_ref_name';
    const SELECT_FREQUENCY_REF_NAME = '_frequency_ref_name';
    const valuesRefs = new RefHoster();
    const currentControlDate = new Date(props.currentControlDate || new Date().toISOString());
    const dateStart = portfolio._dateStart;
    const dateEnd = portfolio._dateEnd;
    const possibleAssets = [...props.extraInstrumentsList,...props.portfolioList];
    const [errorMessage, setErrorMessage] = useState(false);
    const [showGraphs, setShowGraphs] = useState(false);

    const tasks = portfolio.tasks;
    const latestTask = getCurrentPortfolioTask(portfolio);
    // console.log(latestTask)

    const runningTasks = tasks.filter(task => task.status === 'started' && task.msg === 'simulation-simulation');

    const runningSomething = runningTasks.length > 0;
    // const errorTasksMessage = latestTask && latestTask.status === 'error' && !defaultExtraInstrument ? latestTask.errorMessage : null;
    const errorTasksMessage = latestTask && latestTask.status === 'error' && latestTask?.msg === 'simulation-simulation' ? latestTask.errorMessage : null;
    const anyError = errorTasksMessage;
    const isTextErrorMatch = errorTasksMessage?.slice(9).match(/^(.*?),/);
    const textError = isTextErrorMatch && isTextErrorMatch[1];

    const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';
    const resolutionWidthBox = RESOLUTION_SCREEN === 'horizontal' ? '320px' : '120px';
    const resolutionWidthAutocomp = RESOLUTION_SCREEN === 'horizontal' ? '600px' : '220px';
    const stackSpace = RESOLUTION_SCREEN === 'horizontal' ? '73px' : '20px';
    const stackSpaceDatePicker = RESOLUTION_SCREEN === 'horizontal' ? '73px' : '20px';
    const stackHeightDatePicker = RESOLUTION_SCREEN === 'horizontal' ? '40px' : '140px';
    const stackDirection = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';
    const resolutionIconSize = RESOLUTION_SCREEN === 'horizontal' ? '14px' : '8px';

    const [sectionTab, setSectionTab] = useState(TabsItems[0]);

    const [redistributionMode,setRedistributionMode] = useState('uniform');
    const [openListMode,setOpenListMode] = useState(false);
    const openIconMode = !openListMode ? <ExpandMoreIcon sx={{fontSize:'large',color:mainTextColor}} className={'clickable hintBtn'}/>
     : <ExpandLessIcon sx={{fontSize:'large',color:mainTextColor}} className={'clickable hintBtn'}/>;

    const [error, setError] = React.useState<DateValidationError | ''>('');

    const errorMessageDate = React.useMemo(() => {
        switch (error) {
            case "minDate"      : {return 'Select a date'}
            case 'invalidDate'  : {return 'Date is not valid'}
            case 'maxDate'      : {return 'Select a date'}
            default             : {return ''}
        }
    }, [error]);

    const checkDate = (dateStart,dateEnd) => {
        if (dateStart !== undefined||null && dateEnd !== undefined||null){
            return  Date.parse(dateStart) >= Date.parse(dateEnd);
        }
    };

    const onChange = (item: TabItem) => props.infoTabsOnChange ? props.infoTabsOnChange(item) : undefined;


    const selectBenchMark = (selectedItem: AssetVolume) => {
        setSelectBenchmark(selectedItem);
    };

    const rebalnceFrequency = (selectedItem) => {
        setFrequency(selectedItem);
    };

    const changeSliderValue = (selectedItem: number | number[]) => {
        setSliderValue(selectedItem as number)
    };
    const selectExtraInstrumentAsset = (selectedItem) => {
        setExtrsInstrument(selectedItem)
    };

    const showErrorMessage = () => {
        setErrorMessage(true)
    };

    const changeRedistributionMode = (event) => {
        setRedistributionMode(event.target.value);
    };

    const createNewPortfolio = () => {
        onRefresh({'mode': 'newPortfolio'});
    };
    const navigate = useNavigate();

    const getExtraPortfolio = () => {
        // @ts-ignore
        const assetWithExtraWeight = extraInstrument.assets.map((item) => {
            return {symbol: item.symbol, weight:[item.value,sliderValue],portfolioId:extraInstrument.id,portfolioName:extraInstrument.name};
        });
      // const extra = [{portfolioId:extraInstrument.id,portfolioName:extraInstrument.name,assets:assetWithExtraWeight}];
      return assetWithExtraWeight;
    };


    const simulationPortfolio = () => {
        if(extraInstrument.symbol !== '*' && error === null || error === '') {
            const msg = 'simulation-simulation';
            const selectedFrequency = frequency.id === '#' || frequency.id === 'NoRebalancing' ? 'None' : frequency;
            // const extraInstruments = [{symbol:extraInstrument.symbol,weight:sliderValue}]
            const extraInstruments = extraInstrument.symbol !== undefined ? [{symbol:extraInstrument.symbol,weight:sliderValue}]
                : getExtraPortfolio();
            // @ts-ignore
            props.extraCall({...portfolio,benchmark: selectBenchmark, frequency: selectedFrequency,msg:msg, extra_instruments:extraInstruments});
            setShowGraphs(true);
            setErrorMessage(false);
            setSectionTab(TabsItems[0]);
        }else{
            setErrorMessage(true);
        }
    };

    const changeSectionTab = (item: TabItem) => {
        setSectionTab(item);
    };

    const isDateInceptionLessEnd = () => {
        if(dateEnd !== undefined||null) {
            const oneDay = 24 * 60 * 60 * 1000;
            const end =  new Date(dateSerializerNew(dateEnd)).getTime();
            const start =  new Date(dateSerializerNew(dateStart)).getTime();
            const days = Math.round(Math.abs((end - start)/oneDay))
            return days > 184;
        };
    };


    const defaultBench = defaultBenchmark.id === selectBenchmark.id ? defaultBenchmark : selectBenchmark;



    const reSelect = () => {
        const bench = possibleBenchmark.find(it => it.id === selectBenchmark.id);
        if(bench !== undefined && 'base_symbol' in bench) {
            return getClassName(bench.class) === 'CRYPTO' ? bench.name + ' - ' + bench.base_symbol : bench.name;
        }
    }

    const usStockLegend = reSelect();
    const currentFrequency =  frequency !== undefined ? frequency : '';

    return <Stack direction={'column'} width={'100%'} >
        <Stack direction={'column'} spacing={3} marginBottom={'16px'} marginTop={'20px'}>
            <span className={'titleGraphs'}>
                CONFIGURE SIMULATION
            </span>
            <Stack direction={stackDirection} spacing={stackSpace} height={'40px'} >
                <Stack direction={'row'} spacing={stackSpace}>
                    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                        Select and add new Asset to Portfolio
                        {/*<InfoCircleOutlined style={{marginLeft:'10px', fontSize:resolutionIconSize}}/>*/}
                    </Box>
                    <Box>
                        <MyNewAutocomplete
                            id={'Search Simulation'}
                            onChange={selectedItem => selectExtraInstrumentAsset(selectedItem)}
                            inputRef={el => valuesRefs.initRef(SELECT_ASSET_REF_NAME, el)}
                            labelParam={'name'}
                            defaultValue={extraInstrument}
                            options={extraInstrumentsList}
                            width={resolutionWidthAutocomp}
                        />
                    </Box>
                </Stack>
                {errorMessage && extraInstrument.symbol === '*' ? <Box className={'errorText'}>
                    * Please specify a new Asset to Portfolio
                </Box> : <></>
                }
            </Stack>
            <Stack direction='row' alignItems='center' spacing={stackSpaceDatePicker} height={stackHeightDatePicker} >
                <Box sx={{color: mainTextColor, width:resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                    Analysis Period
                </Box>
                <Box sx={{paddingBottom: '34px',alignItems:'center'}}>
                    <DateRangeForm
                        dateStart={dateStart as Date}
                        dateEnd={dateEnd as Date}
                        isError={errorMessageDate}
                        setError={setError}
                        errorDate={error}
                        currentControlDate={currentControlDate}
                        className={'pickerSimulation'}
                    />
                </Box>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={stackSpace} height={'40px'}>
                <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                   Market Benchmark
                </Box>
                <Box sx={{paddingBottom: '14px'}}>
                    <MyNewAutocomplete
                        id={'selectBenchmarkSimulation'}
                        onChange={selectedItem => selectBenchMark(selectedItem)}
                        inputRef={el => valuesRefs.initRef(SELECT_ASSET_REF_NAME, el)}
                        labelParam={'name'}
                        defaultValue={defaultBench}
                        options={possibleBenchmark}
                        changeIcon={'overview'}
                        width={resolutionWidthAutocomp}
                    />
                </Box>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={stackSpace} height={'40px'}>
                <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                    Rebalancing Frequency
                </Box>
                <Box sx={{paddingBottom: '14px'}}>
                    <MyNewAutocomplete
                        id={'frequency'}
                        onChange={selectedItem => rebalnceFrequency(selectedItem)}
                        inputRef={el => valuesRefs.initRef(SELECT_FREQUENCY_REF_NAME, el)}
                        labelParam={'name'}
                        defaultValue={currentFrequency}
                        options={rebalancingFrequency}
                        placeholder={'please select Rebalancing...'}
                        changeIcon={'overview'}
                        width={resolutionWidthAutocomp}
                    />
                </Box>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={stackSpace} height={'40px'} >
                <Stack direction={'row'} spacing={stackSpace}>
                    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                        Select Weight of new Asset in your Portfolio
                        {/*<InfoCircleOutlined style={{marginLeft:'10px', fontSize:resolutionIconSize}}/>*/}
                    </Box>
                    <Box>
                        <DiscreteSliderMarks
                            value={sliderValue}
                            onChange={selectedItem => changeSliderValue(selectedItem)}
                            width={600}
                        />
                    </Box>
                </Stack>
                {/*{errorMessage && sliderValue === 0 ? <Box className={'errorText'}>*/}
                {/*    * Please specify a weight of new Asset in your Portfolio*/}
                {/*</Box> : <></>}*/}
            </Stack>
            {/*<Stack direction='row' alignItems='center' spacing={'58px'} height={'40px'}>*/}
            {/*    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>*/}
            {/*        Redistribution Mode*/}
            {/*    </Box>*/}
            {/*    <Stack direction='row' alignItems='center' spacing={1}>*/}
            {/*        <RadioSelection*/}
            {/*            value={redistributionMode}*/}
            {/*            onChange={changeRedistributionMode}*/}
            {/*        />*/}
            {/*        {redistributionMode === 'selective' && <Box paddingTop={'6px'} onClick={() => { setOpenListMode(!openListMode)}}>*/}
            {/*            {openIconMode}*/}
            {/*        </Box>}*/}
            {/*    </Stack>*/}
            {/*</Stack>*/}
            {/*<Stack direction='row' alignItems='center' spacing={'74px'}>*/}
            {/*    <Box sx={{width: resolutionWidthBox}}></Box>*/}
            {/*    {openListMode && redistributionMode === 'selective' &&*/}
            {/*        <Stack width={resolutionWidthAutocomp}>*/}
            {/*            <CheckBoxForm renderData={portfolio}/>*/}
            {/*        </Stack>*/}
            {/*    }*/}
            {/*</Stack>*/}

            <Stack direction={'row'} width={'100%'} justifyContent={'center'} alignItems={'center'} spacing={2} >
                <Box sx={{width: {xs:'40%',md:'70%',lg:'71%'}}}></Box>
                <Box sx={{width:{xs:'60%',md:'30%',lg:'29%'}}} >
                    {/*<Button disabled={checkDate(dateStart, dateEnd) } className={'advancedBtn'}*/}
                    <Button disabled={checkDate(dateStart, dateEnd) || runningSomething} className={'advancedBtn'}
                             onClick={simulationPortfolio}>
                        Simulate
                    </Button>
                {/*<button className={'advancedBtn'} style={{marginLeft:'16px'}}>Save simulated Portfolio</button>*/}
                </Box>
            </Stack>

            {runningSomething && !anyError && !errorTasksMessage
                ? <Stack justifyContent={'center'} alignItems={'center'} sx={{width: '100%',height: '100px', marginTop:'20px'}}>
                    <span className={'loader'}></span>
                    {/*<Box style={{color:mainTextColor, fontSize:processingFontResolution}}><br/>Processing. Please wait...</Box>*/}
                </Stack>
                : <></>}

            {errorTasksMessage || anyError ? <Stack justifyContent={'center'} alignItems={'center'} sx={{width: '100%',height: '100%', marginTop:'20px'}}>
                {errorTasksMessage?.includes('No data for instrument')  ?
                    // <Box sx={{color: mainTextColor}}> No trading days {errorTasksMessage?.slice(8)} within selected analysis period.</Box>
                    <Box sx={{color: mainTextColor}}> No trading days {textError} within selected analysis period.</Box>
                    : <Box className={'errorMessage'} >
                        ERROR! {errorTasksMessage} {errorMessage}.
                        <br/>
                        Please double-check Your values.
                    </Box>}
            </Stack>: <></>
            }

        </Stack>
        {showGraphs &&  !anyError && !runningSomething && resultResponse === 'success' &&<>
                   <Grid container>
                       <Grid item xs={12}>
                           <EquityCurveView
                               renderData={renderData}
                               caseStr={caseStr}
                               legendName={portfolio.name}
                               dateStart={dateStart as Date}
                               dateEnd={dateEnd as Date}
                               usStockLegend={usStockLegend}
                               panelId={EQUITY_CURVE_SIMULATION_ID}
                           />
                       </Grid>
                   </Grid>
        <Stack direction='row' alignItems='center'sx={{width: '100%',height:'40px'}}  marginTop={'80px'} marginBottom={'47px'}>
            <TabsComponent onChange={changeSectionTab} items={TabsItems} />
        </Stack>
        {sectionTab === TabsItems[0] &&
            <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <KeyMetrics
                        renderData={keyMetricksData}
                        legendName={portfolio.name}
                        className={'keymetricsSimulation'}
                        usStockLegend={usStockLegend}
                        placeUse={'Simulation'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <AnnualReturnsSimulation
                        renderData={annualReturnsSimulation}
                        legendName={portfolio.name}
                        usStockLegend={usStockLegend}
                        panelId={ANNUAL_RETURNS_SIMULATION_ID}
                        placeUse={'simulated'}
                    />
                </Grid>
            </Grid>
        }
        {sectionTab === TabsItems[1] &&
            <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                <Grid item xs={12}>
                    <OptimizedSimulatedAllocation
                        renderData={simulationAllocation}
                        portfolio={portfolio}
                        placeUse={'Simulation'}
                        classCSS={'compositionSimulation'}
                        title={'SIMULATED ASSET ALLOCATION'}
                        extraInstruments={extraInstrument}
                    />
                </Grid>
            </Grid>
        }
        {sectionTab === TabsItems[2] &&
            <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack spacing={5}>
                        <PerformanceMetrics
                            renderData={performanceMetrics}
                            legendName={portfolio.name}
                            usStockLegend={usStockLegend}
                            simulation={true}
                            className={'keymetricsSimulation'}
                            placeUse={'Simulation'}
                        />
                        <DistributionMonthlyReturn
                            renderData={distributionMonthlyData}
                            currentResult={'simulated'}
                        />
                        <MonthlyReturnsPortfolio
                            renderData={monthlyReturns}
                            currentResult={'simulated'}
                        />
                    </Stack>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack  direction={'column'} spacing={5}>
                        {isDateInceptionLessEnd() && <>
                            <RollingSharpe
                                rollingSharpe={rollingSharpe}
                                rollingSharpeCaseString={rollingSharpeSimulatedCaseStr}
                                currentResult={'simulated'}
                                onChange={props.onChange}
                                graphicsName={rollingSharpeSimulatedGraphics}
                                placeUse={'Simulation'}
                            />
                            <RollingSortino
                                rollingSortino={rollingSortinoSimulated}
                                rollingSortinoCaseString={rollingSortinoSimulatedCaseString}
                                onChange={props.onChange}
                                graphicsName={rollingSortinoSimulatedGraphics}
                                placeUse={'Simulation'}
                            />
                        </>}

                        <DailyReturnsPortfioio
                            renderData={dailyReturnsData}
                            currentResult={'simulated'}
                            titleGraph={'DAILY RETURNS SIMULATED PORTFOLIO'}
                        />
                        {/*{isDateInceptionLessEnd() && <>*/}
                        {/*    <Stack sx={{opacity:0.3}}>*/}
                        {/*        <ReturnQuantiles/>*/}
                        {/*    </Stack>*/}
                        {/*</>}*/}
                    </Stack>
                </Grid>
            </Grid>
        }
        {sectionTab === TabsItems[3] &&
            <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack spacing={5}>
                        <WorstDrawdowns
                        renderData={worstDrawDownData}
                        currentResult={'simulated'}
                        dateEnd={dateEnd as Date}
                        />
                        {isDateInceptionLessEnd() && <>
                            <RollingVolatility
                                renderData={rollingVolatility}
                                legendName={portfolio.name}
                                usStockLegend={usStockLegend}
                                panelId={ROLLING_VOLATILITY_SIMULATION_ID}
                            />
                            {/*<Stack sx={{opacity:0.3}}>*/}
                            {/*    <RollingBetaToBenchmark*/}
                            {/*        renderData={rollingVolatility}*/}
                            {/*        legendName={portfolio.name}*/}
                            {/*        usStockLegend={usStockLegend}*/}
                            {/*        panelId={ROLLING_VOLATILITY_SIMULATION_ID}*/}
                            {/*    />*/}
                            {/*</Stack>*/}
                        </>}
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack spacing={5}>
                        <DrawdownPeriods
                            renderData={drawdownPeriodsData}
                            currentResult={'simulated'}
                            legendName={portfolio.name}
                            placeUse={'Simulated Portfolio'}
                        />
                        <UnderwaterPlot
                        renderData={underwaterPlot}
                        currentResult={'simulated'}
                        />
                        {/*{isDateInceptionLessEnd() && <>*/}
                        {/*    <Stack sx={{opacity:0.3}}>*/}
                        {/*        <ValueRiskExpectedShortfall/>*/}
                        {/*    </Stack>*/}
                        {/*</>}*/}
                    </Stack>

                </Grid>

            </Grid>
        }
        </>}
    </Stack>
};

export default PortfolioSimulation;
