// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';

import allNbReducers from 'store/nb_reducers'
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    ...allNbReducers,
  menu,
  snackbar
});

export default reducers;
