import React, { useMemo} from 'react';
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Stack,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Button,
    Popper,
    Grow,
    Paper,
    ClickAwayListener, MenuList
} from '@mui/material';
import {buttonBackgroundColor, headerBackgroundColor, mainTextColor, primaryTextColor} from "../../../../config";
import {goTo} from "../../../../utils/react-utils";
import AppRoutes from "../../../../routes/AppRoutes";
import {strategyDescription,ActionMode} from "../../../../pages/strategy/researche-products.common";
import {useDispatch} from "react-redux";
import {refresh} from "../../../../pages/strategy/research-products.slice";


interface StrategyDemoHeaderProps {

};


const StrategyDemoHeader = (props:StrategyDemoHeaderProps) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<(null | HTMLElement)[]>([null,null]);
    const open = (index) =>  Boolean(anchorEl[index]);


    const openMenu = (index) =>  (event: React.MouseEvent<HTMLButtonElement>) => {
        if(index !== 2) {
            const newAnchorEl = [...anchorEl];
            newAnchorEl[index] = event.currentTarget;
            setAnchorEl(newAnchorEl);
            const anotherIndex = newAnchorEl.findIndex((item,idx) => idx !== index);
            if(newAnchorEl[anotherIndex] !== null) {
                newAnchorEl[anotherIndex] = null;
                setAnchorEl(newAnchorEl)
            };
        } else {
            setAnchorEl([null,null]);
        };
    };
    const closeMenu = (index) => {
        const newAnchorEl = [...anchorEl];
        newAnchorEl[index] = null;
        setAnchorEl(newAnchorEl);
    };

    const bttnStyle = {
        boxShadow:'none',
        '&.MuiButtonBase-root': {
            background:headerBackgroundColor,
            color:primaryTextColor,
            fontFamily:'Noto Sans',
            '&:hover' : {
                color:buttonBackgroundColor,
                boxShadow:'none',
            },
            '&:active':{
                boxShadow:'none',
            },
            '&:focus': {
                boxShadow:'none',
            },
            '&:after': {
                boxShadow:'none',
            }
        }
    };
    const menuStyle = {
        fontFamily:'Noto Sans',
        background: headerBackgroundColor,
        '&:hover':{
            color:buttonBackgroundColor
        },

    };

    const navigate = useNavigate();
    const onCLickNavigation = (index,item) => {

        closeMenu(index);
        dispatch(refresh({mode:ActionMode.strategy,name:item.id_name}))
        navigate(`/researchProducts/${item.type}/${item.location_name}`,{ state: { name: item.name } });
        // navigate(`/researchProducts/${place}`,);
    };

    return <>
        <Stack direction={'row'}  alignItems={'center'} justifyContent={'flex-start'}>
            <Typography onClick={goTo(AppRoutes.RESEARCH_PRODUCTS)} variant={'inherit'} color={primaryTextColor}
                        sx={{":hover":{
                                color: buttonBackgroundColor},
                            cursor:'pointer'
                        }}
                        onMouseOver={openMenu(2)}>
                Research Products
            </Typography>

            <Button
                id="strategy-button"
                aria-controls={open(0) ? 'strategy' : undefined}
                aria-haspopup="true"
                aria-expanded={open(0) ? 'true' : undefined}
                onMouseOver={openMenu(0)}
                sx={{...bttnStyle, margin:'0px 20px 0px 20px'}}
            >
                Strategy
            </Button>
            <Popper
                open={open(0)}
                anchorEl={anchorEl[0]}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                onMouseLeave={() => closeMenu(0)}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => closeMenu(0)}>
                                <MenuList
                                    autoFocusItem={open(0)}
                                    id={'strategy'}
                                    aria-labelledby={'strategy-button'}
                                >
                                    {strategyDescription.map((item, idx) => (
                                        item.type === 'strategy' &&
                                        <MenuItem key={idx + 'stra'} id="strategy + idx"
                                                  onClick={() => onCLickNavigation(0, item)}
                                                  sx={menuStyle}>{item.name}</MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <Button
                id="thematics-button"
                aria-controls={open(1)? 'thematics' : undefined}
                aria-haspopup="true"
                aria-expanded={open(1) ? 'true' : undefined}
                onMouseOver={openMenu(1)}
                sx={bttnStyle}
            >
                Thematics
            </Button>
            <Popper
                open={open(1)}
                anchorEl={anchorEl[1]}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                onMouseLeave={() => closeMenu(1)}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => closeMenu(1)}>
                                <MenuList
                                    autoFocusItem={open(1)}
                                    id={'thematics'}
                                    aria-labelledby={'thematics-button'}
                                >
                                    {strategyDescription.map((item,idx) => (
                                        item.type === 'thematics' &&
                                        <MenuItem key={idx + 'them'} id="thematics + idx" onClick={() => onCLickNavigation(1,item)} sx={menuStyle}>{item.name}</MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Stack>
    </>
};

export default StrategyDemoHeader;
