import React, {createRef, useEffect, useRef, useState} from 'react';
import {createChart, ColorType, TickMarkType, BarPrice, UTCTimestamp} from 'lightweight-charts';
import {Stack, Box} from '@mui/material';
import {mainBackgroundColor, buttonBackgroundColor, errorTextColor} from "../../../config";
import {TabItem} from "../../../components/tab-component";
import {as_timestamp, fullYearDateFormatter, timeFormatter} from "../../../utils/date-utils";
import {gapDataGraphics} from "../data-quality.slice";
import {dateSerializerNew} from "../../../components/controls/form/nb-data-picker";


interface DataQualityProps {
    renderData: any;
    graphics?: TabItem[];
    width?:number;
    height?: number;
    timeRange?:string;
    infoTabs?: string;
    priceFormatter?: Function;
    tooltipForrmatter?:Function;
    portfolioName?:string;
    benchmark?:string| undefined;
    changebackground?:boolean;
    classId?:string;
    caseStr?:string;
}

const DataQualityGraph = (props:DataQualityProps) => {
    const chartContainerRef = useRef <HTMLInputElement | null>(null);
    const tooltipRef = useRef <HTMLInputElement | null>(null);
    const legendSeries = useRef<HTMLInputElement | null>(null);
    const graphics = props.graphics || [];
    const refsSeries = useRef([]);
    const [currentLegend, setCurrentLegend] = useState(0);
    const timeRange = props.timeRange || '';
    const [linePrice, setLinePrice] = useState([]);
    const [currentTime, setCurrentTime] = useState(null);
    const [hideSeries,setHideSeries] = useState(true);
    const idSeries = graphics.map((item) => item.id);
    const renderData = props.renderData ? props.renderData : {};
    const resolutionScreen = () => window.screen.width > window.screen.height ? 'horizontal' : 'vertical';
    const resolutionFontsize = resolutionScreen() === 'horizontal' ? 12 : 8;
    const gaps = gapDataGraphics.getCurrentGraphic();
    const markers = gaps.map((item,idx) => {
        return {
            time:as_timestamp(item.date)/1000,
            position:'inBar',
            color:errorTextColor,
            shape: 'circle',
            size:2,
            // text: item.date
        }
    });
    // console.log(markers)
    refsSeries.current = [];
    const addToRefs = (el) => {
        // @ts-ignore
        if(el && !refsSeries.current.includes(el)) {
            // @ts-ignore
            refsSeries.current.push(el)
        }
    };
    const myPriceFormatter = (price) => {return price.toFixed(2)};
    const currentPriceFormatter = props.priceFormatter|| myPriceFormatter;
    const tooltipPriceFormatter = props.tooltipForrmatter || myPriceFormatter;

    const setId = (id) => {
        setCurrentLegend(id);
    };


    // const result = {};
    // renderData.forEach((item,it) => {
    //     Object.entries(item).forEach(([key,value],idx) => {
    //         if(idSeries.includes(key)){
    //             if(!result[key]){
    //                 result[key] = [];
    //                 result[key].push({time: as_timestamp(item.date)/1000, value});
    //             } else {
    //                 result[key].push({time: as_timestamp(item.date)/1000, value});
    //                 // result[key].push({time: as_timestamp(item.date)/1000});
    //             }
    //         }
    //     })
    // });
    const dataWithGap = renderData.map((item,idx) => {
        return item.value ? {time: as_timestamp(item.date)/1000, value:item.value} : {time: as_timestamp(item.date)/1000}
    });

    const currentData:{}[][] = [];
    let posibleArray:{}[] = [];
    for (const obj of dataWithGap) {
        if (obj.value === undefined) {
            if (posibleArray.length > 0) {
                currentData.push(posibleArray);
            }
            posibleArray = [];
        }
        posibleArray.push(obj);
    }

    if (posibleArray.length > 0) {
        currentData.push(posibleArray);
    }

    useEffect(() => {

        // @ts-ignore
        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: mainBackgroundColor },
                textColor: '#AEA49B',
                fontSize: resolutionFontsize,
                fontFamily: 'Noto Sans',
                fontWieght:400,
            },
            leftPriceScale: {
                visible:true,
                entireTextOnly:true,
                minimumWidth:50
                // scaleMargins: {top: 0.2 , bottom:0.2}
            },
            rightPriceScale: {
                visible:false,
            },
            grid: {
                vertLines: {
                    visible: false
                },
                horzLines: {
                    color: '#AEA49B',
                    style:4
                }
            },
            crosshair: {
                vertLine:{
                    labelBackgroundColor: mainBackgroundColor,
                    labelVisible:false,
                },
                horzLine: {
                    labelBackgroundColor: mainBackgroundColor,
                    labelVisible:false,
                }
            },
            localization:{
                priceFormatter: currentPriceFormatter,
            },

        });

        chart.timeScale().fitContent();
        requestAnimationFrame(() => {
            const visibleLogicalRange =  chart.timeScale().getVisibleLogicalRange();
            if(visibleLogicalRange !== null) {
                chart.timeScale().setVisibleLogicalRange({
                    from:0.35,
                    to:  visibleLogicalRange.to - 0.35,
                })
            }
        } );

        const timeScaleRange = timeRange === 'day' ? 5 : 5;

        chart.timeScale().applyOptions({
            fixLeftEdge:true,
            fixRightEdge:true,
            timeVisible:true,
            tickMarkMaxCharacterLength: timeScaleRange,
        });
        const lineSeries = currentData.map((el,idx) => chart.addLineSeries(
           {
                color: buttonBackgroundColor,
                lineWidth:2,
                priceLineVisible:false,
                lastValueVisible: false,
           }
        ));

        lineSeries.forEach((line,idx) => {
                    // @ts-ignore
            line.setData(currentData[idx]);
            line.setMarkers(markers);
        });

        // const lineSeries = graphics.map((el,idx) => chart.addLineSeries(
        //    {
        //         color: el.graphColor,
        //         lineWidth:2,
        //         // title: el.id,
        //         // lineStyle:2,
        //         // visible: hideSeries,
        //         priceLineVisible:false,
        //         lastValueVisible: false,
        //
        //     }
        // ));


        // lineSeries.forEach((line,idx) => {
        //     for(let key in result) {
        //         if(key === idSeries[idx]) {
        //             line.setData(result[key]);
        //             line.setMarkers(markers);
        //         }
        //     }
        // });



        const toogleSeries = (line,isVisiable) => { line.applyOptions({visible: isVisiable})};

        const visiableLegend = (legend,isVisiable) => {
            legend.style.opacity = isVisiable ? '0.5':'1' ;
        };

        chart.subscribeCrosshairMove((param) => {
            if(tooltipRef.current && chartContainerRef.current !== null) {
                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > chartContainerRef.current.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > chartContainerRef.current.clientHeight
                ){
                    tooltipRef.current.style.display = 'none'
                } else {
                    const data = lineSeries.map((line) => {
                        return param.seriesData.get(line)
                    });
                    // if(data !== undefined) {
                    if(data && data !== undefined) {
                        tooltipRef.current.style.display = 'block';
                        const coordinate = param.point.y + 50;
                        const shiftedCoordinate = param.point.x > chartContainerRef.current.clientHeight - 100 ? param.point.x + -140 : param.point.x + 60;
                        tooltipRef.current.style.left = shiftedCoordinate + 'px';
                        tooltipRef.current.style.top = coordinate + 'px';
                        // @ts-ignore
                        setLinePrice(data);
                        // @ts-ignore
                        const date = new Date((param.time * 1000));
                        // @ts-ignore
                        setCurrentTime(fullYearDateFormatter.format(date) + " " + timeFormatter.format(date));
                    }
                }
            }
        });

        return () => {
            chart.remove()
        };
    },[hideSeries,props.infoTabs,props.timeRange,props.caseStr])
    // },[])

    const priceValue = linePrice.map((item: {value:number,time:number}) => {
        if(item !== undefined && item.value !== undefined) {
            // return item.value.toFixed(2)
            return tooltipPriceFormatter(item.value);
        }
    });

    const borderC = priceValue.length === 0 ? '' : '1px solid'+ buttonBackgroundColor;

    return <div ref={chartContainerRef} style={{position:'relative', marginTop:'0px',height:'100%',width:'100%'}}>
        <Stack direction={'row'} spacing={2} style={{marginBottom: '10px',width:'100%'}}>
            <Stack ref={addToRefs} style={{
                color:'#AEA49B',
                marginBottom: 10,
            }}
                   direction={'row'}
                   alignItems={'center'}
                   spacing={1}
                   key={'legendSeries'}
                   id={`dq`}
            >
                <Box sx={{width: {xs:15,md:20,lg:20},height:2.8,background: buttonBackgroundColor}} key={'legendMark'}></Box>
                <Box key={'legengTitle'} >{props.portfolioName}</Box>
            </Stack>
        </Stack>
        <Stack ref={tooltipRef} style={{
            position:'absolute',
            border:borderC,
            borderRadius: '5%',
            zIndex: 1000,
            color:'#AEA49B',
            background:mainBackgroundColor,
            opacity:0.9,
            padding: 5,
            fontSize: resolutionFontsize,
        }} alignItems={'center'} direction={'column'}>
            <Box marginBottom={'5px'}>{currentTime}</Box>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Box key={'texttitle45'} style={{color: buttonBackgroundColor}}>{props.portfolioName} </Box>
                <Box key={'texttitle2'} style={{color: buttonBackgroundColor, marginLeft:'20px'}}> {priceValue}</Box>
            </Stack>
        </Stack>
    </div>

};

export default DataQualityGraph;

