import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';

import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';

// auth provider
import { DjangoAuthProvider as AuthProvider } from 'contexts/DjangoAuthContext';

import './nb-app.css';
import { WebSockerWrapper } from './components/system/websocket-component';
import AutoSizer from 'react-virtualized-auto-sizer';
import CookieSettings from './pages/Cookie/Cookie';
import React from 'react';
import TradingView from 'charting_library/charting_library';
import TradingViewChartInjector from './components/trading-view/trading-view-chart';


const App = () => (
  <WebSockerWrapper>
  <ThemeCustomization>
    <Locales>
      <ScrollTop>
        <AuthProvider>
          <>
            <TradingViewChartInjector/>
              <Routes />
            <Snackbar />
          </>
        </AuthProvider>
      </ScrollTop>
    </Locales>
  </ThemeCustomization>
  </WebSockerWrapper>
);

export default App;
