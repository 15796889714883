
import { Traded } from 'models/asset';
import React, { ReactElement,useState } from 'react';
import {MarketCapData} from "models/asset";
import { ASSETS } from 'models/asset_data';
import { Box, Stack } from '@mui/material';
import { TickerPrice, TickerVolume } from './values';
import JustTable, {TableFieldDesc} from 'components/controls/table';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import {buttonBackgroundColor, mainTextColor} from 'config';
import { AssetSections } from './assets-info';
import '../controls/tablescss.css'
import { EmptyWatchList } from './assets-extra';
import NorthIcon from '@mui/icons-material/North';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthIcon from '@mui/icons-material/South';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import QuestionCircleOutlined from '@ant-design/icons';
import ConfirmDialog from "../dialogs/dialog";
import TradingV from "../../pages/dashboard/monitor/tv";

interface AssetsTableProps {
  traded:  Traded[];
  currentSectionId: string;
  currentTimeRange: string;
  numberOfRows: number;
  selectFunction: Function;
  sortByFunction: Function;
  currentSortField: string;
  toggleWatchList: Function;
  dataFilter?: Function;
  sortHolder: Record<string, boolean>;
  visiableRowNumber?:number;
  switchCSS:string;
  advanced?:boolean;
}

export default function AssetsTable(props: AssetsTableProps) {
  const { traded, numberOfRows, selectFunction, currentTimeRange,
      sortByFunction, currentSortField,currentSectionId,  sortHolder, visiableRowNumber,
      advanced
  } = props;

  const actualTraded = traded.slice(0, numberOfRows);

  // console.log("################ currentTimeRange [" + currentTimeRange + "] ::", traded)
  // console.log('############# actualSortField:: [' + currentSortField + "] currentSectionId: [" + currentSectionId + "]")

  const toggleWatchList = (record: Traded, index: number) =>  {
    props.toggleWatchList(record.base.id);
  };
  const [trandingDialog,setTrandingDialog] = useState(false);
  const [advancedItem,setAdvancedItem] = useState('');


  const getIcon = (item: Traded) =>  {
    // if (item.base.name.startsWith('DowJones')) {
    //     return ASSETS['JOWJONES'].icon;
    // }
    // if (item.base.name.startsWith('NASDAQ')) {
    //     return ASSETS['NASDAQ'].icon;
    // }
    const coin = ASSETS[item.base.symbol]
    return coin ? coin.icon : '';
  }
  const getName = (item: Traded) => {

      if(item.kind === 'CRYPTO') {
        let name = item.base.name.replace(/([a-z](?=[A-Z]))|([A-Z](?=[a-z]))/g, '$1 $2')
              return name + ' - ' + item.base.symbol
          // return item.base.name + ' - ' + item.base.symbol
      } else {
          if(item.title) {
              if(item.title ===  'Dow Jones Commodity Index'){
                  return item.title
              }
              if (item.title.startsWith('Dow Jones Commodity Index ')) {
                return item.title.substring('Dow Jones Commodity Index '.length)
              }
              if (item.title.startsWith('Dow Jones ') && !item.title.startsWith('Dow Jones Industrial Average')) {
                  const lastPoint = item.title.startsWith('Dow Jones Greece ') || item.title.startsWith('Dow Jones Hong Kong ')
                      || item.title.startsWith('Dow Jones Denmark ') ? item.title.length - ' Index (   )'.length
                      : item.title.length - ' Index'.length;

                return item.title.substring('Dow Jones '.length,lastPoint)
              }
              if (item.title.startsWith('Dow Jones Industrial Average')) {
                return item.title
              }
              if (item.title.startsWith('NASDAQ ') && item.title.length > 'NASDAQ 100'.length) {
                return item.title.substring('NASDAQ '.length)
              }
            return item.title
          }
      }
      // return item.base.name + ' - ' + item.base.symbol
  }

  interface NameCoinProps {
      item: any;
  }
  const NameCoin = (props: NameCoinProps ) =>
        <Stack direction={'row'}  alignItems={'center'}>
            <Box marginRight={'8px'} alignItems={'center'} marginTop={'4px'}>{getIcon(props.item)}</Box>
            <Box marginRight={'8px'}>{getName(props.item)}</Box>
        </Stack>;

  interface PercentScaleProps {
        percentage: number;
  }

  const PercentScale = (props: PercentScaleProps) => {
  const percentage = props.percentage || 0;
  const sign = percentage === 0 ? ' ' : percentage > 0 ? ' ': '';
  const color = percentage > 45 ? "#52C41A"
      :percentage >= 0 ? "#FFCB82"
      :percentage <= -45 ? "#FF4D4F"
      :percentage < 0 ? "#FFCB82":"#FFCB82"
  const arrowMarkerIcon= ()  =>
      percentage > 45 ? <NorthIcon  sx={{fontSize: '18px', color: "#52C41A" }}/>
                :percentage >= 0    ? <NorthEastIcon  sx={{fontSize: '18px',color: '#FFCB82'}}/>
                :percentage <= -45  ? <SouthIcon  sx={{fontSize: '18px', color: '#FF4D4F'}}/>
                :percentage < 0     ? <SouthEastIcon sx={{fontSize: '18px',color: '#FFCB82'}}/>
                :<SouthEastIcon/>
      return <Stack direction={'row'} spacing={'2px'} alignItems={'center'} justifyContent={'center'}>
              {/*<span>{arrowMarkerIcon()}</span> <span style={{color:color}}>{sign + percentage.toFixed(0) + '%'}</span>*/}
              <span>{arrowMarkerIcon()}</span> <span style={{color:color}}>{sign}</span>
            </Stack>
  }


  const starRender = (_, item: Traded, recordIndex: number, iconIndex: number, icons: ReactElement[], onClick): ReactElement => {
    const style = item._inWatchList  ? {color: buttonBackgroundColor} : {};
    return <div key={'star' + recordIndex} onClick={onClick}>{item._inWatchList ? <StarFilled style={style}/> : <StarOutlined style={style} />}</div>;
  }

  const renderPrice = (_, item: Traded): ReactElement => <TickerPrice item={item}/>;

  const timeRangeTitle = (suffix: string) => {
    const trTab = {
      '24H':  'Daily',
      'W':    'Week',
      'M':    'Month',
      'Y':    'Year',
      'YTD':  'YTD',
    }
    return (trTab[currentTimeRange] || '24H') + ' ' + suffix;
  }
  const currentMarketCapLabel = () => {
      const trTab = {
      '24H':  'H24',
      'W':    'WEEK',
      'M':    'MONTH',
      'Y':    'YEAR',
      'YTD':  'YTD',
    }
    return trTab[currentTimeRange];
  }

  const marketCapLabel = currentMarketCapLabel();
  const pct_change_name = `${marketCapLabel}.pct_change`;

  const renderIndicator = (_, item: Traded): ReactElement => {
    let pct_change = 0;
    if (item[marketCapLabel] && item[marketCapLabel].price !== 0) {
      pct_change = item[marketCapLabel].pct_change!;
    }
    const sign = pct_change === 0 ? ' ' : pct_change > 0 ? '+' : '';
    const color = pct_change >= 0 ? '#52C41A' : '#FF4D4F';
    return pct_change ? <span style={{ color: color }}>{sign + pct_change.toFixed(2) + '%'}</span> : <span>&nbsp;</span>
  }

    const pctChangeTrTab = {
      '24H':  'pch_day',
      'W':    'pch_week',
      'M':    'pch_week',
      'Y':    'pch_week',
      'YTD':  'pch_week',
    }


  const renderPctChange = (_, item: Traded): ReactElement => {
    const pct_change = item.values[pctChangeTrTab[currentTimeRange]];
    const sign = pct_change === 0 ? ' ' : pct_change > 0 ? '+' : '';
    const color = pct_change === 0 ? '#adadad' : pct_change > 0 ? '#52C41A' : '#FF4D4F';
    return pct_change !== undefined ? <span style={{ color: color }}>{sign + pct_change.toFixed(2) + '%'}&nbsp;&nbsp;</span> : <span>&nbsp;</span>
  };
  const pct_ch_field_name = () => `values.${pctChangeTrTab[currentTimeRange]}`;

  const renderName = (_, item: Traded): ReactElement => <NameCoin item={item} />;

  const renderTrend = (_, item: Traded): ReactElement => {
      let trend_change = 0;
      if (item[marketCapLabel] && item[marketCapLabel].trend_change !== 0) {
          trend_change = item[marketCapLabel].trend_change;
      }
      return trend_change ? <PercentScale percentage={trend_change}/> : <></>;
  }

  const renderTrendData = (_, item: Traded) => {
      const trend = Number(item.values.trend);
      return advanced ?  <Stack direction={'row'} justifyContent={'center'} spacing={1.5}>
          <Box  width={'30.7px'}>{trend.toFixed(1)}</Box>
          <PercentScale percentage={trend}/>
      </Stack>
          : <PercentScale percentage={trend}/>
  }
  // todo Sergii - If calculation for volatility will change Remove ' * 100'
  const renderVolatility = (_, item: Traded) => {
      // const volatility = Number(item.values.volatility * 100);
      const volatility = Number(item.values.volatility);
      return <span>{volatility.toFixed(1) + '%'}</span>
  }
  const renderLiquidity = (_, item: Traded) => {
      const liquidity = Number(item.values.liquidity);
      return <span>{liquidity.toFixed(1) + '%'}</span>
  }
    // todo Sergii - If calculation for bubble will change Remove ' * 100'
  const renderBubble = (_, item: Traded) => {
      const bubble = Number(item.values.bubble * 100);
      const bubblex = Number(item.values.bubble);
      const widthGradient = () =>  bubble > 90 ? 17
                                           :bubble > 80 ? 15.3 :bubble > 70 ? 13.6 :bubble > 60 ? 11.9
                                           :bubble > 50 ? 10.2 :bubble > 40 ? 8.5  :bubble > 30 ? 6.8
                                           :bubble > 20 ? 5.1  :bubble > 10 ? 3.4  :bubble >= 0 ? 1.7
                                           :bubble < -90 ? 17
                                           :bubble < -80 ? 15.3:bubble < -70 ? 13.6:bubble < -60 ? 11.9
                                           :bubble < -50 ? 10.2:bubble < -40 ? 8.5 :bubble < -30 ? 6.8
                                           :bubble < -20 ? 5.1 :bubble > -10 ? 3.4 :bubble > 0 ? 1.7
                                           :17;
      const stackStyle = {width:'34px', height:'12px'};
      const boxStyle = {width:'17px', height:'12px'};

      const scale = bubble > 0 ?
              <Stack direction={'row'} sx={stackStyle} justifyContent={'center'} alignItems={'center'}>
          <Stack sx={boxStyle} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
              <div style={boxStyle}></div>
          </Stack>
          <div style={{borderRight:'1px solid' + mainTextColor, height:'12px'}}></div>
          <Stack sx={boxStyle} direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
              <div style={{width:widthGradient(), height:'12px', backgroundImage:'linear-gradient(270deg, #FF4D4F 0%, rgba(255, 77, 79, 0.10) 90.00%)'}}></div>
          </Stack>
      </Stack>
        :<Stack direction={'row'} sx={stackStyle} justifyContent={'center'} alignItems={'center'}>
              <Stack sx={boxStyle} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                  <div style={{width:widthGradient(), height:'12px', backgroundImage:'linear-gradient(270deg, rgba(82, 196, 26, 0.10) 10.00%, #52C41A 100%)'}}></div>
              </Stack>
              <div style={{borderRight:'1px solid' + mainTextColor, height:'12px'}}></div>
              <Stack sx={boxStyle} direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                  <div style={boxStyle}></div>
              </Stack>
          </Stack>

      ;

      return  advanced ? <Stack direction={'row'} spacing={1.5}>
              <Box textAlign={'left'} paddingLeft={bubblex > 0 ? '9px':'5px'}>{bubblex.toFixed(1)}</Box>
              <span>{scale}</span>
            </Stack>
          :<span>{scale}</span>
  };

  const renderNumber = (item) => {
      return item !== 0 && <span style={{color: 'white'}}>{item}</span>
  };
  const mainViewClick = () => {
    alert("YES")
  };

  const itemSorter = (field_name: string, sorter) => {
    sortByFunction(field_name, sorter)
  };
  const emptyTableContent = () => props.currentSectionId === AssetSections.watchlist
    ? <EmptyWatchList/>
    : <div></div>;

  const visiableRows = visiableRowNumber ? visiableRowNumber : 15;
  const getTrandingView = (item) => {
      // console.log(item)
      const itemName = item.name.replace(/[\-\/]/g,'/');
      // console.log(itemName)
      setAdvancedItem(itemName);
      setTrandingDialog(true);
  };

  const actionBtn = advanced ? [
      {action:'EXTRAVIEW',onClick:getTrandingView},
      // {action: 'STAR', onClick: toggleWatchList, hint: 'in WatchList', render: starRender }
  ]
  :[
        // {action: 'STAR', onClick: toggleWatchList, hint: 'in WatchList', render: starRender }
    ];


  const fields = [
      // { name: 'values.rank',        title: '',            'width': '4%',  'sorted': 'NUMBER', render: renderNumber },
      { name: 'base.name',          title: 'Asset',                                'width': '28%', 'sorted':'DOWN',  render: renderName},
      { name: 'values.price',       title: 'Price in USD',                         'width': '14%',                   render: renderPrice  },
      // { name: pct_ch_field_name(),  title: timeRangeTitle('Change'),   'width': '17%', 'sorted':'DOWN', render: renderPctChange },
      { name: pct_ch_field_name(),  title: advanced ? 'Change %' : 'Chg%',         'width': '10%', 'sorted':'DOWN',  render: renderPctChange },
      { name: 'values.trend',       title: advanced ? 'Trend and Momenta' : 'TaM', 'width': '11%', 'sorted':'NUMBER',render: renderTrendData},
      { name: 'values.bubble',      title: advanced ? 'Boom or Burst' : 'BoB' ,    'width': '11%', 'sorted':'NUMBER',render: renderBubble},
      { name: 'values.volatility',  title: advanced ? 'Volatility' : 'Vol',            'width': '11%', 'sorted':'NUMBER',render: renderVolatility},
      { name: 'values.liquidity',   title: advanced ? 'Liquidity' : 'Liq',         'width': '11%', 'sorted':'NUMBER',render: renderLiquidity},
      // { name: '_inWatchList', title:'', 'width': '5%', 'sorted': 'DOWN', hint: 'in WatchList'},
      // { name: '',                                   'width': '10%'},
  ] as TableFieldDesc[];
  const _fields = [
      // { name: 'values.rank',        title: '',            'width': '4%',  'sorted': 'NUMBER', render: renderNumber },
      { name: 'base.name',          title: 'Asset',                                'width': '30%', 'sorted':'DOWN',  render: renderName},
      { name: 'values.price',       title: 'Price in USD',                         'width': '24%',                   render: renderPrice  },
      { name: pct_ch_field_name(),  title: advanced ? 'Change %' : 'Chg%',         'width': '10%', 'sorted':'DOWN',  render: renderPctChange },
  ] as TableFieldDesc[];


    return <>
      {currentSectionId === 'crypto' ?
      <JustTable
      name={'assetValues'}
      switchCSS={props.switchCSS}
      data={actualTraded}
      visibleRowsNumber={visiableRows}
      width={'100%'}
      fields={advanced ? [...fields,{name:'','width': '5%'}] : fields}
      actions={actionBtn}
      itemSorter={itemSorter}
      dataFilter={props.dataFilter}
      searchFields={['base.name', 'base.symbol', 'values.price', '#']}
      onSelect={selectFunction}
      emptyTable={emptyTableContent}
      currentSortField={currentSortField}
    /> :
      <JustTable
          name={'assetValues'}
          switchCSS={props.switchCSS}
          data={actualTraded}
          visibleRowsNumber={visiableRows}
          width={'100%'}
          fields={[
              // { name: 'values.rank',        title: '',            'width': '4%',  'sorted': 'NUMBER', render: renderNumber },
              { name: 'base.name',          title: 'Asset',       'width': '30%', 'sorted': 'DOWN', render: renderName},
              { name: 'values.price',       title: 'Value',       'width': '24%',                   render: renderPrice  },
              { name: pct_ch_field_name(),  title: 'Chg%',        'width': '10%', 'sorted':'DOWN',  render: renderPctChange },
          ]}
          actions={[
              {action: 'STAR', onClick: toggleWatchList, hint: 'in WatchList', render: starRender }
          ]}
          itemSorter={itemSorter}
          dataFilter={props.dataFilter}
          searchFields={['base.name', 'base.symbol', 'values.price', '#']}
          onSelect={selectFunction}
          emptyTable={emptyTableContent}
          currentSortField={currentSortField}
      />
      }
      {trandingDialog && <ConfirmDialog title={`TECHNICAL ANALYSIS`} handleAgree={() => {}}
                                        handleCancel={() => {setTrandingDialog(false)}}
                                        advanced={true}
      >
           <TradingV renderData={advancedItem} locationPath={location.pathname}/>
      </ConfirmDialog>}
  </>
}
