import React from 'react';
import { createSlice, current } from '@reduxjs/toolkit';
import { isPendingAction, isRejectedAction, isSucceededAction } from 'utils/refdux-utils';
import { BasePageDefaultState, BasePageState } from 'components/pages/pages-common';
import { COMPONENT_KEY_NAME } from "./live-data.common";

interface State extends BasePageState {
    mode: 'table' | 'graph1',
    data: DistributiveGatewayData[];
}


const initialState: State = {
    ...BasePageDefaultState,
    mode: 'table',
    data: []
}

const dataExample = {
    "msg":"Position",
    "ts":1722511027253843,
    "account":"BNF.ACCOUNT_CPH_PROD_1",
    "position":"-678.6",
    "symbol":"XTZUSDT",
    "initialMargin":"488.69286",
    "maintenanceMargin":"7.33039",
    "positionInitialMargin":"488.69286",
    "unrealizedPnL":"19.77873",
    "leverage":"1",
    "entryPx":"0.74929502",
    "seqn":2
}
export interface DistributiveGatewayData {
    msg: "Position",
    ts:number,
    account: number,
    position: number
    symbol: string,
    initialMargin: number
    maintenanceMargin: number,
    positionInitialMargin: number,
    unrealizedPnL: number,
    leverage: number,
    entryPx: number,
    seqn: number
}

const performSuccessRequest = (state, successPerformer?, action?) => {
    state['apiStatus'] = 'succeeded';
    return successPerformer ? successPerformer(state, action) : state;
}

const applyLiveDataResultSuccess = (state: State, payload) => {
    return state;
}

const apiError = (state, action) => {
    state.apiStatus = 'failed';
    if (action.error && action.error.message && action.error.message.endsWith('401')) {
        state.apiStatus = '401';
    }
    return state;
}
export const liveDataSlice = createSlice({
    name:COMPONENT_KEY_NAME,
    initialState,
    reducers:{
        setData(state, action) {
            state.data.push(action.payload);
            if (state.data.length > 20) {
                state.data.shift();
            }
            return state;
        },
        setMode(state, action) {
            state.mode = action.payload;
            // alert(action.payload)
            return state;
        }
    },
    extraReducers: (builder) => builder
        .addMatcher(isPendingAction,        (state, action) => { state.apiStatus = 'pending'; return state; })
        .addMatcher(isRejectedAction,       (state, action) => apiError(state, action))
        .addMatcher(isSucceededAction,      (state, action) => { state.apiStatus = 'succeeded'; return state; })
});

export const liveDataDefaultActions = liveDataSlice.actions;

export default liveDataSlice.reducer;
