import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import {buttonBackgroundColor, mainBackgroundColor, mainTextColor} from '../../config';

export const NiceButton = props =>
        <Button variant="outlined" onClick={props.handleClickOpen}>
        Just A Test
      </Button>


interface AlertDialogProps {
  title: string,
  // message: string,
  // sub_message?: string;
  handleAgree: Function
  handleCancel: Function,
  children?: React.ReactNode;
  advanced?:boolean;
  discription?:boolean;
  waitlist?: boolean;
}

// const borderColor = '#FFF';
const borderColor = buttonBackgroundColor;
const borderStyle = '1px solid ' + borderColor;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    color: mainTextColor,
    textAlign:'center',
    // padding: theme.spacing(3),
    // backgroundColor: mainBackgroundColor,
    // borderLeft: borderStyle,
    // borderRight: borderStyle,
  },
  // '& .MuiDialogTitle-root': {
  //   padding: theme.spacing(2),
  //   color: mainTextColor,
  //   backgroundColor: mainBackgroundColor,
  //   borderTopLeftRadius: '10px',
  //   borderTopRightRadius: '10px',
  //   borderTop: borderStyle,
  //   borderLeft: borderStyle,
  //   borderRight: borderStyle,
  // },
  '& .MuiDialogActions-root': {
    justifyContent:'center',
    textAlign:'center',
    // padding: theme.spacing(2),
    // color: mainTextColor,
    // backgroundColor: mainBackgroundColor,
    // borderBottomLeftRadius: '10px',
    // borderBottomRightRadius: '10px',
    // borderLeft: borderStyle,
    // borderRight: borderStyle,
    // borderBottom: borderStyle
  },
  '& .MuiDialog-paper' : {
    padding: theme.spacing(2),
    color: mainTextColor,
    backgroundColor: mainBackgroundColor,
    borderRadius: '0px',
    border: borderStyle,
    justifyItems:'center'
  }
}));

export default function ConfirmDialog(props: AlertDialogProps) {
  const advanced = props.advanced ;
  const discription = props.discription;
  const isWaitlist = props.waitlist;
  const [open, setOpen] = React.useState(true);

  const handleCancel = () => {
    setOpen(false);
    props.handleCancel();
  }
  const handleConfirm = () => {
    setOpen(false);
    props.handleAgree();
  }

  return (
    <div>
      <StyledDialog
        className={'RemoveDialog'}
        open={open}
        fullWidth={false}
        maxWidth={advanced && !discription ? 'lg' : 'sm'}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {advanced  && <DialogTitle id="alert-dialog-title">
          <span  className={'titleGraphs'}>{props.title}</span>
        </DialogTitle>}
        {advanced  && <IconButton
              aria-label="close"
              onClick={handleCancel}
              sx={{
                position:'absolute',
                top:8,
                right:8,
                color:mainTextColor
              }}
          >
            <CloseIcon/>
          </IconButton>
        }
        {isWaitlist  && <IconButton
              aria-label="close"
              onClick={handleCancel}
              sx={{
                position:'absolute',
                top:8,
                right:8,
                color:mainTextColor
              }}
          >
            <CloseIcon/>
          </IconButton>
        }

        <DialogContent>
          { props.children }
        </DialogContent>

        {advanced === undefined && discription === undefined && !isWaitlist ? <DialogActions>
          <button  className={'disabledBtn'}  onClick={handleCancel}
                  >Cancel</button>
          <button className={'advancedBtn'} style={{width:'50.97px'}} onClick={handleConfirm}>Yes</button>
        </DialogActions>
            :isWaitlist && advanced === undefined && discription === undefined ?
                <DialogActions> <button className={'advancedBtn'} style={{width:'90px'}} onClick={handleConfirm}> Submite </button></DialogActions>
         : <></> }
      </StyledDialog>
    </div>
  );
}
