import React, {useState} from 'react';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import {TabItem} from "../../../components/tab-component";
import {mainBackgroundColor, RESOLUTION_SCREEN} from "../../../config";


const getChartOptions = (labels: string[],series,graphics:TabItem[],categories,plotOptions,
                         stroke,yTitle,topFiveStart,topFiveEnd,type,barShift) => {
    const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal'? '12px' : '8px';
    const resolutionScreenLabel = RESOLUTION_SCREEN === 'horizontal'? 14 : 10;
    const resolutionScreenMarkers = RESOLUTION_SCREEN === 'horizontal'? [20] : [15];
    const tickAmount = series.map((item) => item.data.length)[0];
    const minValue = () => {
        const minValues = series.map((item) => {
            const value = item.data.map((el) =>{
                return el.x;
            });
            return Math.min(...value)
        });
        return Math.min(...minValues) - barShift;
    }
    const maxValue = () => {
        const maxValues = series.map((item) => {
            const value = item.data.map((el) =>{
                return el.x;
            });
            return Math.max(...value)
        });
        return Math.max(...maxValues) + barShift;
    }

    return {
        chart:{
            type:type,
            toolbar: {
                autoSelected: 'zoom',
                show: false,
                tools: {
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: true,
                    reset: true,
                    selection: true,
                    download:false,
                },
            },
            animations: {
                enabled:false
            },
            events: {
                dataPointMouseEnter: function(event) {
                    if(event.fromElement !== null){
                        event.fromElement.style.cursor = "default";
                    }
                }
            },
            zoom:{
                enabled:false
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: 1,
        },
        plotOptions: {
            bar: {
                horizontal:false
            }
        },
        grid: {
            borderColor: 'rgba(174,164,155,0.5)',
            strokeDashArray: 2,
            borderWidth:0.5,
            xaxis: {
                lines: {
                    show: false,
                }
            },
            yaxis: {
                lines: {
                    show: true,
                }
            },
            padding: {
                left: 15
            },
        },
        xaxis: {
            type: type === 'line' ? 'category' : 'numeric',
            min:type === 'line' ? null : minValue(),
            max:type === 'line' ? null : maxValue(),
            tickAmount:  type === 'line' ? 6 : tickAmount,
            strokeDashArray:1,
            categories: categories ? categories : [],
            labels:{
                style:{
                    colors:'#AEA49B',
                    fontSize: resolutionFontSize,
                    fontFamily: 'Noto Sans',
                    fontWeight: 400,
                },
                formatter:(value,timestamp,opts) => {
                    // console.log(opts.w.config.series.map((item) => item.data))
                    if(type === 'line') {
                        return value
                    } else {
                        return value.toFixed(0) + '%'
                    }
                },
                rotate: tickAmount > 10  && type !== 'line' ? -45 : 0,
                // rotate:  0,
                rotateAlways: tickAmount  > 10 && type !== 'line' ? true: false,
                // hideOverlappingLabels: true,
                // showDuplicates: false,
                // trim: false,
                // offsetX:25,
                // offsetY:20,

            },
            tickPlacement: 'on',
            axisBorder: {
                show: true,
                color:'#AEA49B',
                height:0.1

            },
            axisTicks:{
                show: false
            },
            tooltip:{
                enabled:false,
            },
            crosshairs: type === 'line' ? {
                stroke: {
                    color: 'rgba(174,164,155,1)',
                    width: 0.5,
                    dashArray: 5
                } ,
            } : {show:false},
        },
        yaxis: {
            forceNiceScale: true,
            min:function () {
                const minValues = series.map((item) => {
                    if(type === 'line') {
                        // console.log(item.data)
                        return Math.min(...item.data);
                    }
                });
                return type === 'line' ? Math.min(...minValues) + (-0.005) : 0;
            } ,
            max:  function () {
              const maxValue = series.map((item) => {
                  if(type === 'line') {
                      return Math.max(...item.data);
                  } else {
                      const value = item.data.map((el) => {
                         return el.y
                      })
                      return Math.max(...value)
                  }

              });
              return type === 'line' ?  Math.max(...maxValue) + 0.005 : Math.max(...maxValue) - 1 ;
            },
            labels:{
                style:{
                    colors:'#AEA49B',
                    fontSize: resolutionFontSize,
                    fontFamily: 'Noto Sans',
                    fontWeight: 400,
                },
                formatter: (value) => {
                    if(type === 'line') {
                        return (value * 100)?.toFixed(0) + '%'
                    } else {
                        return (value)?.toFixed(0) + '%'
                    }
                },
            },
            axisBorder: {
                show: true,
                width:'0.1px',
                color:'#AEA49B',

            },
            axisTicks:{
                show: false,
            },
            crosshairs: {
                stroke: {
                    color: 'rgba(174,164,155,1)',
                    width: 1,
                    dashArray: 5
                }
            },
            title:{
                text:yTitle,
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: '#AEA49B',
                    fontSize: resolutionFontSize,
                    fontFamily: 'Noto Sans',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            tooltip: {
                enabled:true
            },
        },
        colors: graphics.map((item) => item.graphColor),
        stroke: stroke,
        tooltip:{
            enabled:true,
            followCursor:true,
            theme:false,
            custom: function({series, seriesIndex, dataPointIndex, w, categories}) {
                let tooltip = '';
                tooltip += '<div class="arrow_box">'
                tooltip += '<div style="margin-bottom: 8px">';
                if(w.globals.lastXAxis.categories[dataPointIndex] !== undefined){
                    tooltip += '<span style="color: #AEA49B">' + w.globals.lastXAxis.categories[dataPointIndex] +'</span>'
                } else {
                    tooltip += '<span style="color: #AEA49B">' + w.globals.labels[dataPointIndex].toFixed(0) + '%'  +' to '+ w.globals.labels[dataPointIndex + 1].toFixed(0) + '%'  +'</span>'
                }
                tooltip += '</div>';
                series.map((item,idx) => {
                    if(item[dataPointIndex] !== null && item[dataPointIndex] !== undefined){
                        tooltip += '<div style="display: flex;flex-direction: row;justify-content: space-between;">';
                        tooltip += '<div>';
                        if(w.globals.seriesNames[idx] !== ''){
                            tooltip += '<span style="color: ' + w.globals.colors[idx] + '" class="value-tooltip">' + w.globals.seriesNames[idx] +  '</span>';
                        } else {
                            tooltip += '<span style="color: ' + w.globals.colors[idx] + '" class="value-tooltip">' + 'Distribution' +  '</span>';
                        }
                        tooltip += '</div>';
                        tooltip += '<div>';
                        if(type === 'line') {
                            tooltip += '<span style="color: ' + w.globals.colors[idx] + ';margin-left: 30px" class="value-tooltip">' + (item[dataPointIndex] * 100).toFixed(2) + '%' + '</span>';
                        } else {
                            tooltip += '<span style="color: ' + w.globals.colors[idx] + ';margin-left: 30px" class="value-tooltip">' + (item[dataPointIndex]).toFixed(2) + '%' + '</span>';

                        }
                        tooltip += '</div>';
                        tooltip += '</div>';
                    } else if(item[dataPointIndex] === undefined) {
                       tooltip += '<div>';
                       tooltip += '<span>' + ' ' + '</span>'
                        tooltip += '</div>';
                    }
                })
                tooltip += '</div>'
                return tooltip;
            }
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            showForSingleSeries: true,
            fontSize:resolutionScreenLabel,
            fontFamily: 'Noto Sans',
            fontWeight: 400,
            labels: {
                colors: '#AEA49B' ,
            },
            markers:{
                width: resolutionScreenMarkers,
                height: 2.8,
                radius:0,
                offsetY: -3,
            }
        },
        annotations:{
            xaxis: [
                {
                    x: '3%',
                    borderColor:  '#C24046',
                    strokeDashArray: 3,
                },
                {
                    x: topFiveStart ? topFiveStart[0] : '',
                    x2:topFiveEnd ? topFiveEnd[0] : '',
                    fillColor:  '#C24046',
                    strokeDashArray: 0,
                    borderColor:mainBackgroundColor,
                    opacity:0.5,
                },
                {
                    x: topFiveStart ? topFiveStart[1] : '',
                    x2:topFiveEnd ? topFiveEnd[1] : '',
                    fillColor:  '#C24046',
                    strokeDashArray: 0,
                    borderColor:mainBackgroundColor,
                    opacity:0.5,
                },
                {
                    x: topFiveStart ? topFiveStart[2] : '',
                    x2:topFiveEnd ?topFiveEnd[2] : '',
                    fillColor:  '#C24046',
                    strokeDashArray: 0,
                    borderColor:mainBackgroundColor,
                    opacity:0.5,
                },
                {
                    x: topFiveStart ? topFiveStart[3] : '',
                    x2:topFiveEnd ? topFiveEnd[3] : '',
                    fillColor:  '#C24046',
                    strokeDashArray: 0,
                    borderColor:mainBackgroundColor,
                    opacity:0.5,
                },
                {
                    x: topFiveStart ? topFiveStart[4] : '',
                    x2:topFiveEnd ? topFiveEnd[4] : '',
                    fillColor:  '#C24046',
                    strokeDashArray: 0,
                    borderColor:mainBackgroundColor,
                    opacity:0.5,
                },
            ],

        },

    }
}
const ApexRangeBar = (props) => {
    const {series, labels,benchmark,graphics,width,height,categories,plotOptions,stroke,yTitle,type,topFiveStart,topFiveEnd,barShift} = props;
    const [options, setOptions] = useState<ChartProps>(getChartOptions(labels,series,
        graphics,categories,plotOptions,stroke,yTitle,topFiveStart,topFiveEnd,type,barShift));

    return (
        <ReactApexChart options={options} series={series} type={type} height={height} width={width}/>
    )
};

export default ApexRangeBar;