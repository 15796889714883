import { createSlice } from '@reduxjs/toolkit'

import { COMPONENT_KEY_NAME } from './trade-lab.common';
import { AsyncApiStatus } from 'models/nb-models';


interface InitialState {
  loading: AsyncApiStatus;
}

const initialState: InitialState = {
  loading: 'idle'
}

export const tradeLabSlice = createSlice({
  name: COMPONENT_KEY_NAME,
  initialState,
  reducers: {
    load(state, action) {
    }
  }
});


export default tradeLabSlice.reducer;
