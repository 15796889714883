import React, {useRef, useState, useMemo, useEffect} from "react";
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import {buttonBackgroundColor, mainBackgroundColor, mainTextColor, RESOLUTION_SCREEN} from "../../../config";
import ReloadOutlined from '@ant-design/icons';
import { $$ } from 'utils/utils';
import {dateSerializerNew} from "../../../components/controls/form/nb-data-picker";
import {TabItem} from "../../../components/tab-component";
import  '../../../components/portfolios/porfolio.css';
import {as_timestamp, fullYearDateFormatter, isDaylightSavingTimeEurope} from "../../../utils/date-utils";
import {Box, Stack} from "@mui/material";
import {useLocation} from "react-router-dom";

const getChartOptions = (labels: string[],series,graphics:TabItem[],categories,plotOptions,stroke,yTitle,lastPriceIndex,lastPriseValue,location) => {
    const resolutionScreenStyle = RESOLUTION_SCREEN === 'horizontal'? '12px' : '8px';
    const resolutionScreenLabel = RESOLUTION_SCREEN === 'horizontal'? 14 : 10;
    const resolutionScreenMarkers = RESOLUTION_SCREEN === 'horizontal'? [20,20,20,20,20,20] : [15,15,15,15,15,15];


    const xaxisValue = (index) => {
        const value = series.map((item) => {
            const timeSeries = item.data.map((el) => el.x)
            return timeSeries
        })
        const time = value[0][index];
        return time;
    };

    const lastTime = (index) => {
        const value = categories.map((item) => {
          for(let key in item){
             if(key === 'date') {
                 return item['date'];
             }
          }
        });
        const time = value[index];
        return Date.parse(time)/1000;
    };

    const getDate = () => {
        const date = categories.map((item) => {
            for(let key in item){
                if(key === 'date') {
                    return item['date'];
                }
            }
        });
        return date.length !== 0 ? date[2].slice(0,10) : (dateSerializerNew(new Date()));
    };

    const minValue = () => {
        const minValues = series.map((item) => {
           const min = item.data.map((el) => el.y);
            return Math.min(...min);
        });
        return Math.round(Math.min(...minValues) * 100)/100;
    };
    // console.log('MIn vavlue',minValue())
    const maxValue = () => {
        const maxValues = series.map((item) => {
            const max = item.data.map((el) => el.y);
            return Math.max(...max);
        });
        return Math.ceil(Math.max(...maxValues) * 100)/100;
    };
    // console.log('maxValue',maxValue())


  return{
      chart: {
          id:'worldMarket',
          height: 350,
          type: 'line',
          zoom: {
              enabled: false,
              autoScaleYaxis: false
          },
          toolbar: {
              autoSelected: 'zoom',
              show: true,
              offsetY:-30,
              offsetX: -30,
              tools: {
                  download:false,
                  customIcons: location.pathname.includes('screenshot') && [
                      {
                          icon:'<img width="20px" height="20px" >',
                          index:0,
                          title:'Download',
                          class:'btnDownload',
                          click:function(chart, options, e){
                            const chartIn = chart.exports;
                            chartIn.exportToSVG();

                          }
                      }
                  ]
              },
          },
          // export: {
          //     svg:{
          //         filename: `World Markets Chart ${getDate()}`
          //     },
          //     png:{
          //         filename: `World Markets Chart ${getDate()}`
          //     },
          //     csv: {
          //         filename: `World Markets Chart ${getDate()}`,
          //         columnDelimiter: ',',
          //         headerCategory:'category',
          //         headerValue: 'value',
          //         dateFormatter(timestamp) {
          //             return new Date(timestamp).toDateString();
          //         },
          //     }
          // },
          animations: {
              enabled:false
          },
          events: {
              dataPointMouseEnter: function(event) {
                  event.fromElement.style.cursor = "default";
              },
          },
      },
      dataLabels: {
          enabled: false
      },
      grid: {
          borderColor: 'rgba(174,164,155,0.5)',
          strokeDashArray: 2,
          borderWidth:0.5,
          xaxis: {
              lines: {
                  show: false,
              }
          },
          yaxis: {
              lines: {
                  show:true,
              }
          },
      },
      stroke: {
          curve: 'straight',
          width:2
      },
      plotOptions:{
      },
      xaxis: {
          type:'category',
          // categories:['00:00', '00:05', '00:10', '00:15', '00:20', '00:25', '00:30', '00:35', '00:40', '00:45', '00:50', '00:55', '01:00', '01:05', '01:10', '01:15', '01:20', '01:25', '01:30', '01:35', '01:40', '01:45', '01:50', '01:55', '02:00', '02:05', '02:10', '02:15', '02:20', '02:25', '02:30', '02:35', '02:40', '02:45', '02:50', '02:55',
          // '03:00', '03:05', '03:10', '03:15', '03:20', '03:25', '03:30', '03:35', '03:40', '03:45', '03:50', '03:55', '04:00', '04:05', '04:10', '04:15', '04:20', '04:25', '04:30', '04:35', '04:40', '04:45', '04:50', '04:55', '05:00', '05:05', '05:10', '05:15', '05:20', '05:25', '05:30', '05:35', '05:40', '05:45', '05:50', '05:55', '06:00', '06:05', '06:10', '06:15', '06:20', '06:25', '06:30', '06:35', '06:40', '06:45', '06:50', '06:55', '07:00', '07:05', '07:10', '07:15', '07:20', '07:25', '07:30', '07:35', '07:40', '07:45', '07:50', '07:55',
          // '08:00', '08:05', '08:10', '08:15','08:20', '08:25', '08:30', '08:35', '08:40', '08:45', '08:50', '08:55', '09:00', '09:05', '09:10', '09:15', '09:20', '09:25', '09:30', '09:35', '09:40', '09:45', '09:50', '09:55',
          // '10:00','10:05', '10:10', '10:15','10:20', '10:25', '10:30', '10:35', '10:40', '10:45', '10:50', '10:55', '11:00', '11:05', '11:10', '11:15', '11:20', '11:25', '11:30', '11:35', '11:40', '11:45', '11:50', '11:55',
          // '12:00','12:05', '12:10', '12:15','12:20', '12:25', '12:30', '12:35', '12:40', '12:45', '12:50', '12:55', '13:00', '13:05', '13:10', '13:15', '13:20', '13:25', '13:30', '13:35', '13:40', '13:45', '13:50', '13:55',
          // '14:00','14:05', '14:10', '14:15','14:20', '14:25', '14:30', '14:35', '14:40', '14:45', '14:50', '14:55', '15:00', '15:05', '15:10', '15:15', '15:20', '15:25', '15:30', '15:35', '15:40', '15:45', '15:50', '15:55',
          // '16:00','16:05', '16:10', '16:15','16:20', '16:25', '16:30', '16:35', '16:40', '16:45', '16:50', '16:55', '17:00', '17:05', '17:10', '17:15', '17:20', '17:25', '17:30', '17:35', '17:40', '17:45', '17:50', '17:55',
          // '18:00','18:05', '18:10', '18:15','18:20', '18:25', '18:30', '18:35', '18:40', '18:45', '18:50', '18:55', '19:00', '19:05', '19:10', '19:15', '19:20', '19:25', '19:30', '19:35', '19:40', '19:45', '19:50', '19:55',
          // '20:00','20:05', '20:10', '20:15','20:20', '20:25', '20:30', '20:35', '20:40', '20:45', '20:50', '20:55', '21:00', '21:05', '21:10', '21:15', '21:20', '21:25', '21:30', '21:35', '21:40', '21:45', '21:50', '21:55',
          // '22:00','22:05', '22:10', '22:15','22:20', '22:25', '22:30', '22:35', '22:40', '22:45', '22:50', '22:55', '23:00', '23:05', '23:10', '23:15', '23:20', '23:25', '23:30', '23:35', '23:40', '23:45', '23:50', '23:55',],
          labels:{
              style:{
                  colors:'#AEA49B',
                  fontSize:resolutionScreenStyle,
                  fontFamily:'Noto Sans',
                  fontWeight:400
              },
              rotate: 0,
              rotateAlways: false,
              hideOverlappingLabels: false,
              showDuplicates: false,
              trim: false,

          },
          tickPlacement: 'on',
          tickAmount:24,

          axisBorder: {
              show: true,
              color:'#AEA49B',
              height:0.2

          },
          axisTicks:{
              show: false
          },
          tooltip:{
              enabled:false,
          },
          crosshairs:{
              stroke: {
                  color: 'rgba(174,164,155,0.6)',
                  width: 1,
                  dashArray: 5
              }
          }
      },
      yaxis: {
          show:true,
          forceNiceScale:true,
          // min:-0.023,
          // min:-0.15,
          // min:minValue(),
          // max:0.035,
          // max:0.045,
          // max:maxValue() === 0.04 ? 0.035 : 0.03,
          labels:{
              style:{
                  colors:'#AEA49B',
                  fontSize:resolutionScreenStyle,
                  fontFamily:'Noto Sans',
                  fontWeight:400
              },
              formatter: (value) => {
                  return (value * 100)?.toFixed(1) + '%'
              },
          },
          axisBorder: {
              show: true,
              width:'0.1px',
              color:'#AEA49B',
              offsetX: 0,
              offsetY: 0
          },
          axisTicks:{
              show: false
          },
          crosshairs: {
              show:true,
              stroke: {
                  color: 'rgba(174,164,155,0.6)',
                  width: 1,
                  dashArray: 5
              },
          },
          title:{
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                  color: '#AEA49B',
                  fontSize: resolutionScreenStyle,
                  fontFamily: 'Noto Sans',
                  fontWeight: 400,
                  cssClass: 'apexcharts-yaxis-title',
              },
          },
          tooltip:{
              enabled:true,
          },
      },
      colors: graphics.map((item) => item.graphColor),
      series:series,
      legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          fontSize: resolutionScreenLabel,
          fontFamily: 'Noto Sans',
          fontWeight: 400,
          offsetX:30,
          labels: {
              colors: '#AEA49B' ,
          },
          itemMargin:{
              horizontal:10,
              vertical:2
          },
          markers:{
              width: resolutionScreenMarkers,
              height: 2.8,
              radius:0,
              offsetY: -3,
          },
      },
      tooltip:{
          enabled:true,
          followCursor:true,
          theme:false,
          marker: {
              show: true,
          },
          custom: function({series,categories,dateTooltip , seriesIndex, dataPointIndex, w}) {
                const date = dateSerializerNew( new Date(new Date().valueOf()));
                  let tooltip = '';
                  tooltip += '<div class="arrow_box">'
                  tooltip += '<div style="margin-bottom: 8px">';
                  tooltip += '<span style="color: #AEA49B">' + w.globals.categoryLabels[dataPointIndex] +' ' + fullYearDateFormatter.format(new Date(getDate())) +'</span>'
                  tooltip += '</div>';
                  series.map((item,idx) => {
                    if(item[dataPointIndex] !== null && item[dataPointIndex] !== undefined ){
                      tooltip += '<div style="display: flex;flex-direction: row;justify-content: space-between;">';
                      tooltip += '<div>';
                      tooltip += '<span style="color: ' + w.globals.colors[idx] + '" class="value-tooltip">' + w.globals.seriesNames[idx] +  '</span>';
                      tooltip += '</div>';
                      tooltip += '<div>';
                      tooltip += '<span style="color: ' + w.globals.colors[idx] + ';margin-left: 30px" class="value-tooltip">'  + (item[dataPointIndex] * 100).toFixed(2) +'%' + '</span>';
                      tooltip += '</div>';
                      tooltip += '</div>';}
                  })
                  tooltip += '</div>'
                  return tooltip;
          },

      },
      markers: {
          showNullDataPoints:false,
          strokeColor:'none',
          hover: {
              size: 4,
          }
      },
      annotations:{
          xaxis: [
              {
                  x: isDaylightSavingTimeEurope() === 'Summer Time' ? '07:00' : '08:00',
                  borderColor: '#AEA49B',
                  strokeDashArray: 0,

              },
              {
                  x: isDaylightSavingTimeEurope() === 'Summer Time' ?'13:30' : '14:30',
                  borderColor: '#AEA49B',
                  strokeDashArray: 0,

              },
          ],
          yaxis:[
              {
                  y:0,
                  borderColor: '#AEA49B',
                  strokeDashArray: 0,
              }
          ],
          points:graphics.map((item,idx) => {
              const currentTime = (new Date().getTime()/1000) - 3600;
              const difference = (i) => ((currentTime - lastTime(lastPriceIndex(i)))/60)-60;
              if(lastPriseValue(idx,1) !== undefined && lastPriceIndex(idx) !== -1 && difference(idx) < 8){
                  return {
                      seriesIndex: idx,
                      x:xaxisValue(lastPriceIndex(idx)),
                      y:lastPriseValue(idx,1),
                      marker:{
                          size:4,
                          shape:'circle',
                          fillColor:item.graphColor,
                          cssClass:'puls-dot',
                      }
                  }
              } else {
                  return{
                      seriesIndex: idx,
                      x:'00:00',
                      y:0.0,
                      marker:{
                          size:0,
                          fillColor:item.graphColor
                      }
                  }
              }

          }),
      }
  };
};


const TestMarketsGraph = (props) => {
    const location = useLocation();
    const {series, labels,benchmark,graphics,width,height,categories,plotOptions,stroke,yTitle,lastPriceIndex,lastPriseValue} = props;
    const [options, setOptions] = useState<ChartProps>(getChartOptions(labels,series,graphics,categories,plotOptions,stroke,yTitle,lastPriceIndex,lastPriseValue,location));
    const [timeRender,setTimeRender] = useState(0);
    const [difference, setDifference] = useState(0);
    const [counterScreenshot,setCounterScreenshot] = useState(0);
    const getTimeRender = () => {
        const currentMinutes = new Date().getUTCMinutes();
        const currentSeconds = new Date().getUTCSeconds();
        if(currentMinutes % 5 === 0 && currentSeconds === 0) {
            setTimeRender(currentMinutes);
        }
    };
    const getDifferenceValue = () => {
        const currentValue = lastPriseValue(5,1);
        const prevValue = lastPriseValue(5,2);
        const differenceValues = currentValue - prevValue;
        if((differenceValues) !== 0) {
            setDifference(differenceValues);
        }
    };
    setTimeout(getTimeRender,60*1000);
    setTimeout(getDifferenceValue,5000);

    useEffect(() => {
        setOptions(getChartOptions(labels,series,graphics,categories,plotOptions,stroke,yTitle,lastPriceIndex,lastPriseValue,location))
    },[timeRender,difference]);


    const clickDownload = () => {
        const aaa = document.getElementsByClassName('apexcharts-toolbar-custom-icon btnDownload');
        if(aaa.length > 0) {
            const button = aaa[0] as HTMLButtonElement;
            button.click();
            setCounterScreenshot(1);
        }
    };

    if(location.pathname.includes('screenshot') && counterScreenshot === 0  ) {
        clickDownload();
    }
    return (
        <>
            <ReactApexChart options={options} series={series} type="line" height={height} width={width}/>
        </>
    );
};

export default TestMarketsGraph;
