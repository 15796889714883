import React, {useRef, useState} from 'react';
import { Stack } from '@mui/material';
import WidgetInfoPanel from 'components/controls/info-panel';
import {TabItem} from 'components/tab-component';
import GraphSelectPanel from 'components/controls/graph-selector';
import {
    getGraphicColours,
    getGraphicIds,
    getTabsGraphsInfo,
    getDefaultGraphId, FREQUENCY,DISTRIBUTION_RETURN_ID ,
} from "../../../data/graphics";
import TestGraph from "./testgraph";
import ApexRangeBar from "./apex-rangeBar";
import {distributionMonthlyGraphics} from "../portfolio.slice";

interface DistributionMonthlyProps {
    renderData:any;
    currentResult:string;
};

const DistributionMonthlyReturn =(props:DistributionMonthlyProps) => {
    const panelId = DISTRIBUTION_RETURN_ID;
    const renderData = distributionMonthlyGraphics.getCurrentGraphic();
    const currentResult = props.currentResult;
    const data = renderData.map((item,idx) => item[`${currentResult}`] ?  item[`${currentResult}`].result : item.clientPortfolio.result)[0];
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = FREQUENCY;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    };


    const barShift = () => {
        const firstValue = data.filter((item,idx) => idx === 0).map((el) => el.date * 100).toString();
        const nextValue = data.filter((item,idx) => idx === 1).map((el) => el.date * 100).toString();
        if(firstValue < 0 && nextValue) {
            // console.log('First if:::::',((-firstValue) - (-nextValue)) / 2)
            return ((-firstValue) - (-nextValue)) / 2
        } if(firstValue === '0' && !nextValue) {
            // console.log('Second if::::',0.5)
            return 0.5
        } else {
            // return (firstValue - nextValue) / 2
            // return ((firstValue) - (-nextValue)) / 2
            // console.log('third if::::',(-firstValue)/2)
            return (-firstValue)/2

        }
    };

    const apexData = () => {
        return [{
            name:graphics().map((item) => item.title)[0],
            data:data.map((item,idx) => {
                if(item.value === 0) {
                    return {x:(item.date * 100) + barShift() ,y:null}
                }else {
                    return{x:(item.date * 100) + barShift(), y:(item.value)}
                }
                // if(item.value !== 0) {
                //     return{x:(item.date * 100) + barShift(), y:(item.value)}
                //
                // }
            }),
            // data:[{x: -60 + barShift(), y: 3.8461538461538463},
            //     {x: -39 + barShift(), y: 5.769230769230769},
            //     {x: -18 + barShift(), y: 67.3076923076923},
            //     {x: 3 + barShift(), y: 13.461538461538462},
            //     {x: 24 + barShift(), y: 5.769230769230769},
            //     {x: 45 + barShift(), y: 1.9230769230769231},
            //     {x: 65 + barShift(), y: null},
            //     {x: 379 + barShift(), y: 1.9230769230769231}],
            type:'bar'
        }]
    };

    const plotOptions = {
        bar:
            {
                horizontal:false,
                columnWidth: '100%',
                strokeWidth: 2,
            }
    };

    const stroke = {
        show:true,
        width:2
    };
    const yAxisTitle = 'frequency';
    // console.log(apexData())

    return <Stack style={{width: '100%', marginTop: '86px'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={'DISTRIBUTION MONTHLY RETURNS PORTFOLIO'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
            // advancedButton={<MainOrangeButton onClick={() => {}} title={'Advanced'}/>}
        />

        {apexData().length !== 0 && <ApexRangeBar
            width={'100%'}
            height={276}
            graphics={graphics()}
            // series={seriesApex}
            series={apexData()}
            // categories={xAxisCategorie}
            plotOptions={plotOptions}
            stroke={stroke}
            yTitle={yAxisTitle}
            barShift={barShift()}
            type={'bar'}
        />}
    </Stack>
}

export default DistributionMonthlyReturn;
