import {ID} from 'models/common';

export interface CoinInfo {
  name: string;
  sign: string;
  link: string;
  icon: any;
}

export interface Exchange {
  id: ID;
  name: string;
  url: string;
}

const ExchangeEmptyItem: Readonly<Exchange> = {
  id: '',
  name: '',
  url: ''
};

export interface Asset {
  id: number;
  name: string;
  symbol: string;
  info?: string;
  class: string;
  base_symbol: string;
}

export const ASSET_DUMMY_ID = -1;
export const AssetEmptyItem: Asset = {
  id: ASSET_DUMMY_ID,
  name: '',
  symbol: '*',
  base_symbol:'*',

} as Asset;

export interface TradedValues {
  volume: number;
  price: number;
  trend: number;
  volatility: number;
  liquidity: number;
  bubble: number;
}

const TradedValuesEmptyItem: Readonly<TradedValues> = {
  volume: 0.0,
  price: 0.0,
  trend: 0,
  volatility: 0,
  liquidity: 0,
  bubble: 0
}

export interface MarketCapData {
  rank: number;
  market_cap: number;
  volume: number;
  price: number;
  pct_change?: number;
  trend_change?:number;
}

export interface Traded {
  id: ID;
  base: Asset;
  target: Asset;
  name?: string;
  title?: string;
  symbol?: string;
  exchange_id: ID;
  exchange: Exchange,
  values: TradedValues;
  values_old?: TradedValues;
  H24: MarketCapData;
  WEEK: MarketCapData;
  rank: MarketCapData;
  kind: 'FOREX' | 'CRYPTO' | 'INDEX' | 'COMMODITY' | 'XXX'
  _inWatchList?: boolean;
}

export const TradedEmptyItem = {
  id: '',
  exchange: ExchangeEmptyItem,
  base: AssetEmptyItem,
  target: AssetEmptyItem,
  values: TradedValuesEmptyItem,
  values_old: TradedValuesEmptyItem
} as Traded;


const pct_change_calculate = (time_range: string, item: Traded) => {
    const current_price = Number(item.values.price || 0);
    if (item[time_range] && item[time_range].price !== 0) {
      const old_price = Number(item[time_range].price);
      // return (current_price )/ old_price - 1;
      return (current_price - old_price)/ old_price * 100;
    }
}

const trend_change_calculate = (time_range: string, item: Traded) => {
  const current_trend = item.values.price || 0 ;
  if (item[time_range] && item[time_range].price !== 0) {
    const old_trend = Number(item[time_range].price);
    return ((current_trend / old_trend) - 1) * 100;
  }
}


export const unpackValues = (items: Traded[]): Traded[] =>
  items.map(item => {
    const itemCopy = { ...item,
      base: {...item.base, name: item.base.name.replaceAll(' ', '')},
      title: item.name,
      price: item.values ? Number(item.values.price) : 0,
      volume: item.values ? Number(item.values.volume) : 0,
      trend: item.values ? Number(item.values.trend) : 0,
      volatility: item.values ? Number(item.values.volatility) : 0,
      liquidity: item.values ? Number(item.values.liquidity) : 0,
      bubble: item.values ? Number(item.values.bubble) : 0,
      // H24:  {...item.H24, rank: item.H24.rank}
    };
    ['H24', 'WEEK'].forEach(range_name => {
      if (item[range_name]) {
        itemCopy[range_name]  = {
          ...item[range_name],
          pct_change: pct_change_calculate(range_name, item),
          trend_change: trend_change_calculate(range_name, item),
          rank: item.H24.rank
        }
      }
    })
    if (!itemCopy.values) {
      itemCopy.values = {price: 0, volume: 0, bubble: 0, liquidity: 0, trend: 0, volatility: 0};
      itemCopy.price = 0;
      itemCopy.volume = 0;
    }
    // itemCopy.price = Number(itemCopy.values.price);
    // itemCopy.volume = Number(itemCopy.values.volume);
    return itemCopy;
  });

export const sortTradedBy = (items: Traded[], fieldName: string): Traded[] => {

  const compareTraded = (a: Traded, b: Traded) => {
    if([ 'volume', 'price' ].includes(fieldName)) {
      return b.values[fieldName] === a.values[fieldName] ? 0
        : b.values[fieldName] > a.values[fieldName] ? 1
          : -1
    }
    if (['exchange'].includes(fieldName)) {
        return b.exchange.name === a.exchange.name ? 0
        : b.exchange.name > a.exchange.name ? 1
          : -1
    }
    if (['asset', 'symbol'].includes(fieldName)) {
        return b.base[fieldName] === a.base[fieldName] ? 0
        : b.base[fieldName] > a.base[fieldName] ? 1
          : -1
    }

    else return 0;
  }

  const sortedItems = items.sort((a, b) => compareTraded(a, b));
  return sortedItems;
};
