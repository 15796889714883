import { AnyAction } from "redux";

export const isPendingAction = (action: AnyAction) => action.type.endsWith('pending');
export const isRejectedAction = (action: AnyAction) => action.type.endsWith('rejected');
export const isSucceededAction = (action: AnyAction) => action.type.endsWith('succeeded');

export const  getCurrentState = state => {
    try {
      return JSON.parse(JSON.stringify(state));
    } catch (e) {
      return null;
    }
}
