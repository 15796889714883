import React, { useEffect } from "react";

import {TradingView} from 'charting_library/charting_library.standalone.js';
import Datafeed from "components/trading-view/my-datafeed";
import {buttonBackgroundColor, mainBackgroundColor, mainTextColor} from "../../../config";
import '../../../nb-app.css';

interface TrandingViweProps {
  renderData: any;
  locationPath:string;
}




const TradingV = (props: TrandingViweProps) => {
    const locationAdvanced = props.locationPath.includes('advancedMarkets');

  useEffect(() => {

      // @ts-ignore
     const widget = window.tvWidget = new TradingView.widget({
          container: "xxxx",
          chartOnly:true,
          width: 1200,
          height: 500,
          symbol: `Binance:${props.renderData}`,
          // symbol: `Binance:ETH/USDT`,
          // compare_symbol:[
          //     { symbol: "Bitfinex:BTC/USD"},
          //     { symbol: "Bitfinex:TRX/ETH"},
          // ],
          interval: "1D",
          datafeed:Datafeed,
          // datafeed: Datafeed.getBars(props.renderData),
          timezone: "UTC",
          library_path: "/charting_library/",
          theme: "dark",
          toolbar_bg:"transparent",
          show_volume:false,
          show_symbol_logo: true,
          locale: "en",
          load_last_chart: true,
          disabled_features:[

          ],
          enabled_features: [

          ],
          custom_font_family:'Noto Sans',
          settings_overrides: {
                "paneProperties.background": mainBackgroundColor,
                "paneProperties.backgroundType": "solid",
                "priceScaleSelectionStrategyName": "left",
                'paneProperties.horzGridProperties.color': '#AEA49B',
                'paneProperties.legendProperties.showSeriesOHLC':false,
                'paneProperties.horzGridProperties.style': 1,
                "paneProperties.separatorColor": '#AEA49B',
                'paneProperties.topMargin': 15,
                'paneProperties.bottomMargin': 15,
                'scalesProperties.showPriceScaleCrosshairLabel':true,
                'scalesProperties.showStudyPlotLabels': true,
                'scalesProperties.textColor': '#AEA49B',
                'scalesProperties.lineColor': '#AEA49B',
                'mainSeriesProperties.showPriceLine': false,
                'mainSeriesProperties.style':2,
                'mainSeriesProperties.priceLineColor' : buttonBackgroundColor,
                'mainSeriesProperties.lineStyle.color' :buttonBackgroundColor,
                 "mainSeriesProperties.visible": false,
          },
          overrides: {

          },
          time_frames:[
              { text: "1W", resolution: "W", description: "1 Week",title:'1 Week' },
              { text: "3M", resolution: "D", description: "3 month", title: '3 Month'},
              { text: "1Y", resolution: "W", description: "12 month", title: '1 Year' },
          ],
     });


  },[]);
  return <div style={{height:'100%',width:'100%'}} id="xxxx"/>
};

export default TradingV;
