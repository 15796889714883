import React, { useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { ID } from 'models/common';
import { Traded, TradedEmptyItem } from 'models/asset';
import AssetsInfo, { AssetSections } from './assets-info';
import AssetsTable from './asset-table';
import { TabItem } from 'components/tab-component';
import {sort} from "d3";

interface CandlesViewProps {
  size?: number;
  traded: Traded[] | null;
  currentSectionId: string;
  selectFunction: Function;
  currentTraded: Traded | null;
  onSectionChange: (item: TabItem) => void;
  sortByFunction: (field: string, obj) => void;
  currentSortField: string;
  toggleWatchListItem: Function;
  sortHolder: Record<string, boolean>;
}

const DEFAULT_ASSETS_COLUMN_NUMBER = 32;

const AssetsView = (props: CandlesViewProps) => {
  const numberOfRows = props.size ? props.size : DEFAULT_ASSETS_COLUMN_NUMBER;
  const traded = props.traded ? props.traded : [];
  const currentTraded = props.currentTraded ? props.currentTraded : TradedEmptyItem;
  const selectFunction = props.selectFunction;
  const sortByFunction = props.sortByFunction;
  const currentSortField = props.currentSortField;
  const currentSectionId = props.currentSectionId;
  const toggleWatchListItem = props.toggleWatchListItem;
  const sortHolder = props.sortHolder;
  const [currentFilter, setCurrentFilter] = useState('');
  const [currentTimeRange, setCurrentTimeRange] = useState('24H');
  // const sectionRef = useRef('crypto');
  const sectionRef = useRef(currentSectionId);
  const filterByWatchList = (item: Traded): boolean => !!item?._inWatchList;

  const notFiltered = (item: Traded): boolean => true;

  const filterAssets = () => currentFilter === 'watchlist' ? filterByWatchList : notFiltered;

  // console.log('################## currentSortField::[' + currentSortField + '] currentSectionId::[' + currentSectionId + ']');

  // console.log('#################### traded::', traded)

  useEffect(() => {
    setCurrentFilter(currentSectionId);
  }, [currentSectionId]);

  const selectFilter = (data) => {
    sectionRef.current = data.id;
    props.onSectionChange(data);
  }

  const sortByFunctionActual = (field_name: string, sorter) =>  {
    return sortByFunction(`${sectionRef.current}:${field_name}`, sorter)}

  // console.log(sectionRef.current)

  const updateTimeRange = (item: TabItem) => {
    setCurrentTimeRange(item.id!)
  }


  // console.log('Traded', traded)
  return <Stack sx={{width: '100%'}} direction="column" alignItems="top">
    <AssetsInfo
                // currentSectionId={currentSectionId}
                currentSectionId={sectionRef.current}
                onSectionChange={selectFilter}
                onTimeRangeChange={updateTimeRange}
                traded={currentTraded}
    />
    <AssetsTable sortHolder={sortHolder}
                 numberOfRows={numberOfRows}
                 traded={traded}
                 currentSectionId={sectionRef.current}
                 currentTimeRange={currentTimeRange}
                 dataFilter={filterAssets()}
                 toggleWatchList={toggleWatchListItem}
                 sortByFunction={sortByFunctionActual}
                 currentSortField={currentSortField}
                 selectFunction={selectFunction}
                 switchCSS={'assetTable'}
    />
  </Stack>
};

export default AssetsView;
