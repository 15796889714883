import { PortfolioInputType } from './portfolio.api';
import { Asset } from 'models/asset';
import { AssetVolume, Portfolio, PortfolioIndicatorsEmptyItem, PortfolioIndicators } from '../../models/portfolio';
import { $$ } from 'utils/utils';

import xx from 'layout/MainLayout/Header/HeaderContent/Message'
import zzz from 'layout/MainLayout/Header/HeaderContent/index'
import { dateSerializerNew } from '../../components/controls/form/nb-data-picker';
import {ROLLSHARPE, ROLLSORTINO} from "../../data/graphics";

export const COMPONENT_KEY_NAME = 'portfolios';

export const equityOverviewGraphSelector = {
  'log-scaled': 0,
  // 'volatility-matched': 1
};
export const equityOverviewGraphTimeranges = ['log-scaled' ];

export const equitySimulationGraphSelector = {
  'log-scaled': 0,
  // 'volatility-matched': 1
};
export const equitySimulationGraphTimeranges = ['log-scaled' ];

export const keyMetricsGraphSelector = {'day':0};
export const keyMetricsGraphTimeranges = ['day'];

export const performanceMetricsGraphSelector = {'day':0};
export const performanceMetricsGraphTimeranges = ['day'];

export const keyMetricsSimulationGraphSelector = {'day':0};
export const keyMetricsSimulationTimeranges = ['day'];

export const simulationAllocationGraphSelector = {'day':0};
export const simulationAllocationTimeranges = ['day'];

export const worstDrawDownsGraphSelector = {'day':0};
export const worstDrawDownsTimeranges = ['day'];
export const annualReturnsOverviewGraphSelector = {'day':0};
export const annualReturnsOverviewTimeranges = ['day'];
export const annualReturnsSimulationGraphSelector = {'day':0};
export const annualReturnsSimulationTimeranges = ['day'];
export const dailyReturnsOverviewGraphSelector = {'day':0};
export const dailyReturnsOverviewTimeranges = ['day'];
export const underwaterPlotGraphSelector = {'day': 0};
export const underwaterPlotTimeranges = ['day'];
export const rollingVolatilityGraphSelector = {'day': 0};
export const rollingVolatilityTimeranges = ['day'];

export const monthlyReturnsOverviewGraphSelector = {'day': 0};
export const monthlyReturnsTimeranges = ['day'];
export const drawdownPeriodsGraphSelector = {'day': 0};
export const drawdownPeriodsTimeranges = ['day'];
export const rollingSharpeGraphSelector = {
  [`${ROLLSHARPE}:6mWindow`]: 0,
  [`${ROLLSHARPE}:2yWindow`]: 1,
};
export const rollingSharpeGraphTimeranges = ['6mWindow', '2yWindow'];

export const rollingSortinoGraphSelector = {
  [`${ROLLSORTINO}:6mWindow`]: 0,
  [`${ROLLSORTINO}:2yWindow`]: 1,
};
export const rollingSortinoGraphTimeranges = ['6mWindow', '2yWindow'];
export const distributionMonthlyGraphSelector = {'day': 0};
export const distributionMonthlyTimeranges = ['day'];


export type ActionModeType = 'portfolioList'
  | 'newPortfolio'
  | 'editPortfolio'
  | 'extraviewPortfolio'
  | 'simulationPortfolio'
  | 'expandPortfolio'
  | 'savePortfolio'
  | 'expandAssets'
  | 'extendPortfolio'
  | 'optimizationPortfolio'
  | 'clonePortfolio'
  |'analysisPortfolio';

export const ActionMode = {
  portfolioList:    'portfolioList'   as ActionModeType,
  newPortfolio:     'newPortfolio'    as ActionModeType,
  editPortfolio:    'editPortfolio'   as ActionModeType,
  extraviewPortfolio:   'extraviewPortfolio'  as ActionModeType,
  simulationPortfolio:  'simulationPortfolio' as ActionModeType,
  expandPortfolio:  'expandPortfolio' as ActionModeType,
  clonePortfolio:   'clonePortfolio'  as ActionModeType,
  expandAssets:     'expandAssets'    as ActionModeType,
  savePortfolio:    'savePortfolio'   as ActionModeType,
  extendPortfolio: 'extendPortfolio'  as ActionModeType,
  optimizationPortfolio: 'optimizationPortfolio' as ActionModeType,
  analysisPortfolio : 'analysisPortfolio'as ActionModeType,
}

const modeToRouteSuffix = {
  newPortfolio:     'add' ,
  editPortfolio:    'edit' ,
  // extraviewPortfolio:   'extraview',
  extraviewPortfolio:   'extraviewPortfolio',
  simulationPortfolio:  'simulation',
  optimizationPortfolio: 'optimization',
  expandPortfolio:  'overview' ,
  extendPortfolio: 'details',
}

export const getRouteSuffix = (action: ActionModeType, portfolio: Portfolio) => {
  if (action === 'newPortfolio') {
    return modeToRouteSuffix[action];
  }
  if (!!portfolio) {
    const suffix =  modeToRouteSuffix[action];
    // return suffix ? `${suffix}/${portfolio.id}` : undefined;
    return suffix && `${suffix}/${portfolio.id}`;
  }
  return undefined;
}

export type ActionDirectionType = 'left'| 'right';
export const ActionDirection = {
  left: 'left' as ActionDirectionType,
  right: 'right' as ActionDirectionType,
}


export type ExtraParameterType = 'updated' | 'viewed';
export const ExtraParameter = {
  updated: 'updated' as ExtraParameterType,
  viewed:  'viewed' as ExtraParameterType,
}


export const expandPortfolio = (data: PortfolioInputType, allAssets: Asset[]): Portfolio => {
    const assets = [] as AssetVolume[];
    const instruments = data.instruments || data.assets;  // 'assets' will be renamed as 'instruments' soon

  if (instruments) {
      instruments.forEach(ast => {
        const asset = allAssets.find(it => it.id === ast.instrumentId);
        assets.push({ ...asset, value: ast.instrumentWeight } as AssetVolume)
      });
    }


    return {
      tasks: [],
      ...data['portfolio'],
       assets,
      _assetCount: assets.length,
      _dateStart: dateSerializerNew($$.getOneYearAgo()),
      _dateEnd: dateSerializerNew($$.getYesterday()),
      _currentTaskSeqn: -1,
      indicators: PortfolioIndicatorsEmptyItem,
      benchmark:data.benchmark,
      // benchmark: benchmark,
      // indicators: data.indicators,
      type: '',
    } as Portfolio;
}

export const expandPortfolios = (input: PortfolioInputType[], allAssets: Asset[]): Portfolio [] => {
  return input ? input.map(it => expandPortfolio(it, allAssets)) : [];
}

// export const initialEmptyAssetName = () => 'please select...';
export const initialEmptyAssetName = () => '';
