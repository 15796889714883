import React, {useRef, useState} from 'react';
import { Stack } from '@mui/material';
import WidgetInfoPanel from 'components/controls/info-panel';
import {TabItem} from 'components/tab-component';
import {
    getTabsGraphsInfo,
    getDefaultGraphId, CLIENT_PORTFOLIO_INITIAL,
} from "../../../data/graphics";
import TrandingViewThreeLine from "./tv-threeline";
import {rollingVolatilityGraphics} from "../portfolio.slice";
import {RESOLUTION_SCREEN} from "../../../config";
import {as_timestamp} from "../../../utils/date-utils";


interface RollingVolatilityProps {
    renderData:any;
    legendName:string;
    usStockLegend:string|undefined;
    panelId:string;
}

const RollingVolatility = (props: RollingVolatilityProps) => {
    const renderData = rollingVolatilityGraphics.getCurrentGraphic();
    const legendName = props.legendName|| '';
    const panelId = props.panelId;
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = CLIENT_PORTFOLIO_INITIAL;
    const defaultData = {value:[{time:Date.now()}]};
    const actualData = {};
    renderData.forEach((item,idx) => {
       Object.entries(item).forEach(([key],idx)=> {
           if(item[key].result !== undefined){
               const transValue = item[key].result.map((el) => {
                   return {time: as_timestamp(el.date)/1000, value: el.value}
               });
               if(!actualData[key]){
                   actualData[key] = [...transValue];
               };
           };
       });
    });
    const currentData = Object.keys(actualData).length !== 0 ? actualData : defaultData;

    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    }

    const graphSelectPanelOnChange = (item: TabItem) => {
        setCurrentGraphicId(item.id!);
    };

    const timeRangeSelectPanelOnChange = (item: TabItem) => {
        console.log("############ timeRangeSelectPanelOnChange TITLE::", item);
    };


    const priceFormatter = (price) => {return (price * 100).toFixed(0) + '%'};
    const tooltipFormatter = (price) => {return (price * 100).toFixed(2) + '%'};
    const resolutionGraphWidth = RESOLUTION_SCREEN === 'horizontal' ? 698 : 400;

    return <Stack style={{width: '100%'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={'ROLLING VOLATILITY (6 MONTHS)'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
        />
        {Object.keys(actualData).length !== 0 && <TrandingViewThreeLine
            renderData={actualData}
            // renderData={currentData}
            graphics={graphics()}
            // width={resolutionGraphWidth}
            height={200}
            timeRange={'year'}
            priceFormatter={priceFormatter}
            tooltipFormatter={tooltipFormatter}
            classId={'threeOneDashed'}
            portfolioName={legendName}
            benchmark={props.usStockLegend}
        />
        }
    </Stack>
};

export default RollingVolatility;
