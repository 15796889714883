import * as React from 'react';
import { WsConnector } from '../ws-connector';
import { WS_MARKET_URL } from 'config';

export const wsConnectorMarket = new WsConnector('');
export const wsConnectorResearch = new WsConnector('');
export const wsConnectorDataprocessor = new WsConnector('');
export const wsConnectorDistGateway = new WsConnector('');

export function WebSockerWrapper({ children }) {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {  });
    }
    return child;
  });
  return <>{childrenWithProps}</>
}
