import { Box, Stack } from '@mui/material';
import React, {ReactNode, useEffect, useRef} from 'react';
import useState from 'react-usestateref'
import { ItemSorter, TableFieldDescSortType } from 'components/util-components/sorter';
import _ from 'lodash';
import './tablescss.css';
import  {QuestionCircleOutlined,
    CaretDownOutlined,EditOutlined,
    CaretUpOutlined, CloseCircleOutlined,
    DownOutlined,StarOutlined,
    MenuFoldOutlined, StarFilled,
    MenuUnfoldOutlined, SearchOutlined,
    UpOutlined,DeleteOutlined,
    PieChartOutlined, LineChartOutlined,
    RedoOutlined
} from '@ant-design/icons';

import {
  buttonBackgroundColor,
  mainBackgroundColor,
  mainTextColor
} from 'config';
import { MyTextField } from './form/textfield';
import { RefHoster } from './form/ref-hoster';
import { $$ } from '../../utils/utils';
import {ActionDirection, ActionDirectionType} from "../../pages/portfolios/portfolio.common";
import ConfirmDialog from "../dialogs/dialog";
import {explanationMarkets} from "../../pages/dashboard/monitor/monitor.common";

export type ActionType = 'EXPAND' | 'EXTEND' | 'EDIT' | 'STAR'|'EXTRAVIEW';

export interface TableFieldDesc {
  name: string,
  title?: string,
  hint?: string,
  width: string,
  sorted?: TableFieldDescSortType;
  render?: Function;
}

interface JustTableProps<ITEM> {
    settings?: {},  // tableTitle: true/false
    name: string,
    data: ITEM[],
    fields: TableFieldDesc[],
    dataFilter?: Function,
    itemSorter?: Function,
    searchFields?: string[],
    actions?: any,
    selectFunction?: Function;
    onSelect?: Function;
    onRefresh?: Function;     // not used for now
    visibleRowsNumber?: number;
    width?: string;
    expandedRowRender?: Function;
    emptyTable?: Function;
    switchCSS: string;
    currentSortField?: string;
}

const DEFAULT_VISIBLE_ROWS_NUMBER = 26;

const itemSorters = {};

const getItemSorter = name => {
  if (!itemSorters[name]) {
    itemSorters[name] = new ItemSorter();
  }
  return itemSorters[name];
}

export default function JustTable<ITEM>(props: JustTableProps<ITEM>) {
  const visibleRowsNumber = props.visibleRowsNumber || DEFAULT_VISIBLE_ROWS_NUMBER;
  const width = props.width || '100%';
  const fields = props.fields;
  const settings = props.settings || { tableTitle: true };
  const numberOfColumns = fields.length;
  const [rows, setRows] = useState(props.data);
  const [changed, setChanged] = useState(0);
  const [lastClicked, setLastClicked] = useState(-1);
  const [onOff, setOnOff] = useState({'EDIT': -1, 'EXPAND': -1, 'EXTEND': -1, 'EXTRAVIEW': -1});
  const [expandedIdx, setExpandedIdx] = useState(-1);
  const [discription,setDiscription] = useState(false);
  const [discriptionName, setDiscriptionName] = useState('');
  const [discriptionTitle, setDiscriptionTitle] = useState('');
  const itemSorterExternal = props.itemSorter;
  const itemSorter = getItemSorter(props.name);
  const extraActions = props.actions || [];
  const searchFields = props.searchFields || [];
  const [searchText, setSearchText] = useState('');
  const onSelect = props.onSelect || function (item: ITEM, index: number) {};
  const actualFilter = props.dataFilter;
  const currentSortField = props.currentSortField;
  const valuesRefs = new RefHoster();
  const sortRef = useRef('');

  const dataForRender = () => itemSorter.sortByField(applyFilters(searchText !== ''
    ? filterByText(rows, searchText, searchFields)
    : rows), sortRef.current);

  useEffect(() => {
      setRows(props.data);
  }, [props.data]);


  const filterByText = (rows: any, text: string, fieldNames: string[]) => {
    var regex = new RegExp( text, 'gi');
    return rows.filter(row => {
      for (let fieldName of fieldNames) {
        const val = '' + $$.getDottedValue(fieldName, row);
        if (val.match(regex)) {
          return true;
        }
      }
      return false;
    });
  };

  const closeSearch = () => setSearchText('');
  const searchTextUpdated = (event: any) => {
    const text = event.target.value as string;
    if (event.code === 'Enter') {
      setSearchText(text);
    }
  }

  const setActualRows = rows => {
      setRows(rows);
      setChanged(changed + 1);
  }

  const killPropagation = e => {
    e.stopPropagation();
    e.preventDefault();
  }

  const applySort = (fieldName: string, sorter) => {
    const sortedRows = itemSorter.sortByField(dataForRender(), fieldName, sorter);
    // const sortedRows = fieldName === 'values.rank' ?   itemSorter.sortByFieldImpl(dataForRender(), fieldName,false, sorter): itemSorter.sortByField(dataForRender(), fieldName, sorter);
      console.log('NAME', fieldName)
    setActualRows(sortedRows);
  }
  const sortByField = (e, fieldName: string, sorter?: TableFieldDescSortType) => {
      killPropagation(e);
      if (!itemSorterExternal) {
        sortRef.current = fieldName;
        applySort(fieldName, sorter);
      }
      else {
        itemSorterExternal(fieldName, sorter);
      }
  }


  const applyFilters = (actualRows: ITEM[]) => actualRows.filter(it => actualFilter && $$.isFunction(actualFilter) ? actualFilter(it): true);

  const isAppliedSorter = (fieldName: string) => itemSorter.isLastApplied(fieldName);

  const getTitle = (desc: TableFieldDesc) => {
    const value = desc.title !== undefined ? desc.title : _.capitalize(desc.name);
    const hint = desc.hint;
    const styleA = { backgroundColor: mainBackgroundColor};
      // console.log(rows.map((item,idx) => item.kind))
      // @ts-ignore
      const fieldKind = rows.map((item,idx) => item.find);

    if (desc.sorted && desc.sorted !== 'OFF') {
      const fieldName = desc.name;
      const color = isAppliedSorter(fieldName) ? mainTextColor : mainTextColor;
      const styleB = { backgroundColor: mainBackgroundColor,color: mainTextColor, fontSize: '10px',cursor:'pointer'};

      const onClick = e => sortByField(e, fieldName, desc.sorted);

      // const resetSort = (e) =>  sortByField(e,fieldKind[1] === 'CRYPTO' ? 'values.rank' : 'rate', "NUMBER");
      const resetSort = (e) =>  sortByField(e,'values.rank', "NUMBER");
      const showDiscription = (name) => {
          setDiscription(true);
          setDiscriptionName(name);
          const title = explanationMarkets.find((item) => item.fieldName === name);
          // console.log(title)
          setDiscriptionTitle(title ? title.title : '');
      };


      return desc.sorted === 'columnName' ?
          <span title={hint} className={'preventSelect'} style={{...styleB, color }} onClick={onClick}>{value}</span>
        : itemSorter.isSortedBy(fieldName)
          ? <Stack title={hint}  className={'preventSelect'} direction={'row'} alignItems={'center'} spacing={1} >
                <Box style={{...styleA,color}}>{value}</Box>
                <Box alignItems={'center'} justifyContent={'center'}></Box>
            </Stack>
          : <Stack title={hint} style={{...styleA, color}} className={'preventSelect'} direction={'row'} alignItems={'center'} spacing={'4px'} >
                  {fieldName === 'base.name' && <Box><RedoOutlined style={{color:mainTextColor,paddingTop:'8px', marginRight:'4px'}} onClick={resetSort}/></Box>}
                <Box>{value}</Box>
                  {fieldName === 'values.trend' && <Box><QuestionCircleOutlined className={'hintBtn'} style={{paddingTop:'8px'}} onClick={() => showDiscription(fieldName)}/></Box>}
                  {fieldName === 'values.bubble' && <Box><QuestionCircleOutlined className={'hintBtn'} style={{paddingTop:'8px'}} onClick={() => showDiscription(fieldName)}/></Box>}
                  {fieldName === 'values.volatility' && <Box><QuestionCircleOutlined className={'hintBtn'} style={{paddingTop:'8px'}} onClick={() => showDiscription(fieldName)}/></Box>}
                  {fieldName === 'values.liquidity' && <Box><QuestionCircleOutlined className={'hintBtn'} style={{paddingTop:'8px'}} onClick={() => showDiscription(fieldName)}/></Box>}
                <Box alignItems={'center'} justifyContent={'center'} onClick={onClick} >
                    {currentSortField === `${fieldName}`+ ':' + 'DOWN'?
                      <CaretUpOutlined className={'tableArrowUp'} style={{...styleB, color: color}} />
                      : <CaretDownOutlined className={'tableArrowDown'} style={{...styleB, color:color}} />
                    }
                </Box>
          </Stack>;
    } else {
      return <span title={hint} style={styleA} className={'preventSelect'}>{value}</span>;
    }
  }

  const actionIcons = {
    'EDIT':   [EditOutlined, EditOutlined],
    'STAR':   [StarFilled, StarOutlined],
    'EXTEND': [MenuFoldOutlined, MenuUnfoldOutlined],
    'EXPAND': [UpOutlined,DownOutlined],
    // 'DETAIL': [DownOutlined, UpOutlined],
    'REMOVE' : [DeleteOutlined, DeleteOutlined],
    'EXTRAVIEW': [LineChartOutlined,LineChartOutlined],
    'SIMULATION': [PieChartOutlined,PieChartOutlined],
  }

  const extraActionsRender = (field: any, row: ITEM, rowIndex: number, posit: string) => {
    const line = extraActions.map(it => {
        const actionPosit = it.posit || 'after';
        if (actionPosit !== posit) {
          return null;
        }
        const hint = it.hint;
        const actionName = it.action as ActionType;
        const currentSelected = onOff[actionName] === rowIndex;
        const onClick = () => {
          setOnOff({...onOff, [actionName]: currentSelected ? -1 : rowIndex});
          if (actionName === 'EXPAND') {
             setExpandedIdx(currentSelected ? -1 : rowIndex);
          }
          setLastClicked(rowIndex);
          it.onClick(row, rowIndex);
        };
        const marginHint = it.posit === 'before' ? '0px' : '8px';
        const style = currentSelected ? {color: buttonBackgroundColor} : {};
        // console.log(currentSelected)
        const iconIndex = onOff[actionName] === rowIndex ? 0 : 1;
        const IconImage = actionIcons[actionName][iconIndex];
        if (it.render) {
          return it.render(field, row, rowIndex, actionIcons[actionName], iconIndex, onClick);
        }
        return <span title={hint} key={actionName}  style={{marginLeft:marginHint}} onClick={onClick}><IconImage  className={'clickable hintBtn'} />&nbsp;</span>;
    });
    return <>{line ? line : ''}</>;
  }

  const extraActionsRenderAfter = (field: any, row: ITEM, rowIndex: number) => extraActionsRender(field, row, rowIndex, 'after')
  const extraActionsRenderBefore = (field: any, row: ITEM, rowIndex: number) => extraActionsRender(field, row, rowIndex, 'before')

  const verticalTabSeparator = {
    height: '100%',
    width: '1px',
    backgroundColor: '#AEA49B',
    padding: 0,
  };

  const renderField = (desc: TableFieldDesc, field: any, row: ITEM, rowIndex: number, colIndex: number, renderer?: Function, numberOfRows?: number) => {
    const hint = desc.hint;
    const actualNumberOfRows = numberOfRows || 0;
    let style = {};
    if (colIndex === 0 && fields[0].name === '#')  {
      style = rowIndex === lastClicked
        ? { color: 'orange', textAlign: 'right', fontWeight: 'bold' }
        : { textAlign: 'right'};
    }

    if (actualNumberOfRows > 0 && desc.name === 'SEPARATOR_VERTICAL') {
      if (rowIndex === 0) {
        return <td width={1} style={verticalTabSeparator} rowSpan={actualNumberOfRows}>&nbsp;</td>;
      }
      return null;
    }
    const content = renderer ? renderer(field, row, rowIndex) : <span title={hint} style={style}>{field}</span>;
    return <td onClick={() => onSelect(row, rowIndex)} key={'td' + desc.name + colIndex}>
      {content}
      </td>;
  }

  const markStyle = (index: number): any => {
    if (index === 0) {
      return { color: 'orange', textAlign: 'right', fontWeight: 'bold' };
    }
    return { textAlign: 'right'};
  }

  const isFirstColumn = columnIndex => columnIndex === 0;
  const isLastColumn = columnIndex => columnIndex === numberOfColumns - 1;

  const DataRow = params => {
    const row = params.item as ITEM;
    const rowIndex = params.index;
    const numberOfRows = params.numberOfRows;

    const result = fields.map((desc: TableFieldDesc, idx) => {
      const value = desc.name === '#' ? params.index + 1 : $$.getDottedValue(desc.name, row);
      const render = desc.render ? desc.render
        : isLastColumn(idx)  && extraActions.length > 0 ? extraActionsRenderAfter
        : isFirstColumn(idx) && extraActions.length > 0 ? extraActionsRenderBefore
        : undefined;
      return renderField(desc, value, row, rowIndex, idx, render, numberOfRows);
    });

    return <tr key={'tr' + rowIndex}>{result}</tr>;
  }

  const searchControl = () => {
    return <Stack style={{paddingLeft: '0px'}} direction={'row'}>
      <SearchOutlined style={{
        fontSize: '120%',
        width: '40px',
        backgroundColor: mainBackgroundColor,
        paddingLeft: '0px',
        paddingTop: '10px',
        paddingRight: '10px',
      }} />
      <MyTextField placeholder={'Search and add assets to your watchlist'}
                   variant={'filled'}
                   defaultValue={searchText}
                   inputRef={el => valuesRefs.initRef('searchFieldId', el)}
                   onKeyDown={searchTextUpdated}
                   style={{ width: '100%' }}/>
      <span style={{backgroundColor: mainBackgroundColor}} onClick={closeSearch}><CloseCircleOutlined style={{
        fontSize: '120%',
        width: '40px',
        backgroundColor: mainBackgroundColor,
        paddingTop: '10px',
        paddingLeft: '10px',
      }} /></span>
    </Stack>;
  }

  const headerElementStyle = { paddingLeft: '5px', paddingRight: '5px', fontWeight: 'normal' };

  const TableTdSizer = params => <tr>{fields.map((desc: TableFieldDesc, idx) =>
      <th style={{...headerElementStyle, height: 0, width: desc.width}} key={'szth' + idx}></th>)}</tr>;

  const TableHeader = params => {
    const header = fields.map((desc: TableFieldDesc, idx) =>
      <th onClick={killPropagation}  key={'th' + idx}>
        {getTitle(desc)}
      </th>);

    const search = searchFields.length > 0
      ? <tr><td style={{...headerElementStyle, backgroundColor: mainBackgroundColor }}
                colSpan={numberOfColumns}>{searchControl()}</td></tr>
      : null;

    return <thead>
        <TableTdSizer/>
        {/* it works - bit not required for now*/}
        {/*{search}*/}
        {settings['tableTitle'] &&
        <tr key={'tableHeader'} style={{width: '100%', color: mainTextColor, whiteSpace: 'nowrap' }}>
          {header}
        </tr>
        }
    </thead>;
  }

  const expandedRow = (row: ITEM, index: number, numberOfRows: number) =>
    props.expandedRowRender
      ? props.expandedRowRender(row, index, numberOfColumns)
      : <tr><td colSpan={numberOfColumns} >&nbsp;</td></tr>;
  const getRow = (item: ITEM, index: number, numberOfRows: number) => {
    return <React.Fragment key={'frg' + index}>
      <DataRow item={item} key={'itm' + index} index={index} numberOfRows={numberOfRows}/>
      {expandedIdx === index && expandedRow(item, index, numberOfRows) }
    </React.Fragment>
  }

  interface TableCSSProps {
    classCss: string;
  }

  const TableCSS = (props: TableCSSProps) => {
    return(
        <table className={props.classCss} style={{tableLayout: 'fixed', width: width}}>
          <TableHeader/>
          <tbody>
          { _renderData.map((item: ITEM, idx: number) => getRow(item, idx, _numberOfRows)) }
          </tbody>
          <TableFooter/>
        </table>
    )
  }

  const TableFooter = () => <tfoot></tfoot>;
  const _renderData = dataForRender().slice(0, visibleRowsNumber);
  const _numberOfRows = _renderData.length;
  const noRecords = _renderData.length === 0;

  return <Box sx={{ display: 'flex', justifyContent: 'flex-start', color: 'blue'}}>
    {noRecords && props.emptyTable
      ? <div style={{width: width}}>{props.emptyTable()}</div>
      : <TableCSS classCss={props.switchCSS}/>
    }
      {discription && <ConfirmDialog title={discriptionTitle} handleAgree={() => {}}
                                     handleCancel={() => {setDiscription(false)}}
                                     advanced={true} discription={true}
      >
          {explanationMarkets.map((item,idx) => <React.Fragment key={idx +'description'}>
                    {item.fieldName === discriptionName && item.explanation}
                </React.Fragment>
          )}
      </ConfirmDialog>
      }
    </Box>
}
