import { createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_DATAPROCESSOR_URL, BASE_DJANGO_URL } from 'config';

import { getCachedValue, setCachedValue } from 'utils/local_storage';

import MyAxios from 'api';
import { COMPONENT_KEY_NAME } from '../portfolios/portfolio.common';
import axios from '../../api';
import { PortfolioInputType } from '../portfolios/portfolio.api';


// export type AssetInputType = {
//   id: ID,
//   assetId: number,
//   value: number
//   instrumentId: number;
//   instrumentWeight: number;
// }


const info = () => MyAxios.get(`${BASE_DATAPROCESSOR_URL}/api/info`)


export const startSyncActionApi = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/startSynkAction`,
  async (action: string) => {
    const response = await MyAxios.get(`${BASE_DATAPROCESSOR_URL}/start_task/${action}`);
    return {'action': action, 'data': response['data']};
  }
);

export const getBuildInfoApi = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/getInfo`,
  async () => {
    const response = await MyAxios.get(`${BASE_DATAPROCESSOR_URL}/info`);
    return response['data'];
  }
);

export const getDataStatisticsApi = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/get_data_statistics`,
  async () => {
    const response = await MyAxios.get(`${BASE_DATAPROCESSOR_URL}/start_task/data_statistics`);
    return response['data'];
  }
);

export const resetTasksStatLogApi = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/reset_tasks_stat_log`,
  async () => {
    const response = await MyAxios.get(`${BASE_DATAPROCESSOR_URL}/info/reset_task_logstat`);
    return response['data'];
  }
);
