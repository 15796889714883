import React from 'react';
import PortfolioComposition from "./portfolio-composition";
import {Stack,Box,Button,Grid} from '@mui/material';
import {buttonBackgroundColor, mainTextColor, primaryTextColor, RESOLUTION_SCREEN} from "../../config";
import {AssetVolume, AssetVolumeEmptyItem, Portfolio} from "../../models/portfolio";
import ButtonsNavigation from "../../components/portfolios/portfolio-buttons/portfolio-navigation-buttons";
import {ActionMode, initialEmptyAssetName} from "./portfolio.common";
import {MyNewAutocomplete} from "../../components/controls/form/autocomplete-new";
import DateRangeForm from "../../components/controls/form/date-range";
import useState from "react-usestateref";
import {RefHoster} from "../../components/controls/form/ref-hoster";
import {Asset, ASSET_DUMMY_ID} from "../../models/asset";
import {TabItem, TabsComponent} from "../../components/tab-component";
import OptimizedSimulatedAllocation from "./optimized-simulated-allocation";
import EfficientFrontier from "./portfolio-graphs/efficient-frontier";
import OptimizationConstraints, {ExtraOptInstruments} from "./portfolio-graphs/optimization-constraints";
import {initialEmptyAsset} from "../../components/portfolios/portfolio-update/portfolio-update";
import {getCurrentPortfolioTask} from "./helper";
import AnnualReturnsSimulation from "./portfolio-graphs/annual-returns-simulation";
import PerformanceMetrics from "./portfolio-graphs/performance-metrics";
import UnderwaterPlot from "./portfolio-graphs/underwater-plot";
import DailyReturnsPortfioio from "./portfolio-graphs/daily-returns-portfioio";
import WorstDrawdowns from "./portfolio-graphs/worst-drawdowns";
import RollingVolatility from "./portfolio-graphs/rolling-volatility";
import RollingBetaToBenchmark from "./portfolio-graphs/rolling-beta-benchmark";
import RollingSharpe from "./portfolio-graphs/rolling-sharpe";
import RollingSortino from "./portfolio-graphs/rolling-sortino";
import DrawdownPeriods from "./portfolio-graphs/drawdown-periods";
import MonthlyReturnsPortfolio from "./portfolio-graphs/monthly-returns-portfolio";
import ValueRiskExpectedShortfall from "./portfolio-graphs/value-risk-expected-shortfall";
import DistributionMonthlyReturn from "./portfolio-graphs/distribution-monthly-return";
import ReturnQuantiles from "./portfolio-graphs/return-quantiles";
import KeyMetrics from "./portfolio-graphs/key-metrics";
import EquityCurveView from "./portfolio-graphs/equity-curve";
import {processingFontResolution} from "./portfolio-view-overview";
import {
    ANNUAL_RETURNS_OPTIMIZATION_ID,
    EQUITY_CURVE_OPTIMIZATION_ID, ROLLING_VOLATILITY_OPTIMIZATION_ID,
    ROLLING_VOLATILITY_SIMULATION_ID
} from "../../data/graphics";
import {rollingSharpeOptimizeGraphics, rollingSortinoOptimizedGraphics} from "./portfolio.slice";
import {useNavigate} from "react-router-dom";
import { DateValidationError } from '@mui/x-date-pickers/models';

interface PortfolioOptimizationProps {
    portfolio: Portfolio;
    onRefresh: Function;
    possibleAssets: Asset[];
    infoTabsOnChange?:Function;
    currentControlDate: string;
    currentAssetVolume: AssetVolume,
    rollingSharpeOptimizedData:any;
    rollingSharpeOptimizedCaseStr:string;
    onChange:(item: TabItem) => void;
    drawdownPeriodsData:any;
    keyMetricksData:any;
    optimizationAllocation:any;
    annualReturnsOptimization:any;
    performanceMetrics:any;
    possibleBenchmark: Asset[];
    extraCall?: Function;
    caseStr:string;
    renderData:any;
    monthlyReturns:any;
    dailyReturnsData:any;
    worstDrawDownData:any;
    rollingVolatility:any;
    underwaterPlot:any;
    rollingSortinoOptimizedCurrentCaseStr:string;
    rollingSortinoOptimizedData:any;
    distributionMonthlyCaseString:string;
    distributionMothlyData:any;
};

const rebalancingFrequency = [
    { name: 'No Rebalancing',   id: 'NoRebalancing'},
    { name: 'Daily',            id: 'Daily'},
    { name: 'Weekly',           id: 'Weekly'},
    { name: 'Monthly',          id: 'Monthly'},
    { name: 'Quarterly',        id: 'Quarterly'},
];

const optimizationMethod = [
    {name:'Mean Variance',           id:'Mean Variance'},
    {name:'Equally Weighted',        id:'Equally Weighted'},
    {name:'Risk Parity',             id:'Risk Parity'},
    {name:'Hirarchical risk Parity', id:'Hirarchical risk Parity'},
];

const objectiveFunction = [
    {name:'Maximized Sharpe',     id:'Maximized Sharpe'},
    {name:'Minimized Volatility', id:'Minimized Volatility'},
    {name:'Mean Variance',        id:'Mean Variance'},
    // {name:'Maximized Return', id:'Maximized Return'},
];

const constraintLevel = [
    {name:'Position',  id:'Position'},
    {name:'Portfolio', id: 'Portfolio'},
];

const TabsItems = [
    {title:'Asset Allocation',id:'Asset Allocation' },
    {title:'Overview',        id:'Overview'},
    {title:'Performance',     id:'Performance'},
    {title:'Risk Metrics',    id:'Risk Metrics'},
] as TabItem[];

const PortfolioOptimization = (props:PortfolioOptimizationProps ) => {
    const portfolio = props.portfolio;
    const onRefresh = props.onRefresh;
    const currentAssetVolume = props.currentAssetVolume;
    const drawdownPeriodsData = props.drawdownPeriodsData;
    const rollingSharpeOptimizedData = props.rollingSharpeOptimizedData;
    const rollingSharpeOptimizedCaseStr = props.rollingSharpeOptimizedCaseStr;
    const optimizationAllocation = props.optimizationAllocation;
    const renderData = props.renderData;
    const caseStr = props.caseStr;
    const keyMetricksData = props.keyMetricksData;
    const performanceMetrics= props.performanceMetrics;
    const annualReturnsOptimization = props.annualReturnsOptimization;
    const monthlyReturns = props.monthlyReturns;
    const  dailyReturnsData = props.dailyReturnsData;
    const worstDrawDownData = props.worstDrawDownData;
    const rollingVolatility = props.rollingVolatility;
    const underwaterPlot = props.underwaterPlot;
    const rollingSortinoOptimizedCurrentCaseStr = props.rollingSortinoOptimizedCurrentCaseStr;
    const rollingSortinoOptimizedData = props.rollingSortinoOptimizedData;
    const distributionMonthlyData = props.distributionMothlyData;
    const distributionMonthlyCaseString = props.distributionMonthlyCaseString;

    const [errorMessage, setErrorMessage] = useState(false);
    const [showGraphs, setShowGraphs] = useState(false);

    const noBenchmarkAsset = {id: -1, name: 'No Benchmark', symbol: '*', value: 0};
    const selectedBenchmark = portfolio.benchmark || noBenchmarkAsset;
    const defaultBenchmarkAsset = {id: -2, name: 'please select Benchmark...', symbol: '*', value: 0};
    const [defaultBenchmark,setDefaultBenchmark] = useState(defaultBenchmarkAsset);
    const [selectBenchmark, setSelectBenchmark] = useState(selectedBenchmark);
    const possibleBenchmark = [noBenchmarkAsset,...props.possibleBenchmark];
    
    const SELECT_ASSET_REF_NAME = '_asset_ref_name';
    const SELECT_FREQUENCY_REF_NAME = '_frequency_ref_name';
    const SELECT_OPT_METHOD = '_method_ref_name';
    const SELECT_OBJECTIVE_FUNCTION = '_objective_function_ref_name';
    const SELECT_CONSTRAINT_LEVEL = '_constraint_level_ref_name';
    const currentControlDate = new Date(props.currentControlDate || new Date().toISOString());
    const dateStart = portfolio._dateStart;
    const dateEnd = portfolio._dateEnd;
    const errorMessageD = '';
    const valuesRefs = new RefHoster();
    const  possibleAssets = props.possibleAssets;
    const [sectionTab, setSectionTab] = useState(TabsItems[0])

    const extraOptArray = [] as ExtraOptInstruments[];
    const emptyAssetVolumes = [] as  ExtraOptInstruments[];
    const [extraOptInstruments, setExtraOptInstruments] = useState(extraOptArray);
    const [currentEditableExtraInstrument, setCurrentEditableExtraInstrument] = useState({});
    const [defaultValue, setDefaultValue] = useState(currentAssetVolume);
    const [removedAssets, setRemovedAssets] = useState(emptyAssetVolumes);
    const [allocationError, setAllocationError] = useState(false);
    const [selectedForEdit, setSelectedForEdit] = useState(false);
    const defaultMinWeight = '0';
    const defaultMaxWeight = '0';

    const ENTER_MIN_REF_NAME = '_min_ref_name';
    const ENTER_MAX_REF_NAME = '_max_ref_name';
    const setInputValue = (id: string, value) => valuesRefs.setInputValue(id, value);
    const getInputValue = (id: string, defaultValue:any = '') => valuesRefs.getInputValue(id, defaultValue);


    const defaultFrequency  = { name: 'No Rebalancing',   id: 'NoRebalancing'};
    const [frequency, setFrequency] = useState(defaultFrequency);

    const defaultMethod = {name:'Mean Variance',id:'Mean Variance'};
    const [optMethod, setOptMethod] = useState(defaultMethod);
    const defaultObjectiveFunction = {name:'Maximized Sharpe', id:'Maximized Sharpe'};
    const [objective, setObjectiveFunction ] = useState(defaultObjectiveFunction);
    const defaultConstraintLevel = {name:'Position', id:'Position'};
    const [constraint, setConstraintLevel] = useState(defaultConstraintLevel);

    const onChange = (item: TabItem) => props.infoTabsOnChange ? props.infoTabsOnChange(item) : undefined;
    const tasks = portfolio.tasks;
    const latestTask = getCurrentPortfolioTask(portfolio);
    const errorTasksMessage = latestTask && latestTask.status === 'error' && latestTask?.msg === 'simulation-optimization' ? latestTask.errorMessage : null;
    const anyError = errorTasksMessage;
    const isTextErrorMatch = errorTasksMessage?.slice(9).match(/^(.*?),/);
    const textError = isTextErrorMatch && isTextErrorMatch[1];

    const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';
    const resolutionWidthBox = RESOLUTION_SCREEN === 'horizontal' ? '140px' : '100px';
    const resolutionWidthAutocomp = RESOLUTION_SCREEN === 'horizontal' ? '488px' : '220px';
    const stackSpace = RESOLUTION_SCREEN === 'horizontal' ? '61px' : '20px';
    const stackSpaceDatePicker = RESOLUTION_SCREEN === 'horizontal' ? '62px' : '20px';
    const stackHeightDatePicker = RESOLUTION_SCREEN === 'horizontal' ? '40px' : '140px';
    const stackDirection = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';
    const resolutionIconSize = RESOLUTION_SCREEN === 'horizontal' ? '14px' : '8px';

    const runningTasks = tasks.filter(task => task.status === 'started');

    const runningSomething = runningTasks.length > 0;
    const resultResponse = 'success';

    const [sliderValue,setSliderValue] = React.useState<number[]>([0,100]);

    const doubleSliderChange = (event:Event,newValue: number | number[], ) => {
        setSliderValue(newValue as number[])
        // todo If we will need weight for extraInstruments, now it send 0&100
        // setCurrentEditableExtraInstrument({ ...currentEditableExtraInstrument, minWeight: sliderValue[0], maxWeight:sliderValue[1] });
    }
    const navigate = useNavigate();


    const [error, setError] = React.useState<DateValidationError | ''>('');

    const errorMessageDate = React.useMemo(() => {
        switch (error) {
            case "minDate"      : {return 'Select a date'}
            case 'invalidDate'  : {return 'Date is not valid'}
            case 'maxDate'      : {return 'Select a date'}
            default             : {return ''}
        }
    }, [error]);


    const checkDate = (dateStart,dateEnd) => {
        if (dateStart !== undefined||null && dateEnd !== undefined||null){
            return  Date.parse(dateStart) >= Date.parse(dateEnd);
        }
    };
    const changeSectionTab = (item: TabItem) => {
        setSectionTab(item);
    };

    const selectBenchMark = (selectedItem: AssetVolume) => {
        setDefaultBenchmark(selectedItem);
        setSelectBenchmark(selectedItem);
    };

    const rebalnceFrequency = (selectedItem) => {
        setFrequency(selectedItem);
    };

    const setOptimizationMethod = (selectedItem) => {
       setOptMethod(selectedItem);
    };

    const getObjectiveFunction = (selectedItem) => {
        setObjectiveFunction(selectedItem)
    };

    const getConstraintLevel = (selectedItem) => {
      setConstraintLevel(selectedItem);
    };

    const isItemSelectedForEdit = (item) => {
        return selectedForEdit && defaultValue.id === item.id;
    }

    const selectAsset = (selectedItem) => {
        setDefaultValue(selectedItem);
        setSelectedForEdit(false);
        const minWeight = sliderValue[0];
        const maxWeight = sliderValue[1];
        setCurrentEditableExtraInstrument({...selectedItem,minWeight:minWeight, maxWeight:maxWeight});
    };

    const resetSelectedOption = () => {
        setDefaultValue(initialEmptyAsset);
        setSelectedForEdit(false);
    };

    const updateOrInsertAsset = (assetValues: ExtraOptInstruments[], actualValue): ExtraOptInstruments[] => {
        const result = [] as ExtraOptInstruments[];
        let added = false;
        for (let asset of assetValues) {
            if (asset.id === actualValue.id) {
                result.push(actualValue);
                added = true;
            }
            else {
                result.push(asset);
            }
        }
        if (!added) {
            result.push(actualValue);
        }
        return result;
    };

    const removeAsset = (asset) => {
        setRemovedAssets([...removedAssets, asset]);

        const newAssets = extraOptInstruments.filter(it => it.id !== asset.id);
        setExtraOptInstruments([...newAssets]);
    }

    const updateMin = (event: any) => {
            let value = Number(event.target.value) as number | string;
            if (value < 0) value = ' ';
            setInputValue(ENTER_MIN_REF_NAME, value);
            setCurrentEditableExtraInstrument({ ...currentEditableExtraInstrument, minWeight: value });
    };

    const updateMax = (event: any) => {
            let value = Number(event.target.value) as number | string;
            if (value < 0) value = ' ';
            setInputValue(ENTER_MAX_REF_NAME, value);
            setCurrentEditableExtraInstrument({ ...currentEditableExtraInstrument, maxWeight: value });
    };
    const currentAssetAddToAssets = () => {
        const selectedAsset = getInputValue(SELECT_ASSET_REF_NAME, {});
        if (!currentEditableExtraInstrument || currentEditableExtraInstrument === ASSET_DUMMY_ID) {
            setAllocationError(true);
            return;
        }
        if (selectedAsset === initialEmptyAssetName() || defaultValue.symbol === '*') {
            setAllocationError(true);
            return;
        }
        setAllocationError(false);
        resetSelectedOption();
        setInputValue(SELECT_ASSET_REF_NAME, initialEmptyAsset);
        setSliderValue([0,100]);
        const nextCurrentAssets = [...updateOrInsertAsset(extraOptInstruments, currentEditableExtraInstrument)];
        setExtraOptInstruments(nextCurrentAssets);
    };

    const optimizationPortfolio = () => {
        const msg = 'simulation-optimization';
        const selectedFrequency = frequency.id === '#' || frequency.id === 'NoRebalancing' ? 'None' : frequency;
        const optimizedMethod = currentOptMethod.id.replace(' ','');
        const optimizedFunction = currentObjective.id.replace(' ','');
        if(constraint.id === 'Position') {
            if(extraOptInstruments.length !== 0 && error === ''|| error === null){
                const extraInstruments = extraOptInstruments.map((item,idx) => {
                    return {symbol:item.symbol, minWeight:item.minWeight, maxWeight: item.maxWeight}
                });
                // @ts-ignore
                props.extraCall({...portfolio,benchmark: selectBenchmark,
                                    frequency: selectedFrequency,msg:msg,
                                    extra_instruments:extraInstruments,
                                    optimized_method:optimizedMethod,
                                    optimized_function:optimizedFunction,
                });
                setShowGraphs(true);
                setErrorMessage(false);
                setAllocationError(false);
                setSectionTab(TabsItems[0]);
            } else {
                setErrorMessage(true);
                setAllocationError(true);
            }
        } else {
            // @ts-ignore
            props.extraCall({...portfolio,benchmark: selectBenchmark,
                            frequency: selectedFrequency,msg:msg,
                            optimized_method:optimizedMethod,
                            optimized_function:optimizedFunction,
            });
            setShowGraphs(true);
            setErrorMessage(false);
            setSectionTab(TabsItems[0]);
        }
    };



    const defaultBench = selectedBenchmark.id === selectBenchmark.id ?
        possibleBenchmark.find(it => it.id === selectedBenchmark.instrumentId)
        :selectBenchmark;

    const select = selectBenchmark.name;
    const reSelect = possibleBenchmark.find(it => it.id === selectedBenchmark.instrumentId)?.name;

    const usStockLegend = select || reSelect;

    const currentFrequency =  frequency !== undefined ? frequency : '';
    const currentOptMethod = !optMethod ? defaultMethod : optMethod;
    const currentObjective = !objective ? defaultObjectiveFunction : objective;
    const currentConstraintLevel = !constraint ? defaultConstraintLevel : constraint;


    return <>
        <Stack direction={'column'} width={'100%'}>
            <Stack direction={'column'} spacing={3} marginBottom={'16px'}>
                <span className={'titleGraphs'}>
                    CONFIGURE OPTIMIZATION
                </span>
                {/*todo Delete or return back */}
                {/*<Stack direction={'row'} spacing={stackSpace} height={'40px'} >*/}
                {/*    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>*/}
                {/*        Optimization Method*/}
                {/*    </Box>*/}
                {/*    <Box>*/}
                {/*        <MyNewAutocomplete*/}
                {/*            id={'OptimizationMethod'}*/}
                {/*            onChange={selectedItem => setOptimizationMethod(selectedItem)}*/}
                {/*            inputRef={el => valuesRefs.initRef(SELECT_OPT_METHOD, el)}*/}
                {/*            labelParam={'name'}*/}
                {/*            defaultValue={currentOptMethod}*/}
                {/*            options={optimizationMethod}*/}
                {/*            width={resolutionWidthAutocomp}*/}
                {/*            changeIcon={'overview'}*/}
                {/*        />*/}
                {/*    </Box>*/}
                {/*</Stack>*/}
                <Stack direction={'row'} spacing={stackSpace} height={'40px'} >
                    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                        Objective Function
                    </Box>
                    <Box>
                        <MyNewAutocomplete
                            id={'ObjectiveFunction'}
                            onChange={selectedItem => getObjectiveFunction(selectedItem)}
                            inputRef={el => valuesRefs.initRef(SELECT_OBJECTIVE_FUNCTION, el)}
                            labelParam={'name'}
                            defaultValue={currentObjective}
                            options={objectiveFunction}
                            width={resolutionWidthAutocomp}
                            changeIcon={'overview'}
                        />
                    </Box>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={stackSpaceDatePicker} height={stackHeightDatePicker} >
                    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                        Analysis Period
                    </Box>
                    <Box sx={{paddingBottom: '34px'}}>
                        <DateRangeForm
                            dateStart={dateStart as Date}
                            dateEnd={dateEnd as Date}
                            isError={errorMessageDate}
                            setError={setError}
                            errorDate={error}

                            currentControlDate={currentControlDate}
                            className={'pickerOptimization'}
                        />
                    </Box>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={stackSpace} height={'40px'}>
                    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                        Market Benchmark
                    </Box>
                    <Box sx={{paddingBottom: '14px'}}>
                        <MyNewAutocomplete
                            id={'selectBenchmarkOptimization'}
                            onChange={selectedItem => selectBenchMark(selectedItem)}
                            inputRef={el => valuesRefs.initRef(SELECT_ASSET_REF_NAME, el)}
                            labelParam={'name'}
                            defaultValue={defaultBench}
                            options={possibleBenchmark}
                            changeIcon={'overview'}
                            width={resolutionWidthAutocomp}
                        />
                    </Box>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={stackSpace} height={'40px'}>
                    <Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>
                        Rebalancing Frequency
                    </Box>
                    <Box sx={{paddingBottom: '14px'}}>
                        <MyNewAutocomplete
                            id={'frequencyOptimization'}
                            onChange={selectedItem => rebalnceFrequency(selectedItem)}
                            inputRef={el => valuesRefs.initRef(SELECT_FREQUENCY_REF_NAME, el)}
                            labelParam={'name'}
                            defaultValue={currentFrequency}
                            options={rebalancingFrequency}
                            placeholder={'please select Rebalancing...'}
                            changeIcon={'overview'}
                            width={resolutionWidthAutocomp}
                        />
                    </Box>
                </Stack>
            </Stack>
            <Stack direction={'column'} spacing={3} marginBottom={'16px'}>
                 <span className={'titleGraphs'}>
                    CONFIGURE CONSTRAINTS
                </span>
                {/*<Stack direction='row' alignItems='center' spacing={stackSpace} height={'40px'}>*/}
                    {/*<Box sx={{color: mainTextColor, width: resolutionWidthBox, fontWeight:500, fontSize:resolutionFontSize}}>*/}
                    {/*    Constraint Level*/}
                    {/*</Box>*/}
                    {/*<Box sx={{paddingBottom: '14px'}}>*/}
                    {/*    <MyNewAutocomplete*/}
                    {/*        id={'ConstraintLevel'}*/}
                    {/*        onChange={selectedItem => getConstraintLevel(selectedItem)}*/}
                    {/*        inputRef={el => valuesRefs.initRef(SELECT_CONSTRAINT_LEVEL, el)}*/}
                    {/*        labelParam={'name'}*/}
                    {/*        defaultValue={currentConstraintLevel}*/}
                    {/*        options={constraintLevel}*/}
                    {/*        width={resolutionWidthAutocomp}*/}
                    {/*        changeIcon={'overview'}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                {/*</Stack>*/}
                {constraint.name === 'Position' && <OptimizationConstraints
                                                    possibleAssets={possibleAssets}
                                                    currentAssetVolume={currentAssetVolume}
                                                    extraOptInstruments={extraOptInstruments}
                                                    removeAsset={removeAsset}
                                                    isItemSelectedForEdit={isItemSelectedForEdit}
                                                    selectAsset={selectAsset}
                                                    defaultValue={defaultValue}
                                                    updateMin={updateMin}
                                                    updateMax={updateMax}
                                                    currentAssetAddToAssets={currentAssetAddToAssets}
                                                    allocationError={allocationError}
                                                    defaultMinWeight={defaultMinWeight}
                                                    defaultMaxWeight={defaultMaxWeight}
                                                    valuesRefs={valuesRefs}
                                                    doubleSliderChange={doubleSliderChange}
                                                    doubleSliderValue={sliderValue}
                                                     />
                }
            </Stack>
            <Stack direction={'row'} sx={{width:'100%', marginBottom:'30px'}} justifyContent={'center'} alignItems={'center'} spacing={2} >
                <Box sx={{width: {sx:'60%',md:'53%',lg:'53%'}}}></Box>
                <Box sx={{width: {sx:'40%',md:'47%',lg:'47%'}}}>
                    <Button disabled={checkDate(dateStart, dateEnd) || runningSomething} className={'advancedBtn'}
                            onClick={() => optimizationPortfolio()}>
                        Run Optimization
                    </Button>
                    <button className={'advancedBtn'} style={{ marginLeft:'16px'}}>Save optimized Portfolio</button>
                </Box>
            </Stack>
            {runningSomething && !anyError && ! errorTasksMessage
                ? <Stack justifyContent={'center'} alignItems={'center'} sx={{width: '100%',height: '100px', marginTop:'20px'}}>
                    <span className={'loader'}></span>
                    <Box style={{color:mainTextColor, fontSize:processingFontResolution}}><br/>Processing. Please wait...</Box>
                </Stack>
                : <></>}

            {errorTasksMessage || anyError ? <Stack justifyContent={'center'} alignItems={'center'} sx={{width: '100%',height: '100%', marginTop:'20px'}}>
                {errorTasksMessage?.includes('No data for instrument') ?
                    <Box sx={{color: mainTextColor}}> No trading days {textError} within selected analysis period.</Box>
                    : <Box className={'errorMessage'} >
                        ERROR! {errorTasksMessage} {errorMessage}.
                        <br/>
                        Please double-check Your values.
                    </Box>}
            </Stack>: <></>
            }

            {showGraphs && !anyError && !runningSomething && resultResponse === 'success'&& <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <EquityCurveView
                            renderData={renderData}
                            caseStr={caseStr}
                            legendName={portfolio.name}
                            dateStart={dateStart as Date}
                            dateEnd={dateEnd as Date}
                            usStockLegend={usStockLegend}
                            panelId={EQUITY_CURVE_OPTIMIZATION_ID}
                        />
                    </Grid>
                </Grid>
                <Stack direction='row' alignItems='center'sx={{width: '100%',height:'34px',marginBottom:'47px',
                    marginTop:'80px'
                }} >
                    <TabsComponent onChange={changeSectionTab} items={TabsItems} />
                </Stack>
                {sectionTab === TabsItems[0] &&
                    <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <OptimizedSimulatedAllocation
                                renderData={optimizationAllocation}
                                portfolio={portfolio}
                                placeUse={'Optimized'}
                                classCSS={'compositionSimulation'}
                                title={'OPTIMIZED ASSET ALLOCATION'}
                                extraInstruments={extraOptInstruments}
                            />
                         </Grid>
                    </Grid>
                }
                {sectionTab === TabsItems[1] &&
                    <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={5}>
                                <Stack sx={{opacity:0.3}}>
                                    <EfficientFrontier/>
                                </Stack>
                                <AnnualReturnsSimulation
                                renderData={annualReturnsOptimization}
                                legendName={portfolio.name}
                                usStockLegend={usStockLegend}
                                panelId={ANNUAL_RETURNS_OPTIMIZATION_ID}
                                placeUse={'optimized'}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <KeyMetrics
                                renderData={keyMetricksData}
                                legendName={portfolio.name}
                                usStockLegend={usStockLegend}
                                className={'keymetricsSimulation'}
                                placeUse={'Optimization'}
                            />
                        </Grid>
                    </Grid>
                }
                {sectionTab === TabsItems[2] &&
                    <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={5}>
                                <PerformanceMetrics
                                    renderData={performanceMetrics}
                                    legendName={portfolio.name}
                                    usStockLegend={usStockLegend}
                                    simulation={true}
                                    className={'keymetricsSimulation'}
                                    placeUse={'Optimization'}
                                />
                                <DistributionMonthlyReturn
                                    renderData={distributionMonthlyData}
                                    currentResult={'optimized'}
                                />
                                <MonthlyReturnsPortfolio
                                    renderData={monthlyReturns}
                                    currentResult={'optimized'}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={5}>
                                <RollingSharpe
                                    graphicsName={rollingSharpeOptimizeGraphics}
                                    rollingSharpe={rollingSharpeOptimizedData}
                                    rollingSharpeCaseString={rollingSharpeOptimizedCaseStr}
                                    currentResult={'optimized'}
                                    onChange={props.onChange}
                                    placeUse={'Optimization'}
                                />
                                <RollingSortino
                                    graphicsName={rollingSortinoOptimizedGraphics}
                                    rollingSortinoCaseString={rollingSortinoOptimizedCurrentCaseStr}
                                    rollingSortino={rollingSortinoOptimizedData}
                                    onChange={props.onChange}
                                    placeUse={'Optimization'}
                                />
                                <DailyReturnsPortfioio
                                    renderData={dailyReturnsData}
                                    currentResult={'optimized'}
                                    titleGraph={'DAILY RETURNS OPTIMIZED PORTFOLIO'}
                                />

                                <Stack spacing={4} sx={{opacity:0.3}}>
                                    <ReturnQuantiles/>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                }
                {sectionTab === TabsItems[3] &&
                    <Grid container spacing={{sm:2,md:2, lg:5,xl:6}} sx={{marginBottom:'32px'}}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={5}>
                                <WorstDrawdowns
                                    renderData={worstDrawDownData}
                                    currentResult={'optimized'}
                                    dateEnd={dateEnd as Date}
                                />
                                <RollingVolatility
                                    renderData={rollingVolatility}
                                    legendName={portfolio.name}
                                    usStockLegend={usStockLegend}
                                    panelId={ROLLING_VOLATILITY_OPTIMIZATION_ID}
                                />
                                <Stack sx={{opacity:0.3}}>
                                    <RollingBetaToBenchmark
                                        renderData={rollingVolatility}
                                        legendName={portfolio.name}
                                        usStockLegend={usStockLegend}
                                        panelId={ROLLING_VOLATILITY_OPTIMIZATION_ID}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={5}>
                                <DrawdownPeriods
                                    renderData={drawdownPeriodsData}
                                    currentResult={'optimized'}
                                    legendName={portfolio.name}
                                    placeUse={'Optimized Portfolio'}
                                />
                                <UnderwaterPlot
                                    renderData={underwaterPlot}
                                    currentResult={'optimized'}
                                />
                                <Stack sx={{opacity:0.3}}>
                                    <ValueRiskExpectedShortfall/>
                                </Stack>
                            </Stack>
                        </Grid>

                    </Grid>
                }
            </>}
        </Stack>
    </>

};

export default PortfolioOptimization;
