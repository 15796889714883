import MainLayout from 'layout/MainLayout';
import DashboardMonitor from 'pages/dashboard/monitor/router-proxy';
import TradeLab from 'pages/trade-lab/trade-lab';
import Portfolios from 'pages/portfolios/portfolio';

import AppRoutes from './AppRoutes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import CommonComponent from "../pages/common/common";
import AdminPage from '../pages/admin/router-proxy';
import DataQualityPage from '../pages/data-quality/router-proxy';
import LiveDataPage from '../pages/live-data/router-proxy';
import PortfolioViewOverview from "../pages/portfolios/portfolio-view-overview";
import {ActionMode} from "../pages/portfolios/portfolio.common";
import ResearchProducts from "../pages/strategy/research-products";

const MainRoutes = {
  path: '/',
  children: [
    { path: '/',
      element: (<AuthGuard><MainLayout /></AuthGuard>),
      children: [
        { path: AppRoutes.NB_HOME,                      element: <DashboardMonitor />},
        { path: AppRoutes.DASHBOARD_MONITOR,            element: <DashboardMonitor /> },
        { path: AppRoutes.DASHBOARD_SCREENSHOT,         element: <DashboardMonitor /> },
        { path: AppRoutes.ADMIN_PAGE,                   element: <AdminPage /> },
        {path: AppRoutes.DATA_QUALITY_PAGE,             element: <DataQualityPage/>},
        {path: AppRoutes.LIVE_DATA_PAGE,                element: <LiveDataPage/>},
        // { path: AppRoutes.DASHBOARD_MARKET_SUMMARY,     element: <DashboardMonitor /> },
        { path: AppRoutes.DASHBOARD_MARKET_SUMMARY,     element: <DashboardMonitor />,children: [
          {path:':action',        element: <DashboardMonitor />}
        ]},
        { path: AppRoutes.WEALTH_LAB,                   element: <Portfolios />, children:[
          { path: `:action/:id`,  element: <Portfolios />},
          { path: `:action`,      element: <Portfolios />},
        ]},
        // { path: AppRoutes.TRADE_LAB,                    element: <TradeLab /> },
        // { path: AppRoutes.PORTFOLIOS,                   element: <Portfolios /> },
        { path: AppRoutes.COMMON_lAB,                   element: <CommonComponent/> },
        {path:AppRoutes.RESEARCH_PRODUCTS,             element:<ResearchProducts/>, children: [
            { path: `:action/:name`,      element:<ResearchProducts/>},
          ]}
      ]
    }
  ]
};

export default MainRoutes;
