
export interface Candle {
  timestamp: Date;
  ticker: number;
  count: number;
  low: number;
  high: number;
  open: number;
  close: number;
  volume:number;
}

const orderedFields = ['timestamp', 'ticker', 'count', 'low', 'high', 'open', 'close', 'volume'];


export const asCandle = (values: any[], keys: string[] | null = null): Candle => {
  const names = keys || orderedFields;
  const result = {};
  for (let i = 0; i < names.length; i += 1) {
      // @ts-ignore
    result[names[i]] = values[i];
  }
  return result as Candle;
}

const candleForGraphic = (candle: Candle) => ({
  low: candle.low,
  high: candle.high,
  open: candle.open,
  close: candle.close,
  volume: candle.volume,
  date: candle.timestamp,
});

export const candlesForGraphic = (candles: Candle[]) => candles.map(it => candleForGraphic(it));
