import React, {ReactNode} from "react";
import {styled} from "@mui/system";
import {Stack} from "@mui/material"
import {
    controlBackgroundColor,  mainBackgroundColor,
    secondTextColor
} from "../../../config";
import WidgetInfoPanel from "../../../components/controls/info-panel";
import {monthlyReturnsOverviewGraphics} from "../portfolio.slice";


const TdStyleBody = styled ('td') ({
    border:'1px solid #000A2B',
    color:controlBackgroundColor,
    width:'44px',
    height:'15px',
    fontSize: '11px',
    lineHeight: '15px',
    textAlign:'center',
})

interface  TdBriketProps {
    row: number;
    colum: number;
    backgroundColor: string;
    children: ReactNode
}

export const TdBriket = (props: TdBriketProps) =>  {
    return (

            <TdStyleBody key={props.colum}
                 sx={{fontSize:{xs:'8px',md:'8px',lg:'11px'},
                     width:{xs:'28px',md:'44px',lg:'44px'},
                     backgroundColor: props.backgroundColor
                 }}
            >
                {props.children}
            </TdStyleBody>
    )
};

const TdHeader = styled('td') ({
    color:secondTextColor,
    width:'44px',
    height:'24px',
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '15px',
    textAlign: 'center'
})
const dummyMonth = [' ','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const xxxx = ['1','2','3','4','5','6','7','8','9','10','11','12'];


export interface MonthlyPortfolioProps {
   renderData: any;
   currentResult:string;
}

const MonthlyReturnsPortfolio = (props: MonthlyPortfolioProps) => {
    const render = monthlyReturnsOverviewGraphics.getCurrentGraphic();
    const currentResult = props.currentResult;
    const data = render.map((item,idx) => item[`${currentResult}`] ?  item[`${currentResult}`].result : item.clientPortfolio.result)[0];
    const dataYear = data.map((item) => new Date(item.date).getFullYear());
    const year = dataYear.filter((x,i) => dataYear.indexOf(x) === i);


    const splitToArrays = () => {
        const result = year.map((item,idx) => {
           const currentYear = data.filter((el,it) => {
                if(el.date.includes(item)) {
                    return [el.value]
                }
            });
           return currentYear;
        });
        return result;
    };

    const addEmptyMonthVAlue = (arr) =>{
        const result = arr.map((el)=> {
          const fixedArray = xxxx.map((item,idx) => {
             const emptyMonth = el.find((it) => {
                  const month = (new Date(it.date).getMonth() + 1).toString();
                  if(month === item) {
                      return it.value
                  }else{
                      return undefined
                  }
              });
             return emptyMonth;
          });
          return fixedArray;
        })
        return result;
    }

    const dataX ={
            month:dummyMonth,
            year:year,
            value:addEmptyMonthVAlue(splitToArrays())
    };


    const BgColor = item =>
        item >= 30 ? '#657355'
        :item >= 27 ? '#6D7C5C'
        :item >= 24 ? '#798966'
        :item >= 21 ?'#82936E'
        :item >= 18 ? '#8B9E77'
        :item >= 15 ? '#92A57C'
        :item >= 12 ? '#9BAF84'
        :item >= 9 ? '#A1B689'
        :item >= 6 ? '#AAC091'
        :item >= 3 ? '#B4CA9A'
        :item >= 0.01 ? '#CDE4B3'
        :item === 0 ? '#F0EEEB'
        :item <= -30 ? '#D43B10'
        :item <= -27 ? '#D54822'
        :item <= -24 ? '#D5512D'
        :item <= -21 ? '#D65A38'
        :item <= -18 ? '#D66546'
        :item <= -15 ? '#D76D50'
        :item <= -12 ? '#D7755A'
        :item <= -9 ? '#D87D64'
        :item <= -6 ? '#D8866F'
        :item <= -0.001 ? '#D99481'
        :'#F0EEEB'

    const value_format = value =>  (value * 100).toFixed(2);
    return <Stack justifyContent={'center'} direction={'column'} spacing={'14px'}>
        <WidgetInfoPanel
            title={'MONTHLY RETURNS PORTFOLIO'}
            infoMarkers={[]}
        />

        {Object.keys(dataX).length !== 0 && <Stack direction='row' alignItems='center' justifyContent='left' spacing={'67px'}>
            <table style={{borderCollapse: 'collapse'}}>
                <thead>
                <tr>
                    {dataX.month.map((item, idx) =>
                        <TdHeader key={idx + 'head'}
                          sx={{
                              fontSize: {xs: '8px', md: '8px', lg: '11px'},
                              width: {xs: '28px', md: '44px', lg: '44px'}
                          }}
                        >{item}</TdHeader>
                    )}
                </tr>
                </thead>
                <tbody>
                {dataX.value.map((element, rowNumber) =>
                    <tr key={rowNumber + 'row'}>
                        {element.map((item, columNumber) =>
                            <React.Fragment key={columNumber + ':' + rowNumber}>
                                {columNumber === 0 && <TdHeader
                                    sx={{
                                        fontSize: {xs: '8px', md: '8px', lg: '11px'},
                                        width: {xs: '28px', md: '44px', lg: '44px'}
                                    }}
                                    key={columNumber + 'colm'}>{dataX.year[rowNumber]}</TdHeader>}
                                <TdBriket row={rowNumber + 1} colum={columNumber}
                                          backgroundColor={item !== undefined ? BgColor(item.value * 100) : mainBackgroundColor}>
                                    {item !== undefined && value_format(item.value)}
                                </TdBriket>
                            </React.Fragment>
                        )}
                    </tr>
                )}
                </tbody>
            </table>
        </Stack>}
    </Stack>
}

export default MonthlyReturnsPortfolio;
