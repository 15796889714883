import React from 'react';
import { connect } from 'react-redux';
import { RootState } from "store";

import { adminDefaultActions, incrementAsync, SysInfo, TaskInterface, TaskNameInfo } from './admin.slice';
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { randomString } from "utils/misc";
import { getBuildInfoApi, getDataStatisticsApi, startSyncActionApi, resetTasksStatLogApi } from './admin.api';
import { RouterParams } from 'routes/router-proxy-model';
import { Button, Stack } from '@mui/material';
import { wsConnectorDataprocessor, wsConnectorMarket } from '../../components/system/websocket-component';
import { WS_DATAPROCESSOR_URL, WS_MARKET_URL } from '../../config';
import { DjangoContextType, UserProfile } from '../../types/auth';
import {DeleteOutlined} from "@ant-design/icons";
import { $$ } from '../../utils/utils';
import { round } from 'lodash';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons/lib/icons';
// import { AdminParams } from './admin-params-model';

interface ComponentProps extends RouterParams, StateProps, DispatchProps {}


class AdminDefaultComponent extends React.Component<ComponentProps> {

  // static contextType = AuthContext;
  // getRouter() { return this.props['router']; }
  // xuser = this.getRouter().user;


  componentDidMount() {
    const user = this.getUser();

    if (this.props.user) {
      this.initWs(this.props.user.token)
       // console.log('######################## USER ', user);
    }

  }
  getUser(): UserProfile | undefined | null {
    const context = this.context as DjangoContextType;
    return context.user;
  }
  initWs(token) {
    if (!wsConnectorDataprocessor.ready) {
        wsConnectorDataprocessor.url = `${WS_DATAPROCESSOR_URL}/${token}`;
        wsConnectorDataprocessor.init_receiver((payload) => {
          // console.log('################# DATA-PROCESSOR - WS PAYLOAD::', JSON.parse(payload.data || '{}'))
          this.props.updateInstantValues(JSON.parse(payload.data || '{}'))
        });
    }
  }

  onClick = () => {
    this.props.setName('TEST::' + randomString('USER') );
    this.props.incrementAsync('RANDOM::' + randomString('ABC'));
  }

  getBuildInfo = () => {
    if (this.isOpened('BuildInfo')) {
      this.toggleTab('BuildInfo')
    }
    else {
      this.props.getBuildInfo();
    }
  }

  getDataStatistics = () => {
    if (!this.isOpened('DataStatistics')) {
      this.props.getDataStatistics();
    }
    this.toggleTab('DataStatistics');
  }

  toggleTab = (name: string) => {
    this.props.toggleTab(name);
  }
  resetTaskLogs = () => {
     this.props.resetTasksStatLog();
  }

  isOpened = (name: string) => this.props.tabs[name];

  render() {
    // const name = this.props.name;
    const {
      name,
      tabs,
      tasks,
      feeds,
      apps,
      hosts,
      appNames,
      streams,
      currentTime,
      sysUsage,
      // data_statistics,
      build_info
    } = this.props;

    const taskKinds = [ 'failed', 'running', 'finished' ];
    const taskColors = { 'failed': 'red', 'running': 'orange', 'finished': 'green' };
    const currentDateTime = $$.getDateAsString(currentTime);

    const _getRowException = (task: TaskInterface) => {
      return <tr>
        <td style={{ 'width': 360 }}>&nbsp;</td>
        <td colSpan={4} style={{ 'width': 520 }}>{task.exception}</td>
      </tr>;
    };
    const _getRowNormal = (task: TaskInterface) => {
      return <tr>
        <td style={{ 'width': 360 }}>{task.name}</td>
        <td style={{ 'width': 150 }}>{task.start}</td>
        <td style={{ 'width': 150 }}>{task.stop ? task.stop : ''}</td>
        <td style={{ 'width': 100 }}>{task.duration}</td>
        <td style={{ 'width': 120 }}>{task.kind}</td>
      </tr>;
    };
    const getRow = (kind: string, task: TaskInterface, filter: string) => {
      return !task.exception ? _getRowNormal(task) : <>{_getRowNormal(task)} {_getRowException(task)} </>;
    };

    const secToHuman = (seconds: number) => {
      if (!seconds) {
        return ''
      }
      return $$.durationForHuman(seconds, true, true);
      // if (seconds < 60) {
      //   return `${seconds} sec`;
      // }
      // if (seconds < 60 * 60)  {
      //   const mins = $$.zeroPad(Math.floor(seconds / 60), 2);
      //   const secs = $$.zeroPad(seconds % 60, 2);
      //   return `[${mins}]:${secs}`;
      // }
      // if (seconds < 60 * 60 * 24)  {
      //   const hours = $$.zeroPad(Math.floor(seconds / (60 * 60)), 2);
      //   const mins = $$.zeroPad(seconds % (60 * 60), 2);
      //   const secs = $$.zeroPad(seconds % 60, 2);
      //   return `${hours}h  <${mins}>:${secs}`;
      // }
      // else {
      //   const days = Math.floor(seconds / (60 * 60 * 24));
      //   const hours = $$.zeroPad(seconds % (60 * 60 * 24), 2);
      //   const mins = $$.zeroPad(seconds % (60 * 60), 2);
      //   const secs = $$.zeroPad(seconds % 60, 2);
      //   return `${days}d   ${hours}:[${mins}]:${secs}`;
      // }
    };

    const getDataStatisticsRow = (it, i) => {
      const allItems = getTasks('data_statistics');
      const lastItem = i > 0 ? allItems[i - 1] : undefined;
      const hour_section = it.section.endsWith('1h');
      const error_bound_delta = hour_section ? 5000 : 500;

      const section_color = lastItem && lastItem.section === it.section ? 'grey' : 'white';

      let color = it.status === 'OK' ? 'green' : 'red';
      let status = it.status;
      if (it.status === 'ERR') {
        if (it.delta < error_bound_delta) {
          color = 'orange';
          status = 'WARN';
        }
      }
      return <tr key={i + 'datastat'}>
        <td><span style={{ 'color': color }}>{status}</span></td>
        <td>{it.symbol}</td>
        <td><span style={{ 'color': section_color }}>{it.section || ''}</span></td>
        <td style={{'textAlign': 'right'}}><span title={'' + it.delta + ' sec'}>{secToHuman(it.delta)}</span>&nbsp;&nbsp;&nbsp;&nbsp;</td>
        <td>{it.date || ''}</td>
        <td>&nbsp;&nbsp;</td>
        <td><span style={{ 'color': section_color }}>{it.now || ''}</span></td>
        <td>{it.mess || ''}</td>
      </tr>;
    };

    const memoryVal = value => `${value.toFixed(2)} MB`;
    const percentVal = value => `${value.toFixed(2)}%`;

    const loadAver = (value, cpu_count) => {
      const color = value <= cpu_count * 1.5 ? 'green' : value <= cpu_count * 2.5 ? 'orange' : 'red';
      return <span style={{ 'color': color }}>{percentVal(value)}</span>;
    };

    const systemInfo = (item: SysInfo) => {
      return item ? <table width={450} border={1} style={{ 'color': 'white' }}>
        <tbody>
        <tr>
          <td width={100}>hostname</td>
          <td colSpan={2}>{item.hostname}</td>
        </tr>
        <tr>
          <td width={100}>service</td>
          <td colSpan={2}>{item.service_name || 'unknown'}</td>
        </tr>
        <tr>
          <td valign="top" width={100}>os</td>
          <td colSpan={2}>{item.platform.os} {item.platform.version} {item.platform.release}</td>
        </tr>
        <tr>
          <td valign="top" width={100}>docker</td>
          <td colSpan={2}>{item.docker ? 'yes' : ''}</td>
        </tr>
        <tr>
          <td width={100}>uptime</td>
          <td width={300} colSpan={1}>{item.process.started}</td>
          <td colSpan={1}>{item.process.running}</td>
        </tr>
        <tr>
          <td>python</td>
          <td colSpan={2}>{item.python.version}</td>
        </tr>
        <tr>
          <td valign="top" rowSpan={6}>memory</td>
          <td width={180}>percent</td>
          <td align={'right'} width={250}
              style={{ 'color': item.memory.percent > 85 ? 'red' : 'green' }}>{percentVal(item.memory.percent)}</td>
        </tr>
        <tr>
          <td>total</td>
          <td align={'right'}>{memoryVal(item.memory.total)}</td>
        </tr>
        <tr>
          <td>used</td>
          <td align={'right'}>{memoryVal(item.memory.used)}</td>
        </tr>
        <tr>
          <td>available</td>
          <td align={'right'}>{memoryVal(item.memory.available)}</td>
        </tr>

        <tr>
          <td>python rss</td>
          <td align={'right'}>{memoryVal(item.python.memory.rss)}</td>
        </tr>
        <tr>
          <td>python vms</td>
          <td align={'right'}>{memoryVal(item.python.memory.vms)}</td>
        </tr>

        <tr>
          <td valign="top" rowSpan={3}>cpu</td>
          <td>count</td>
          <td align={'right'}>{item.cpu.cpu_count}</td>
        </tr>
        <tr>
          <td>load</td>
          <td align={'right'}
              style={{ 'color': item.cpu.cpu_load > 85 ? 'red' : 'green' }}>
            {percentVal(item.cpu.cpu_load)}
          </td>
        </tr>
        <tr>
          <td>load average</td>
          <td align={'right'}>
            {loadAver(item.cpu.load1, item.cpu.cpu_count)}&nbsp;
            {loadAver(item.cpu.load5, item.cpu.cpu_count)}&nbsp;
            {loadAver(item.cpu.load15, item.cpu.cpu_count)}
          </td>
        </tr>
        <tr>
          <td valign="top" rowSpan={4}>disk</td>
          <td>free</td>
          <td align={'right'}><b>{memoryVal(item.disk.free)}</b></td>
        </tr>
        <tr>
          <td>used</td>
          <td align={'right'}>{memoryVal(item.disk.used)} </td>
        </tr>
        <tr>
          <td>total</td>
          <td align={'right'}>{memoryVal(item.disk.total)}</td>
        </tr>
        <tr>
          <td>percent</td>
          <td align={'right'}
              style={{ 'color': item.disk.percent > 85 ? 'red' : 'green' }}>{percentVal(item.disk.percent)}</td>
        </tr>

        </tbody>
      </table> : <div>&nbsp;&nbsp;&nbsp;<span style={{ 'color': 'orange' }}>Calculated. Please wait ....</span></div>;
    };

    const systemInfos = () => {
      const hostnames = Object.keys(sysUsage);
      const hostname = hostnames[0];
      return systemInfo(sysUsage[hostname]);
    };

    const bitesToGb = count_bytes => '' + Math.round(count_bytes / (1024 * 1024 * 1024)) + ' GB';

    const statusBar = (mode: string, value: any='') => {
      const baseColor =
          mode === 'URGENT' ? '#7F00FF'
        : mode === 'MINUS' ? '#9ACD32'
        : mode === 'ERR' ? '#FF4D4F'
        : mode === 'WARN'? '#FFA500'
        : '#52C41A'
      return <div style={{
        width: '100%',
        height: '16px',
        fontSize: '80%',
        backgroundImage: 'linear-gradient(270deg, rgba(82, 196, 26, 0.10) 10.00%, ' + baseColor + ' 100%)'
      }}>{value}</div>;
    }

    const statusBarByTimestamp = (datemark: number, thresholds: number[], value: any=null) => {
      const diff = $$.getDateDiffNow(datemark);
      const mode = diff > thresholds[1] ? 'ERR' : diff > thresholds[0] ? 'WARN' : 'OK'
      return statusBar(mode, value || $$.getDateDiffNow(datemark))
    }

    const getUptime = it => $$.secondsToDHM($$.getDateDiffNow($$.getDateFromString(it.started + ' GMT')));
    const statusBarByValue = (base_val: number, thresholds: number[], value: any=null, param: string = '') => {
      let mode = 'OK';
      if (param !== 'skip-minus' && base_val < 0) {
        mode = 'MINUS';
      }
      else if(thresholds.length > 2 && base_val > thresholds[2]) {
        mode = 'URGENT';
      }
      else {
        mode = base_val > thresholds[1] ? 'ERR'
             : base_val > thresholds[0] ? 'WARN'
             : 'OK'
      }
      return statusBar(mode, value || base_val)
    }

    const feedsInstantInfos = () => {
      const datas = [] as any [];
      for (const key of Object.keys(feeds)) {
        datas.push(feeds[key]);
      }
      return <table border={1} style={{ 'color': 'white' }}>
        <tbody>
        {datas.map((it, i) => <tr>
          <td style={{ 'whiteSpace': 'nowrap' }}>{it.feed}</td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{statusBarByTimestamp(it.datemark, [10, 20], $$.getDateString(it.datemark))}</td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{it.data}</td>
        </tr>)}
        </tbody>
      </table>;
    };

    const hostsInstantInfos = () => {
      const datas = [] as any [];
      for (const key of Object.keys(hosts)) {
        datas.push(hosts[key]);
      }

      return <table border={1} style={{ 'color': 'white' }}>
        <tbody>
        <tr>
          <th>Host</th>
          <th style={{ 'textAlign': 'left' }}>{$$.getDateAsString()}</th>
          <th colSpan={3}>Disk usage</th>
        </tr>
        {datas.map((it, i) => <tr>
          <td style={{ 'whiteSpace': 'nowrap' }}>{it.host}</td>
          {/*<td style={{ 'whiteSpace': 'nowrap' }}>{statusBarByTimestamp(it.time, [ 60, 125 ], `${it.host} ${$$.getDateString(it.time)}`)}</td>*/}
          <td style={{ 'whiteSpace': 'nowrap' }}>{statusBarByTimestamp(it.time, [ 60, 125 ], `${$$.getDateString(it.time)}`)}</td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{bitesToGb(it.disk.total)}</td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{bitesToGb(it.disk.free)}</td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{statusBarByValue(it.disk.usage, [70, 85], `${it.disk.usage}%`)}</td>
        </tr>)}
        </tbody>
      </table>;
    };

    const runningApplication = () => {
      const datas = apps.map(app => app.data);

      const disk_usage = it => it['disk'] ? it['disk']['percent'] : -1;
      // style={{whiteSpace: "nowrap"}}
      return <table border={1} style={{ 'color': 'white', whiteSpace: "nowrap", 'fontSize': '86%' }}>
        <tbody>
        <tr>
          <th>name</th>
          <th>pid</th>
          <th>host/docker</th>
          <th></th>
          <th>started</th>
          <th>uptime</th>
          <th style={{ 'textAlign': 'left' }}>{$$.getDateAsString()}</th>
          <th>disk</th>
          <th>cpu_load</th>
          <th>mem.rss</th>
          <th>mem.rss1</th>
          <th>mem.rss3</th>
          <th>mem.rss15</th>
          <th>processed</th>
        </tr>
        {datas.map((it, i) => <tr>
          <td style={{ 'whiteSpace': 'nowrap' }} title={it.app}>[{i}] {it.feed}</td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{it.pid}</td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{it.hostname} &nbsp;
            {it.docker === 'True' && <CheckCircleFilled style={{ color: 'blue', fontSize: '1', fontStyle: 'bold' }}/>}
          </td>
          <td style={{ 'whiteSpace': 'nowrap' }}>
            {! it.docker && <CloseCircleOutlined onClick={() => this.killProcess(it)}
                            style={{ color: 'red', fontSize: '1', fontStyle: 'bold' }}/> }
          </td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{it.started}</td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{getUptime(it)}</td>
          <td
            style={{ 'whiteSpace': 'nowrap' }}>{statusBarByTimestamp(Date.parse(it.checked + ' GMT'), [ 60, 125 ], it.checked)}</td>
          <td>{statusBarByValue(disk_usage(it), [ 75, 85 ], disk_usage(it) + '%')}</td>
          <td>{statusBarByValue(it['sys.cpu_load'], [ 75, 85 ])}</td>
          <td>{statusBarByValue(it['mem.rss1'].current - it.max_rss, [ -2, -1, 50 ], `${it['mem.rss1'].current} - ${it.max_rss} (${round(it['mem.rss1'].current - it.max_rss, 2)})`, 'skip-minus')}</td>
          <td>{statusBarByValue(it['mem.rss1'].percent, [ 0.2, 1 ], `${it['mem.rss1'].current} (${it['mem.rss1'].percent}%)`)}</td>
          <td>{statusBarByValue(it['mem.rss3'].percent, [ 0.2, 1 ], `${it['mem.rss3'].current} (${it['mem.rss3'].percent}%)`)}</td>
          <td>{statusBarByValue(it['mem.rss15'].percent, [ 0.2, 1 ], `${it['mem.rss15'].current} (${it['mem.rss15'].percent}%)`)}</td>

          <td>{it['processed_success']}</td>
        </tr>)}
        </tbody>
      </table>;
    };

    const appInstantInfoDetails = () => {
      const datas = [] as any [];
      for (const key of Object.keys(streams)) {
          datas.push({ ...streams[key], name: key });
      }
      const queue_line = it =>  it ? `${it.in} [${it.qsize}] (${it.ps} in sec)` : '';

      const stored_in_db = it => it && it['database:stored'] ? it['database:stored']['processed'] : '';

      return <table border={1} style={{ 'color': 'white' }}>
        <tbody>
        <tr>
          <th colSpan={2}>name</th>
          <th colSpan={5}>queues</th>
          <th>stored to DB</th>
        </tr>
        <tr>
          <th></th>
          <th style={{'textAlign': 'left'}}>{$$.getDateAsString()}</th>
          <th>input</th>
          <th>database</th>
          <th>candles</th>
          <th>multicast</th>
          <th>redis</th>
          {/*<th>redis candles</th>*/}
          {/*<th>control</th>*/}
          <th></th>
        </tr>
        {datas.map((it, i) => <tr>
          <td style={{ 'whiteSpace': 'nowrap' }}>[{i}] {it.name}</td>
          <td style={{ 'whiteSpace': 'nowrap' }}>{statusBarByTimestamp(it.datemark, [10, 20], $$.getDateString(it.datemark))}</td>
          {[ 'queue:input', 'queue:dbstore', 'queue:candle', 'queue:multicast', 'queue:redis'].map(p =>
            <td
              style={{ 'whiteSpace': 'nowrap' }}>
              {statusBarByValue(it[p] ? it[p]['qsize'] : -1, [ 50, 200, 1000 ], queue_line(it[p]))}
            </td>)}
          <td style={{ 'whiteSpace': 'nowrap' }}><b>{stored_in_db(it)}</b></td>
        </tr>)}
        </tbody>
      </table>;
    };

    const getTasks = (kind: string) => tasks[kind] || [];
    const startAction = (action: string) => () => this.props.startSyncAction(action);


    const isWorking = (taskName: string) => {
      const started = getTasks('running');
      const taskRun = started.find(it => it.name === taskName);
      return !!taskRun;
    };

    const workingStatus = (taskName: string) =>
      isWorking(taskName) ? <span style={{ 'color': 'white' }}>working...</span> : <></>;

    return <div>
      <h1 style={{ 'color': 'white' }}>NEW BRIDGE Admin</h1>
      <h3 style={{ 'color': 'white' }}>{currentDateTime}</h3>

      <Stack>
        <div><Button onClick={this.getBuildInfo}>Build Info</Button></div>
        {this.isOpened('BuildInfo')
          && <pre style={{ 'color': 'white' }}>{build_info}</pre>
        }
        <p/>
      </Stack>

      <Stack>
        <div><Button onClick={this.getDataStatistics}>Data Statistics</Button></div>
        <div style={{ 'color': 'white' }}>
          {this.isOpened('DataStatistics') &&
             <>{getTasks('data_statistics').length === 0 ?
                <div>&nbsp;&nbsp;&nbsp;<span style={{'color': 'orange'}}>Statistics calculated. Please wait ....
                  </span>
                </div>
               :
                <table style={{'color': 'white'}}>
                  <tbody>
                  {getTasks('data_statistics').map((it, i) => getDataStatisticsRow(it, i))}
                  </tbody>
                </table>
             }</>
          }
        </div>
      </Stack>

      <Stack>
        <div><Button onClick={() => this.toggleTab('SystemInfo')}>System info</Button></div>
        {this.isOpened('SystemInfo')
          && systemInfos()
        }
        <p/>
      </Stack>

      <Stack>
        <div><Button onClick={() => this.toggleTab('HostsInfo')}>Hosts info</Button></div>
        {this.isOpened('HostsInfo')
          && hostsInstantInfos()
        }
        <p/>
      </Stack>

      <Stack>
        <div><Button onClick={() => this.toggleTab('FeedInfo')}>Feed info</Button></div>
        {this.isOpened('FeedInfo')
          && feedsInstantInfos()
        }
        <p/>
      </Stack>

      <Stack>
        <div><Button onClick={() => this.toggleTab('appInstantInfos')}>Running Applications</Button></div>
        {this.isOpened('appInstantInfos')
          && runningApplication()
        }
        <p/>
      </Stack>

      <Stack>
        <div><Button onClick={() => this.toggleTab('appInstantInfoDetails')}>Apps Details</Button></div>
        {this.isOpened('appInstantInfoDetails')
          && appInstantInfoDetails()
        }
        <p/>
      </Stack>

      <Stack>
        <div><Button onClick={() => this.toggleTab('TasksInfo')}>Tasks Status Log</Button></div>
        {this.isOpened('TasksInfo')
          && taskKinds.map(kind => <div style={{ 'color': taskColors[kind] }}>
            <br/>{kind} : <>{
              getTasks(kind).length > 0
                ? <><div><Button onClick={() => this.resetTaskLogs()}>Reset tasks log</Button></div>
                  <table border={1} style={{ 'borderColor': 'grey' }}>
                    <tbody>
                    {getTasks(kind).map((task: TaskInterface, idx) => idx < 20 && getRow(kind, task, ''))}
                    </tbody>
                  </table>
                </>
                : <span style={{ 'color': 'green' }}>no for now...</span>}</>
          </div>)}
        <p/>
      </Stack>

      <Stack>
        <div><Button onClick={() => this.toggleTab('TasksStarter')}>Tasks Starters</Button></div>
        {this.isOpened('TasksStarter')
          && <hr style={{ 'color': 'white', 'height': '2px', 'width': '100%' }}/>
          && <>{this.props.taskNames.map((taskName: TaskNameInfo) => <div><Button
            onClick={startAction(taskName.name)}>{taskName.title || taskName.name}</Button> {workingStatus(taskName.name)}
          </div>)}</>
        }
      </Stack>
    </div>;
  }

  private killProcess(it) {
      this.props.startSyncAction('kill:' + it.pid + ':' + it.feed);
  }
}

const mapStateToProps = ({ adminMain: state }: RootState) => ({
  version: '001', ...state
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    setName:              bindActionCreators(adminDefaultActions.setName, dispatch),
    toggleTab:            bindActionCreators(adminDefaultActions.toggleTab, dispatch),
    updateInstantValues:  bindActionCreators(adminDefaultActions.updateInstantValues, dispatch),
    incrementAsync:       bindActionCreators(incrementAsync, dispatch),
    getBuildInfo:         bindActionCreators(getBuildInfoApi, dispatch),
    getDataStatistics:    bindActionCreators(getDataStatisticsApi, dispatch),
    resetTasksStatLog:    bindActionCreators(resetTasksStatLogApi, dispatch),
    startSyncAction:      bindActionCreators(startSyncActionApi, dispatch),
});


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AdminDefaultComponent);
