import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import {
    loadPortfoliosInfoApi,
    updatePortfolioApi,
    removePortfolioApi,
    loadInstrumentsInfoApi,
    simulatePortfolioApi,

} from './portfolio.api';
import { AnyAction, bindActionCreators, Dispatch } from "redux";

import { ActionMode, COMPONENT_KEY_NAME, getRouteSuffix } from './portfolio.common';

import { Box, Stack } from '@mui/material';
import PortfolioSimulation from "./portfolio-simulation";
import PortfolioListView from 'components/portfolios/portfolio-list/portfolios-view';
import {AssetVolume, Portfolio, PortfolioEmptyItem, PortfolioTask, PortfolioTaskStatusType} from 'models/portfolio';
import PortfolioUpdateView from 'components/portfolios/portfolio-update/portfolio-update-view';
import {dashboardMonitorActions, equityOverviewGraphics, rollingSharpeOptimizeGraphics} from './portfolio.slice';
import { ID } from 'models/common';
import { $$ } from 'utils/utils';
import PortfolioViewOverview from "./portfolio-view-overview";
import { restoreUser, storeUser } from 'store/reducers/auth';
import { wsConnectorResearch } from 'components/system/websocket-component';
import { WS_RESEARCH_URL } from 'config';
import {getIndexByPortfolioId, getPortfolioByIndex} from './helper';
import {goTo} from "../../utils/react-utils";
import AppRoutes from "routes/AppRoutes";
import { withRouter } from 'components/util-components/with_router';
import PortfolioOptimization from "./portfolio-optimization";
import {TabItem} from "../../components/tab-component";
import {mode} from "d3";
import {dateSerializerNew} from "../../components/controls/form/nb-data-picker";
import PortfolioAnalysis from "./portfolio-analysis";
import {randomString} from "../../utils/misc";



interface ComponentProps extends StateProps, DispatchProps {}

let portfolioSimulationScheduler;

class DashboardMonitorComponent extends React.Component<ComponentProps> {

    getBaseRouteStr = () => '/' +  AppRoutes.WEALTH_LAB;

    getUser = () => restoreUser();


    initWs(userId) {
        // console.log('ÍNITWS', userId);
        // console.log(!wsConnectorResearch.ready)
      if (!wsConnectorResearch.ready) {
          const secureKey  = 'RSX';
          wsConnectorResearch.url = `${WS_RESEARCH_URL}/${userId}/${secureKey}`;
          console.log("############# wsConnectorResearch.url::", wsConnectorResearch.url)
          setInterval(() => {
              wsConnectorResearch.send('heartbeat')
          },20000);


          wsConnectorResearch.init_receiver((payload) => {
            let error: string | undefined = undefined;
            try {
                const data = JSON.parse(payload.data || '{}');

               if(data.result === 'error') {
                  error = data['err-msg']
                  console.log('WEBSOCKET DATA REPORTED ERROR::', data)
                  this.props.reportTaskError({data, message: error})
               }
               else {
                 console.log('############### PORTFOLIO. WEBSOCKET Payload::DATA', data);
                 if (data.heartbeat || !data['msg']) {
                   return;
                 }
                 this.props.applySimulationWsResult(data);
                 const action = data['msg'] === 'task-started' ? 'task-started'
                   : data['msg'].startsWith('simulation') ? 'task-completed' : null;
                 if (action) {
                   const taskId = data.taskId;
                   wsConnectorResearch.send_event({ msg: action, taskId });
                 }
               }
            }
            catch (e) {
              this.props.reportTaskError({data: {}, message: 'Unexpected error'})
            }
          });
      }
  }

  componentDidMount() {
    const { assets, portfolios, currentPortfolioIndex } = this.props;
    const { location, navigate, params } = this.props['router'];
    const user = this.getUser();

    if (!user) {
      return;
    }
    const useWebsocketTasks = true;
    if (useWebsocketTasks) {
      this.initWs(user.id);
    }

    if (!portfolios  || portfolios.length === 0) {
      this.props.loadPortfoliosInfo();
    }
    if (! assets || assets.length === 0) {
      this.props.loadInstrumentsInfo();
    }
    if (!portfolioSimulationScheduler) {
      portfolioSimulationScheduler = setInterval(() => {
        this.props.runPeriodic({});
      // }, 3000);
      }, 120000);
    }
    if(params.id && location.pathname !== '/wealthLab') {
      if(assets.length !== 0 && portfolios.length !== 0) {
        setTimeout(() => this.props.refresh({mode: ActionMode[`${params.action}`], targetId:`${params.id}`}),500);
      } else {
        setTimeout(() => this.props.refresh({mode: ActionMode[`${params.action}`], targetId:`${params.id}`}),1000);
      }
    }
      this.getUpdatePortfolio(params.id);
  }

  getSnapshotBeforeUpdate () {
    return this.props;
  }

  componentDidUpdate() {

  }



  reset = () => {
    this.props.reset();
  }

  setValue = () => {
    this.props.setValue('')
  }

  selectPortfolio = (item: Portfolio) => {
  }

  clonePortfolio = (targetId: ID) => {
    const base = this.props.portfolios.find(it => it.id === targetId) as Portfolio;
    const portfolio = { ...base, id: '', name: base.name + ' CLONED' };
    // @ts-ignore
    portfolio.assets = base.assets.map(it => ({ id: it.id, value: it.value }));
    this.updatePortfolio(portfolio);
  }

  onRefresh = (updateParams: {}) => {
    if(updateParams['mode'] === ActionMode.clonePortfolio) {
      this.clonePortfolio(updateParams['targetId']);
    } else {
      this.props.refresh(updateParams);
    }
  }

  getUpdatePortfolio = (id) => {
        setTimeout(() => this.props.applyTemporraryPortfolioTask(id), 800);
  }

  setCurrentTask = (seqn: number) => this.props.setCurrentTask(seqn);

  updatePortfolio = (portfolio: Portfolio) => {
    // @ts-ignore
    this.props.updatePortfolio(portfolio);
  }

  removePortfolio = (portfolioId: ID) => {
    // @ts-ignore
    this.props.removePortfolio({id: portfolioId});
  }

  portfolioCompleted = (portfolio: Portfolio) => {
    this.updatePortfolio(portfolio);
  }

    // windowID = 'windowID' + randomString();

    simulatePortfolio = (portfolio: Portfolio) => {
      const user = restoreUser();
    const dateStart = () => portfolio._dateStart;
    const dateEnd = () => portfolio._dateEnd;
    const inceptionDate = () => {
        if(portfolio.inceptionDate !== null) {
            const inception = dateSerializerNew(new Date(portfolio.inceptionDate * 1000));
            return inception;
        } else {
           return  portfolio._dateStart;
        }
    };

    const benchmark = () =>  {
        if(portfolio.benchmark !== null||undefined) {
            const bench = this. benchmarkList().find(it => it.id === portfolio.benchmark.instrumentId) || portfolio.benchmark;
            return {symbol: bench?.symbol};
        }
    };
    const msg = () => portfolio.msg;

    const extraInstruments = portfolio.extra_instruments;

    const instrumentAssets = portfolio.assets.map((it,idx) => {
      return {symbol: it.symbol, weight: it.value}
    });

        let simulationSeq = user['simulationSeqn'] || 0;
        simulationSeq += 1
        user['simulationSeqn'] = simulationSeq;
        storeUser(user);

    const datawithoutBench = {
        // msg : 'simulation-request',
        msg:msg(),
        ts: Date.now().valueOf(),
        // dateStart: dateStart(),
        dateStart: msg() === 'simulation-request' ? inceptionDate() : dateStart(),
        dateEnd: dateEnd(),
        seqn: simulationSeq,
        rebalance_interval:portfolio.frequency,
        referencing_currency:portfolio.currency,
        // optimized_method:portfolio.optimized_method,
        optimized_function:portfolio.optimized_function,
        clientId: user.id,
        instruments: instrumentAssets,
        extra_instruments:extraInstruments
    }

    const data = portfolio.benchmark.symbol === '*'  ?  datawithoutBench : {...datawithoutBench, benchmark:benchmark()}
    // @ts-ignore
    this.props.simulatePortfolio(data);
  }

  startSimulation = (portfolio: Portfolio) => {
    this.simulatePortfolio(portfolio);
  }

  portfolioRemove = (portfolioId: ID) => {
    this.removePortfolio(portfolioId);
  }
 cleanAssets = () => {
    const withoutLongShort =  this.props.assets.filter((item, idx) => {
        return item.name !== 'Crypto Long/Short';
    });
    return withoutLongShort;
 };
  cleanAssetsCurrent = () => {
      const withoutLongShort = this.props.assetsCurrent.filter((item,idx) => {
          return item.name !== 'Crypto Long/Short';
      });
      return withoutLongShort;
  }

  assetOptions = () => $$.isNonEmptyArray(this.props.assetsCurrent)
    // ? this.props.assetsCurrent : this.props.assets;
    ? this.cleanAssetsCurrent() : this.cleanAssets();


  benchmarkList = () => $$.isNonEmptyArray(this.props.assetsCurrent)
    // ? this.props.assets : this.props.assets;
    ? this.cleanAssets() : this.cleanAssets();



  extraInstrumentsList = () => $$.isNonEmptyArray(this.props.assetsCurrent)
    // ? this.props.assets : this.props.assets;
    ? this.cleanAssets() : this.cleanAssets();

  sortBy = (field:string, sorter)  => {
      this.props.sortPortfolioBy({field, sorter});
  }

  render() {
    const {
        actionMode,
        portfolios,
        currentPortfolioIndex,
        equityOverviewCurrentCaseStr,
        equityOverviewData,
        equitySimulationData,
        equitySimulationCurrentCaseStr,
        keyMetricsOverviewData,
        performanceMetricsOverviewData,
        keyMetricsSimulationData,
        simulationAllocationData,
        annualReturnsSimulationData,
        monthlyReturnsOverviewData,
        underwaterPlotData,
        worstDrawDownsData,
        drawdownPeriodsData,
        rollingVolatilityData,
        annualReturnsOverviewData,
        dailyReturnsOverviewData,
        rollingSharpeCurrentCaseStr,
        rollingSharpeData,
        rollingSharpeSimulatedCurrentCaseStr,
        rollingSharpeSimulatedData,
        rollingSharpeOptimizeCurrentCaseStr,
        rollingSharpeOptimizeData,
        rollingSortinoCurrentCaseStr,
        rollingSortinoData,
        rollingSortinoSimulatedCurrentCaseStr,
        rollingSortinoSimulatedData,
        rollingSortinoOptimizedCurrentCaseStr,
        rollingSortinoOptimizedData,
        keyMetricsOverviewCurrentCaseStr,
        distributionMonthlyCurrentCaseStr,
        distributionMonthlyData,
        errorMessage,
        currentControlDate,
        currentSortField,
        apiStatus,
        startTimeRequest,

    } = this.props
    const { location, navigate, params } = this.props['router'];
    if (apiStatus === '401') {

    }
    // const portfolio = getPortfolioByIndex(portfolios, currentPortfolioIndex);
    const portfolio = () => {
        const index = getIndexByPortfolioId(portfolios,params.id);
        if(currentPortfolioIndex !== -1 && currentPortfolioIndex === index) {
           return getPortfolioByIndex(portfolios, currentPortfolioIndex);
        } else {
           return getPortfolioByIndex(portfolios,index);
        }
    };

    const suffix = getRouteSuffix(actionMode, portfolio());

      // console.log(this.windowID)
    const handleChange = (item: TabItem) => {
      this.props.setSection(item);
    };

    const isCurrentPageId = portfolio().id === params.id;

    return (
        <>
          {location.pathname.includes('newPortfolio') || location.pathname.includes('editPortfolio')  && isCurrentPageId
            ? <PortfolioUpdateView
                possibleAssets={this.assetOptions()}
                possibleBenchmark={this.benchmarkList()}
                currentPortfolio={portfolio()}
                currentAssetVolume={this.props.currentAssetVolume}
                holder={this.props.holder}
                onRefresh={this.onRefresh}
                onComplete={this.portfolioCompleted}
                onRemove={this.portfolioRemove}
                extraCall={this.simulatePortfolio}
                currentControlDate={currentControlDate}
                portfolioList={portfolios}
              />
          :location.pathname.includes('analysisPortfolio') && isCurrentPageId
            ? <PortfolioAnalysis
                 possibleAssets={this.assetOptions()}
                 portfolio={portfolio()}
                 onRefresh={this.onRefresh}
                 holder={this.props.holder}
                 actionMode={actionMode}
                 possibleBenchmark={this.benchmarkList()}
                 currentAssetVolume={this.props.currentAssetVolume}
                 onRemove={this.portfolioRemove}
                 extraCall={this.simulatePortfolio}
                 onChange={handleChange}
                 extraInstrumentsList={this.extraInstrumentsList()}
                 portfolioList={portfolios}
                 simulationAllocation={simulationAllocationData}
                 caseStr={equityOverviewCurrentCaseStr}
                 renderData={equityOverviewData}
                 keyMetricksData={keyMetricsOverviewData}
                 currentControlDate={currentControlDate}
                 performanceMetrics={performanceMetricsOverviewData}
                 worstDrawDownData={worstDrawDownsData}
                 dailyReturnsData={dailyReturnsOverviewData}
                 annualReturns={annualReturnsOverviewData}
                 underwaterPlot={underwaterPlotData}
                 rollingVolatility ={rollingVolatilityData}
                 rollingSharpe={rollingSharpeData}
                 rollingSharpeCaseString={rollingSharpeCurrentCaseStr}
                 rollingSortino={rollingSortinoData}
                 rollingSortinoCaseString={rollingSortinoCurrentCaseStr}
                 monthlyReturns={monthlyReturnsOverviewData}
                 distributionMonthlyCaseString={distributionMonthlyCurrentCaseStr}
                 distributionMothlyData={distributionMonthlyData}
                 setCurrentTask={this.setCurrentTask}
                 drawdownPeriodsData={drawdownPeriodsData}
                 startTimeRequest={startTimeRequest}
            />
          :<Stack direction="row">
              <PortfolioListView
                portfolios={portfolios}
                currentPortfolio={portfolio()}
                selectFunction={this.selectPortfolio}
                holder={this.props.holder}
                onRefresh={this.onRefresh}
                onRemove={this.portfolioRemove}
                extraCall={this.simulatePortfolio}
                currentSortField={currentSortField}
                sortByFunction={this.sortBy}
             />
          </Stack>}
        </>
    )

  }
}

const ownSettings = {
    version: '0.01'
};


const mapStateToProps = ( { [COMPONENT_KEY_NAME]: state } : RootState) => ({
  ...ownSettings, ...state
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    loadPortfoliosInfo: bindActionCreators(loadPortfoliosInfoApi, dispatch),
    updatePortfolio:    bindActionCreators(updatePortfolioApi, dispatch),
    removePortfolio:    bindActionCreators(removePortfolioApi, dispatch),
    loadInstrumentsInfo:bindActionCreators(loadInstrumentsInfoApi, dispatch),
    simulatePortfolio:  bindActionCreators(simulatePortfolioApi, dispatch),
    reset:              bindActionCreators(dashboardMonitorActions.reset, dispatch),
    setValue:           bindActionCreators(dashboardMonitorActions.setValue, dispatch),
    setCurrentTask:     bindActionCreators(dashboardMonitorActions.setCurrentTask, dispatch),
    applySimulationWsResult: bindActionCreators(dashboardMonitorActions.applySimulationWsResult, dispatch),
    refresh:            bindActionCreators(dashboardMonitorActions.refresh, dispatch),
    runPeriodic:        bindActionCreators(dashboardMonitorActions.runPeriodic, dispatch),
    setTestData:        bindActionCreators(dashboardMonitorActions.setTestData, dispatch),
    setSection:         bindActionCreators(dashboardMonitorActions.setSection, dispatch),
    sortPortfolioBy:    bindActionCreators(dashboardMonitorActions.sortPortfolioBy, dispatch),
    reportTaskError:    bindActionCreators(dashboardMonitorActions.reportTaskError, dispatch),
    applyTemporraryPortfolioTask:    bindActionCreators(dashboardMonitorActions.applyTemporraryPortfolioTask, dispatch),
    // setResultResponse: bindActionCreators(dashboardMonitorActions.setResultResponse, dispatch),
    // getPortfolioTask: bindActionCreators(dashboardMonitorActions.getPortfolioTask, dispatch),
    setCurrentControlDate: bindActionCreators(dashboardMonitorActions.setCurrentControlDate,dispatch),
});


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;


const DashboardMonitorComponentWithRouter = withRouter(DashboardMonitorComponent);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardMonitorComponentWithRouter);
