/**
 * Set of common types
 * */


export type AsyncApiStatus =  'idle' | 'pending' | 'succeeded' | 'rejected' | 'failed' | '401';
export type AsyncInProgressStatus =  'inprogress' | 'done';

export interface CandleResponse {
    duration: number;
    keys: string[];
    candles: any[];
}

export const EmptyCandleResponse = {
    duration: 0,
    keys:[],
    candles: []
} as CandleResponse;
