import { TabItem } from '../components/tab-component';

export const WORLD_MARKETS_PANEL_ID = 'WORLDMARKETS';
export const SECTOR_INDICES_PANEL_ID = 'SECTORINDICES';
export const STRESS_INDICES_PANEL_ID = 'STRESSINDICES';
export const EQUITY_CURVE_ID = 'EQUITYCURVE';
export const EQUITY_CURVE_SIMULATION_ID = 'EQUITYCURVESIMULATION';
export const EQUITY_CURVE_OPTIMIZATION_ID = 'EQUITYCURVEOPTIMIZATION';
export const ANNUAL_RETURNS_ID = 'ANNUALRETURNS';
export const ANNUAL_RETURNS_SIMULATION_ID = 'ANNUALRETURNSSIMULATION';
export const ANNUAL_RETURNS_OPTIMIZATION_ID = 'ANNUALRETURNSOPTIMIZATION';
export const ROLLING_SHARPE_ID = 'ROLLINGSHARPE';
export const ROLLING_VOLATILITY_ID = 'ROLLINGVOLATILITY';
export const ROLLING_VOLATILITY_SIMULATION_ID = 'ROLLINGVOLATILITYSIMULATION';
export const ROLLING_VOLATILITY_OPTIMIZATION_ID = 'ROLLINGVOLATILITYOPTIMIZATION';
export const ROLLING_BETA_BENCHMARK_ID = 'ROLLINGBETABENCHMARK';
export const ROLLING_SORTINO_ID = 'ROLLINGSORTINO';
export const RETURN_QUANTILES_ID = 'RETURNQUANTILES';
export const DAILY_RETURNS_PORTFOLIO_ID = 'DAILYRETURNSPORTFOLIO';
export const VALUE_SHORTFALL_ID = 'VALUESHORTFALL';
export const DRAWDOWN_PERIODS_ID = 'DRAWDOWNPERIOD';
export const UNDERWATER_PLOT_ID = 'UNDERWATERPLOT';
export const DISTRIBUTION_RETURN_ID = 'DISTRIBUTIONRETURN';
export const EFFICIENT_FRONTIER_ID = 'EFFICIENTFRONTIER';
export const STRESSINDEX = 'STRESSINDEX';
export const CROSSASSET = 'CROSSASSET';
export const CRYPTO = 'CRYPTO';
export const WORLDWIDE = 'WORLDWIDE';
export const ASIA = 'ASIA';
export const EUROPE = 'EUROPE';
export const US = 'US';
export const THRESHOLD = 'THRESHOLD';
export const US_STOCKS = 'US_STOCKS';
export const CLIENT_PORTFOLIO_INITIAL = 'CLIENT';
export const MEAN_CLIENT_PORTFOLIO_INITIAL = 'MEANCLIENT';
export const DAILY = 'DAILY';
export const WEEKLY = 'WEEKLY';
export const MONTHLY = 'MONTHLY';
export const QUARTERLY = 'QUARTERLY';
export const YEARLY = 'YEARLY';
export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';
export const HIGH = 'HIGH';
export const LOW = 'LOW';
export const ROMB = 'ROMB';
export const DRAWDOWN ='DRAWDOWN';
export const FREQUENCY ='FREQUENCY';
export const ROLLSHARPE = 'ROLLSHARPE';
export const ROLLSORTINO = 'ROLLSORTINO';





const SECTOR_INDICES_CROSSASSET = SECTOR_INDICES_PANEL_ID + ':' + CROSSASSET;
const SECTOR_INDICES_CRYPTO     = SECTOR_INDICES_PANEL_ID + ':' + CRYPTO;

const STRESS_INDICES_CRYPTO     = STRESS_INDICES_PANEL_ID + ':' + CRYPTO;

const X_WORLD_MARKETS_WORLDWIDE = 'X_' + WORLD_MARKETS_PANEL_ID + ':' + WORLDWIDE;
const WORLD_MARKETS_WORLDWIDE = WORLD_MARKETS_PANEL_ID + ':' + WORLDWIDE;
const WORLD_MARKETS_ASIA      = WORLD_MARKETS_PANEL_ID + ':' + ASIA;
const WORLD_MARKETS_EUROPE    = WORLD_MARKETS_PANEL_ID + ':' + EUROPE;
const WORLD_MARKETS_US        = WORLD_MARKETS_PANEL_ID + ':' + US;

const EQUITY_CURVE_SIMULATION_INITIAL = EQUITY_CURVE_SIMULATION_ID + ':' + CLIENT_PORTFOLIO_INITIAL;
const EQUITY_CURVE_OPTIMIZATION_INITIAL = EQUITY_CURVE_OPTIMIZATION_ID + ':' + CLIENT_PORTFOLIO_INITIAL;
const EQUITY_CURVE_CLIENT_PORTFOLIO_INITIAL = EQUITY_CURVE_ID + ':' + CLIENT_PORTFOLIO_INITIAL;

const ANNUAL_RETURNS_CLIENT_PORTFOLIO_INITIAL = ANNUAL_RETURNS_ID + ':' + CLIENT_PORTFOLIO_INITIAL;
const ANNUAL_RETURNS_SIMULATION_CLIENT_PORTFOLIO_INITIAL = ANNUAL_RETURNS_SIMULATION_ID + ':' + CLIENT_PORTFOLIO_INITIAL;
const ANNUAL_RETURNS_OPTIMIZATION_CLIENT_PORTFOLIO_INITIAL = ANNUAL_RETURNS_OPTIMIZATION_ID + ':' + CLIENT_PORTFOLIO_INITIAL;

const ROLLING_SHARPE_CLIENT_PORTFOLIO_INITIAL = ROLLING_SHARPE_ID + ':' + CLIENT_PORTFOLIO_INITIAL;
const ROLLING_VOLATILITY_CLIENT_PORTFOLIO_INITIAL = ROLLING_VOLATILITY_ID + ':' + CLIENT_PORTFOLIO_INITIAL;
const ROLLING_VOLATILITY_SIMULATION_CLIENT_PORTFOLIO = ROLLING_VOLATILITY_SIMULATION_ID + ':' + CLIENT_PORTFOLIO_INITIAL;
const ROLLING_VOLATILITY_OPTIMIZATION_CLIENT_PORTFOLIO = ROLLING_VOLATILITY_OPTIMIZATION_ID + ':' + CLIENT_PORTFOLIO_INITIAL;
const ROLLING_BETA_BENCHMARK_CLIENT_PORTFOLIO_INITIAL = ROLLING_BETA_BENCHMARK_ID + ':' + CLIENT_PORTFOLIO_INITIAL;

const ROLLING_SORTINO_CLIENT_PORTFOLIO_INITIAL = ROLLING_SORTINO_ID + ':' + CLIENT_PORTFOLIO_INITIAL;

const DAILY_RETURNS_PORTFOLIO_DAILY = DAILY_RETURNS_PORTFOLIO_ID + ':' + DAILY;
const DISTRIBUTION_RETURN_FREQUENCY = DISTRIBUTION_RETURN_ID + ':' + FREQUENCY;

const RETURN_QUANTILES_DAILY = RETURN_QUANTILES_ID + ':' + DAILY;
const VALUE_SHORTFALL_DAILY = VALUE_SHORTFALL_ID + ':' + DAILY;
const DRAWDOWN_PERIODS_CLIENT_PORTFOLIO_INITIAL = DRAWDOWN_PERIODS_ID + ':' + CLIENT_PORTFOLIO_INITIAL;
const UNDERWATER_PLOT_DRAWDOWN = UNDERWATER_PLOT_ID + ':' + DRAWDOWN;
const EFFICIENT_FRONTIER_PORTFOLIO = EFFICIENT_FRONTIER_ID + ':' + CLIENT_PORTFOLIO_INITIAL;





export const IndexBySectors = {
  'DJI':    { name: 'Dow Jones',          marker: 'ZZ', fullname: 'Dow Jones Industrial Average' },
  'SPX':    { name: 'S&P 500',            marker: 'ZZ', fullname: "Standard & Poor's 500" },
  'DJCI':   { name: 'Commodities',        marker: 'ZZ', fullname: 'Dow Jones Commodity Index' },
  'DJCIGC': { name: 'Gold',               marker: 'ZZ', fullname: 'Dow Jones Commodity Index Gold' },
  'DJCIFC': { name: 'Dow Jones Commodity Feeder Cattle',
                                          marker: 'ZZ', fullnsme: 'Dow Jones Commodity Index Feeder Cattle' },
  'DJSH':   { name: 'Dow Jones Shanghai', marker: 'ZZ', fullname: 'Dow Jones Shanghai Index' },
  'E1DOW':  { name: 'Dow Jones Europe',   marker: 'ZZ', fullname: 'Dow Jones Europe Index' },
  'DJSZ':   { name: 'Dow Jones Shenzhen', marker: 'ZZ', fullname: 'Dow Jones Shenzhen Index' },
  'W1DOW':  { name: 'Global Equities',    marker: 'ZZ', fullname: 'Dow Jones Global Index' },
  'NQG35':  { name: 'Real Estate',        marker: 'ZZ', fullname: 'NASDAQ Global Real Estate Index' }
}

export const Graphics = {
  [SECTOR_INDICES_CROSSASSET]: {
    graphics: [
      { title: 'Crypto',              id: 'CRYPTO',       graphColor: '#FF8A53'},
      { title: 'Equities',            id: 'W1DOW',        graphColor: '#6F0EFE'},
      { title: 'Commodities',         id: 'DJCI',         graphColor: '#975487'},
      { title: 'Real Estate',         id: 'NQG35',        graphColor: '#6F7E5E'},
      // { title: 'Global Equities',     id: 'W1DOW',        graphColor: '#6F0EFE'},
      { title: 'Bonds',          id: 'BNDX',          graphColor: '#67A4B4'},


      // { title: 'Equities',            id: 'W1DOW',        graphColor: 'cyan'},
      // { title: 'S&P 500',            id: 'SPX',         graphColor: '#67A4B4'},
      // { title: 'Dow Jones Europe',   id: 'E1DOW',       graphColor: '#6F7E5E'},
      // { title: 'Gold',               id: 'DJCIGC',      graphColor: 'yellow'},
    ] as TabItem[]
  },
  SECTOR_INDICES_CROSSASSET_X: {
    graphics: [
      { title: 'Crypto Total',   id: 'CRYPTOTOTAL', graphColor: '#FF8A53'},
      { title: 'S&P 500',        id: 'SP500',       graphColor: '#67A4B4'},
      { title: 'Commodities',    id: 'COMMODITIES', graphColor: '#975487'},
      { title: 'Global Bonds',   id: 'GLOBALBONDS', graphColor: '#6F7E5E'},
    ] as TabItem[]
  },
  [SECTOR_INDICES_CRYPTO]: {
    graphics: [
      { title: 'DeFi',                       id: 'DEFI',         graphColor: '#B5D7DC'},
      // { title: 'WEB3',                id: 'WEB3',         graphColor: '#FF8A53'},
      { title: 'NFT',                        id: 'NFT',          graphColor: '#67A4B4'},
      { title: 'Pure Cryptos',               id: 'PPRIVACY',     graphColor: '#6F7E5E'},
      { title: 'Privacy',                    id: 'PRIVACY',      graphColor: '#C196B8'},
      { title: 'Smart Contract Platforms',   id:'SMART',         graphColor: '#FFCB82'},

      // { title: 'Stablecoin',          id: 'STABLECOIN',   graphColor: '#6F7E5E'},
    ] as TabItem[]
  },
  [STRESS_INDICES_CRYPTO]: {
    graphics: [
      { title: 'Crypto Stress Index',  id: 'STRESS_CRYPTO',     graphColor: '#FF8A53'},
      // { title: 'threshold',            id: 'STRESS_THRESHOLD',  graphColor: '#AEA49B'},
    ] as TabItem[]
  },
  [X_WORLD_MARKETS_WORLDWIDE]: {
    graphics: [
      { title: 'Nikkei 225',    id: 'NIKKEI225',  graphColor:   '#CDBEB4', section: ASIA },
      { title: 'Hang Seng',     id: 'HANGSENG',   graphColor:   '#B5D7DC', section: ASIA },
      { title: 'FTSE 100',      id: 'FTSE100',    graphColor:   '#CDBEB4', section: EUROPE },
      { title: 'DAX',           id: 'DAX',        graphColor:   '#B5D7DC', section: EUROPE },
      { title: 'S&P 500',       id: 'SP500',      graphColor:   '#C196B8', section: US },
      { title: 'Crypto Total MC Index',
                                id: 'CRYPTOTOTAL',graphColor:   '#FF8A53', section: CRYPTO },
    ] as TabItem[]
  },
    [WORLD_MARKETS_WORLDWIDE]: {
    graphics: [
      // { title: 'NQG35',         id: 'NQG35',    graphColor:   '#CDBEB4', section: ASIA },
      // { title: 'base',  id: 'base',   graphColor:   '#a9a9a9', section: '' },
      { title: 'Nikkei 225',          id: 'N225',    graphColor:   '#B5D7DC', section: ASIA },
      // { title: 'DJI',           id: 'DJI',      graphColor:   '#B5D7DC', section: ASIA },
      { title: 'Hang Seng',           id: 'HSI',      graphColor:   '#67A4B4', section: ASIA },
      // { title: 'DJSZ',          id: 'DJSZ',      graphColor:  '#804A6C', section: ASIA },
      { title: 'FTSE 100',          id: 'FTSE',      graphColor:  '#EAC4D9', section: EUROPE },
      { title: 'DAX',               id: 'GDAXI',      graphColor:  '#975487', section: EUROPE },
      // { title: 'GDAXI',          id: 'GDAXI',      graphColor:  '#804A6C', section: ASIA },
      { title: 'S&P 500',          id: 'SP500TR',   graphColor:  '#FFCB82',   _graphColor:  '#C196B8', section: US },
      // { title: 'DJCIGC',        id: 'DJCIGC',   graphColor:   '#CDBEB4', section: EUROPE },
      // { title: 'W1DOW',         id: 'W1DOW',    graphColor:   'brown', section: EUROPE },
      // { title: 'S&P 500',       id: 'SPX',      graphColor:   '#C196B8', section: US },
      // { title: 'S&P 500',       id: 'SPX',      graphColor:   'red', section: US },
      { title: 'Crypto Total MC Index',  id: 'CRYPTO',   graphColor:   '#FF8A53', section: CRYPTO },
    ] as TabItem[]
  },
//FTSE','GDAXI', 'SP500TR'

  [EQUITY_CURVE_CLIENT_PORTFOLIO_INITIAL] : {
    graphics: [
      {title: 'Client Portfolio Initial', id: 'value', graphColor: '#FF8A53'},
      { title: 'US Stocks',               id: 'benchmark',    graphColor: '#AEA49B'},
    ] as TabItem[]
  },
  [EQUITY_CURVE_SIMULATION_INITIAL]:{
    graphics: [
      {title: 'Simulation',               id: 'simulated', graphColor: '#FF8A53'},
      {title: 'Client Portfolio Initial', id: 'value', graphColor: '#67A4B4'},
      { title: 'US Stocks',               id: 'benchmark',    graphColor: '#AEA49B'},
    ] as TabItem[]
  },
  [EQUITY_CURVE_OPTIMIZATION_INITIAL]:{
    graphics: [
      {title: 'Optimization',               id: 'optimized', graphColor: '#FF8A53'},
      {title: 'Client Portfolio Initial', id: 'value', graphColor: '#67A4B4'},
      { title: 'US Stocks',               id: 'benchmark',    graphColor: '#AEA49B'},
    ] as TabItem[]
  },
  [ANNUAL_RETURNS_CLIENT_PORTFOLIO_INITIAL] : {
    graphics: [
      {title:'Client Portfolio Initial',       id: 'value', graphColor: '#FF8A53' },
      {title: '',                              id: 'value',graphColor: '#C24046'},
      {title:'US Stocks',                      id: 'value',    graphColor: '#CDBEB4'},
    ] as TabItem[]
  },
  [ANNUAL_RETURNS_SIMULATION_CLIENT_PORTFOLIO_INITIAL] : {
    graphics: [
      {title:'Simulated',                      id: 'value', graphColor: '#FF8A53' },
      {title: '',                              id: 'value', graphColor: '#C24046'},
      {title:'Client Portfolio Initial',       id: 'value', graphColor: '#67A4B4' },
      {title:'US Stocks',                      id: 'value', graphColor: '#CDBEB4'},
    ] as TabItem[]
  },
  [ANNUAL_RETURNS_OPTIMIZATION_CLIENT_PORTFOLIO_INITIAL] : {
    graphics: [
      {title:'Optimized',                      id: 'value', graphColor: '#FF8A53' },
      {title: '',                              id: 'value', graphColor: '#C24046'},
      {title:'Client Portfolio Initial',       id: 'value', graphColor: '#67A4B4' },
      {title:'US Stocks',                      id: 'value', graphColor: '#CDBEB4'},
    ] as TabItem[]
  },
  [ROLLING_SHARPE_CLIENT_PORTFOLIO_INITIAL] : {
    graphics: [
      {title:'Client Portfolio Initial',       id: 'value', graphColor: '#67A4B4' },
      {title: 'Mean',                          id: 'value', graphColor: '#C24046'},
    ] as TabItem[]
  },
  [ROLLING_SORTINO_CLIENT_PORTFOLIO_INITIAL] : {
    graphics: [
      {title:'Client Portfolio Initial',       id: 'value', graphColor: '#67A4B4' },
      {title: 'Mean',                          id: 'value', graphColor: '#C24046'},
    ] as TabItem[]
  },
  [DAILY_RETURNS_PORTFOLIO_DAILY] : {
    graphics: [
      {title:'Daily returns',       id: 'value',  graphColor: '#67A4B4' },
    ] as TabItem[]
  },
  [RETURN_QUANTILES_DAILY] : {
    graphics: [
      {title: 'Daily',       id: 'daily',      graphColor: '#B5D7DC' },
      {title: 'Weekly',      id: 'weekly',     graphColor: '#67A4B4'},
      {title: 'Monthly',     id: 'monthly',    graphColor: '#EAC4D9'},
      {title: 'Quarterly',   id: 'quarterly',  graphColor: '#C196B8'},
      {title: 'Yearly',      id: 'yearly',     graphColor: '#975487'},
      {title: 'Romb',        id: 'romb',       graphColor: '#AEA49B'},
    ] as TabItem[]
  },
  [ROLLING_VOLATILITY_CLIENT_PORTFOLIO_INITIAL] : {
    graphics: [
      {title:'Client Portfolio Initial',       id: 'clientPortfolio',     graphColor: '#FF8A53' },
      {title: 'US Stocks',                     id: 'benchmark',           graphColor:'#67A4B4'},
      // {title: 'Mean Client Portfolio', id: 'mean',                graphColor: '#C24046'},
    ] as TabItem[]
  },
  [ROLLING_VOLATILITY_SIMULATION_CLIENT_PORTFOLIO] : {
    graphics: [
      {title:'Simulation',                      id: 'simulated',     graphColor: '#FF8A53' },
      {title:'Client Portfolio Initial',       id: 'clientPortfolio',     graphColor: '#67A4B4' },
      {title: 'US Stocks',                     id: 'benchmark',           graphColor:'#CDBEB4'},
      // {title: 'Mean Client Portfolio', id: 'mean',                graphColor: '#C24046'},
    ] as TabItem[]
  },
  [ROLLING_VOLATILITY_OPTIMIZATION_CLIENT_PORTFOLIO] : {
    graphics: [
      {title:'Optimization',                      id: 'optimized',     graphColor: '#FF8A53' },
      {title:'Client Portfolio Initial',       id: 'clientPortfolio',     graphColor: '#67A4B4' },
      {title: 'US Stocks',                     id: 'benchmark',           graphColor:'#CDBEB4'},
      // {title: 'Mean Client Portfolio', id: 'mean',                graphColor: '#C24046'},
    ] as TabItem[]
  },
  [ROLLING_BETA_BENCHMARK_CLIENT_PORTFOLIO_INITIAL] : {
    graphics: [
      {title:'6 month',       id: 'SIX_MONTH',    graphColor: '#FF8A53' },
      {title: '12 month',     id: 'TWELVE_MONTH', graphColor:'#67A4B4'},
      {title: 'Mean Client',  id: 'MEAN_CLIENT',  graphColor: '#C24046'},
    ] as TabItem[]
  },
  [VALUE_SHORTFALL_DAILY] : {
    graphics: [
      {title:'Daily Returns Client Portfolio Initial',       id: 'DAILY_RETURNS', graphColor: '#67A4B4' },
      {title: 'VaR',                                         id: 'VAR',           graphColor:'#FF8A53'},
      {title: 'ES',                                          id: 'ES',            graphColor: '#FFCB82'},
    ] as TabItem[]
  },
  [DRAWDOWN_PERIODS_CLIENT_PORTFOLIO_INITIAL]: {
    graphics: [
      {title:'Initial Client Portfolio ',       id: 'DRAWDOWN_PERIODS_CLIENT', graphColor: '#67A4B4' },
      // {title: 'Mean Client', id: 'MEAN_CLIENT',graphColor: '#C24046'},
    ] as TabItem[]
  },
  [UNDERWATER_PLOT_DRAWDOWN]: {
    graphics: [
      {title:'Drawdown ',       id: 'result', graphColor: '#B5D7DC' },
      {title: 'Mean',           id: 'mean',graphColor: '#C24046'},
    ] as TabItem[]
  },
  [DISTRIBUTION_RETURN_FREQUENCY]: {
    graphics: [
      {title:'Distribution ',       id: 'value', graphColor: '#67A4B4' },
      // {title: 'Mean', id: 'MEAN',graphColor: '#C24046'},
    ] as TabItem[]
  },
  [EFFICIENT_FRONTIER_PORTFOLIO]: {
    graphics: [
      {title:'Efficient Frontier',       id: 'EFFICIENT',      graphColor: '#67A4B4'},
      {title: 'Newbridge Frontier',      id: 'NEWBRIDGE',      graphColor:'#FF8A53'},
      {title: 'Portfolios',              id: 'PORTFOLIO',      graphColor: '#CDBEB4'},
    ] as TabItem[]
  },

}

export const getTabsGraphsInfo = (id: string, section: string): TabItem[] => {
    const index = id + ':' + section;
    return Graphics[index].graphics;
}

export const getDefaultGraphId = (id: string, section: string): string => {
    const index = id + ':' + section;
    return Graphics[index].graphics[0].id!;
}


export const getGraphicColours = (graphics: TabItem[]) => graphics.map(it => it.graphColor) as string[];
export const getGraphicIds = (graphics: TabItem[]) => graphics.map(it => it.id) as string[];
