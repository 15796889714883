import {TextField,InputAdornment} from '@mui/material';
import React from 'react';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import {buttonBackgroundColor, mainBackgroundColor, mainTextColor} from 'config';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {OverridableStringUnion} from "@mui/types";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";


interface MyTextFieldProps {
    style?: React.CSSProperties,
    placeholder?: string;
    defaultValue?: string;
    inputRef?: any;
    onChange?: any;
    onBlur?:any;
    onKeyDown?: any;
    variant?: 'standard' | 'filled' | 'outlined';
    errorBgColor?:string;
}

export const MyTextField = (props: MyTextFieldProps) => {
  const variant = props.variant || 'standard';
  const placeholder = props.placeholder || '';
  const baseStyle = {
      color: 'white',
      fontWeight: 'normal',
      fontSize: '120%',
      backgroundColor: mainBackgroundColor,

    };

  const style = {...baseStyle, ...props.style };

    return <TextField
    inputRef={props.inputRef}
    placeholder={placeholder}
    onChange={props.onChange}
    onKeyDown={props.onKeyDown}
    style={style}
    required={true}
    defaultValue={props.defaultValue}
    size={'small'}
    variant={variant}
    color={'primary'}
    />
 ;
}

export const MyNumberField = (props: MyTextFieldProps) => {

    const placeholder = props.placeholder || '';

    const style = {
        "& .MuiInputBase-formControl":{
            backgroundColor:props.errorBgColor,
            height:'22px',
            paddingBottom:'0px',
            width:'85px',
        },
        "& .MuiInputAdornment-root":{
            marginLeft:'3px',
        },
        "& .MuiTypography-root":{
            color:props.defaultValue !== '' ? mainTextColor : '#8c8c8c',
            fontSize: '12px'
        },
        "& .MuiTextField-root":{
            borderBottom:'0px'
        },
    };

    return <TextField
        type={'number'}
        className={'allocationField'}
        inputProps={{
            inputMode: 'numeric',
            // pattern: '[0-9]*.2[0-9]',
            pattern: '[0-9]*',
            step:0.01,
            min:0.00,
            max:100.00,
            style:{
                textAlign:'right',
            },
         }}
        InputProps={{ endAdornment:<InputAdornment position={'end'}>%</InputAdornment> }}
        inputRef={props.inputRef}
        placeholder={placeholder}
        onBlur={props.onBlur}
        sx={style}
        defaultValue={props.defaultValue}
        size={'small'}
        variant="standard"
    />;

}
