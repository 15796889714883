import React, {useRef, useState} from 'react';
import { Stack } from '@mui/material';
import WidgetInfoPanel from 'components/controls/info-panel';
import {TabItem} from 'components/tab-component';
import GraphSelectPanel from 'components/controls/graph-selector';
import {
    getTabsGraphsInfo,
    getDefaultGraphId, CLIENT_PORTFOLIO_INITIAL, ROLLING_BETA_BENCHMARK_ID,
} from "../../../data/graphics";
import {timeFormat} from "d3-time-format";
import {rollingVolatilityGraphics} from "../portfolio.slice";
import TrandingViewThreeLine from "./tv-threeline";
import {RESOLUTION_SCREEN} from "../../../config";
import {as_timestamp} from "../../../utils/date-utils";


interface RollingBetaToBenchmarkProps{
    renderData:any;
    legendName:string;
    usStockLegend:string|undefined;
    panelId:string;
}

const RollingBetaToBenchmark = (props:RollingBetaToBenchmarkProps) => {
    const renderData = rollingVolatilityGraphics.getCurrentGraphic();
    const legendName = props.legendName|| '';
    const panelId = props.panelId;
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = CLIENT_PORTFOLIO_INITIAL;
    const actualdata = {};
    renderData.forEach((item,idx) => {
        Object.entries(item).forEach(([key],idx)=> {
            if(item[key].result !== undefined){
                const transValue = item[key].result.map((el) => {
                    return {time: as_timestamp(el.date) /1000, value: el.value}
                });
                if(!actualdata[key]){
                    actualdata[key] = [...transValue];
                };
            };
        });
    });
    // const panelId = ROLLING_BETA_BENCHMARK_ID;
    // const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    // const initialGraphicId = CLIENT_PORTFOLIO_INITIAL;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    }

    const graphSelectPanelOnChange = (item: TabItem) => {
        setCurrentGraphicId(item.id!);
    };

    const timeRangeSelectPanelOnChange = (item: TabItem) => {
        console.log("############ timeRangeSelectPanelOnChange TITLE::", item);
    };




    const priceFormatter = (price) => {return (price * 100).toFixed(0) + '%'};
    const tooltipFormatter = (price) => {return (price * 100).toFixed(2) + '%'};
    const resolutionGraphWidth = RESOLUTION_SCREEN === 'horizontal' ? 698 : 400;

    return <Stack style={{width: '100%'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={'ROLLING BETA TO BENCHMARK'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
            // advancedButton={<MainOrangeButton onClick={() => {}} title={'Advanced'}/>}
        />
        {/*<GraphSelectPanel*/}
        {/*    graphicsOnChange={graphSelectPanelOnChange}*/}
        {/*    timeRangeOnChange={timeRangeSelectPanelOnChange}*/}
        {/*    infoMarkers={[]}*/}
        {/*    // infoTabs={graphics()}*/}
        {/*    infoTabs={[]}*/}
        {/*    width={'100%'}*/}
        {/*/>*/}
        {Object.keys(actualdata).length !== 0 && <TrandingViewThreeLine
            renderData={actualdata}
            graphics={graphics()}
            // width={resolutionGraphWidth}
            height={200}
            priceFormatter={priceFormatter}
            tooltipFormatter={tooltipFormatter}
            timeRange={'year'}
            classId={'threeOneDashed'}
            portfolioName={legendName}
            benchmark={props.usStockLegend}
        />}
    </Stack>
};

export default RollingBetaToBenchmark;
