import React, {useRef, useState} from 'react';
import { Stack } from '@mui/material';
import WidgetInfoPanel from "../../../components/controls/info-panel";
import {
    CLIENT_PORTFOLIO_INITIAL,
    EFFICIENT_FRONTIER_ID, getDefaultGraphId,
    getTabsGraphsInfo
} from "../../../data/graphics";
import EfficientGraph from "./efficient-graph";




interface EfficientFrontierProps{

};

const render = [
    // {'volatility': '5'},
    {'volatility': '5.5','EFFICIENT':{x:5.5,y:-5},'NEWBRIDGE':{x:5.5,y:-4.1}},
    {'volatility': '5.6','EFFICIENT':{x:5.6,y:-0.4},'NEWBRIDGE': {x:5.6,y:0.1},'PORTFOLIO': {x:5.6,y:-4.5}},
    {'volatility': '5.65','EFFICIENT':{x:5.65,y:0.2},'NEWBRIDGE': {x:5.65, y:0.7},'PORTFOLIO': {x:5.65,y:-3.7}},
    {'volatility': '5.7','EFFICIENT': {x:5.7,y:2.5},'NEWBRIDGE': {x:5.7,y:3},'PORTFOLIO': {x:5.7,y:-2.7}},
    {'volatility': '6', 'NEWBRIDGE': {x:6, y:3.5},'EFFICIENT': {x:6, y:3}},
    {'volatility': '7','EFFICIENT':{x:7,y:3.2}, 'NEWBRIDGE':{x:7,y:3.8}},
    {'volatility': '7.5', 'NEWBRIDGE': {x:7.5,y:3.9},'EFFICIENT':{x:7.5,y:3.2}},
    {'volatility': '7.7', 'NEWBRIDGE': {x:7.7,y:3.9},'EFFICIENT':{x:7.7,y:3.2}},
    // {'volatility': '7.9'},


];
const scatter = [{x:5.6,y:-4.5},{x:5.65,y:-3.7},{x:5.7,y:-2.7},
    {x:5.66,y:-0.3},{x:7,y:0},{x:7.2,y:0.2},{x:7.5,y:0.4},
    {x:5.67,y:-0.3},{x:6.1,y:0},{x:6.2,y:0.2},{x:7.1,y:0.4},
];



const test = ['5','5.5','5.7','6','7','7.5','7.7','7.9'];

const EfficientFrontier = (props: EfficientFrontierProps) => {
    const panelId = EFFICIENT_FRONTIER_ID;
    const initialGraphicId = CLIENT_PORTFOLIO_INITIAL;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const seriesApex = () => {
        const resultForApex = graphics().map((item,idx) => {
            if(item.id === 'PORTFOLIO') {
                return {
                    name: item.title,
                    // data:render.map((el,idx) => el[`${item.id}`] === undefined ? null : el[`${item.id}`]),
                    data:scatter,
                    type:'scatter'
                }
            }
            if(item.id !== 'PORTFOLIO'){
                return {
                    name: item.title,
                    data:render.map((el,idx) => el[`${item.id}`] === undefined ? null : el[`${item.id}`]),
                    type:'line'
                }
            }

           // if(item.id === 'PORTFOLIO') {
           //     return {
           //         name: item.title,
           //         data: scatter,
           //         type: 'scatter'
           //     }
           //  }
           //  if(item.id === 'NEWBRIDGE') {
           //      return {  name:item.title,
           //      data:newB,
           //      type:'line'
           //      }
           //  }
           //  if(item.id === 'EFFICIENT'){
           //      return {  name:item.title,
           //          data:eff,
           //          type:'line'
           //      }
           //  }
        });
        return resultForApex;
    };

    const xAxisCategorie = render.map((item) => item.volatility + '%');
    const stroke = {
        // show:true,
        curve:'smoth',
    };
    const yAxisTitle = 'Return';


    console.log(seriesApex())
    return <Stack style={{width: '100%'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={'EFFICIENT FRONTIER'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            // infoTabsOnChange={infoTabsOnChange}
            // advancedButton={<MainOrangeButton onClick={() => {}} title={'Advanced'}/>}
        />
        {/*<TestGraph*/}
        {/*    width={698}*/}
        {/*    height={276}*/}
        {/*    graphics={graphics()}*/}
        {/*    series={seriesApex()}*/}
        {/*    categories={xAxisCategorie}*/}
        {/*    stroke={stroke}*/}
        {/*    yTitle={yAxisTitle}*/}
        {/*/>*/}
        <EfficientGraph
            width={698}
            height={276}
            // labels={test}
            graphics={graphics()}
            series={seriesApex()}
            yTitle={yAxisTitle}
        />
    </Stack>
};

export default EfficientFrontier;
