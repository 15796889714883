import React, {useRef, useState} from "react";
import {Stack} from "@mui/material";
import WidgetInfoPanel from "../../../components/controls/info-panel";
import {
     DAILY, DAILY_RETURNS_PORTFOLIO_ID,
    getDefaultGraphId,
    getTabsGraphsInfo,
} from "../../../data/graphics";
import {TabItem} from "../../../components/tab-component";
import {dailyReturnsOverviewGraphics} from "../portfolio.slice";
import {RESOLUTION_SCREEN} from "../../../config";
import TrandingviewStraightLines from "./tv-straight-lines";

interface DailyReturnsProps {
    renderData:any;
    currentResult:string;
    titleGraph:string;
};


const DailyReturnsPortfioio = (props:DailyReturnsProps) => {
    const renderData = dailyReturnsOverviewGraphics.getCurrentGraphic();
    const currentResult = props.currentResult;
    const data = renderData.map((item,idx) => item[`${currentResult}`] ?  item[`${currentResult}`].result : item.clientPortfolio.result);
    const panelId = DAILY_RETURNS_PORTFOLIO_ID;
    const titleGraph = props.titleGraph;
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = DAILY;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    }


    const priceFormatter = (price) => {return (price * 100).toFixed(0) + '%'};
    const tooltipFormatter = (price) => {return (price * 100).toFixed(2) + '%'};
    const resolutionGraphWidth = RESOLUTION_SCREEN === 'horizontal' ? 698 : 400;


    return <Stack style={{width: '100%', height:'190px'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={titleGraph}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
        />
        {data[0] !== undefined && <TrandingviewStraightLines
            renderData={data[0]}
            // width={resolutionGraphWidth}
            height={148}
            graphics={graphics()}
            timeRange={'year'}
            priceFormatter={priceFormatter}
            tooltipForrmatter={tooltipFormatter}
        />}
    </Stack>
};

export default DailyReturnsPortfioio;

