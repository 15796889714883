import { CROSSASSET, CRYPTO, STRESSINDEX } from '../../../data/graphics';

export const COMPONENT_KEY_NAME = 'dashboardMonitor';

export type CandleViewMode = 'table' | 'graph';
export type PageViewMode = 'candles' | 'infos';

export const wordMarketGraphSelector = {
    'day': 0
  };

export const wordMarketGraphTimeranges = ['day'];

export const sectorIndexGraphSelector = {
    [`${CROSSASSET}:day`]: 0,
    [`${CROSSASSET}:week`]: 1,
    [`${CROSSASSET}:month`]: 2,
    [`${CRYPTO}:day`]: 3,
    [`${CRYPTO}:week`]: 4,
    [`${CRYPTO}:month`]: 5,
  };

export const sectorIndexGraphTimeranges = ['day', 'week', 'month'];


export const stressIndexGraphSelector = {
    [`${STRESSINDEX}:week`]: 0,
    [`${STRESSINDEX}:month1`]: 1,
    [`${STRESSINDEX}:month3`]: 2,
  };
export const stressIndexGraphTimeranges = ['week', 'month1', 'month3'];


export const correlationTableSelector = {
    [`${CRYPTO}:30day`]: 0,
    [`${CRYPTO}:60day`]: 1,
    [`${CRYPTO}:90day`]: 2,
    [`${CROSSASSET}:30day`]: 3,
    [`${CROSSASSET}:60day`]: 4,
    [`${CROSSASSET}:90day`]: 5,
};
// export const defaultCorrelationGraph = `${CRYPTO}:30day`;
export const defaultCorrelationGraph = `crypto:30day`;
export const correlationTableTimerange = ['30day','60day','90day'];

export const explanationMarkets = [
    {fieldName:'values.trend',      title:'Trend and Momenta',  explanation:<><p>- Many indicators (called "kernels") identify the trend of the price of a financial instrument, each of them computed with a set of given parameters. Each kernel gives two values: +1 when the trend is up, -1, when the trend is down.</p><br/>
                                              <p> - TaM sums up a large number of kernels. If TaM =+(-)100, it means that all the kernels signal a buy (sell). If Tam=0, it means that as many kernels signal a buy than a sell. As such, not only does TaM give the overall direction of the market, but it also gives valuable information on its intensity.</p></>},
    {fieldName: 'values.bubble',    title:'Boom or Burst',      explanation:<><p>- Many indicators (called "kernels") identify how over-extended short-term price swing are. These price swings are measured by a set of centered and normalized oscillators computed over different time frames. BoB aggregates all of them and is bounded from -3 to +3.</p><br/>
                                              <p>- When BoB {'>='} 2, the market is caught in a bubble of optimism (Boom) whereas when BoB {'<='} -2, it is caught in a bubble of pessimism (Burst).</p></>},
    {fieldName:'values.volatility', title:'Volatility',         explanation:<><p>- Aggregate of many volatility estimators such as historical close-to-close, high-low Parkinson, Garman Klass, Garman Klass modified by Yang & Zhang, Roger & Satchell Volatility, Yang & Zhang volatilities, etc.</p><br/>
                                              <p>- Volatility is the most important factor in position sizing: the higher volatility is, the lower risk exposure is</p></>},
    {fieldName: 'values.liquidity', title:'Liquidity',          explanation:<><p>- Our liquidity index is the percentile rank (bound from 0 to 100) of a synthetic measure aggregating different estimates of market liquidity.</p><br/>
                                              <p>- As such it informs market participants on the current liquidity level compared to the recent past: a score higher than 80 (lower than 20) means that liquidity is the high (low) compared to this recent past. For momentum trader, it also gives an indication of the stability of their strategy as seemingly profitable momentum strategies are often associated with relatively lower liquidity.</p></>},
];
