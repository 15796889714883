import { logGrouped } from 'config';
import { current } from '@reduxjs/toolkit';


export const logger = store => next => action => {
  if (process.env.NODE_ENV !== 'production') {
    const { type, payload, meta } = action;

    if (logGrouped) {
      console.groupCollapsed(type);
      console.log('Payload:', payload);
      console.log('Meta:', meta);

      console.info('dispatching', action);
      const result = next(action);
      // only 'in reducer' id possible
      // console.log("############## current(state)::", current(state));
      console.log('next state', store.getState());

      console.groupEnd();
      return result;
    }
  }
  return next(action);
};

export default logger;
