import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { TabItem } from 'components/tab-component';

import PortfolioUpdateInfo from './portfolio-update-info';
import PortfolioUpdate, { initialEmptyAsset } from './portfolio-update';
import {AssetVolume, Portfolio, PortfolioEmptyItem} from 'models/portfolio';
import { Asset, ASSET_DUMMY_ID, AssetEmptyItem } from 'models/asset';
import { initialEmptyAssetName } from '../../../pages/portfolios/portfolio.common';
import {buttonBackgroundColor, headerBackgroundColor, mainBackgroundColor, mainTextColor} from "../../../config";
import {BubbleChat} from "flowise-embed-react";

interface PortfolioUpdateViewProps {
  possibleAssets: Asset[],
  possibleBenchmark?: Asset[],
  currentPortfolio: Portfolio;
  currentAssetVolume: AssetVolume;
  onRefresh: Function;
  holder: Record<string, any>;
  onComplete: Function;
  onRemove: Function;
  extraCall?: Function;
  currentControlDate: string;
  portfolioList:Portfolio[];
}

const DEFAULT_ASSETS_COLUMN_NUMBER = 26;


const PortfolioUpdateView = (props: PortfolioUpdateViewProps) => {
  const currentPortfolio = props.currentPortfolio;
  const holder = props.holder;
  const onRefresh = props.onRefresh;
  const onComplete = props.onComplete;
  const onRemove = props.onRemove;
  const portfolio = props.currentPortfolio ? props.currentPortfolio : PortfolioEmptyItem;
  const portfolioEdited = portfolio.id !== '';
  const title = portfolioEdited ? 'UPDATE PORTFOLIO' : 'CREATE PORTFOLIO';
  let currentAssetVolume = props.currentAssetVolume;
  const possibleBenchmark = props.possibleBenchmark || [];
  const portfolioList = props.portfolioList;

  if (currentAssetVolume.id === ASSET_DUMMY_ID) {
    currentAssetVolume = {...currentAssetVolume, name: initialEmptyAssetName()};
  };

  let possibleAssets = [initialEmptyAsset, ...props.possibleAssets];
  const needToAddCurrentAsset = !possibleAssets.find(it => it.id === currentAssetVolume.id);
  if (needToAddCurrentAsset) {
    possibleAssets = [ currentAssetVolume, ...possibleAssets ];
  };

  return <Stack direction="column" alignItems="top" sx={{minWidth: '100%'}}>
    <PortfolioUpdateInfo
        title={title}
        portfolio={currentPortfolio}
        holder={holder}
        onRefresh={onRefresh}
        extraCall={props.extraCall}
    />
    <PortfolioUpdate
      // possibleAssets={props.possibleAssets}
      possibleAssets={possibleAssets}
      possibleBenchmark={possibleBenchmark}
      portfolio={currentPortfolio}
      currentAssetVolume={currentAssetVolume}
      holder={holder}
      onRefresh={onRefresh}
      onComplete={onComplete}
      onRemove={onRemove}
      currentControlDate={props.currentControlDate}
      portfolioList={portfolioList}
    />
    {/*todo ChatBot , add it if you need */}
    {/*<BubbleChat*/}
    {/*    chatflowid={"c855092e-0829-48e4-8d2c-72135a7556fb"}*/}
    {/*    apiHost={"https://flowise-newbridge.onrender.com"}*/}
    {/*    theme={{*/}
    {/*      button: {*/}
    {/*        backgroundColor:headerBackgroundColor,*/}
    {/*        right:20,*/}
    {/*        bottom:20,*/}
    {/*        iconColor: buttonBackgroundColor,*/}
    {/*      },*/}
    {/*      chatWindow:{*/}
    {/*        backgroundColor:mainBackgroundColor,*/}
    {/*        height:450,*/}
    {/*        width: 300,*/}
    {/*        fontSize: 12,*/}
    {/*        poweredByTextColor:mainBackgroundColor,*/}
    {/*        botMessage: {*/}
    {/*          backgroundColor:mainTextColor,*/}
    {/*          textColor: "#303235",*/}
    {/*        },*/}
    {/*        userMessage:{*/}
    {/*          backgroundColor:buttonBackgroundColor,*/}
    {/*          textColor: "#303235",*/}
    {/*        },*/}
    {/*        textInput:{*/}
    {/*          backgroundColor:mainTextColor,*/}
    {/*          textColor: "#303235",*/}
    {/*          sendButtonColor: mainBackgroundColor,*/}
    {/*        }*/}
    {/*      },*/}
    {/*    }}*/}
    {/*/>*/}
  </Stack>
};

export default PortfolioUpdateView;
