import React, { useEffect, useState } from 'react';
import {headerBackgroundColor, mainBackgroundColor, buttonBackgroundColor, RESOLUTION_SCREEN} from 'config';
import {Box, Button, Stack} from "@mui/material";
import {styled} from '@mui/system';

const LOCAL_STORAGE_COOKIE_CONSENT_DISPLAY = 'cookie_consent_display'

const isCookieConsentShouldBeDisplayed = () =>   {
    const conset = window.localStorage.getItem(LOCAL_STORAGE_COOKIE_CONSENT_DISPLAY);
    return !conset;
}

const setCookieAccepted = () =>   {
    window.localStorage.setItem(LOCAL_STORAGE_COOKIE_CONSENT_DISPLAY, String(new Date().getTime()));
}

const CookieSettings = () => {
    const [ shouldDisplay, setShouldDisplay ] = useState(isCookieConsentShouldBeDisplayed())

    if (!shouldDisplay) {
        return <></>;
    }

    const setCookieAccepted = () => {
        window.localStorage.setItem(LOCAL_STORAGE_COOKIE_CONSENT_DISPLAY, String(new Date().getTime()));
        setShouldDisplay(false);
    }

    const resolutionFontSizeTitle = RESOLUTION_SCREEN === 'horizontal' ? '16px' : '12px';
    const resolutionFontSizeText = RESOLUTION_SCREEN === 'horizontal' ? '14px' : '8px';


    return <Stack direction="row" marginTop={'20px'}>
        <Box sx={{
            width: {xs:'300px',md:'1400px',lg:'1400px'},
            height: '120px',
            backgroundColor: mainBackgroundColor,
            border: '1px solid' + buttonBackgroundColor
        }}>
            <Box sx={{ height: '10px' }}>
                <p style={{ color: 'white', textAlign: 'center', fontSize: resolutionFontSizeTitle, lineHeight: '10px' }}>Cookie
                    Settings</p>
            </Box>
            <Box sx={{ textAlign: 'center', height: '40px' }}>
                {/*<p style={{color:'white', fontSize:'11px', paddingLeft:'14px'}}>This website uses cookies</p>*/}
                <p style={{ fontSize: resolutionFontSizeText, color: 'white', padding: '11px' }}>We employ cookies to enhance your
                    browsing experience. By navigating our site, you consent to our use of these tools.</p>
            </Box>

            <Stack direction="row" justifyContent="flex-end" style={{ marginBottom: '14px', marginRight: '14px' }}>
                <button className={'advancedBtn'} onClick={setCookieAccepted}>
                    Accept
                </button>
            </Stack>
        </Box>
    </Stack>;
}

export default CookieSettings;
