import dashboardDefault from 'pages/dashboard/default/dashboard.slice';
import dashboardMonitor from 'pages/dashboard/monitor/monitor.slice';
import tradeLab from 'pages/trade-lab/trade-lab.slice';
import portfolios from 'pages/portfolios/portfolio.slice';
import adminMain from 'pages/admin/admin.slice';
import researchProducts from 'pages/strategy/research-products.slice';
import dataQuality from 'pages/data-quality/data-quality.slice';
import liveData from 'pages/live-data/live-data.slice';

export const allNbReducers = {
  adminMain,
  dashboardDefault,
  dashboardMonitor,
  tradeLab,
  portfolios,
  researchProducts,
  dataQuality,
  liveData
};

export default allNbReducers;
