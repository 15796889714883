export const COMPONENT_KEY_NAME = 'dataQuality';

export interface InstrumentsQuality{
    exchange:string;
    symbol:string;
    interval:string;
    contract_type:string;
    schema:string;
    field:string;
}
export interface DataQualityTask {
    instruments:InstrumentsQuality[];
    time_range:[];
    options:{};
};

export const gapDataGraphSelector = {'day':0};
export const gapDataGraphTimeranges = ['day'];