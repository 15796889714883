import React, {useEffect, useRef, useState} from "react";
import {Stack, Box, Grid, Button} from "@mui/material";
import AssetsTable from "../../../components/assests/asset-table";
import {Traded} from "../../../models/asset";
import {TabItem} from "../../../components/tab-component";
import {AssetSections} from "../../../components/assests/assets-info";


interface AdvancedMarketsProps {
    size?: number;
    traded: Traded[] | null;
    selectFunction: Function;
    currentTraded: Traded | null;
    sortByFunction: (field: string, obj) => void;
    currentSortField: string;
    toggleWatchListItem: Function;
    sortHolder: Record<string, boolean>;
    onSectionChange:(item: TabItem) => void;
}

const DEFAULT_ASSETS_COLUMN_NUMBER = 32;

const AdvancedMarkets = (props: AdvancedMarketsProps) => {
    const sortHolder = props.sortHolder;
    const numberOfRows = props.size ? props.size : DEFAULT_ASSETS_COLUMN_NUMBER;
    const traded = props.traded ? props.traded : [];
    const currentSortField = props.currentSortField;
    const selectFunction = props.selectFunction;
    const sortByFunction = props.sortByFunction;
    useEffect(() =>{
        props.onSectionChange( { title: 'Crypto',    id: AssetSections.crypto});
    },[]);
    const sectionRef = useRef('crypto');
    const [currentTimeRange, setCurrentTimeRange] = useState('24H');
    const [currentFilter, setCurrentFilter] = useState('');
    const filterByWatchList = (item: Traded): boolean => !!item?._inWatchList;
    const notFiltered = (item: Traded): boolean => true;

    const filterAssets = () => currentFilter === 'watchlist' ? filterByWatchList : notFiltered;
    const toggleWatchListItem = props.toggleWatchListItem;
    const sortByFunctionActual = (field_name: string, sorter) =>  {
        return sortByFunction(`${sectionRef.current}:${field_name}`, sorter)
    };

    return <Stack sx={{width: '100%'}} direction="column" >
         <Box sx={{marginTop:'14px'}}>
            <span className={'titleGraphs'}>
                CRYPTO MARKETS
            </span>
         </Box>
        <AssetsTable sortHolder={sortHolder}
                     numberOfRows={numberOfRows}
                     traded={traded}
                     currentSectionId={sectionRef.current}
                     currentTimeRange={currentTimeRange}
                     dataFilter={filterAssets()}
                     toggleWatchList={toggleWatchListItem}
                     sortByFunction={sortByFunctionActual}
                     currentSortField={currentSortField}
                     selectFunction={selectFunction}
                     visiableRowNumber={numberOfRows}
                     switchCSS={'assetTableAdvanced'}
                     advanced={true}
        />
    </Stack>
};

export default AdvancedMarkets;
