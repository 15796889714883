import { useEffect, useState } from 'react';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import {primaryTextColor} from "../../../config";

const getChartOptions = (labels: string[],series,colors) => {
    const offset = () => {
        const offsetWidth = series.map((item,idx) => {
            if(idx % 2 === 0) {
                return 70
            }else {
                return 90
            }
        });
        return offsetWidth;
    }
    return {
        chart: {
            type: 'donut',
            animations: {
                enabled: false,
                name:{
                    show:true
                }
            },
            // width:'400px',
            // height:'auto',
            // offsetX:20,
            // offsetY: 20
        },
        plotOptions: {
            pie: {
                donut:{
                    labels:{
                        show:true,
                        name:{
                            show:true,
                            fontSize:'18px',
                            fontFamily:'Noto Sans',
                        },
                        value:{
                            show:true,
                            fontSize:'18px',
                            fontFamily:'Noto Sans',
                            color:primaryTextColor,
                            formatter: function (val) {
                                return val + '%';
                            }
                        }
                    },
                },

                expandOnClick: false,
                // dataLabels:{
                //     // offset:offset(),
                //     offset:70,
                //     minAngleToShowLabel:0
                // },
                customScale:0.8,
            }
        },
        tooltip:{
            enabled: false,
        },

        dataLabels: {
            enabled:false,
          // formatter(val, opts){
          //     const name = opts.w.globals.labels[opts.seriesIndex]
          //     // return [name, val.toFixed(1) + '%'];
          //     return name;
          // }
        },
        legend: {
            show:false,
        },
        states: {
            hover: {
                filter: 'none'
            },
            active:{
                filter: 'none'
            }
        },

        colors: ['#B5D7DC','#FF8A53','#975487','#FFCB82','#A5B585','#C196B8','#6F7E5E','#67A4B4','#CDBEB4'],

        stroke:{
            show:false,
        },
        labels:labels
    }
};

const ChartPie = (props) => {
    const {series, labels,colors} = props;
    const [options, setOptions] = useState<ChartProps>(getChartOptions(labels,series,colors));

    return (
        <ReactApexChart options={options} series={series} type="donut"/>
    )

};

export default ChartPie;
