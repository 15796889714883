import { DefaultConfigProps } from 'types/config';
import AppRoutes from 'routes/AppRoutes';

export const BASE_ASYNC_URL = process.env.REACT_APP_BASE_URL;

export const BASE_DJANGO_URL = process.env.REACT_APP_DJANGO_APP_BASE_URL;
export const REACT_APP_GITTAG = process.env.REACT_APP_GITTAG;
export const REACT_APP_BUILDTIME = process.env.REACT_APP_BUILDTIME;
export const REACT_APP_ENVINROMENT = process.env.REACT_APP_ENVINROMENT;
export const BASE_RESEARCH_URL = process.env.REACT_APP_RESEARCH_APP_BASE_URL;

export const BASE_DATAPROCESSOR_URL = process.env.REACT_APP_DATAPROCESSOR_APP_BASE_URL;
export const WS_DATAPROCESSOR_URL = process.env.REACT_APP_DATAPROCESSOR_APP_WS_URL;

export const WS_MARKET_URL = process.env.REACT_APP_WS_URL as string;
export const WS_RESEARCH_URL = process.env.REACT_APP_RESEARCH_WS_URL as string
export const RESOLUTION_SCREEN = window.screen.width > window.screen.height ? 'horizontal' : 'vertical';
export const REACT_APP_MAX_INSTRUMENTS = process.env.REACT_APP_MAX_INSTR ? +process.env.REACT_APP_MAX_INSTR : 50;

export const drawerWidth = 0;

export const mainBackgroundColor = '#000A2B';
// export const controlBackgroundColor = '#002553';
export const controlBackgroundColor = '#000A2B';
export const headerBackgroundColor = '#002553';

export const mainWarmShadeBgColor = '#FFCB82';
export const secondaryWarmShadeBgColor = '#FFBB96';
export const lightWarmShadeBgColor = '#FFCB82';
export const mainColdShadeBgColor = '#1A3C65';
export const secondaryColdShadeBgColor = '#67A4B4';
export const lightColdShadeBgColor = '#B5D7DC';


export const mainTextColor = '#FFF';
export const primaryTextColor = '#CDBEB4';
export const secondTextColor = '#AEA49B';
export const buttonBackgroundColor = '#FF8A53';
export const errorTextColor = '#C24046';
export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';


// @todo - remove this - because of Mantis template data
export const FIREBASE_API = {
  apiKey: 'AIzaSyC74w_JCigORyepa_esLkPt-B3HgtI_X3o',
  authDomain: 'mantis-4040b.firebaseapp.com',
  projectId: 'mantis-4040b',
  storageBucket: 'mantis-4040b.appspot.com',
  messagingSenderId: '1073498457348',
  appId: '1:1073498457348:web:268210e18c8f2cab30fc51',
  measurementId: 'G-7SP8EXFS48'
};


export const WINDOW_WIDTH = 1200;

export const DJANGO_API = {
  baseUrl: process.env.REACT_DJANGO_APP_URL
};

export const AWS_API = {
  poolId: 'us-east-1_AOfOTXLvD',
  appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

export const JWT_API = {
  secret: 'SECRET-KEY',
  timeout: '1 days'
};

export const AUTH0_API = {
  client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
  domain: 'dev-w0-vxep3.us.auth0.com'
};

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  defaultPath: AppRoutes.NB_HOME,
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: true,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export const styledControlColorFix = {
  backgroundColor: controlBackgroundColor
}


export const extraLogging = false;
export const applyDummyData = () => false;
export const applyDummyDataForMainpage = () => false;

// export const applyDummyData = () => false;
// export const logGrouped = false;
// export const logGrouped = false;
export const logGrouped = false;
export default config;
