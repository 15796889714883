import { xrange } from './misc';
import { $$ } from './utils';


export const floatRound = (value: number, digits=2): number => {
  const rate = xrange(digits-1).reduce((a, b) => a * 10, 10);
  return Math.round(value * rate) / rate;
}


export const indexOfMaxValie = (vals: number[]) =>
  vals.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);

export const indexOfMinValie = (vals: number[]) =>
  vals.reduce((iMin, x, i, arr) => x < arr[iMin] ? i : iMin, 0);


export const normaliseAllocating = (values: number[]): number[] => {
  const countOfItems = values.length;
  let summa = values.reduce((a, b) => a + b, 0);
  let rate = 100 / summa;
  let newValues = values.map(n => floatRound(n * rate));

  summa = newValues.reduce((a, b) => a + b, 0);
  if (summa === 100.00) {
    return newValues;
  }
  const idx = summa > 100.00 ? indexOfMaxValie(newValues) : indexOfMinValie(newValues);
  const disp = floatRound(100 - summa);
  newValues[idx] = floatRound(newValues[idx] + disp);
  return  newValues;
};
