import React from "react";
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import {buttonBackgroundColor, RESOLUTION_SCREEN} from "../../../config";
import useState from "react-usestateref";

interface DiscreteSliderMarksProps {
    // value: number;
    value: number|number[];
    onChange:Function;
    doubleSlider?:boolean;
    width?:number;
}

const marks = [
    {value: 0, label: '0.0%'},
    {value: 100, label: '100.0%'},
];
const doubleMarks = [
    {value:0, label:'Minimum Weight'},
    {value:100, label:'Maximum Weight'}
];


function valuetext(value: number) {
    return `${value}%`;
};

const DiscreteSliderMarks = (props:DiscreteSliderMarksProps) => {
    const value = props.value;
    const onChange = props.onChange;
    const onChangeSelected = (a, b) => onChange(b);
    const onChangeDouble = (a,b,c) => onChange(a,b,c);
    const resolutionWidth = RESOLUTION_SCREEN === 'horizontal' ? props.width : 220;
    const resolutionWidthDouble = RESOLUTION_SCREEN === 'horizontal' ? 390 : 200;
    return<>
        {props.doubleSlider ? <><Box sx={{width:resolutionWidthDouble}}>
                <Slider
                    getAriaLabel={() => 'Level'}
                    value={value}
                    onChange={onChangeDouble}
                    getAriaValueText={valuetext}
                    step={0.1}
                    valueLabelDisplay="auto"
                    marks={doubleMarks}
                    valueLabelFormat={valuetext}
                    disableSwap={false}
                />
            </Box></>
        :<><Box sx={{ width: resolutionWidth}}>
            <Slider
                aria-label="Custom marks"
                // defaultValue={0}
                value={value}
                onChange={onChangeSelected}
                getAriaValueText={valuetext}
                step={1}
                valueLabelDisplay="on"
                marks={marks}
                valueLabelFormat={valuetext}
            />
        </Box></>
        }</>
};

export default DiscreteSliderMarks;
