import { sortBy } from 'utils/misc';

export type TableFieldDescSortType = 'NUMBER' | 'OFF' | 'UP' | 'DOWN' | 'columnName' | Function;

export class ItemSorter {
  sortHolder: Record<string, boolean>;
  lastApplied: string;

  constructor(sortHolder = {}) {
    this.sortHolder = sortHolder;
    this.lastApplied = '__nothing_real_';
  }

  sortByFieldImpl(items: any[], fieldName: string, applyReverse: boolean, sorter?: TableFieldDescSortType) {
    this.sortHolder[fieldName] = applyReverse ?  !this.sortHolder[fieldName] : this.sortHolder[fieldName];
    // const sortDirection = !this.sortHolder[fieldName];
    const sortDirection = !applyReverse ? applyReverse : !this.sortHolder[fieldName];
    this.lastApplied = fieldName;
    if (!fieldName || fieldName === '') {
      return items;
    }
    // console.log("APPLY SORT: [" + fieldName + "] Reverse [" + sortDirection + "] ... checkReverse: [" + applyReverse + "]")
    return sortBy(items, fieldName, sortDirection, sorter);
  }

  sortByField(items: any[], fieldName: string, sorter?: TableFieldDescSortType) {
    return this.sortByFieldImpl(items, fieldName, true, sorter);
  }

  sortAgain(items: any[], sorter?: TableFieldDescSortType) {
    return this.wasSorted() ? this.sortByFieldImpl(items, this.lastApplied, false, sorter) : items;
  }

  sortAgainAndSortedField(items: any[], sorter?: TableFieldDescSortType) {
      return [
        this.sortAgain(items, sorter),
        this.sortedField()
      ]
  }

  isSortedBy(fieldName: string): boolean {
    return this.sortHolder[fieldName];
  }
  isLastApplied(fieldName: string): boolean {
    return this.lastApplied === fieldName;
  }
  wasSorted(): boolean {
    return this.lastApplied !== '__nothing_real_';
  }

  sortedField(): string {
    const direction = this.sortHolder[this.lastApplied] ? 'DOWN': 'UP' ;
    const result = this.lastApplied !== '__nothing_real_' ? `${this.lastApplied}:${direction}` : ':';
    // console.log('SORTED BY: [' + result + ']')
    return result;
  }
}
