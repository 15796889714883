import React, {useEffect} from "react";
import {Stack, Box, Grid, Button} from "@mui/material";
import {headerBackgroundColor, primaryTextColor} from "../../config";
import {styled} from "@mui/system";
import {useNavigate} from "react-router-dom";
import {ActionMode, strategyDescription} from "./researche-products.common";
import {Portfolio} from 'models/portfolio';

interface ProductsListProps{
    portfolios: Portfolio[];
    onRefresh: Function;
};


const ProductsList = (props:ProductsListProps) => {
    const navigate = useNavigate();

    const portfolios = props.portfolios;
    const onRefresh = props.onRefresh;

    const getDiscriptionPortfolio = (item) => strategyDescription.find(el => el.id_name === item);
    const widthBox = place => place === 'strategy' ? '320px' : '240px';
    const heightBox = place => place === 'strategy' ? '212px' : '160px';
    const BoxStyled = styled(Box)({
        // height:'212px',
        textAlign:'center',
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        margin:'10px',
        letterSpacing:'0.12em',
        background:headerBackgroundColor,
        color:primaryTextColor,
        padding:'20px',
        cursor:'pointer',
    });
    const navigateToLongSort = (item,descriptionItem) => {
        onRefresh({'mode': ActionMode.strategy, targetId: item.id})
        navigate(`${descriptionItem.type}/${descriptionItem.location_name}`,{ state: { id: item.id } });
    };

    return <>
        <Stack justifyItems={'center'} alignItems={'left'} sx={{margin: '20px 150px 20px 150px', padding:'20px'}} spacing={2}>
            <Stack direction={'column'}  spacing={2}>
                <Box>
                    <span className={'titlePage'}>
                      Our Strategies
                    </span>
                </Box>
                <Stack direction={'row'}>
                    {portfolios.map((item,idx) => <React.Fragment key={idx + 'strategyDescrip'}>
                        { getDiscriptionPortfolio(item.name)?.type === 'strategy' && <BoxStyled sx={{width:widthBox(getDiscriptionPortfolio(item.name)?.type),
                                        height:heightBox(getDiscriptionPortfolio(item.name)?.type)}}
                                        onClick={() => navigateToLongSort(item,getDiscriptionPortfolio(item.name))}>
                             {getDiscriptionPortfolio(item.name)?.name}
                        </BoxStyled>}
                    </React.Fragment>)}
                </Stack>
            </Stack>
            <Stack direction={'column'} spacing={2}>
                <Box>
                    <span className={'titlePage'}>
                        Crypto Thematic Indices
                    </span>
                </Box>
                <Stack direction={'row'} flexWrap={'wrap'}>
                    {portfolios.map((item,idx) => <React.Fragment key={idx + 'thematicsDescrip'}>
                        { getDiscriptionPortfolio(item.name)?.type === 'thematics' && <BoxStyled sx={{width:widthBox(getDiscriptionPortfolio(item.name)?.type),
                            height:heightBox(getDiscriptionPortfolio(item.name)?.type)}}
                            onClick={() => navigateToLongSort(item,getDiscriptionPortfolio(item.name))}>
                            {getDiscriptionPortfolio(item.name)?.name}
                        </BoxStyled>}
                    </React.Fragment>)}
                </Stack>
            </Stack>
        </Stack>
    </>
};

export default ProductsList;
