import {
  Portfolio,
  PortfolioEmptyItem,
  PortfolioTask
} from 'models/portfolio';
import {buttonBackgroundColor, mainTextColor, secondaryColdShadeBgColor} from "../../config";
import {floatRound} from "../../utils/finance-utils";

const defaultEmptyPortfolio = PortfolioEmptyItem;

export const getPortfolioByIndex = (portfolios: Portfolio[], index: number): Portfolio => {
  if (index === -1) {
    return defaultEmptyPortfolio;
  }
  return index > portfolios.length ? portfolios[0] : portfolios[index];
}

export const getIndexByPortfolioId = (portfolios: Portfolio[], id): number => {
  for (let i= 0; i < portfolios.length; i+=1) {
    // console.log('helper',portfolios)
    if (portfolios[i].id === id) {
    // console.log('PORTFOLIO IDDDDDDDDDDDDDDDDD::::::',id)
      return i;
    }
  }
  return -1;
}

export const getPortfolioTaskBySeqn = (portfolios: Portfolio[], seqn: number): PortfolioTask | null => {
  for (const portfolio of portfolios) {
    for (const task of portfolio.tasks) {
      if (task.seqn === seqn) {
        return task
      }
    }
  }
  return null;
}

export const getLatestPortfolioTask = (portfolio: Portfolio) => {
  const tasks = portfolio.tasks;

  if (tasks.length === 1) {
    return tasks[0]
  }

  let result = tasks[0];
  for (let i= 1; i < tasks.length; i += 1) {
    if (result.ts < tasks[i].ts) {
      result = tasks[i];
    }
  }
  return result;
}

export const getCurrentPortfolioTask = (portfolio: Portfolio) => {
  return portfolio._currentTaskSeqn === -1
    ? getLatestPortfolioTask(portfolio)
    : portfolio.tasks.find(it => it.seqn === portfolio._currentTaskSeqn);

}

export const getColorClass = (item) => {
  const color =
      item === 'EQUITY'           ? secondaryColdShadeBgColor
      : item === 'FIXED INCOME'   ? '#A5B585'
      : item === 'COMMODITIES'    ? '#CDBEB4'
      : item === 'CRYPTO'         ? buttonBackgroundColor
      : item === 'CASH'           ? '#FFCB82'
      : mainTextColor;
  return color;
};

export const getClassName = (item:string) => {
  if(item !== undefined && item !== null && item !== 'undefined') {
    if (item !== 'Fixed Income') {
      if(item === 'forex' || item === 'Forex'){
        return 'CASH';
      }else {
        return item.toUpperCase().split(' ').slice(0, 1).toString()
      }
    } else {
      return item.toUpperCase().slice(0, 12).toString()
    }
  } else {
    return 'EMPTY CLASS';
  }
};

const getValue = (value: number, minValue= 0) =>  {
  const v = Number(value);
  return isNaN(v) ? 0 :  Math.max(v, minValue);
};

export const totalClassPct = (item,className) => {
  const summ = item.reduce((sum,b) => {getClassName(b.class) === className ? sum = getValue(sum) + getValue(b.value) : 0
    return sum
  },0)
  return floatRound(summ, 2)
};

export const titleClass = (item) => {
  const nameClass = item.map((it, idx) => {
    if(it && it.class && it.class !== undefined && it.class !== null ) {
      if(it.class === 'forex' || it.class === 'Forex'){
        return 'CASH';
      }
      if(it.class === 'Fixed Income' ) {
        return it.class.toUpperCase().slice(0, 12).toString();
      }
      return it.class.toUpperCase().split(' ').slice(0, 1).toString();
    } else {
      return 'EMPTY CLASS';
    }
  });
  const uniqueTitle = nameClass.filter((x,i) => nameClass.indexOf(x) === i );
  return uniqueTitle;
};

export const totalClassProcent = (item: Portfolio) => {
  const result = item.assets.reduce((sum, el) => {
    getClassName(el.class) === 'CRYPTO'        ? sum[0] = sum[0] + el.value
        :getClassName(el.class) === 'CASH'          ? sum[1] = sum[1] + el.value
            :getClassName(el.class) === 'FIXED INCOME'  ? sum[2] = sum[2] + el.value
                :getClassName(el.class) === 'COMMODITIES'   ? sum[3] = sum[3] + el.value
                    :getClassName(el.class) === 'EQUITY'        ? sum[4] = sum[4] + el.value
                        : 0
    return sum
  },[0,0,0,0,0])
  return result;
};

export const totalClassPctComposition = (item: Portfolio) => {
  const result = item.assets.reduce((sum,el,it) =>  {
    titleClass(item.assets).map((element,idx) => {
      getClassName(el.class) === element ? sum[idx] = sum[idx] + el.value : 0
    })
    return sum;
  },[0,0,0,0,0])
  return result;
};
