export const EVENTS = {"data": [
    {"title": "CPI Release", "date": 16593652.153789, "impact": "low", "source": "US Government", "url": "https://www.nytimes.com/section/markets-overview", "params": "{}"},
    {"title": "Bitcoin Halving", "date": 1683743671.133204, "impact": "middle", "source": "Bitcoin", "url": "https://www.nytimes.com/section/markets-overview", "params": "{}"},
    {"title": "CB Consumer Confidence", "date": 1683743671.133209, "impact": "high", "source": "US Government", "url": "https://www.nytimes.com/section/markets-overview", "params": "{}"},
    {"title": "German Inflation Rate YoY ", "date": 1683743671.133209, "impact": "middle", "source": "German Government", "url": "https://www.nytimes.com/section/markets-overview", "params": "{}"},
    {"title": "Unemployment Rate", "date": 1683743671.13321, "impact": "low", "source": "US Government", "url": "https://www.nytimes.com/section/markets-overview", "params": "{}"},
    {"title": "Shanghai Fork", "date": 1683743724.51619, "impact": "middle", "source": "Ethereum", "url": "https://www.nytimes.com/section/markets-overview", "params": "{}"},
    {"title": "Speech Satoshi Nakamoto", "date": 1683743724.516196, "impact": "high", "source": "Bitcoin", "url": "https://www.nytimes.com/section/markets-overview", "params": "{}"},
    // {"title": "BayBay vs Hello", "date": 1683743724.516197, "impact": "high", "source": "just test", "url": "https://www.nytimes.com/section/markets-overview", "params": "{}"},
    // {"title": "Secret event", "date": 1683743724.516197, "impact": "low", "source": "just test", "url": "https://www.nytimes.com/section/markets-overview", "params": "{}"}
    ]};

export const HEADLINES = {"data": [
    {"title": "Why Bitcoin will not make an all time high in 2023", "date": 1683744246.348238, "impact": "high", "source": "Coindesk", "url": null, "params": "{\"coin\": \"BTC\"}"},
    {"title": "dYdX Price Spikes 35% After FTX Crash, Here’s Who Benefited Most", "date": 1683744246.348239, "impact": "low", "source": "Crypto Briefing", "url": null, "params": "{\"coin\": \"DYDX\"}"},
    {"title": "Ethereum Price Holds Key Support and Eyes Fresh Move To $1,700", "date": 1683744246.348239, "impact": "low", "source": "NewsBTC", "url": null, "params": "{\"coin\": \"ETH\"}"},
    {"title": "Russia’s Largest Bank to Launch Ethereum-Compatible DeFi Platform", "date": 1683744246.348237, "impact": "middle", "source": "NewsBTC", "url": null, "params": "{\"coin\": \"ETH\"}"},
    {"title": "MATIC Up 12%, Bloomberg's Senior Strategist Explains Why Polygon Is Superior", "date": 1683744246.348241, "impact": "high", "source": "U.Today", "url": null, "params": "{\"coin\": \"MATIC\"}"},
    // {"title": "super news E", "date": 1683744246.34824, "impact": "middle", "source": "unknown", "url": null, "params": "{\"coin\": \"LTC\"}"}
    ]
};
