import React from 'react';
import {styled} from "@mui/material";
import {
    buttonBackgroundColor,
    mainBackgroundColor,
    mainTextColor,
    RESOLUTION_SCREEN,
    secondaryColdShadeBgColor
} from "../../../config";
import {Stack, Box} from '@mui/material';
import {Portfolio} from "models/portfolio";
import '../porfolio.css';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {floatRound} from "../../../utils/finance-utils";
import {getColorClass,getClassName,totalClassPct,titleClass} from "pages/portfolios/helper";


interface PortfolioInsertTableProps {
    portfolio: Portfolio;
    classCSS?: string;
    borderBottom?: boolean;
    thematics?:boolean;

}

const resolutionFontSize = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';


const HeaderTable = styled(Box) ({
    textAlign:'left',
    paddingRight:'8px',
    paddingLeft:'4px',
    paddingTop:'4px',
    height: '28px',
    fontWeight: 500,
    fontSize: resolutionFontSize,
    color: mainTextColor,
});


const widthTd = columnPlace => columnPlace === 'first' ? '83.2%'
    : columnPlace === 'second' ? '16.8%'
    : '16.8%';


const PortfolioInsertTable = (props: PortfolioInsertTableProps) => {
    const portfolio = props.portfolio;
    const thematics = props.thematics;
    const sortedPortfolioThematics = [...portfolio.assets].sort((a,b) => {return b.value - a.value});

    return <Stack>
        <Stack sx={{width:'100%'}} justifyContent={'space-between'} key={'head'} direction={'row'}>
            {props.borderBottom ? <>
                <HeaderTable style={{width:widthTd('first'),paddingLeft: '16px', borderBottom:'1px solid' + mainTextColor,}}>Assets</HeaderTable>
                <HeaderTable style={{width:widthTd('second'), borderBottom:'1px solid' + mainTextColor,}}>Allocation in %</HeaderTable>
            </>
            :<>
                <HeaderTable style={{width:widthTd('first'),paddingLeft: '16px'}}>Assets</HeaderTable>
                <HeaderTable style={{width:widthTd('second'),textAlign:'right'}}>Allocation in %</HeaderTable>
            </>
            }
        </Stack>
        {!thematics ? <>{titleClass(portfolio.assets).map((item,idx) => <React.Fragment key={'fr' + idx}>
            <Stack sx={{width: '100%'}} justifyContent={'space-between'} key={idx + 'stack'} direction={'row'}>
                <Box className={props.classCSS} key={'headtd45' + idx}
                     style={{width: widthTd('first'), color: getColorClass(item)}}>
                    {item}
                </Box>
                {item &&
                    <Box className={props.classCSS} key={'head3' + idx} style={{
                        width: widthTd('second'),
                        color: getColorClass(item), textAlign: 'right'
                    }}>{totalClassPct(portfolio.assets, item).toFixed(2)} %</Box>
                }
            </Stack>
                {portfolio.assets.map((it, ind) => <React.Fragment key={'idxfrmet' + ind}>
                        {item === getClassName(it.class) ?
                            <Stack sx={{width: '100%'}} justifyContent={'space-between'} key={ind + 'indexstack'} direction={'row'}>
                                <Box className={props.classCSS} key={idx + 'tdtest1'}
                                     style={{width: widthTd('first'), paddingLeft: '16px'}}>
                                    {getClassName(it.class) === 'CRYPTO' ? it.name + ' - ' + it.base_symbol
                                        : getClassName(it.class) === 'EMPTY CLASS' ? 'No Asset'
                                            : it.name}
                                </Box>
                                <Box className={props.classCSS} key={'tdtest2' + idx}
                                     style={{width: widthTd('second'), textAlign: 'right'}}>
                                    {it.value.toFixed(2)} %
                                </Box>
                            </Stack> : <></>}
                </React.Fragment>)}
            </React.Fragment>
        )}
        </>
            :<>
            {sortedPortfolioThematics.map((it,ind) => <React.Fragment key={'idfrag' + ind}>
                <Stack sx={{width: '100%'}} justifyContent={'space-between'} key={ind + 'indexstack'} direction={'row'}>
                    <Box className={props.classCSS} key={ind + 'tdtest1'}
                         style={{width: widthTd('first'), paddingLeft: '16px'}}>
                        {getClassName(it.class) === 'CRYPTO' ? it.name + ' - ' + it.base_symbol
                            : getClassName(it.class) === 'EMPTY CLASS' ? 'No Asset'
                                : it.name}
                    </Box>
                    <Box className={props.classCSS} key={'tdtest2' + ind}
                         style={{width: widthTd('second'), textAlign: 'right'}}>
                        {it.value.toFixed(2)} %
                    </Box>
                </Stack>
            </React.Fragment>)}
            </>
        }

    </Stack>
};

export default PortfolioInsertTable;
