import React, { useState } from 'react';
import { Stack,Box } from '@mui/material';

import MiniWrapLayout from 'layout/MainLayout/mini-wrap-layout';
import { TabItem } from 'components/tab-component';
import { GraphColorsItem, GraphColorPanelProps } from 'components/controls/graph-selector';
import { ASIA, CRYPTO, EUROPE, US } from 'data/graphics';
import {mainTextColor, RESOLUTION_SCREEN, secondTextColor} from 'config';
import { createReflector, SizedDiv } from 'components/controls/sizer';
import {isDaylightSavingTimeEurope} from "../../../utils/date-utils";

interface GraphSelectPanelProps {
  infoTabs?: TabItem[];
  infoMarkers?: string[];
  timeRangeOnChange?: Function;
  graphicsOnChange?: Function;
  advancedButton?: JSX.Element;
  onRefresh?: Function;
}

interface WorldMarketsGraphColorPanelProps {
  infoTabs?: TabItem[];
  onChange?: Function;
}


const SECTIONS = [ASIA, EUROPE, US, CRYPTO];

export const SectionalGraphColorsTabsComponent = (props: GraphColorPanelProps) => {
  const items = props.infoTabs || [];
  const [currentSelected, setCurrentSelected] = useState(items.length > 0 ? items[0] : {'title': ''});
  const onChange = (item: TabItem) => {
    setCurrentSelected(item);
    return props.onChange ? props.onChange(item) : undefined;
  }

  const reflector =  createReflector();
  const itemsForSection = (section: string) => items.filter(it => it.section === section);
  const getSize = (section: string) => {
    if(section === 'EUROPE') {
      if(RESOLUTION_SCREEN === 'horizontal') {
        // return '290px'
        //todo Anastasiia -- this is Summer Time
        return isDaylightSavingTimeEurope() === 'Summer Time' ? '350px' : '290px';
      } else {
        return '195px'
      }
    }
    if(section === 'US') {
      if(RESOLUTION_SCREEN === 'horizontal') {
        return '300px'
      } else {
        return '160px'
      }
    }
    if(section === 'CRYPTO'){
      if(RESOLUTION_SCREEN === 'horizontal') {
        return '200px'
      } else {
        return '120px'
      }
    }
    if(RESOLUTION_SCREEN === 'horizontal') {
      // return '420px'
      //todo Anastasiia -- this is Summer Time
      return isDaylightSavingTimeEurope() === 'Summer Time' ? '360px' : '420px';
    } else {
      return '230px'
    }
  }

  return <SizedDiv width='100%' reflector={reflector} style={{marginLeft:'67px'}}>
    <Stack sx={{width: '100%'}} direction="row" justifyContent={'flex-start'} spacing={2}>
    { SECTIONS.map((section, ix) =>
      <Box key={ix + 100}  style={{color: secondTextColor, width:getSize(section)}} >
        {section} <Stack key={50+ix} direction={'row'} alignItems={'center'} spacing={2}>
    {/*    {*/}
    {/*      itemsForSection(section).map((it, i) =>*/}
    {/*        <Box key={i + 'section'} width={'100px'}  ><GraphColorsItem key={i+'secIt'} current={currentSelected.title === it.title}  onChange={onChange} item={it}/></Box>)*/}
    {/*}*/}
        </Stack></Box>)}
  </Stack>
  </SizedDiv>
}

const WorldMarketsGraphSelectPanel = (props: GraphSelectPanelProps) => {
  const infoTabs = props.infoTabs || [];
  const graphicsOnChange = (item: TabItem) => props.graphicsOnChange ? props.graphicsOnChange(item) : undefined;

  const lineOne = <Stack sx={{width: '100%'}} direction="row" justifyContent="space-between" >
    </Stack>;

  const lineTwo = <Stack direction='row' alignItems='center' gap={1}>
    <SectionalGraphColorsTabsComponent onChange={graphicsOnChange} infoTabs={infoTabs}/>
  </Stack>

  return <MiniWrapLayout lineOne={lineOne} lineTwo={lineTwo}/>
};

export default WorldMarketsGraphSelectPanel;
