import React, {useRef, useState} from "react";
import {
    CLIENT_PORTFOLIO_INITIAL, getDefaultGraphId,
    getTabsGraphsInfo,
     ROLLING_SORTINO_ID, ROLLSORTINO
} from "../../../data/graphics";
import {TabItem} from "../../../components/tab-component";
import {Stack} from "@mui/material";
import WidgetInfoPanel from "../../../components/controls/info-panel";
import GraphSelectPanel from "../../../components/controls/graph-selector";
import TrandingViewMarkets from "../../dashboard/monitor/tv-markets";
import {as_timestamp} from "../../../utils/date-utils";
import {rollingSortinoGraphics} from "../portfolio.slice";

interface RollingSortinoProps {
    rollingSortino:any;
    rollingSortinoCaseString:string;
    onChange:(item: TabItem) => void;
    graphicsName:any;
    portfolioName?:string;
    placeUse?:string;
};


const RollingSortino = (props: RollingSortinoProps) => {
    const graphicsName = props.graphicsName;
    const placeUse = props.placeUse;
    const renderData = graphicsName.getCurrentGraphic()[0].length !== 0 ? graphicsName.getCurrentGraphic() : rollingSortinoGraphics.getCurrentGraphic();
    const panelId = ROLLING_SORTINO_ID;
    const portfolioName = props.portfolioName ? props.portfolioName : placeUse;
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = CLIENT_PORTFOLIO_INITIAL;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const caseStr = props.rollingSortinoCaseString || 'ROLLSORTINO:month6';
    const timeRange = caseStr.split(':')[1] || 'month6';

    const actualData  = renderData.map((item,idx) => {
        const values = item.map((el,idx)=> {
            const transValue = el.map((elem,idx) => {
                return {time:as_timestamp(elem.date) / 1000, value:elem.value}
            });
            return transValue;
        });
        return values;
    });
    // console.log(actualData[0])

    const infoTabsOnChange = (item: TabItem) => {
        // setCurrentGraphicSetId(item.id || '');
    }

    const graphSelectPanelOnChange = (item: TabItem) => {
        // setCurrentGraphicId(item.id!);
    };

    const timeRangeSelectPanelOnChange = (item: TabItem) => {
        props.onChange({id: ROLLSORTINO, timeRange: item.id} as TabItem)
        // console.log("############ timeRangeSelectPanelOnChange TITLE::", item);
    };


    const priceFormatter = (price) => {return (price).toFixed(0)};
    const tooltipFormatter = (price) => {return (price).toFixed(2)};

    return <Stack style={{width: '100%', marginBottom:'15px'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={'ROLLING SORTINO'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
            // advancedButton={<MainOrangeButton onClick={() => {}} title={'Advanced'}/>}
        />
        <GraphSelectPanel
            graphicsOnChange={graphSelectPanelOnChange}
            timeRangeOnChange={timeRangeSelectPanelOnChange}
            infoMarkers={['6m window', '2y window']}
            // infoTabs={graphics()}
            infoTabs={[]}
            width={'100%'}
        />

        {actualData[0] !== undefined && <TrandingViewMarkets
            renderData={actualData[0]}
            graphics={graphics()}
            // width={698}
            height={200}
            timeRange={timeRange}
            priceFormatter={priceFormatter}
            tooltipForrmatter={tooltipFormatter}
            portfolioName={portfolioName}
            classId={'twoOneDashed'}
        />}
    </Stack>
};

export default RollingSortino;
