import React, {ReactNode, useState} from 'react';
import { Box, Stack} from '@mui/material';

import { buttonBackgroundColor, mainTextColor, secondTextColor } from 'config';
import MiniWrapLayout from 'layout/MainLayout/mini-wrap-layout';
import { HMWY, TabItem, TabsComponent } from 'components/tab-component';

interface GraphSelectPanelProps {
  infoTabs?: TabItem[];
  infoMarkers?: string[];   // @todo rename / update type after it would be more clear
  timeRangeOnChange?: Function;
  graphicsOnChange?: Function;
  advancedButton?: JSX.Element;
  onRefresh?: Function;
  width: string;
  currentTabId?: string;
}

const defaultDevTabItems = [
    { title: 'test1',         id: 'CRYPTOTOTAL', graphColor: '#FF8A53'},
    { title: 'test2',         id: 'SP500',       graphColor: '#67A4B4'},
] as TabItem[];

export interface GraphColorPanelProps {
  infoTabs?: TabItem[];
  onChange?: Function;
}

const defaultDevMarkers = ['1 day', '1 week', '1 month'];

export const GraphColorsItem = (props: {item: TabItem, current: boolean, onChange: Function}) => {
  // const color = props.current ? buttonBackgroundColor : secondTextColor;
  return <Box onClick={() => props.onChange(props.item)} style={{ width: '100%'}}>
            <Box className={'coloredLine'} sx={{ backgroundColor: props.item.graphColor }}>&nbsp;</Box>
            <span className={'nowrap'}
                  style={{ color: secondTextColor}}>{props.item.title}&nbsp;&nbsp;</span>
  </Box>;
}

export const GraphColorsTabsComponent = (props: GraphColorPanelProps) => {
  const items = props.infoTabs || [];
  const [currentSelected, setCurrentSelected] = useState(items.length > 0 ? items[0] : {'title': ''});
  const onChange = (item: TabItem) => {
    setCurrentSelected(item);
    return props.onChange ? props.onChange(item) : undefined;
  }
  return <Stack sx={{width: '100%'}} direction="row" justifyContent="space-between" >
    { items.map(it => it.id !== 'MEAN_CLIENT' && <GraphColorsItem current={currentSelected.title === it.title} key={it.title} onChange={onChange} item={it}/>) }
  </Stack>;
}


const GraphSelectPanel = (props: GraphSelectPanelProps) => {
  const advancedButton = props.advancedButton || <></>;
  const infoTabs = props.infoTabs || defaultDevTabItems;
  const infoMarkers = props.infoMarkers || defaultDevMarkers;
  const currentTabId = props.currentTabId || '';
    // console.log(currentTabId)
  const timeRangeOnChange = (item: TabItem) => props.timeRangeOnChange ? props.timeRangeOnChange(item) : undefined;
  const graphicsOnChange = (item: TabItem) => props.graphicsOnChange ? props.graphicsOnChange(item) : undefined;


    const lineTwoOld =
        <Box width={props.width} marginTop={'4px'}>
          <Stack  direction='row' justifyContent='space-between' marginBottom={'22px'}>
            <Box >
              <GraphColorsTabsComponent onChange={graphicsOnChange} infoTabs={infoTabs}/>
            </Box>
             <Box position={'relative'}>
                <Box position={'absolute'} right={'1px'} style={{cursor:'pointer'}}>
                  <TabsComponent width={'100%'} variant={HMWY} onChange={timeRangeOnChange} items={infoMarkers} applyBorder={true} currentTabId={currentTabId}/>
                </Box>
             </Box>
          </Stack>
        </Box>

  // const lineTwo = <Stack justifyContent='space-between' direction='row' spacing={'500px'}>
  //   <Stack alignItems={"center"} justifyContent={"flex-start"} direction={"row"}>
  //     <GraphColorsTabsComponent onChange={graphicsOnChange} infoTabs={infoTabs}/>
  // </Stack>
  //
  // <Stack alignItems={"center"} justifyContent={"flex-end"} direction={"row"}>
  //   <TabsComponent width={'100%'} variant={HMWY} onChange={timeRangeOnChange} items={infoMarkers} applyBorder={true}/>
  // </Stack>
  // </Stack>

  return <MiniWrapLayout  lineTwo={lineTwoOld}/>
};

export default GraphSelectPanel;
