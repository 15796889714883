

export const getCachedValue = (name: string) => {
 const content = window.localStorage.getItem(name);
 const now = Date.now().valueOf();
 if (content) {
  const value = JSON.parse(content);
  if (! value.expiration || value.expiration < now) {
   window.localStorage.removeItem(name);
   return null;
  }
  if (value.data) {
   return value.data;
  }
  return null;
 }
}

export const setCachedValue = (name: string, data: any, expiration_sec: number = 60 * 10) => {
 return  window.localStorage.setItem(name,  JSON.stringify({
  data: data, expiration: Date.now().valueOf() + expiration_sec * 1000
 }));
}
