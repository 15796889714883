import { MutableRefObject, ReactElement, useRef } from 'react';

export class RefHoster {
    valuesRefs:  MutableRefObject<any>;

    constructor() {
      this.valuesRefs  = useRef([]);
    }

    public initRef(id: string, el: ReactElement) {
      this.valuesRefs.current[id] = el;
    }

    public getInputValue = (id: string, defaultValue='') => {
      const ref = this.valuesRefs.current[id];
      return ref ? ref.value : defaultValue
    }

    public getRef = (id: string) => {
      return this.valuesRefs.current[id];
    }

    public setInputValue = (id: string, value) => this.valuesRefs.current[id].value = value;

}
