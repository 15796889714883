import React from "react";
import {Portfolio} from "models/portfolio";
import {
    mainTextColor, RESOLUTION_SCREEN,
} from "config";
import {Box, Stack, styled} from "@mui/material";
import AssetDonutChart from "./portfolio-graphs/asset-donut-chart";
import PortfolioInsertTable from "../../components/portfolios/portfolio-insert/portfolio-insert-table";
import {getColorClass,titleClass,totalClassPctComposition,} from "pages/portfolios/helper";
import {floatRound} from "../../utils/finance-utils";
import ChartPie from "./portfolio-graphs/chart-pie";


interface PortfolioCompositionProps {
    portfolio: Portfolio;
    thematics?:boolean;
}


const PortfolioComposition = (props: PortfolioCompositionProps ) => {
    const {portfolio,thematics} = props;
    const assetsValues = totalClassPctComposition(portfolio);
    const assetsTitles = titleClass(portfolio.assets);
    const donutColors = titleClass(portfolio.assets).map((item) => getColorClass(item));
    const stackDirection = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';
    const resolutionSignSize = RESOLUTION_SCREEN === 'horizontal' ? '28px' : '14px';
    const sortedPortfolioThematics = [...portfolio.assets].sort((a,b) => {
        return b.value - a.value
    });
    const assetWeight = sortedPortfolioThematics.map((item) => item.value);
    const assetName = sortedPortfolioThematics.map((item) => item.name);



    return (<>
        <Stack direction={stackDirection}  spacing={2}  alignItems={!thematics ? 'flex-start' : 'space-between'}
               style={{width:'100%', height: '100%', marginBottom:'12px'}}>
            <Stack  marginTop={'16px'} sx={{width:'100%'}}>
                <Box style={{height:'100%'}}>
                    <PortfolioInsertTable portfolio={portfolio} classCSS={'composition'} thematics={thematics}/>
                </Box>
            </Stack>
            <Stack direction={'row'}  spacing={1} style={{width:'100%', height: '100%', marginBottom:'24px'}}>
                {!thematics && <Stack direction={'row-reverse'} flexWrap={'wrap'} sx={{width: {md: '250px', lg: '364px'}}}
                        height={"100%"} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    {assetsTitles.map((item, idx) => <Stack direction={'column'} key={'stack' + idx}
                                                            sx={{width: {md: '90px', lg: '160px'}}}
                                                            margin={'20px 0px 10px 5px'}>
                        {item && <> <Box key={'titleasset' + idx} sx={{
                            fontSize: {xs: '24px', md: '32px', lg: '42px'},
                            color: getColorClass(assetsTitles[idx]), textAlign: 'center', width: '100%', height: '64px'
                        }}>
                            {assetsValues[idx].toFixed(2)}<span key={'procent' + idx} style={{
                            fontSize: resolutionSignSize,
                            width: '100%'
                        }}>%</span></Box>
                            <Box key={'pocentTit' + idx} sx={{
                                color: mainTextColor, fontSize: {xs: '8px', md: '10px', lg: '12px'},
                                fontWeight: 500, textAlign: 'left', width: '70%', marginLeft: '18px'
                            }}>
                                {item}
                            </Box></>
                        }
                    </Stack>)}
                </Stack>}
                <Box style={{width:!thematics ? '330px' : '100%', height:'100%', marginTop:'8px',paddingLeft:!thematics ? '0px': '150px'}}>
                {/*<Box style={{width: '100%', height:'100%', marginTop:'8px'}}>*/}
                    {!thematics ? <AssetDonutChart series={assetsValues} labels={assetsTitles} colors={donutColors}/>
                        : <ChartPie series={assetWeight} labels={assetName}/>}
                </Box>
            </Stack>
        </Stack>
        </>
    )
};

export default PortfolioComposition;
