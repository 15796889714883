import { COMPONENT_KEY_NAME } from "./monitor.common";
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'api';
import { BASE_ASYNC_URL, BASE_DJANGO_URL } from 'config';
import { ID } from 'models/common';

const getCandles = (traded: number) => axios.get(`${BASE_ASYNC_URL}/api/candles/${traded}`)


export type CorrelationDaterange = '30day' | '60day' | '90day';
export type StressIndexDaterange = 'week' | 'month1' | 'month3' | 'all';
export type IndicesDaterange = 'day' | 'week' | 'month' | 'all';

const getTraded = () => axios.get(`${BASE_ASYNC_URL}/api/traded`);
const getStressIndex = (timerange: StressIndexDaterange) => axios.get(`${BASE_ASYNC_URL}/api/stress-index/${timerange}`);
const getAllStressIndex = () => axios.get(`${BASE_ASYNC_URL}/api/all-stress-index`);
const getCorrelation = (timerange: CorrelationDaterange) => axios.get(`${BASE_ASYNC_URL}/api/correlation/${timerange}`);
const getAllSectorIndices = (timerange: IndicesDaterange) => axios.get(`${BASE_ASYNC_URL}/api/all-sector-index`);
const getWorldMarketIndices = () => axios.get(`${BASE_ASYNC_URL}/api/world-market-index`);
// const getSectorIndicesTraditional = (timerange: IndicesDaterange) => axios.get(`${BASE_ASYNC_URL}/api/index/cross/${timerange}`);
// const getSectorIndicesCrypto = (timerange: IndicesDaterange) => axios.get(`${BASE_ASYNC_URL}/api/index/crypto/${timerange}`);
const marketCap = () => axios.get(`${BASE_ASYNC_URL}/api/marketcap/all`);
const getEventNews = () => axios.get(`${BASE_DJANGO_URL}/infonews/events/`);
const toggleWatchList = (assetId: ID) => axios.post(`${BASE_DJANGO_URL}/watchlist/`, { assetId: assetId });
const getWatchList = () => axios.get(`${BASE_DJANGO_URL}/watchlist/`);

const getHeadlines = () => axios.get(`${BASE_DJANGO_URL}/infonews/headlines/`);

export const loadMarketCap = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/load_market_cap`,
  async (param:any, thunkAPI) => {
    const response = await marketCap();
    return {data: response.data};
    }
  );


export const loadCandles = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/load`,
  async (param:any, thunkAPI) => {
    const response = await getCandles(param.tradedId);
    return {data: response.data, tradedId: param.tradedId};
    }
  );

export const loadTradedInfo = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/traded_info`,
  async (param:any, thunkAPI) => {
    const response = await getTraded();
    return response.data;
    }
  );

export const loadWatchList = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/get_watch_list`,
  async (param:any, thunkAPI) => {
    const response = await getWatchList();
    return response.data;
    }
  );

export const loadUserTradedInfo = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/traded_info_user`,
  async (param:any, thunkAPI) => {
    return await axios.all([getTraded(), getWatchList()])
    }
  );


export const toggleWatchListItem = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/toggle_watch_list`,
  async (assetId:ID, thunkAPI) => {
    const response = await toggleWatchList(assetId);
    return response.data;
    }
  );


export const loadEventNewsInfo = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/event_news`,
  async (param:any, thunkAPI) => {
    const response = await getEventNews();
    return response.data.data;
    }
  );

export const loadHeadlinesInfo = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/headlines`,
  async (param:any, thunkAPI) => {
    const response = await getHeadlines();
    return response.data.data;
    }
  );

export const loadAllStressIndexes = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/load_all_stress_indexes`,
  async (indexRange: StressIndexDaterange, thunkAPI) => {
    const response = await getAllStressIndex();
    return {data: response.data, range: indexRange };
    }
  );

export const loadStressIndexes = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/load_stress_indexes`,
  async (indexRange: StressIndexDaterange, thunkAPI) => {
    const response = await getStressIndex(indexRange);
    return response.data;
    // return {data: response.data, range: indexRange };
    }
  );

export const loadCorrelationData = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/load_correlation`,
  async (indexRange: CorrelationDaterange, thunkAPI) => {
    const response = await getCorrelation(indexRange);
    return response.data;
    }
  );


export const loadAllSectorIndexes = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/all-sector-index`,
  async (indexRange: IndicesDaterange, thunkAPI) => {
    const response = await getAllSectorIndices(indexRange);
    return {data: response.data, range: 'all' };
    }
  );

export const loadWorldMarketIndexes = createAsyncThunk(
  `${COMPONENT_KEY_NAME}/world-market-index`,
  async (indexRange: IndicesDaterange, thunkAPI) => {
    const response = await getWorldMarketIndices();
    return {data: response.data, range: 'all' };
    }
  );


// export const loadSectorIndexes = createAsyncThunk(
//   `${COMPONENT_KEY_NAME}/load_sector_indexes`,
//   async (indexRange: IndicesDaterange, thunkAPI) => {
//     const response = await getSectorIndicesTraditional(indexRange);
//     return {data: response.data, range: indexRange };
//     }
//   );
//
//
// export const loadCryptoSectorIndexes = createAsyncThunk(
//   `${COMPONENT_KEY_NAME}/load_crypto_sector_indexes`,
//   async (indexRange: IndicesDaterange, thunkAPI) => {
//     const response = await getSectorIndicesCrypto(indexRange);
//     return {data: response.data, range: indexRange };
//     }
//   );
