import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from 'api';
import {BASE_ASYNC_URL,BASE_DJANGO_URL, BASE_RESEARCH_URL } from "../../config";
import { ID } from "models/common";
import MyAxios from 'api';
import {getCachedValue,setCachedValue} from "../../utils/local_storage";
import {COMPONENT_KEY_NAME} from "./researche-products.common";
import {PortfolioInputType, simulationReply} from "../portfolios/portfolio.api";

const getInstruments = () => axios.get(`${BASE_ASYNC_URL}/api/portfolio/instruments`);

const getPortfolios = () => MyAxios.get(`${BASE_DJANGO_URL}/portfolio/`);

export const simulatePortfolioApi = createAsyncThunk(
    `${COMPONENT_KEY_NAME}/simulatePortfolio`,
    async (data) => {
        const response = await axios.post(`${BASE_RESEARCH_URL}/portfolio/simulate`, data);
        return response['data'] as simulationReply;
    }
);

export const loadPortfoliosInfoApi = createAsyncThunk(
    `${COMPONENT_KEY_NAME}/portfolios`,
    async (thunkAPI) => {
        const data = await getPortfolios()
        return data['data']['data'] as PortfolioInputType[];
    }
);

export const loadInstrumentsInfoApiFunct = () => {
    const marker = `${COMPONENT_KEY_NAME}/instruments`;

    return createAsyncThunk(
        marker,
        async (thunkAPI) => {
            const cachedData = getCachedValue(marker);
            if (cachedData) {
                return cachedData;
            }
            const data = await getInstruments();
            const value = data['data']
            setCachedValue(marker, value);
            return value;
        }
    );
}

export const loadInstrumentsInfoApi = loadInstrumentsInfoApiFunct();