import React, {useRef, useState, useMemo} from "react";
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import {buttonBackgroundColor, mainBackgroundColor, mainTextColor} from "../../../config";
import ReloadOutlined from '@ant-design/icons';
import { $$ } from 'utils/utils';
import {dateSerializerNew} from "../../../components/controls/form/nb-data-picker";
import {TabItem} from "../../../components/tab-component";
import '../../../components/portfolios/porfolio.css';


const getChartOptions = (labels: string[],series,graphics:TabItem[],categories,plotOptions,stroke,yTitle,type) => {

    return{
        chart:{
            type:type,
            height:350,
            toolbar: {
                autoSelected: 'zoom',
                show: false,
                tools: {
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: true,
                    reset: true,
                    selection: true,
                    download:false,
                },
            },
            animations: {
                enabled:false
            },
            events: {
                dataPointMouseEnter: function(event) {
                    event.fromElement.style.cursor = "default";
                }
            },

        },
        dataLabels:{
            enabled: false
        },
        grid: {
            borderColor: 'rgba(174,164,155,0.5)',
            strokeDashArray: 2,
            borderWidth:0.5,
            xaxis: {
                lines: {
                    show: false,
                }
            },

        },
        stroke:{
            show:true,
            width:1,
            colors:['#AEA49B']
        },
        plotOptions: {
            boxPlot: {
                colors: {
                    lower:'#975487'
                }
            },
        },
        legend: {
            show:false
        },
        xaxis: {
            type:'numeric',
            strokeDashArray:2,
            tickAmount:4,
            // categories: categories,
            labels:{
                style:{
                    colors:'#AEA49B'
                },
                rotateAlways: false,
                hideOverlappingLabels: false,
                showDuplicates: false,
                trim: false,
                formatter:function (value){
                   return value === 0 ? 'Daily'
                       :value === 1 ? 'Weekly'
                       :value === 2 ? 'Monthly'
                       :value === 3 ? 'Quaterly'
                       :value === 4 ? 'Yearly'
                       :'';

                }

            },
            tickPlacement: 'on',

            axisBorder: {
                show: true,
                // width:'0.1px',
                color:'#AEA49B',
                height:0.2
            },
            axisTicks:{
                show: false
            },
            tooltip:{
                enabled:false,
            },
            crosshairs: {
                stroke: {
                    color: 'rgba(174,164,155,0.6)',
                    width: 1,
                    dashArray: 5
                }
            },
        },
        yaxis: {
            labels:{
                style:{
                    colors:'#AEA49B'
                },
                formatter: (value) => { return (value * 100)?.toFixed(2) + '%' },
            },
            axisBorder: {
                show: true,
                width:'0.1px',
                color:'#AEA49B',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks:{
                show: false
            },
            crosshairs: {
                stroke: {
                    color: 'rgba(174,164,155,0.6)',
                    width: 1,
                    dashArray: 5
                }
            },
            title:{
                text:yTitle,
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: '#AEA49B',
                    fontSize: '12px',
                    fontFamily: 'Noto Sans',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            tooltip:{
                enabled:true,
            }
        },
        markers: {
            size: [0,6]
        },
        fill:{
            colors:graphics.map((item) => item.graphColor),
            type:'solid',
            opacity: 1,
        },
        colors: graphics.map((item) => item.graphColor),
        tooltip:{
            enabled:true,
            followCursor:true,
            theme:false,
            custom: function({series, seriesIndex, dataPointIndex, w, categories}) {
                let tooltip = '';
                tooltip += '<div class="arrow_box">'
                tooltip += '<div style="margin-bottom: 8px">';
                tooltip += '<span style="color: #AEA49B">' + w.globals.lastXAxis.categories[dataPointIndex] +'</span>'
                tooltip += '</div>';
                series.map((item,idx) => {
                    if(item[dataPointIndex] !== null && item[dataPointIndex] !== undefined ){
                        tooltip += '<div style="display: flex;flex-direction: row;justify-content: space-between;">';
                        tooltip += '<div>';
                        tooltip += '<span style="color: ' + w.globals.colors[idx] + '" class="value-tooltip">' + w.globals.seriesNames[idx] +  '</span>';
                        tooltip += '</div>';
                        tooltip += '<div>';
                        tooltip += '<span style="color: ' + w.globals.colors[idx] + ';margin-left: 30px" class="value-tooltip">'  + (item[dataPointIndex] * 100).toFixed(2) +'%' + '</span>';
                        tooltip += '</div>';
                        tooltip += '</div>';}
                })
                tooltip += '</div>'
                return tooltip;
            }
        }

    };
};

const TestGraphBox = (props ) => {
    const {series, labels,graphics,width,height,categories,plotOptions,stroke,yTitle,type} = props;
    const [options, setOptions] = useState<ChartProps>(getChartOptions(labels,series,graphics,categories,plotOptions,stroke,yTitle,type));
    return (
        <ReactApexChart options={options} series={series} type={type} height={height} width={width}/>
    );
};

export default TestGraphBox;
