import React, { useState } from 'react';
import { Box, Stack,  Typography } from '@mui/material';
import { OrangeButton } from 'components/controls/nb-buttons';
import {LeftOutlined, PlusOutlined, RightOutlined} from '@ant-design/icons'
import {buttonBackgroundColor, mainBackgroundColor, mainTextColor, primaryTextColor, RESOLUTION_SCREEN} from 'config';
import MiniWrapLayout from 'layout/MainLayout/mini-wrap-layout';
import {MainOrangeButton, TabItem, TabsComponent} from 'components/tab-component';
import { Portfolio, PortfolioEmptyItem } from 'models/portfolio';
import {useNavigate} from "react-router-dom";


interface PortfolioListInfoProps {
   portfolio: Portfolio;
   onRefresh: Function;
}

const SectionTabItems = [
    { title: 'All Portfolios', id: 'all'},
    { title: 'Latest',         id: 'latest'},
    { title: 'Crypto Only',    id: 'crypto'},
] as TabItem[];

const DatesTabItems = ['A', 'B', 'C', 'D', 'E'];

const PortfolioListInfo = (props: PortfolioListInfoProps) => {
  const portfolio = props.portfolio ? props.portfolio : PortfolioEmptyItem;
  const onRefresh = props.onRefresh;
  // const portfolioSelected = portfolio.id !== '';
  const portfolioSelected = false;

  const onChange = () => {};

  const navigate = useNavigate();
  const createNewPortfolio = () => {
    onRefresh({'mode': 'newPortfolio'});
    navigate('newPortfolio')
  };

  const resolutionWidth = RESOLUTION_SCREEN === 'horizontal' ? '100px' : '86px';

  const lineOne = <Stack sx={{width: '100%'}} direction="row" alignItems={'center'} >
      {/*<span style={{color: primaryTextColor, fontWeight: 600, fontSize:'14.5px', letterSpacing:'0.15em'}}>PORTFOLIO OVERVIEW</span>*/}
      {/*<TabsComponent width={'40%'} variant={'HMWY'} onChange={onChange} items={DatesTabItems}/>*/}
    { ! portfolioSelected
       ? <button className={'advancedBtn'}  onClick={createNewPortfolio}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <PlusOutlined style={{fontSize: '8px', color: mainBackgroundColor,marginRight:'4px'}}/>
              New Portfolio
            </Stack>
        </button>
      : <div style={{minWidth: '72px'}}></div>
    }
    </Stack>;

  // const lineTwo = <Stack direction='row' alignItems='center' gap={1}>
  //   <TabsComponent onChange={onChange} items={SectionTabItems}/>
  // </Stack>

  return <MiniWrapLayout lineOne={lineOne} />
};

export default PortfolioListInfo;
