import ReconnectingWebSocket from 'reconnecting-websocket';
import { extraLogging } from '../config';


export class WsConnector {
    url: string;
    connect_message: any | null = null;
    ws: ReconnectingWebSocket | null = null;
    ready = false;
    params: {} = { };

    constructor(url: string, params = null) {
        this.url = url;
        if (params) {
            this.params = params;
        }
    }

    on_close(e) {
        extraLogging && console.log('WS Closed')
    }

    init_receiver(handler) {
        if (!this.ready || !this.ws) {
            this.ws = this.reconnect()
        }
        this.ws.onclose = this.on_close;
        this.ws.onmessage = handler;
    }


    reconnect() {
        if (this.ws) {
            console.log("##################### DISCONNECT WS [" + this.url + "]")
            this.disconnect()
        }

        const self = this;
        this.ws = new ReconnectingWebSocket(self.url, undefined, this.params);
        this.ws.onopen = function() {
            self.ready = true;
            self.on_connect();
            extraLogging && console.log('WS opened: [' + self.url + ']');
        };
        return this.ws;
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
            this.ready = false
        }
        console.log("WS Disconnected: [" + this.url + "]")
    }

    on_connect() {
        if (this.connect_message) {
            this.send(this.connect_message);
             console.log("##### connect message sent: ", this.connect_message);
        }
    }
    send_event(data) {
        if (data) {
            if (!this.ready || !this.ws) {
                this.ws = this.reconnect()
            }
            this.ws.send(JSON.stringify(data));
            console.log("##### message sent: ", data);
        }
    }

    send (data) {
        this.send_event(data);
    }

}
