import React, { createContext, useEffect, useReducer } from 'react';
import Cookies from 'universal-cookie';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer, { restoreUser } from 'store/reducers/auth';

import Loader from 'components/Loader';
import { AuthProps, DjangoContextType } from 'types/auth';
import { doAjaxPostAsForm } from 'api';
import AppRoutes from '../routes/AppRoutes';
import { BASE_DJANGO_URL } from '../config';
import {useNavigate} from "react-router-dom";


const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: undefined
};``

const BASE_LOGIN_URL = `${BASE_DJANGO_URL}/login/api/`;

const DjangoContext = createContext<DjangoContextType | null>(null);

export const DjangoAuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [userExists, setUserExists] = React.useState(true);
  setInterval(() => {
    setUserExists(!!restoreUser());
  },2000);

  useEffect(() => {
    const init = async () => {
      try {
        const user = restoreUser()
        if (user) {
          const user = restoreUser()
          console.log('if user',user)
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: !!user,
              user
            }
          });
        } else {
          dispatch({ type: LOGOUT});
          navigate('login')
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: LOGOUT});
        navigate('login')
      }
    };

    init();
  }, [userExists]);

  const login = async (email: string, password: string) => {
    const response = await doAjaxPostAsForm(BASE_LOGIN_URL, {kind: 'login', username: email, password})
    const { kind, settings, user } = response.data;
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string, registerKey: string, company: string='') => {
    const response = await doAjaxPostAsForm(BASE_LOGIN_URL,
      {kind: 'register', username: email, password, firstName, lastName, registerKey, company});
    return response;
  };

  const logout = async () => {
    const response = await doAjaxPostAsForm(BASE_LOGIN_URL, {kind: 'logout'});
    console.log("LOG OUT ### response::", response);
    document.location.href = AppRoutes.NB_AFTER_LOGOUT;
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <DjangoContext.Provider value={{
    ...state,
    login,
    logout,
    register,
    resetPassword,
    updateProfile
  }}>{children}</DjangoContext.Provider>;
};

export default DjangoContext;
