import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from 'store'

import { COMPONENT_KEY_NAME } from "./dashboard.common";
import { AsyncApiStatus } from 'models/nb-models';

interface InitialState {
  loading: AsyncApiStatus;
  name?: string;
}

const initialState: InitialState = {
  loading: 'idle',
  name: ''
}

export const dashboardSlice = createSlice({
  name: COMPONENT_KEY_NAME,
  initialState,
  reducers: {
    setName(state, action) { state.name = action.payload },
  },
});

export const incrementAsync = name => dispatch => {
  setTimeout(() => {
    dispatch(dashboardSlice.actions.setName(name))
  }, 1000)
}


export const dashboardDefaultActions = dashboardSlice.actions;

export const selectName = (state: RootState) => state.dashboardDefault.name;

export default dashboardSlice.reducer;
