import { ReactNode, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, AppBarProps } from '@mui/material';

import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import IconButton from 'components/@extended/IconButton';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { buttonBackgroundColor, headerBackgroundColor } from 'config';
import { SquareButton } from 'components/controls/nb-buttons';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const headerContent = useMemo(() => <HeaderContent />, []);

  const mainHeader: ReactNode = (
    <Toolbar style={{ backgroundColor: headerBackgroundColor}}>
      <SquareButton open={open} onClick={handleDrawerToggle}>
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </SquareButton>
      {headerContent}
    </Toolbar>
  );

  const MainHeader = props => <Toolbar style={{ backgroundColor: headerBackgroundColor}}>
      {headerContent}
    </Toolbar>;

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    // position: 'absolute',
    color: 'inherit',
    elevation: 0,
    sx: {
      width:'100%',
      zIndex: 1200
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          <MainHeader/>
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>
           <MainHeader/>
        </AppBar>
      )}
    </>
  );
};

export default Header;
