import React, {useRef, useState, useMemo} from "react";
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import {Box} from '@mui/material';
import {getColorClass} from "../helper";

const getChartOptions = (labels) => {
    return {
        chart: {
            type: 'bar',
            stacked: true,
            stackType: '100%',
            marginBottom: '10px',
            height: '56px',
            toolbar: {
                show: false
            },
            animations: {
                enabled: false,
            },
            sparkline: {
                enabled: false,
            }
        },
        // colors: ['#FF8A53','#FFCB82', '#A5B585', '#CDBEB4','#67A4B4' ],
        colors: labels.map((item) => getColorClass(item)),
        dataLabels: {
            enabled: false,

        },
        states: {
            hover: {
                filter: 'none'
            },
            active:{
                filter: 'none'
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '300%',
                marginBottom:'10px',
            },
        },
        legend: {
            show:false,
        },
        tooltip:{
            enabled: false,
        },
        xaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },

        },
        yaxis: {
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
        },
        fill: {
            opacity: 1
        },
        grid: {
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: -10,
                right: 0,
                bottom: 8,
                left: 0
            },
        }
    }
}


const AssetsClassesGraph = (props) => {
    const {series, labels} = props;
    const [options, setOptions] = useState<ChartProps>(getChartOptions(labels));
    const seriesS =[{
            name:labels.map((item,idx) => item).slice(0,1),
            data:series.map((item,idx) => item).slice(0,1)
    },
    {
            name:labels.map(item => item).slice(1,2),
            data:series.map((item,idx) => item).slice(1,2)
    },
    {
            name:labels.map(item => item).slice(2,3),
            data:series.map((item,idx) => item).slice(2,3)
    },
    {
            name:labels.map(item => item).slice(3,4),
            data:series.map((item,idx) => item).slice(3,4)
    },
    {
            name:labels.map(item => item).slice(4,5),
            data:series.map((item,idx) => item).slice(4,5)
    },
    {
            name:labels.map(item => item).slice(5,6),
            data:series.map((item,idx) => item).slice(5,6)
    },
    ];


    return (
        <Box sx={{height:'56px'}}><ReactApexChart options={options} series={seriesS} type="bar" height={56} width={'100%'}/></Box>

    )

 };

export default AssetsClassesGraph;
