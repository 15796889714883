export const colorUp = "#52C41A";
export const colorDown = "#FF4D4F";
export const colorPartial = "#4682B4";
export const colorBlack = "#000";


export const colorTop = colorUp;
export const colorBottom = colorDown;
export const colorMiddle = "#812828";
export const textFillColor = "#FFF";
export const edgeFillColor = "#FFF";
export const interactiveEdgeFill = "#FFF";
export const interactiveBgFill = "#FFF";
export const ORG_gridLinesStroke = "#E2E4EC";
export const gridLinesStroke = "#002553";  // graph grid
export const bearPowerColor = colorUp;
export const bullPowerColor = colorDown;



export const colorUpOrg = "#26a69a";
export const colorDownOrg = "#ef5350";
export const xxx = "#1B7978";
export const zzz = "#0D394E";
export const www = "010A2B";




export const Black = "blue";  // axis
