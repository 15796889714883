import { createSlice, current } from '@reduxjs/toolkit';

import {
  CandleViewMode,
  COMPONENT_KEY_NAME,
  correlationTableSelector,
  correlationTableTimerange,
  defaultCorrelationGraph,
  PageViewMode,
  sectorIndexGraphSelector,
  sectorIndexGraphTimeranges,
  stressIndexGraphSelector,
  stressIndexGraphTimeranges,
  wordMarketGraphSelector, wordMarketGraphTimeranges
} from './monitor.common';
import { CandleResponse, EmptyCandleResponse } from 'models/nb-models';
import { isRejectedAction, isSucceededAction } from 'utils/refdux-utils';
import {
  loadCandles,
  loadCorrelationData,
  // loadCryptoSectorIndexes,
  loadEventNewsInfo,
  loadHeadlinesInfo,
  loadMarketCap,
  // loadSectorIndexes,
  loadAllSectorIndexes,
  loadStressIndexes,
  loadTradedInfo,
  loadUserTradedInfo,
  loadWatchList,
  toggleWatchListItem,
  loadAllStressIndexes, loadWorldMarketIndexes
} from './monitor.api';
import { asCandle, Candle } from 'models/candle';
import { Exchange, sortTradedBy, Traded, TradedValues, unpackValues } from 'models/asset';

import { uncompress } from 'snappyjs';
import { AssetSections } from 'components/assests/assets-info';
import { ToggleHolder } from 'utils/component-utils';
import { BasePageDefaultState, BasePageState } from 'components/pages/pages-common';
import { NewsEvent, Headline } from 'models/ndews-events';
import { timestampToString } from 'utils/date-utils';
import { jsonUnPack, randomString } from 'utils/misc';
import { AssetWatchListInfo } from 'models/user_info';
import { EtfAsset, MarketCapLine } from 'models/market_cap';
import { TRADED_AND_SOURCES } from 'data/traded';
import { WATCHLIST } from 'data/wathclist';
import { HEADLINES, EVENTS } from '../../../data/news';
import {
  CorrelationInputDataInterface, EMPTY_CASE_STR, GraphicCase, GraphicCaseImpl,
  SectorIndexInterface,
  StressIndexInterface,
  StressIndexValueInterface
} from '../../../models';

import { CORRELATION_DATA } from '../../../data/correlation';
import { CROSSASSET, CRYPTO, STRESSINDEX } from 'data/graphics';
import { applyDummyDataForMainpage } from 'config';
import { ItemSorter } from '../../../components/util-components/sorter';
import { resetUser } from '../../../store/reducers/auth';


// it doesn't work for current snappy :(
// @todo move / remove - waiting if somebody fix it
const unCompressJson = dataCompressed => {
  const data_bytes = uncompress(new Uint8Array(dataCompressed));
  const data_str = new TextDecoder().decode(data_bytes);
  const data = JSON.parse(data_str);
  return data;
}

  const itemSorters = {};

  const getItemSorter = (name) => {
    if (!itemSorters[name]) {
      itemSorters[name] = new ItemSorter();
    }
    return itemSorters[name];
  }


interface State extends BasePageState {
  exchanges: Exchange[];
  traded: Traded[];
  worldMarketData: any[],
  marketCap: MarketCapLine[];

  stressIndexesCurrentCaseStr: string;
  sectorIndexesCurrentCaseStr: string;
  worldMarketsCurrentCaseStr: string;
  correlationCurrentCaseStr: string;

  correlationData: any[],
  correlationDataWhole: {},
  etfAssets: EtfAsset[]
  watchList: AssetWatchListInfo[];
  newsEvents: NewsEvent[];
  headlines: Headline[];
  currentVisibleTraded: Traded[];
  currentTraded: Traded | null;
  candles: Candle[];
  candlesResponse: CandleResponse;
  candleView: CandleViewMode; // todo - remove
  pageView: PageViewMode;
  currentSectionId: string;
  currentSortField: string;
  // hideCookie: boolean;
}


export const worldMarkerGraphics = new GraphicCaseImpl(wordMarketGraphSelector, 'day', wordMarketGraphTimeranges);
export const sectorIndexesGraphics = new GraphicCaseImpl(sectorIndexGraphSelector, `${CRYPTO}:day`, sectorIndexGraphTimeranges);
export const stressIndexesGraphics = new GraphicCaseImpl(stressIndexGraphSelector, `${STRESSINDEX}:week`, stressIndexGraphTimeranges);
export const correlationTableGraphics = new GraphicCaseImpl(correlationTableSelector,`${CRYPTO}:30day`, correlationTableTimerange);

const initialState: State = {
  ...BasePageDefaultState,
  exchanges: [],
  candles: [],
  traded: [],
  worldMarketData: [],
  marketCap: [],

  stressIndexesCurrentCaseStr: EMPTY_CASE_STR,
  sectorIndexesCurrentCaseStr: EMPTY_CASE_STR,
  worldMarketsCurrentCaseStr: EMPTY_CASE_STR,
  correlationCurrentCaseStr:EMPTY_CASE_STR,
  correlationData: [],
  correlationDataWhole: {},
  etfAssets: [],
  watchList: [],
  newsEvents: [],
  headlines: [],
  currentVisibleTraded: [],
  currentTraded: null,
  candlesResponse: EmptyCandleResponse,
  candleView: 'table',
  pageView: 'candles',
  currentSectionId: AssetSections.crypto,
  currentSortField: '',
  // hideCookie: true
}

const performPendingRequest = (state: State, mode: string, meta?, pendingPerformer?) => {
  state[mode] = 'pending';
  return state;
}

const closeCandles = (state: State) => {
  state.candlesResponse = EmptyCandleResponse;
  state.traded = [];
  return state;
}

const applyCandles = (state: State, action) => {
  const { data, tradedId } = action.payload;

  state.candlesResponse = data;
  state.candles = data.candles.map(it => asCandle(it, data.keys));
  state.currentTraded = state.traded.find(it => it.id === tradedId) as Traded;
  state.inprogress = 'done';
  return state;
}

const applyMarketCap  = (state: State, action) => {
  // console.log('################# applyMarketCap data::', data)
  const { data } = action.payload;
  state.marketCap = data.data;
  state.etfAssets = data.assets;
  return state;
}

const applyUserTradedInfo = (state: State, action) => {
  return state
}

const applyTradedInfo = (state: State, action) => {
  // const payload = action.payload;
  // @ts-ignore
  // debugger
  const payload = applyDummyDataForMainpage() ? {...TRADED_AND_SOURCES} : action.payload;
  // @ts-ignore
  state.traded = sortTradedBy(unpackValues(payload.trades || []), 'price');
  // console.log("################# APPLYTRADEDINFO action.payload::", payload)

  // @ts-ignore
  state.exchanges = payload.sources;
  state.traded.forEach(it => {
    it.exchange = state.exchanges.find(exch => exch.id === it.exchange_id) as Exchange;
  });

  // state.traded.forEach(it => {
  //   it.base.name = it.base.name.replaceAll(' ', '');
  // });

  // state.traded.forEach(it => {
  //   ['H24', 'WEEK', 'MONTH', 'YEAR', 'YTD'].forEach(time_range => pct_change_calculate(time_range, it));
  // });
  //

  state.traded.forEach(it => {
    it._inWatchList = false;  // @todo from Django DB
  });

  const suppressed_names = [
    'S&P 500', 'NASDAQ 100', 'NYSE COMPOSITE', 'NYSE COMPOSITE ', 'Russell 2000'];

   // @ts-ignore
  state.traded = state.traded.map(item => {
    console.log('[' + item.name + ']', item)
    // return item;
     // @ts-ignore
    return suppressed_names.includes(item.name) ? null : item;
  }).filter(item => item !== null);

  // const currentVisibleTraded = state.traded;
  const currentVisibleTraded = state.traded.filter(it => it.kind === 'CRYPTO');
  state.currentTraded = currentVisibleTraded[0];

  state.currentVisibleTraded = currentVisibleTraded;
  injectWatchListInfo(state);

  return state;
}


const _inject = (traded: Traded[], watchList?: AssetWatchListInfo[]) => {
  if (traded && watchList) {
      traded.forEach(it => {
        const watchItem = watchList.find(wl => wl.sourceId === it.base.id);
        it._inWatchList = !!watchItem;
      });
  }
}

const injectWatchListInfo = (state: State) => {
  _inject(state.traded, state.watchList);
  _inject(state.currentVisibleTraded, state.watchList);
}

const applyWatchList = (state: State, action) => {
  // const watchList = action.payload.data;
  // @ts-ignore
  const watchList = applyDummyDataForMainpage() ? WATCHLIST.data : action.payload.data
  // @ts-ignore
  state.watchList = watchList as AssetWatchListInfo[];

  injectWatchListInfo(state);
  return state;
}

const applyWatchListToggle = (state: State, action) => {
  const watchListItem = action.payload.data as AssetWatchListInfo;

  const watchList = state.watchList || [];
  const item = watchList?.find(it => it.sourceId === watchListItem.sourceId);
  if (item && ! watchListItem?.selected !== undefined) {
    state.watchList = watchList.filter(it => it.sourceId !== watchListItem.sourceId);
  }
  else {
    state.watchList?.push(watchListItem)
  }
  injectWatchListInfo(state);
  return state;
}


const applyEventNewsInfo = (state: State, action) => {
  // const newsEvents = action.payload || [];
  // @ts-ignore
  const newsEvents = applyDummyDataForMainpage() ? EVENTS.data : action.payload || [];
  // @ts-ignore
  state.newsEvents = newsEvents.map(it => ({...it, params: jsonUnPack(it.params), date: timestampToString(it.date)}));
  return state;
}


const applyHeadlinesInfo = (state: State, action) => {
  // const headlines = action.payload || [];
  // const headlines = HEADLINES.data;
   // @ts-ignore
  const headlines = applyDummyDataForMainpage() ? HEADLINES.data : action.payload || [];
  // @ts-ignore
  state.headlines = headlines.map(it => ({...it, params: jsonUnPack(it.params), date: timestampToString(it.date)}));
  return state;
}

// const applyStressIndexes = (state: State, action) => {
//   // const payload = action.payload || [] as StressIndexInterface[];
//   // const payload = applyDummyDataForMainpage() ? STRESS_INDICES : action.payload || [] as StressIndexInterface[];
//   // state.stressIndexes = payload.map(it => ({STRESS_CRYPTO: it.index_value, date: it.data_marker}))
//   // return state;
// }

const applyAllStressIndexes = (state: State, action) => {
  const payload = action.payload || { data: [], range: ''};
  state.stressIndexesCurrentCaseStr = stressIndexesGraphics.getDefaultCaseStr();

  const updated = [] as any[];
  for (let i= 0; i < payload.data.length; i += 1) {
    updated.push(payload.data[i].map(it => ({STRESS_CRYPTO: it.index_value, date: it.data_marker})));
  }
  stressIndexesGraphics.setInitialCases(updated);
  return state;
}

const applyCorrelationData = (state: State, action) => {
  const payload = action.payload;
  const {crypto, cross} = payload;
  state.correlationDataWhole = payload;
  state.correlationData = crypto['30day'];
  state.correlationCurrentCaseStr = defaultCorrelationGraph;

  // @Anastasia - please implement 'cross'
  // old code below  - please remove it
  // state.correlationDataWhole = payload;
  // state.correlationData = payload['30day'];
  return state;
}

const applyAllSectorIndexes = (state: State, action) => {
  const payload = action.payload || { data: [], range: ''};
  // data should be an array of 6 lines for now. see 'const sectorIndexGraphSelector'
  // the first 3 lines - cross assets
  // the next 3 lanes - crypto (by sector)

  sectorIndexesGraphics.setInitialCases(payload.data);
  state.sectorIndexesCurrentCaseStr = sectorIndexesGraphics.getDefaultCaseStr();
  return state;
}

const applyWorldMarketIndexes = (state: State, action, mode: string) => {
  const payload = action.payload || { data: [], range: '' };
  state.worldMarketData = payload.data;
  worldMarkerGraphics.setInitialCases([payload.data]);
  state.worldMarketsCurrentCaseStr = worldMarkerGraphics.getDefaultCaseStr();
  return state;
}

const apiError = (state, action) => {
    state.apiStatus = 'failed';
    if (action.error && action.error.message && action.error.message.endsWith('401')) {
      // resetUser()
      // state.apiStatus = '401';
    }
    return state;
}


const performSuccessLoad = (state, successPerformer?, action?) => {
      state.loading = 'succeeded';
      return successPerformer ? successPerformer(state, action) : state;
}

const performSuccessRequest = (state, kind: string, successPerformer?, action?) => {
      state[kind] = 'succeeded';
      return successPerformer ? successPerformer(state, action) : state;
}

const performRejectRequest = (state, mode: string, rejectPerformer?) => {
      state[mode] = 'rejected';
      return rejectPerformer ? rejectPerformer(state) : state;
}

export const dashboardMonitorSlice = createSlice({
  name: COMPONENT_KEY_NAME,
  initialState,
  reducers: {
    // setHideCookie(state,action) {state.hideCookie = action.payload},
    reset(state) {
      state.candles = [];
      return state;
    },
    setValue(state, action) {
      const { name, value } = action.payload;
      state[name] = value;
      return state;
    },


    sortTradedBy(state, action) {
      const {field, sorter} = action.payload;
      const [currentSection, sortField] = field.split(':');
      // console.log('SORT currentSection::', currentSection, sortField)
      const internalSorter = getItemSorter(currentSection);
      const sortFunction = undefined
      // state.currentVisibleTraded = internalSorter.sortByField(state.currentVisibleTraded, sortField, sortFunction);
      state.currentVisibleTraded = sortField === 'values.rank' ? internalSorter. sortByFieldImpl(state.currentVisibleTraded, 'values.rank', false,sortFunction)
          :internalSorter.sortByField(state.currentVisibleTraded, sortField, sortFunction);
      state.currentSortField = internalSorter.sortedField();
      return state;
    },

    setSection(state, action) {
      /** Dispatches ALL section changes  */
      const {id, timeRange} = action.payload;
      // console.log('slice',id)
      const sectionId = id;

      if (sectionId === STRESSINDEX) {
        const xtab = {
          '1 week': 'week',
          '1 month': 'month1',
          '3 months': 'month3'
        }
        const actualTimeRange = xtab[timeRange];
        const index = stressIndexesGraphics.getIndex(sectionId, actualTimeRange);
        // console.log('################# SECTION ID:::sectionId[' + sectionId + "] timeRange[" + timeRange + "]  (" + actualTimeRange + "); index: [" + index + "]...", action.payload)
        state.stressIndexesCurrentCaseStr = `${sectionId}:${actualTimeRange}`;
        stressIndexesGraphics.selectCase(sectionId, actualTimeRange);
      }
     if ([CRYPTO, CROSSASSET].includes(sectionId)) {
        const index = sectorIndexesGraphics.getIndex(sectionId, timeRange);
        state.sectorIndexesCurrentCaseStr = `${sectionId}:${timeRange}`;
        sectorIndexesGraphics.selectCase(sectionId, timeRange);
     }
     const sorter = getItemSorter(sectionId);
     let actualSortedField = '';

      if (sectionId === AssetSections.correlation + 'crypto') {
        state.correlationCurrentCaseStr = `crypto:${timeRange}`;
        state.correlationData = state.correlationDataWhole['crypto'][timeRange]
      }
      if (sectionId === AssetSections.correlation + 'cross') {
        state.correlationCurrentCaseStr = `cross:${timeRange}`;
        state.correlationData = state.correlationDataWhole['cross'][timeRange]
     }

     if (sectionId === AssetSections.watchlist) {
       state.currentVisibleTraded = sorter.sortAgain(state.traded.filter(it => parseInt(it.exchange_id) === 1));
      }
      if (sectionId === AssetSections.commodity) {
        state.currentSectionId = sectionId;
        [state.currentVisibleTraded, actualSortedField] = sorter.sortAgainAndSortedField(state.traded.filter(it => it.kind === 'COMMODITY'));
      }
      if (sectionId === AssetSections.forex) {
        state.currentSectionId = sectionId;
        [state.currentVisibleTraded, actualSortedField] = sorter.sortAgainAndSortedField(state.traded.filter(it => it.kind === 'FOREX'));
      }
      if (sectionId === AssetSections.index) {
        state.currentSectionId = sectionId;
        [state.currentVisibleTraded, actualSortedField] = sorter.sortAgainAndSortedField(state.traded.filter(it => it.kind === 'INDEX'));
      }
      if (sectionId === AssetSections.crypto) {
        state.currentSectionId = sectionId;
        // @ts-ignore
        [state.currentVisibleTraded, actualSortedField] = sorter.sortAgainAndSortedField(state.traded.filter(it => it.kind === 'CRYPTO'));
      }

      if (actualSortedField) {
        state.currentSortField = actualSortedField
      }

      state.currentTraded = state.currentVisibleTraded[0];
      state.inprogress = 'inprogress';
      return state;
    },

    updateInstantValues(state, action) {
      const {mode, data} = action.payload;
      if (! mode || ! data) {
        return state;
      }

      if (['crypto:instant', 'forex:instant', 'index:instant', 'worldmarket:cumret:1', 'worldmarket:cumret:5'].includes(mode)) {
        if (['worldmarket:cumret:1', 'worldmarket:cumret:5'].includes(mode)) {
            state.worldMarketData = data;
            worldMarkerGraphics.setInitialCases([data]);
            state.worldMarketsCurrentCaseStr = `auto:${randomString()}`;
            // console.log("UPDATEINSTANTVALUES ####  mode::[" + mode + "] data", data);
            return state;
        }

        Object.keys(data).forEach(ticker => {
          const obj = data[ticker];
          const kind = obj['kind'];
          const [ source, target ] = ticker.split('-');

          const applyNewValueCrypto = (item, data) => {
            if (item) {
              if (true) {
                // if (item.values.price !== data.price) {
                item.values_old = item.values;
                item.values = data as TradedValues;
              }
            }
          }
          const applyNewValueForex = (item, data) => {
            if (item) {
              if (true) {
                // if (item.values.price !== data.price) {
                item.values_old = item.values;
                item.values = data as TradedValues;
              }
            }
          }
          const applyNewValueInstant = (item, data) => applyNewValueCrypto(item, data);

          if (kind === 'crypto') {
            applyNewValueCrypto(state.traded.find((it: Traded) => it.base.symbol === source && it.target.symbol === target),obj),
            applyNewValueCrypto(state.currentVisibleTraded.find((it: Traded) => it.base.symbol === source && it.target.symbol === target), obj)
          }
          if (kind === 'forex') {
            applyNewValueForex(state.traded.find((it: Traded) => it.base.symbol === source && it.target.symbol === target), obj)
            applyNewValueForex(state.currentVisibleTraded.find((it: Traded) => it.base.symbol === source && it.target.symbol === target), obj)
          }
          if (kind === 'index' || kind === 'commodity') {
            applyNewValueInstant(state.traded.find((it: Traded) => it.base.symbol === source), obj)
            applyNewValueInstant(state.currentVisibleTraded.find((it: Traded) => it.base.symbol === source), obj)
          }
        });
      }

      return state;
    }
  },
  extraReducers: (builder) => builder
    .addCase(loadMarketCap.fulfilled,  (state, action)   => performSuccessLoad(state, applyMarketCap, action))
    .addCase(loadCandles.fulfilled,    (state, action)   => performSuccessLoad(state, applyCandles, action))
    .addCase(loadTradedInfo.fulfilled,(state, action)     => performSuccessLoad(state, applyTradedInfo, action))
    .addCase(loadUserTradedInfo.fulfilled,(state, action) => performSuccessLoad(state, applyUserTradedInfo, action))
    .addCase(loadWatchList.fulfilled,(state, action)      => performSuccessLoad(state, applyWatchList, action))
    .addCase(toggleWatchListItem.fulfilled,(state, action)=> performSuccessLoad(state, applyWatchListToggle, action))
    .addCase(loadEventNewsInfo.fulfilled,(state, action)  => performSuccessLoad(state, applyEventNewsInfo, action))
    .addCase(loadHeadlinesInfo.fulfilled,(state, action)  => performSuccessLoad(state, applyHeadlinesInfo, action))
    .addCase(loadAllStressIndexes.fulfilled,(state, action)  => performSuccessLoad(state, applyAllStressIndexes, action))
    .addCase(loadCorrelationData.fulfilled,(state, action)  => performSuccessLoad(state, applyCorrelationData, action))
    .addCase(loadWorldMarketIndexes.fulfilled,(state, action)  => performSuccessLoad(state, applyWorldMarketIndexes, action))
    .addCase(loadAllSectorIndexes.fulfilled,(state, action)  => performSuccessLoad(state, applyAllSectorIndexes, action))
    .addMatcher(isRejectedAction,       (state, action) => apiError(state, action))
    .addMatcher(isSucceededAction, (state, action) => { state.apiStatus = 'succeeded'; return state; })
});

export const dashboardMonitorActions = dashboardMonitorSlice.actions;
export default dashboardMonitorSlice.reducer;
export const DASHBOARD_MONITOR_KEY_NAME = dashboardMonitorSlice.name;
