import React, { ReactElement } from 'react';
import { Box, Grid, Paper, Stack } from '@mui/material';

interface MiniWrapLayoutProps {
   lineOne?: ReactElement;
   lineTwo?: ReactElement;
}

const MiniWrapLayout = (props: MiniWrapLayoutProps) => {
  return <Stack style={{width: '100%'}} direction='column' alignItems='left' gap={1}>
      <Box sx={{ color: 'blue', width: '100%', fontSize: '110%' }}>
      <Stack direction='row' alignItems='center' gap={1}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {props.lineOne}
           </Grid>
        </Grid>
      </Stack>
    </Box>
    <Box sx={{ color: 'blue', width: '100%' }}>
      <Stack direction='row' alignItems='center' gap={1}>{props.lineTwo}</Stack>
    </Box>
    {/*<Box sx={{ color: 'blue', width: '100%' }}><hr/></Box>*/}
  </Stack>
};

export default MiniWrapLayout;
