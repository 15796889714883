import { headerBackgroundColor, buttonBackgroundColor } from 'config';
import { StarOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { AssetSections } from './assets-info';
import { dashboardMonitorActions } from 'pages/dashboard/monitor/monitor.slice';

interface EmptyWatchListProps {
}
export const EmptyWatchList = (props: EmptyWatchListProps)  => {
  const style = {
    backgroundColor: headerBackgroundColor,
    padding: '20px',
    color: 'white'
  }
  const dispatch = useDispatch();
  const mainView = () => {
    dispatch(dashboardMonitorActions.setSection(AssetSections.crypto));
  }

  return <div style={style}>
    <div>Watchlist is empty.</div>
    <div>&nbsp;</div>
    <div>Please select assets you are the most interesting in using
      &nbsp;<StarOutlined style={{color: buttonBackgroundColor}}/>&nbsp;
      icon in <a style={{color: buttonBackgroundColor}} onClick={mainView}>main view</a>
    </div>
  </div>
}
