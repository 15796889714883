import React, {useRef, useState} from 'react';
import { Stack } from '@mui/material';
import WidgetInfoPanel from 'components/controls/info-panel';
import {TabItem} from 'components/tab-component';
import {
    getTabsGraphsInfo,
    getDefaultGraphId, CLIENT_PORTFOLIO_INITIAL, MEAN_CLIENT_PORTFOLIO_INITIAL, DRAWDOWN_PERIODS_ID,
} from "../../../data/graphics";
import {drawdownPeriodsGraphics} from "../portfolio.slice";
import ApexRangeBar from "./apex-rangeBar";
import {RESOLUTION_SCREEN} from "../../../config";
import {as_timestamp} from "../../../utils/date-utils";

interface DrawdownPeriodsProps {
    renderData:any;
    currentResult:string;
    legendName:string;
    placeUse?:string;
}


const DrawdownPeriods =(props:DrawdownPeriodsProps) => {

    const renderData = drawdownPeriodsGraphics.getCurrentGraphic();
    const currentResult = props.currentResult;
    const plaseUse = props.placeUse;
    const panelId = DRAWDOWN_PERIODS_ID;
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = CLIENT_PORTFOLIO_INITIAL;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    };

    const actualData = renderData.map((item,idx) => item[`${currentResult}`] ? item[`${currentResult}`] : item.clientPortfolio);


    const topFive = (currentDate) => {
        const lastDateNotRecovered = actualData.map((item) => item.graph.result.find((el,idx) =>
            idx === (item.graph.result.length -1)))[0];
        const datesTopFive = actualData.map((item) => item.drawdown.map((el) => {
            if(el.drawdown !== 0) {
                const month = el[currentDate].includes('yet') ? new Intl.DateTimeFormat("en-US", { month: "short" }).format(new Date(lastDateNotRecovered.date)):new Intl.DateTimeFormat("en-US", { month: "short" }).format(new Date(el[currentDate]));
                const year =  el[currentDate].includes('yet') ? lastDateNotRecovered.date.slice(2, 4) : el[currentDate].slice(2, 4);
                const day =   el[currentDate].includes('yet') ? lastDateNotRecovered.date.slice(8, 10): el[currentDate].slice(8, 10);
                const xxx = el[currentDate].includes('yet') ?
                    new Intl.DateTimeFormat('en', { day:'2-digit',month: "short",year: 'numeric',timeZone:'UTC'}).format(new Date(lastDateNotRecovered.date))
                    :new Intl.DateTimeFormat('en', { day:'2-digit',month: "short",year: 'numeric',timeZone:'UTC'}).format(new Date(el[currentDate]));
                return xxx;
                // return day +' '+month +' '+ year;
            }
        }));
        return datesTopFive;
    };

    const categories = actualData.map((item) => item.graph.result.map((el) => {
        const year = el.date.slice(2, 4);
        const day = el.date.slice(8, 10);
        const month = new Intl.DateTimeFormat("en", { month: "short" }).format(new Date(el.date))
        const xxx = new Intl.DateTimeFormat('en', {day:'2-digit',month: "short",year: 'numeric',timeZone:'UTC'}).format(new Date(el.date));
            return xxx;
            // return day + ' '+ month + ' ' + year;
    }));


    const apexData = () => {
        const resultForApex = graphics().map((item,idx) => {
            return {name: plaseUse ? plaseUse : props.legendName, type: 'line',
                data:actualData.map((item,idx) => {
                    const convert = item.graph.result.map((el) => el.value !== undefined ? el.value : null );
                    return convert;
                })[0],
            }
        });
        return resultForApex;
    };

    const stroke = {
        show:true,
        curve: 'straight',
        width:1.5,
    };
    const resolutionGraphWidth = RESOLUTION_SCREEN === 'horizontal' ? '100%' : '100%';
    const resolutionGraphHeight = RESOLUTION_SCREEN === 'horizontal' ? 310 : 250;



    return <Stack style={{width: '100%'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={'TOP 5 DRAWDOWN PERIODS'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
        />

        {apexData().length !== 0 && <ApexRangeBar
            series={apexData()}
            graphics={graphics()}
            categories={categories[0]}
            width={resolutionGraphWidth}
            height={resolutionGraphHeight}
            topFiveStart={topFive(['drawdown_start'])[0]}
            topFiveEnd={topFive(['recovery_date'])[0]}
            stroke={stroke}
            type={'line'}
        />}
    </Stack>
}

export default DrawdownPeriods;
