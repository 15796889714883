import React, {useRef, useState} from 'react';
import { Stack } from '@mui/material';
import WidgetInfoPanel from 'components/controls/info-panel';
import {TabItem} from 'components/tab-component';
import {
    getTabsGraphsInfo,
    getDefaultGraphId, DRAWDOWN, UNDERWATER_PLOT_ID,
} from "../../../data/graphics";
import TrandingViewArea from "./tv-area";
import {underwaterGraphics} from "../portfolio.slice";
import {RESOLUTION_SCREEN} from "../../../config";
import {as_timestamp} from "../../../utils/date-utils";

interface UnderwaterProps{
    renderData:any;
    currentResult:string;
}


const UnderwaterPlot =(props: UnderwaterProps) => {
    const renderData = underwaterGraphics.getCurrentGraphic();
    const currentResult = props.currentResult;
    const data = renderData.map((item) => item[`${currentResult}`] ? item[`${currentResult}`].result.result : item.clientPortfolio.result.result);
    const panelId = UNDERWATER_PLOT_ID;
    const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
    const initialGraphicId = DRAWDOWN;
    const [currentGraphicSetId, setCurrentGraphicSetId] = useState(initialGraphicId);
    const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));
    const infoTabsOnChange = (item: TabItem) => {
        setCurrentGraphicSetId(item.id || '');
    }

    const graphSelectPanelOnChange = (item: TabItem) => {
        setCurrentGraphicId(item.id!);
    };

    const timeRangeSelectPanelOnChange = (item: TabItem) => {
        console.log("############ timeRangeSelectPanelOnChange TITLE::", item);
    };
    const actualdata = {};
    renderData.forEach((item,idx) => {
        const currentItem = item[`${currentResult}`] ? item[`${currentResult}`] : item.clientPortfolio;
        Object.entries(currentItem).forEach(([key],idx)=> {
                const transValue = currentItem[key].result.map((el) => {
                    return {time: as_timestamp(el.date) / 1000, value: el.value}
                });
                if(!actualdata[key]){
                    actualdata[key] = [...transValue];
                };
        });
    });

    const priceFormatter = (price) => {return (price * 100).toFixed(0) + '%'};
    const tooltipFormatter = (price) => {return (price * 100).toFixed(2) + '%'};
    const resolutionGraphWidth = RESOLUTION_SCREEN === 'horizontal' ? 690 : 400;


    return <Stack style={{width: '100%'}} direction="column" alignItems="top">
        <WidgetInfoPanel
            id={panelId}
            title={'UNDERWATER PLOT'}
            infoTabs={[]}
            graphics={graphics()}
            infoMarkers={[]}
            infoTabsOnChange={infoTabsOnChange}
            // advancedButton={<MainOrangeButton onClick={() => {}} title={'Advanced'}/>}
        />

        {Object.keys(actualdata).length !== 0 && <TrandingViewArea
            renderData={actualdata}
            graphics={graphics()}
            // width={resolutionGraphWidth}
            height={200}
            timeRange={'year'}
            priceFormatter={priceFormatter}
            tooltipForrmatter={tooltipFormatter}
            classId={'area'}
        />}

    </Stack>
}

export default UnderwaterPlot;
