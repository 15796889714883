import React from 'react';
import {Grid, Stack, Box, Typography} from '@mui/material';
import PortfolioInfo from './portfolios-info';
import PortfolioListTable from './portfolios-table';
import { Portfolio } from 'models/portfolio';
import { buttonBackgroundColor, extraLogging, primaryTextColor } from '../../../config';
import PortfolioUpdateInfo from "../portfolio-update/portfolio-update-info";
import ConfirmDialog from "../../dialogs/dialog";
import {goTo} from "../../../utils/react-utils";
import AppRoutes from "../../../routes/AppRoutes";


interface PortfolioListViewProps {
  portfolios: Portfolio[];
  currentPortfolio: Portfolio;
  selectFunction: Function;
  onRefresh: Function;
  onRemove: Function;
  holder: Record<string, any>;
  extraCall?: Function;
  currentSortField:string;
  sortByFunction:(field: string, obj) => void;
}

const DEFAULT_ASSETS_COLUMN_NUMBER = 26;

const PortfolioListView = (props: PortfolioListViewProps) => {
  const portfolios = props.portfolios;
  // const suffix = props.suffix ? props.suffix : '';
  const currentPortfolio = props.currentPortfolio;
  const selectFunction = props.selectFunction;
  const holder = props.holder;
  const onRefresh = props.onRefresh;
  const sortByFunction = props.sortByFunction;
  const currentSortField = props.currentSortField;
  const onRemove = props.onRemove;

  const actualSortFunction = (field_name, sorter) => {
    return sortByFunction(`${field_name}`,sorter)
  };

  return<Stack direction="column"  sx={{width: '100%'}} spacing={2}>
      <Stack direction={'row'} justifyContent={'flex-end'}  alignItems={'center'} width={'100%'} >
          <Box>
              <PortfolioInfo portfolio={currentPortfolio} onRefresh={onRefresh}/>
          </Box>
      </Stack>
      <PortfolioUpdateInfo portfolio={currentPortfolio}
                           holder={holder}
                           onRefresh={onRefresh}
                           title={'PORTFOLIO WORKSPACE'}

      />
      <PortfolioListTable
        portfolios={portfolios}
        selectFunction={selectFunction}
        holder={holder}
        onRefresh={onRefresh}
        onRemove={onRemove}
        sortByFunction={actualSortFunction}
        currentSortField={currentSortField}
        extraCall={props.extraCall}
      />
    </Stack>

};

export default PortfolioListView;
