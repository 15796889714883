import React, {ReactNode,useState} from 'react';
import {Stack,Box} from "@mui/material";
import {
    mainBackgroundColor,
    buttonBackgroundColor,
    secondTextColor,
    controlBackgroundColor,
    mainWarmShadeBgColor,
    secondaryWarmShadeBgColor,
    lightWarmShadeBgColor,
    primaryTextColor,
    lightColdShadeBgColor, secondaryColdShadeBgColor, mainColdShadeBgColor, RESOLUTION_SCREEN
} from 'config';
import {styled, width} from '@mui/system';
import {HMWY, MainOrangeButton, TabItem, TabsComponent} from "../tab-component";
import {RightOutlined} from "@ant-design/icons";
import WidgetInfoPanel from "../controls/info-panel";
import {
    CROSSASSET,
    CRYPTO,
} from 'data/graphics';
import GraphSelectPanel from "../controls/graph-selector";
import { AssetSections } from '../assests/assets-info';
import {correlationTableGraphics} from "../../pages/dashboard/monitor/monitor.slice";
import { defaultCorrelationGraph } from '../../pages/dashboard/monitor/monitor.common';
import { $$ } from '../../utils/utils';
import {current} from "@reduxjs/toolkit";




const TdStyleBody = styled ('td') ({
    border:'1px solid #000A2B',
    color:controlBackgroundColor,
    // width:'32px',
    height:'18px',
    // fontSize: '11px',
    lineHeight: '15px',
    textAlign:'center',
})

interface  TdBriketProps {
    row: number;
    colum: number;
    backgroundColor: string;
    children: ReactNode
}


export const TdBriket = (props: TdBriketProps) =>  {
    return (
        props.row > props.colum
                ? <TdStyleBody key={props.colum} style={{backgroundColor: props.backgroundColor}}
                               sx={{fontSize:{xs:'8px',md:'8px',lg:'11px'},
                                   width:{xs:'23px',md:'32px',lg:'32px'}
                               }}
                >
                    {props.children}
                </TdStyleBody>
                : <TdStyleBody>&nbsp;</TdStyleBody>
    )
}

const TdHeader = styled('td') ({
    color:secondTextColor,
    width:'32px',
    height:'18px',
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '15px',
    textAlign: 'center'
})

const GradienScale = styled('div') ({
    position: 'absolute',
    left: '15px',
    color: secondTextColor,
    // fontSize: '11px',
    paddingRight: '16px',
    lineHeight: '20px',
    marginLeft: '3px'
})
    const DatesTabItems = [
        { title: 'Crypto',      id: 'crypto'},
        { title: 'Cross Asset', id: 'cross'},
    ] as TabItem[];

const timeRange = [
    {title:'30 day', id:'30day'},
    {title:'60 day', id:'60day'},
    {title:'90 day', id:'90day'}
]


export interface TableCorrelationProps {
    data: any,
    timeRangeOnChange: Function;
    caseStr:string;
}
const TableCorrelation  = (props: TableCorrelationProps) => {
    const caseStr = props.caseStr || defaultCorrelationGraph;
    const [currentGraphicSetId, currentTimeRange] = correlationTableGraphics.parseCaseStr(caseStr);
    const [selectionId,setSelectionId] = useState(DatesTabItems[0]);
    const inputCorrelationData = props.data || {
       names: [],
       values: [[]],
    };
    const data = correlationTableGraphics.getCurrentGraphic();

    const timeRangeOnChange = props.timeRangeOnChange;
    const initialTimeRange = '30day';
    // console.log('################ CORRELATION selectionId::', currentGraphicSetId)
    // console.log('################ CORRELATION currentTimeRange::', currentTimeRange)
    // console.log('################ CORRELATION',data)

    const BgColor = item =>
         item >= 1 ? '#FF8A53'
        :item >= 0.9 ? '#FF9867'
        :item >= 0.8 ? '#FFA276'
        :item >= 0.7 ?'#FFAD87'
        :item >= 0.6 ? '#FFB998'
        :item >= 0.5 ? '#FFC3A7'
        :item >= 0.4 ? '#FFCFB9'
        :item >= 0.3 ? '#FFD9C7'
        :item >= 0.2 ? '#FFE6DA'
        :item >= 0.1 ? '#FFF3ED'
        :item >= 0 ? '#FFFFFF'
        :item === -1 ? '#032855'
        :item <= -0.9 ? '#28476E'
        :item <= -0.8 ? '#3D597C'
        :item <= -0.7 ? '#576F8E'
        :item <= -0.6 ? '#6F849E'
        :item <= -0.5 ? '#8799AE'
        :item <= -0.4 ? '#A3B1C1'
        :item <= -0.3 ? '#BAC4D0'
        :item <= -0.2 ? '#D2D8E1'
        :item <= -0.001 ? '#EAEDF1'
       :mainWarmShadeBgColor

    const onChange = (data) => {
        timeRangeOnChange({id: AssetSections.correlation + selectionId.id, timeRange: data.id.replace(' ', '')})
    };
    const infoTabsOnChange = (item: TabItem) => {
        const key = item.id || initialTimeRange;
        setSelectionId(item)
        timeRangeOnChange({id: AssetSections.correlation + key, timeRange: currentTimeRange})
    }

    const defaultDevMarkers = ['30 day', '60 day', '90 day'];

    if (! inputCorrelationData || ! inputCorrelationData.names || inputCorrelationData.names.length === 0) {
        return <></>;
    }

    const correlationData = {
       names:  ['', ... inputCorrelationData.names],
       values: [... inputCorrelationData.values],
    };

    const resolutionDirectionStack = RESOLUTION_SCREEN === 'horizontal' ? 'row' : 'column';

    const value_format = value =>  parseFloat(value).toFixed(2);
    const currentTime = currentTimeRange.slice(0,2);

    return(
        <>
            <Stack justifyContent={'center'} direction={'column'} sx={{width:'100%'}}>
                <WidgetInfoPanel
                    // id={panelId}
                    title={'CORRELATION'}
                    infoTabs={DatesTabItems}
                    // graphics={graphics()}
                    infoMarkers={[]}
                    infoTabsOnChange={infoTabsOnChange}
                    advancedButton={<MainOrangeButton onClick={() => {}} title={'Advanced'} disable={true}/>}
                    currentTabId={currentGraphicSetId}
                />
                <Stack justifyContent='flex-end' position={'relative'} width={'100%'} sx={{marginTop: '30px',marginBottom: '10px'}}>
                    <Box  position={'absolute'} right={'1px'} marginTop={'20px'}>
                        <TabsComponent width={'100%'} variant={HMWY} onChange={onChange} items={defaultDevMarkers} applyBorder={true} currentTabId={currentTime+ ' day'}/>
                    </Box>
                </Stack>
                <Stack direction={'column'} alignItems= 'center' justifyContent='left' spacing={{xs:1,md:4,lg:4}} >

                    <table style={{borderCollapse: 'collapse'}}>
                        <thead>
                            <tr key={'trheader'}>
                                {correlationData.names.map((item, idx) =>
                                    <TdHeader key={idx + 'head'}
                                              sx={{fontSize:{xs:'8px',md:'8px',lg:'11px'},
                                                  width:{xs:'23px',md:'32px',lg:'32px'}
                                              }}>{item}</TdHeader>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {correlationData.values.map((element, rowNumber) =>
                                <tr key={rowNumber + 'row'}>
                                    {element.map((item, columNumber) =>
                                        <React.Fragment key={columNumber + ':' + rowNumber}>
                                            {columNumber === 0 && <TdHeader key={columNumber + 'colm'}
                                                sx={{fontSize:{xs:'8px',md:'8px',lg:'11px'},
                                                    width:{xs:'23px',md:'32px',lg:'32px'}
                                                }}
                                            >
                                                {correlationData.names[rowNumber +1]}</TdHeader>}
                                            <TdBriket  row={rowNumber +1} colum={columNumber}
                                                       backgroundColor={BgColor(parseFloat(item))}
                                            >
                                                {value_format(item)}
                                            </TdBriket>
                                        </React.Fragment>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <div key={'horgradient'}
                           style={{width:'240px', height:'13px',position:'relative',
                               backgroundImage: 'linear-gradient(to right,#FF8A53,#FFFFFF,#002553)',
                               marginTop: '18px',
                           }}>
                        <GradienScale key={1+'gradient'} sx={{fontSize:{xs:'8px',md:'8px',lg:'11px'},left: '0px',top:'15px'}}> 1.0</GradienScale>
                        <GradienScale key={2+'gradient'} sx={{fontSize:{xs:'8px',md:'8px',lg:'11px',left: '55px',top:'15px'}}}> 0.5</GradienScale>
                        <GradienScale key={3+'gradient'} sx={{fontSize:{xs:'8px',md:'8px',lg:'11px'},left: '110px',top:'15px'}}> 0</GradienScale>
                        <GradienScale key={4+'gradient'} sx={{fontSize:{xs:'8px',md:'8px',lg:'11px'},left: '165px',top:'15px'}}> -0.5</GradienScale>
                        <GradienScale key={5+'gradient'} sx={{fontSize:{xs:'8px',md:'8px',lg:'11px'},left: '220px',top:'15px'}}> -1.0</GradienScale>
                    </div>
                </Stack>
            </Stack>
        </>
    )
}

export default TableCorrelation;
