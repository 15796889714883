import React, {ReactElement, useEffect, useState} from 'react';
import { Stack,Box } from '@mui/material';
import { timeFormat} from "d3-time-format";
import WidgetInfoPanel from 'components/controls/info-panel';
import {MainOrangeButton, TabItem} from 'components/tab-component';

import GraphSelectPanel from 'components/controls/graph-selector';
import {
  SECTOR_INDICES_PANEL_ID,
  CROSSASSET,
  CRYPTO,
  getTabsGraphsInfo,
  getGraphicColours,
  getGraphicIds, getDefaultGraphId
} from 'data/graphics';

import ValueGraph, { defaultValueGraphSettings } from 'components/graphs/value-graph';
import { sectorIndexesGraphics } from '../../../../pages/dashboard/monitor/monitor.slice';
import {height} from "@mui/system";
import {LineSeries, StraightLine} from "../../../../@react-financial-charts/series";
import {zipAsDict} from "../../../../utils/misc";
import TrandingViewLight from '../../../../pages/dashboard/monitor/tv-light';
import {RESOLUTION_SCREEN} from "../../../../config";


interface SectorIndexesProps {
   // renderData: any,
   caseStr: string,
   onRefresh?: Function;
   onChange:(item: TabItem) => void;
}

const infoTabs = [
    { title: 'Crypto',      id: CRYPTO},
    { title: 'Cross Asset', id: CROSSASSET},
] as TabItem[];



const initialTimeRange = 'day';
const SectorIndexesView = (props: SectorIndexesProps) => {
  const panelId = SECTOR_INDICES_PANEL_ID;

  const renderData  = sectorIndexesGraphics.getCurrentGraphic();
   const caseStr = props.caseStr || `${CROSSASSET}:day`;
  const [currentGraphicSetId, currentTimeRange] = sectorIndexesGraphics.parseCaseStr(caseStr);
  const timeRange = caseStr.split(':')[1] || 'day';
  const x_xticks = {
    'day': 8, 'week': 7, 'month': 10
  }[timeRange];
  const xticks = 8;

  if (!sectorIndexesGraphics.isInited()) {
    return <></>;
  }

  const graphics = () => getTabsGraphsInfo(panelId, currentGraphicSetId);
  // console.log("################# <" + panelId + "> caseStr[" + caseStr + "] currentGraphicSetId:", currentGraphicSetId, "  currentTimeRange:", currentTimeRange )
  const timeRangeIndex = sectorIndexesGraphics.getIndex(currentGraphicSetId, currentTimeRange);
  const [currentGraphicId, setCurrentGraphicId] = useState(getDefaultGraphId(panelId, currentGraphicSetId));

  const infoTabsOnChange = (item: TabItem,data) => {
    // const key = item.id || initialTimeRange;
    const key = item.id || currentGraphicSetId;
    // setCurrentGraphicSetId(key);
    props.onChange({id: key, timeRange: currentTimeRange} as TabItem)
  }

  const graphSelectPanelOnChange = (item: TabItem) => {
     setCurrentGraphicId(item.id || initialTimeRange);
  };

  const timeRangeSelectPanelOnChange = (item: TabItem) => {
    const possiblePrefix = '1 ';
    const id = (item.id && item.id.startsWith(possiblePrefix) ? item.id.substring(possiblePrefix.length) : item.id) || initialTimeRange;
    props.onChange({id: currentGraphicSetId, timeRange: id} as TabItem)
  };

  function getGraphicsInfo(panelId: string, currentGraphicsSet: string) {
    return undefined;
  }


  const priceFormatter = (price) => {return (price * 100).toFixed(0) + '%'};
  const tooltipFormatter = (price) => {return (price * 100).toFixed(2) + '%'};
  const resolutionGraphHeight = RESOLUTION_SCREEN === 'horizontal' ? '400px' : '300px';

  // console.log('DTAA', renderData)

  return <Stack style={{width: '100%',height: resolutionGraphHeight}} direction="column" alignItems="top" >
    <WidgetInfoPanel
      id={panelId}
      title={'SECTOR INDICES'}
      infoTabs={infoTabs}
      graphics={graphics()}
      infoMarkers={[]}
      infoTabsOnChange={infoTabsOnChange}
      advancedButton={<MainOrangeButton onClick={() => {}} title={'Advanced'} disable={true}/>}
      currentTabId={currentGraphicSetId}
    />
    <GraphSelectPanel
      graphicsOnChange={graphSelectPanelOnChange}
      timeRangeOnChange={timeRangeSelectPanelOnChange}
      // infoTabs={graphics()}
      infoTabs={[]}
      width={'100%'}
      currentTabId={'1 '+currentTimeRange}
    />

    <TrandingViewLight
        renderData={renderData}
        graphics={graphics()}
        // width={804}
        // height={275}
        timeRange={timeRange}
        infoTabs={currentGraphicSetId}
        priceFormatter={priceFormatter}
        tooltipForrmatter={tooltipFormatter}
    />
  </Stack>
};

export default SectorIndexesView;
